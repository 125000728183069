import { range } from "lodash-es";

import { rectangle } from "@/math/coordinates";
import type { BoardIteration } from "@/model/board";
import type { Rectangle, RelativeCoordinate } from "@/model/coordinates";
import { isCoordinate, relativeCoord } from "@/model/coordinates";
import type { Shape } from "@/model/shape";
import { fixLine } from "@/model/shape";
import { useSessionStore } from "@/store/session";
import { i18n } from "@/translations/i18n";

import type { BoardLocation } from "./BoardLocation";
import { borderLines } from "./BoardLocation";

export const OBJECTIVES_INDEX = -1;
export const RISKS_INDEX = -2;
export const EMPTY_INDEX = -3;

export class TeamBoardLocation implements BoardLocation {
  constructor(
    private iterations: BoardIteration[],
    private regionIndex: number,
  ) {}

  static of(
    iterations: BoardIteration[],
    value: RelativeCoordinate | number,
  ): TeamBoardLocation {
    return isCoordinate(value)
      ? TeamBoardLocation.fromCoordinate(iterations, value)
      : new TeamBoardLocation(iterations, value);
  }

  static fromCoordinate(
    iterations: BoardIteration[],
    coord: RelativeCoordinate,
  ): TeamBoardLocation {
    const left = Math.floor(coord.x * columns(iterations));
    if (coord.y < 0.5) {
      return new TeamBoardLocation(iterations, left);
    }
    switch (left) {
      case 0:
        return new TeamBoardLocation(iterations, OBJECTIVES_INDEX);
      case 1:
        return new TeamBoardLocation(iterations, RISKS_INDEX);
      default: {
        const iter = left + columns(iterations) - 2;
        return iter < iterations.length
          ? new TeamBoardLocation(iterations, iter)
          : new TeamBoardLocation(iterations, EMPTY_INDEX);
      }
    }
  }

  matches(coord: RelativeCoordinate) {
    return (
      TeamBoardLocation.fromCoordinate(this.iterations, coord).regionIndex ===
      this.regionIndex
    );
  }

  get iterationId(): number | null {
    return this.regionIndex < 0 ? null : this.regionIndex;
  }

  get isIteration() {
    return this.iterationId !== null;
  }

  get name() {
    switch (this.regionIndex) {
      case OBJECTIVES_INDEX:
        return i18n.global.t("general.objectives");
      case RISKS_INDEX:
        return i18n.global.t("teamBoardLocation.risks");
      case EMPTY_INDEX:
        return " ";
      default:
        return i18n.global.t("general.namedIteration", {
          name: useSessionStore().iterations[this.regionIndex].name,
        });
    }
  }

  get boardIteration(): BoardIteration | undefined {
    return this.iterations[this.regionIndex];
  }

  get bounds(): Rectangle<RelativeCoordinate> {
    return rectangle({
      from: this.topLeft,
      size: relativeCoord(1 / this.columns, 0.5),
    });
  }

  get shapes(): Shape[] {
    return [
      ...borderLines,
      fixLine("h", relativeCoord(0, 0.5), relativeCoord(1, 0.5)),
      ...range(1, this.columns).map((col) =>
        fixLine(
          "v" + col,
          relativeCoord(col / this.columns, 0),
          relativeCoord(col / this.columns, 1),
        ),
      ),
    ];
  }

  private get topLeft(): RelativeCoordinate {
    switch (this.regionIndex) {
      case OBJECTIVES_INDEX:
        return relativeCoord(0, 0.5);
      case RISKS_INDEX:
        return relativeCoord(1 / this.columns, 0.5);
      case EMPTY_INDEX:
        return relativeCoord(1 - 1 / this.columns, 0.5);
      default:
        return this.regionIndex < this.columns
          ? relativeCoord(this.regionIndex / this.columns, 0)
          : relativeCoord(
              (this.regionIndex - this.columns + 2) / this.columns,
              0.5,
            );
    }
  }

  get columns() {
    return columns(this.iterations);
  }

  index() {
    return [this.regionIndex];
  }

  isObjectives() {
    return this.regionIndex === OBJECTIVES_INDEX;
  }

  isRisks() {
    return this.regionIndex === RISKS_INDEX;
  }

  isEmpty() {
    return this.regionIndex === EMPTY_INDEX;
  }
}

function columns(iterations: unknown[]) {
  return Math.ceil(1 + iterations.length / 2);
}
