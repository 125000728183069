import type {
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosRequestTransformer,
  AxiosResponse,
} from "axios";
import axios, { AxiosError } from "axios";
import { isArray, noop } from "lodash-es";

import { NoSuccessError } from "@/backend/NoSuccessError";
import type { UserResponse } from "@/backend/serverModel";
import { CancelError } from "@/error/CancelError";
import { UnauthenticatedError } from "@/error/UnauthenticatedError";
import { captureMessage } from "@/error/sentry";
import type { LogoutReason } from "@/router/types";
import { useClientStore } from "@/store/client";
import { useConnectionStore } from "@/store/connection";
import * as Environment from "@/utils/env/Environment";
import { isCloud, loginTimeout } from "@/utils/env/Environment";

import type { LoginRequest, LoginResponse } from "./auth.service";

const defaultRetries = 1;

export type ExtendedRequestConfig = AxiosRequestConfig & {
  sentAt: string;
  retry: number;
  maxRetries: number;
  isGoogleReachable?: boolean;
};
let unauthorizedHandler = noop;
let refreshing: Promise<UserResponse> | null = null;

export function createApiClient(
  url: string,
  options?: Partial<AxiosRequestConfig>,
) {
  const client = axios.create({
    baseURL: url,
    withCredentials: true,
    timeout: 10000,
    transformRequest,
    ...options,
  });
  return enhanceRequestInterceptor(
    networkErrorInterceptor(authenticationInterceptor(client)),
  );
}

export function modernInterceptor(
  client: AxiosInstance,
  options: { defaultValue?: unknown } = {},
) {
  client.interceptors.response.use((res) => {
    if (res.data.status !== "success") {
      const contexts = {
        request: requestData(res.config as ExtendedRequestConfig),
        response: responseData(res),
      };

      if ("defaultValue" in options) {
        void captureMessage("Unsuccessful request", contexts);
        res.data = options.defaultValue;
        return res;
      }

      throw new NoSuccessError(res.config.url || "unknown", contexts);
    }
    res.data = res.data.data;
    return res;
  });
  return client;
}

function enhanceRequestInterceptor(client: AxiosInstance) {
  client.interceptors.request.use<ExtendedRequestConfig>((request) => {
    return {
      ...request,
      sentAt: new Date().toISOString(),
      retry: (request as any).retry || 0,
      maxRetries: (request as any).maxRetries || defaultRetries,
    };
  });
  return client;
}

function networkErrorInterceptor(client: AxiosInstance) {
  client.interceptors.response.use(
    (response) => response,
    async (fail) => {
      if (isNetworkError(fail)) {
        if (!useConnectionStore().netOnline) {
          throw new CancelError("Network problem: Browser is offline", fail);
        }
        let ok = true;
        if (fail.config.retry === 0 && isCloud) {
          // the browser thinks it's online, but we could not reach our server -> try to reach google.
          // if it works, the problem is on our server
          // if it does not work, the problem is on the client's network connection
          ok = fail.config.isGoogleReachable = await canLoadImage(
            "https://www.google.com/favicon.ico",
            2000,
          );
        }
        if (ok && fail.config.retry < fail.config.maxRetries) {
          fail.config.retry++;
          return client.request(fail.config);
        }
        throw new CancelError("Network problem", fail);
      }
      throw fail;
    },
  );
  return client;
}

function authenticationInterceptor(client: AxiosInstance) {
  client.interceptors.response.use(
    (response) => response,
    async (fail) => {
      if (fail.response?.status === 401) {
        const data = fail.response.data;
        switch (data?.error?.code) {
          case "UNAUTHENTICATED":
            throw new UnauthenticatedError({
              request: requestData(fail.config),
              response: fail.response?.data,
            });
          case "TOKEN_EXPIRED":
            // if we already are refreshing the token, don't refresh again,
            // but wait for the refresh to end before repeating the request
            if (!refreshing) {
              refreshing = refreshToken({
                correlationId: fail.config.headers["correlation-id"],
              });
            }
            try {
              await refreshing;
            } catch (err) {
              unauthorized(fail.response?.data);
            } finally {
              refreshing = null;
            }
            return await rawClient.request(fail.config);
        }
        return unauthorized(data);
      }
      throw fail;
    },
  );
  return client;
}

function requestData(config: ExtendedRequestConfig) {
  return {
    baseUrl: config.baseURL,
    url: config.url,
    headers: config.headers,
    sentAt: config.sentAt,
    retry: config.retry,
    maxRetries: config.maxRetries,
  };
}

function responseData(res: AxiosResponse) {
  return {
    code: res.data.status,
    message: res.data.error?.code || "unknown",
    details: res.data.data,
  };
}

/**
 * Use a non axios way to check if the network connection works.
 * @param url
 * @param timeout
 */
async function canLoadImage(url: string, timeout: number) {
  try {
    return !!(await loadImage(url, timeout));
  } catch (e) {
    return false;
  }
}

function loadImage(url: string, timeout: number) {
  return new Promise((resolve, reject) => {
    window.setTimeout(reject, timeout);
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (e) => reject(e);
    const random = Math.floor((1 + Math.random()) * 0x10000).toString(16);
    // add a random query parameter to avoid caching
    img.src = `${url}?no-cache=${random}`;
  });
}

function unauthorized(data?: any): never {
  // Calls against authserver (e.g. /auth/user, which is called when
  // loading the app initially to figure out if a user is already logged
  // in) can return with a redirectUrl which will lead the user to the
  // correct login-page to authenticate
  if (data?.redirectUrl) {
    window.location.assign(data.redirectUrl);
  } else {
    unauthorizedHandler();
  }
  throw new CancelError("Unauthorized");
}

function transformRequest(data: unknown, headers: AxiosRequestHeaders) {
  if (headers && !headers["correlation-id"]) {
    headers["correlation-id"] = useClientStore().newCorrelationId();
  }
  for (const transform of defaultTransformers()) {
    data = transform(data, headers);
  }
  return data;
}

function defaultTransformers(): AxiosRequestTransformer[] {
  const transformers = axios.defaults.transformRequest;
  if (isArray(transformers)) {
    return transformers;
  }
  if (transformers) {
    return [transformers];
  }
  return [];
}

const rawClient = enhanceRequestInterceptor(
  networkErrorInterceptor(
    axios.create({
      baseURL: Environment.authAPIUrl,
      withCredentials: true,
      timeout: 10000,
      transformRequest,
    }),
  ),
);

// login and loginPage can never return TOKEN_EXPIRED
export async function login(
  params: LoginRequest,
): Promise<AxiosResponse<LoginResponse>> {
  return await rawClient.get("/auth/login", { params, timeout: loginTimeout });
}

export function loginPage(params: LoginRequest): AxiosPromise<LoginResponse> {
  return rawClient.get("/auth/loginPage", { params });
}

// using createApiClient for refreshToken or logout could lead to endless loop
export async function refreshToken(config: {
  path?: string;
  correlationId?: string;
}): Promise<UserResponse> {
  const res = await rawClient.get(
    `auth/token/refresh?path=${encodeURIComponent(config.path || "")}`,
    { headers: { "correlation-id": config.correlationId || "" } },
  );
  return res.data;
}

export function logout(reason: LogoutReason) {
  return rawClient.get("/auth/logout", { params: { reason } });
}

export async function isAuthenticated(path?: string): Promise<UserResponse> {
  const res = await rawClient.get(
    `auth/user?path=${encodeURIComponent(path || "")}`,
  );
  return res.data;
}

function isNetworkError(err: AxiosError) {
  return (
    err?.code === AxiosError.ECONNABORTED ||
    err?.code === AxiosError.ERR_NETWORK
  );
}

export function setUnauthorizedHandler(handler: () => void) {
  unauthorizedHandler = handler;
}
