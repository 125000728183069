<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { createTimer } from "@/backend/Backend";
import SidePanelContent from "@/components/SidePanel/SidePanelContent.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { boardTypeName } from "@/model/baseTypes";
import type { ServerTime } from "@/model/timer";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useTimerStore } from "@/store/timer";
import { timerAdded } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import EditTimer from "./EditTimer.vue";

defineEmits<{ close: [] }>();

const { t } = useI18n();

const boardTimers = computed(() => useTimerStore().boardTimers);
const artTimers = computed(() => useTimerStore().artTimers);
const boardName = computed(() =>
  boardTypeName(useBoardStore().currentBoard().type),
);
const artName = computed(() =>
  t("general.namedArt", { name: useArtStore().current.name }),
);

function addBoardTimer() {
  trackEvent(timerAdded("board"));
  addTimer({ boardId: useBoardStore().currentBoard().id });
}

function addArtTimer() {
  trackEvent(timerAdded("art"));
  addTimer({ artId: useArtStore().current.id });
}

function addTimer(target: { boardId?: string; artId?: string }) {
  createTimer(
    {
      name: "",
      state: "stopped",
      duration: 0,
      end: 0 as ServerTime,
    },
    target,
  );
}
</script>

<template>
  <SidePanelContent
    v-focus-trap="{ setReturnFocus: '#facilitation-menu-trigger' }"
    v-autofocus
    :title="$t('timer.title')"
    :aria-label="$t('label.timerPanel')"
  >
    <div class="edit-timers">
      <div class="scope">{{ $t(boardName) }}</div>
      <EditTimer v-for="timer in boardTimers" :key="timer.id" :timer="timer" />
      <BaseButton
        v-if="boardTimers.length < 1"
        variant="ghost"
        color="primary"
        icon-before="base/plus"
        class="add-timer"
        :aria-label="$t('label.timerPanel.addTeamTimer')"
        @click="addBoardTimer"
      >
        {{ $t("timer.add") }}
      </BaseButton>

      <div class="scope">{{ artName }}</div>
      <EditTimer v-for="timer in artTimers" :key="timer.id" :timer="timer" />
      <BaseButton
        v-if="artTimers.length < 1"
        variant="ghost"
        color="primary"
        icon-before="base/plus"
        class="add-timer"
        :aria-label="$t('label.timerPanel.addArtTimer')"
        @click="addArtTimer"
      >
        {{ $t("timer.add") }}
      </BaseButton>
    </div>
  </SidePanelContent>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.edit-timers {
  margin-bottom: 2em;

  .scope {
    padding: 2em 2em 1em;
    font-weight: font.$weight-bold;
    color: colors-old.$text-primary-color;
    font-size: 14px;
  }

  .add-timer {
    margin-left: 16px;
  }
}
</style>
