import { applyActionParams } from "@/action/actions";
import { toggleActions } from "@/action/toggleActions";
import { relativeClientCoord } from "@/math/coordinate-systems";
import type { BoardType } from "@/model/baseTypes";
import type { RelativeCoordinate } from "@/model/coordinates";
import { useSessionStore } from "@/store/session";

import { BaseBoard } from "./BaseBoard";
import type { PlanningBoardLocation } from "./location/PlanningBoardLocation";

/* eslint-disable @typescript-eslint/class-literal-property-style */
export abstract class BasePlanningBoard<
  T extends BoardType,
> extends BaseBoard<T> {
  nonTeamGroup(name: string) {
    return {
      id: "",
      name,
      iterations: useSessionStore().iterations,
    };
  }

  get showRiskyLinks() {
    return true;
  }

  abstract location(
    pos: RelativeCoordinate | number,
    top?: number,
  ): PlanningBoardLocation;

  get contextActions() {
    return {
      board: {
        draw: this.drawAction(),
      },
      selection: {
        stickyMove: true,
        link: true,
      },
    };
  }

  positionalActions(c: RelativeCoordinate) {
    const loc = this.location(c);
    return {
      region: {
        name: loc.names().join(" "),
        overview: this.overviewAction(loc),
      },
    };
  }

  overview(e: MouseEvent) {
    this.overviewAction(this.location(relativeClientCoord(e)))?.("mouse");
  }

  overviewAction(
    loc: PlanningBoardLocation,
    setReturnFocus?: string, // Selector of element to be focused when overview is closed
  ) {
    return applyActionParams(toggleActions.showOverview, {
      boardId: this.board.id,
      location: loc.index(),
      load: loc.groupIteration?.load,
      velocity: loc.groupIteration?.velocity,
      setReturnFocus,
    });
  }
}
