<script lang="ts" setup>
import { ref, watch } from "vue";

import SearchInput from "@/components/SearchInput/SearchInput.vue";
import { i18n } from "@/translations/i18n";

interface Props {
  placeholder?: string;
  iconTitle?: string;
}

withDefaults(defineProps<Props>(), {
  placeholder: i18n.global.t("action.search"),
  iconTitle: i18n.global.t("action.search"),
});

const emit = defineEmits<{ search: [value: string] }>();

const searchValue = ref("");
watch(searchValue, (value: string) => emit("search", value));
</script>

<template>
  <SearchInput
    v-model="searchValue"
    icon-position="before"
    size="small"
    :placeholder="placeholder"
    :icon-title="iconTitle"
    @focusout.stop
  />
</template>
