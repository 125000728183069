import { boardActions } from "@/action/boardActions";
import { cardActions } from "@/action/cardActions";
import { navigationActions } from "@/action/navigationActions";
import { toggleActions } from "@/action/toggleActions";
import { key } from "@/components/shortcut/key";
import { registerGlobalShortcut } from "@/components/shortcut/shortcuts";
import { sendStickyNoteAction } from "@/composables/useEventBus";
import { useActivityStore } from "@/store/activity";
import { useBoardStore } from "@/store/board";
import { useDrawStore } from "@/store/draw";
import { useLinkStore } from "@/store/link";
import { useModalStore } from "@/store/modal";
import { usePointerStore } from "@/store/pointer";
import { useSelectionStore } from "@/store/selection";
import { useSwitchSessionStore } from "@/store/switchSession";
import { useTimerStore } from "@/store/timer";

export function registerGlobalActions() {
  registerGlobalShortcut(cardActions.openAssigneeDropdown, { prevent: false });
  registerGlobalShortcut(toggleActions.showStats, { prevent: false });
  registerGlobalShortcut(toggleActions.showStatGraphs, { prevent: false });
  registerGlobalShortcut(toggleActions.toggleBoardDiff, { prevent: false });
  registerGlobalShortcut(navigationActions.goBack);
  registerGlobalShortcut(
    {
      key: key("Escape"),
      exec: () => {
        if (useModalStore().isModalOpen()) {
          navigationActions.closeModal("keyboard-shortcut");
        } else if (useDrawStore().active) {
          useDrawStore().inactivate();
        } else if (useActivityStore().active) {
          useActivityStore().inactivate();
        } else if (useSwitchSessionStore().active) {
          useSwitchSessionStore().inactivate();
        } else if (useTimerStore().active) {
          useTimerStore().toggleTimer();
        } else {
          if (useBoardStore().selectedStickyIds.length > 0) {
            boardActions.clearCardSelection("keyboard-shortcut");
          } else {
            useSelectionStore().selecting = "no";
          }

          // Clear pinned sticky
          if (useLinkStore().isMarkingLinks) {
            useLinkStore().removeAllMarks();
          }
          const enlargedStickyId = useBoardStore().enlargedStickyNoteId;
          if (enlargedStickyId) {
            sendStickyNoteAction(enlargedStickyId, { action: "shrink" });
          }
        }
        usePointerStore().activatePointer(false);
      },
    },
    { prevent: false },
  );
}
