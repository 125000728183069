import { applyActionParams } from "@/action/actions";
import { boardActions } from "@/action/boardActions";
import { toggleActions } from "@/action/toggleActions";
import type { Action } from "@/action/types";
import { isAlmSync } from "@/backend/Backend";
import { relativeClientCoord } from "@/math/coordinate-systems";
import type { RelativeCoordinate } from "@/model/coordinates";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useConnectionStore } from "@/store/connection";
import { useStickyTypeStore } from "@/store/stickyType";
import { useUserStore } from "@/store/user";

import { BaseBoard } from "./BaseBoard";
import { TeamBoardLocation } from "./location/TeamBoardLocation";

/* eslint-disable @typescript-eslint/class-literal-property-style */
export class TeamBoard extends BaseBoard<"team"> {
  location(pos: RelativeCoordinate | number) {
    return TeamBoardLocation.of(this.board.iterations, pos);
  }

  positionalCardProperties(pos: RelativeCoordinate) {
    const loc = this.location(pos);
    return { iterationId: loc.iterationId, teamId: this.board.team.id };
  }

  get showPermanentLinks() {
    return useClientSettingsStore().permanentTeamLinks;
  }

  get showRiskyLinks() {
    return true;
  }

  get possibleStickyTypes() {
    return useStickyTypeStore().nonFlexBoardStickyTypes("team");
  }

  get contextActions() {
    return {
      board: { draw: this.drawAction() },
      selection: {
        stickyMove: true,
        link: true,
      },
    };
  }

  positionalActions(c: RelativeCoordinate) {
    const loc = this.location(c);
    if (!loc.isIteration && !loc.isRisks()) {
      return {};
    }
    return {
      region: {
        name: loc.name,
        arrange: this.arrangeAction(loc),
        overview: this.overviewAction(loc),
        sync: this.syncAction(loc),
        zoom: this.zoomAction(loc),
      },
    };
  }

  syncAction(loc: TeamBoardLocation): Action | undefined {
    if (
      useUserStore().isAllowed("edit") &&
      useConnectionStore().alm &&
      isAlmSync() &&
      !!loc.boardIteration &&
      loc.boardIteration.state.status !== "syncing"
    ) {
      return applyActionParams(
        boardActions.confirmSyncIteration,
        loc.iterationId,
        loc.boardIteration?.state.status,
      );
    }
  }

  overview(e: MouseEvent) {
    this.overviewAction(this.location(relativeClientCoord(e)))?.("mouse");
  }

  overviewAction(loc: TeamBoardLocation): Action | undefined {
    if (loc.isObjectives()) {
      return;
    }
    return applyActionParams(toggleActions.showOverview, {
      load: loc.boardIteration?.load,
      velocity: loc.boardIteration?.velocity,
      boardId: this.board.id,
      location: loc.index(),
    });
  }
}
