<script lang="ts" setup>
import MenuList from "@/components/ui/MenuList/MenuList.vue";
import MenuListHeader from "@/components/ui/MenuList/components/MenuListHeader.vue";
import MenuListItem from "@/components/ui/MenuList/components/MenuListItem.vue";
import MenuListItems from "@/components/ui/MenuList/components/MenuListItems.vue";
import MenuListTitle from "@/components/ui/MenuList/components/MenuListTitle.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import type { BoardData } from "@/model/board";

type TeamBoard = BoardData<"team">;
export type SelectEvent =
  | { type: "team-board"; board: TeamBoard }
  | { type: "risk-board" };

defineProps<{ boards: TeamBoard[] }>();

const emit = defineEmits<{ select: [SelectEvent] }>();
</script>

<template>
  <MenuList
    role="dialog"
    aria-labelledby="board-selector-title"
    @keydown.tab.stop
  >
    <MenuListHeader id="board-selector-title">
      <MenuListTitle>{{ $t("action.moveSticky") }}</MenuListTitle>
    </MenuListHeader>
    <MenuListItems aria-labelledby="board-selector-title">
      <MenuListItem @click="emit('select', { type: 'risk-board' })">
        <template #before>
          <SvgIcon name="board/team" width="20" height="20" />
        </template>
        {{ $t("board.risk") }}
      </MenuListItem>
      <MenuListItem
        v-for="board in boards"
        :key="board.id"
        @click="emit('select', { type: 'team-board', board })"
      >
        <template #before>
          <SvgIcon name="board/team" width="20" height="20" />
        </template>
        {{ $t("board.team.long", { team: board.team.name }) }}
      </MenuListItem>
    </MenuListItems>
  </MenuList>
</template>
