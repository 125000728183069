<script setup lang="ts">
import { computed } from "vue";

import type { Counter } from "@/model/Counter";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useStatisticsStore } from "@/store/statistics";

import AreaGraph from "./AreaGraph.vue";

const show = computed(() => useClientSettingsStore().showStats);
const statistics = computed(() => useStatisticsStore().statistics);
const current = computed(() => useStatisticsStore().current);

function counter(c: Counter) {
  return c.ok + "/" + c.fail;
}
</script>

<template>
  <div v-if="show > 0" id="statistics" data-no-screenshot>
    <div>
      <AreaGraph
        v-if="show > 1"
        :stats="statistics"
        properties="subscriptions"
      />
      <div data-t>Sub {{ current.subscriptions }}</div>
    </div>
    <div>
      <AreaGraph v-if="show > 1" :stats="statistics" properties="calls" />
      <div data-t>Call {{ counter(current.calls) }}</div>
    </div>
    <div>
      <AreaGraph v-if="show > 1" :stats="statistics" properties="sends" />
      <div data-t>Send {{ counter(current.sends) }}</div>
    </div>
    <div>
      <AreaGraph v-if="show > 1" :stats="statistics" properties="publishes" />
      <div data-t>Pub {{ counter(current.publishes) }}</div>
    </div>
    <div>
      <AreaGraph v-if="show > 1" :stats="statistics" properties="receives" />
      <div data-t>Recv {{ counter(current.receives) }}</div>
    </div>
    <div>
      <AreaGraph
        v-if="show > 1"
        :stats="statistics"
        properties="reconnects"
        diff
      />
      <div data-t>Reconnects {{ current.reconnects }}</div>
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/variables";
@use "@/styles/z-index";

#statistics {
  position: fixed;
  bottom: 1em;
  left: 70px;
  z-index: z-index.$statistics;
  font-size: 8px;
  filter: opacity(40%);
  pointer-events: none;

  div {
    display: inline-block;
    width: 10em;
    margin-right: 1em;
  }

  .graph {
    height: 7em;
  }
}
</style>
