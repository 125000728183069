<script setup lang="ts">
import { isFunction } from "lodash-es";
import { computed, onMounted, ref } from "vue";

import PieChart from "@/components/PieChart/PieChart.vue";
import type { StickyType } from "@/model/stickyType";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useSessionStore } from "@/store/session";
import { toCssColor } from "@/utils/color";

import MetricsModalBodyLayout from "./MetricsModalBodyLayout.vue";
import type { CapacityAllocation } from "./metrics";
import { calcCapacityAllocation } from "./metrics";

export interface CapacityAllocationBodyItem {
  name: string;
}

// Props to override internal vars/functions
// Used to mimic the old mixin behavior
interface Props {
  overrides?: {
    title?: string;
    items?: CapacityAllocationBodyItem[];
    iterData?: (iterationIndex?: number) => CapacityAllocation;
  };
}

const props = defineProps<Props>();

const width = ref(0);
const height = ref(0);
const iterSize = ref(0);
const teamSize = ref(0);
const selected = ref<StickyType | null>(null);
const hoverSelected = ref<StickyType | null>(null);

onMounted(() => {
  width.value = document.documentElement.clientWidth * 0.75;
  height.value = document.documentElement.clientHeight * 0.85;
  const pieWidth = width.value / 2 / Math.ceil(items.value.length / 2);
  const pieHeight = (height.value - 150) / 2 - 100;
  iterSize.value = Math.min(pieWidth, pieHeight);
  teamSize.value = width.value / 4;
});

const items = computed(
  () => props.overrides?.items || useSessionStore().iterations,
);

function load(itemIndex?: number): number {
  return iterData(itemIndex).total;
}

function left(i: number) {
  const line = Math.ceil(items.value.length / 2);
  return (100 * (i % line)) / line;
}

function top(i: number) {
  const line = Math.ceil(items.value.length / 2);
  return 50 * Math.floor(i / line);
}

function iterData(iterationIndex?: number): CapacityAllocation {
  if (isFunction(props.overrides?.iterData)) {
    return props.overrides?.iterData(iterationIndex);
  }
  return calcCapacityAllocation([board.value], iterationIndex);
}

const board = computed(() => useBoardsStore().boardByType("team"));

const stickyTypes = computed(() =>
  useBoardStore().possibleStickyTypes.filter(
    (t) => t.origin === "team" && t.functionality === "workitem",
  ),
);

function color(type: StickyType) {
  return toCssColor(type.color);
}

function select(type: StickyType | null, hover: boolean) {
  if (hover) {
    hoverSelected.value = type;
  } else {
    selected.value = type === selected.value ? null : type;
  }
}

const selectName = computed(
  () => (hoverSelected.value || selected.value)?.name,
);
</script>

<template>
  <div class="load-stat-modal-capacity-allocation-body">
    <MetricsModalBodyLayout>
      <template #left>
        <div class="container">
          <h3>
            {{ props.overrides?.title || $t("metricsModal.allIterations") }}
          </h3>
          <h4>
            {{
              $t("modal.loadValue", {
                number: parseFloat(load().toFixed(1)),
              })
            }}
          </h4>
          <PieChart
            :value="iterData().values"
            :total="load()"
            :select="selectName"
            :size="teamSize"
            hole
          />
          <div class="legend">
            <button
              v-for="stickyType in stickyTypes"
              :key="stickyType.id"
              class="item"
              :title="stickyType.name"
              :class="{
                selected: stickyType === selected,
                current: stickyType === hoverSelected,
              }"
              @click="select(stickyType, false)"
              @pointerenter="select(stickyType, true)"
              @pointerleave="select(null, true)"
            >
              <span
                class="color"
                :style="{ backgroundColor: color(stickyType) }"
              />
              <span>{{ stickyType.name }}</span>
            </button>
          </div>
        </div>
      </template>
      <template #right>
        <div
          v-for="(iter, i) in items"
          :key="iter.name"
          :style="{
            left: left(i) + '%',
            top: top(i) + '%',
            width: 200 / items.length + '%',
          }"
        >
          <h3>{{ iter.name }}</h3>
          <h4>
            {{
              $t("modal.loadValue", {
                number: parseFloat(load(i).toFixed(1)),
              })
            }}
          </h4>
          <div>
            <PieChart
              :value="iterData(i).values"
              :total="load(i)"
              :select="selectName"
              :size="iterSize"
              hole
            />
          </div>
        </div>
      </template>
    </MetricsModalBodyLayout>
  </div>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins/utils";

.load-stat-modal-capacity-allocation-body {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    justify-content: center;
    height: 100%;
    width: 80%;
  }

  .mode {
    cursor: pointer;
    position: absolute;
    top: 1em;
    right: 1em;
    background-color: colors-old.$menu-color;
    color: colors-old.$back-color;
    padding: 0.5em;
  }

  h2 {
    white-space: pre;
  }

  h3 {
    font-size: 20px;
    font-weight: font.$weight-normal;
    margin-bottom: 8px;
  }

  h4 {
    font-size: 28px;
    font-weight: font.$weight-extra-bold;
  }

  .legend {
    align-content: space-between;
    align-items: flex-start;
    display: flex;
    flex-flow: row wrap;
    max-width: 100%;

    .item {
      @include utils.ellipsis;

      padding: 0.5em;
      flex: 1 1 50%;
      outline: revert;
    }

    .color {
      display: inline-block;
      border: 1px solid colors-old.$menu-color;
      width: 0.8em;
      height: 0.8em;
      border-radius: 0.4em;
      margin-right: 1em;
    }
  }

  .right {
    margin: 2% 0;

    h4 {
      font-size: 20px;
    }
  }
}
</style>
