<script lang="ts" setup></script>

<template>
  <div class="menu-list-header">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/variables/colors";

.menu-list-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border-bottom: 1px solid colors.$border-subtle;
}
</style>
