import { type Ref, computed, ref, watch } from "vue";

import { useActionMenu } from "@/components/ActionMenu/useActionMenu";

export function useSelectionActionMenu(
  rect: Ref<HTMLElement | undefined>,
  attrs: Ref<unknown>,
) {
  const menu = useActionMenu({
    referenceEl: rect,
    open: ref(true),
    disabled: ref(false),
    config: computed(() => ({ placement: "top", offset: [0, 5] })),
  });

  watch(attrs, menu.updateMenu, { deep: true });

  return menu;
}
