import type { AxiosResponse } from "axios";

import type { Tenant } from "@/model/session";
import * as Environment from "@/utils/env/Environment";

import {
  createApiClient,
  isAuthenticated,
  login,
  loginPage,
  logout,
  refreshToken,
} from "./api.config";

const apiClient = createApiClient(Environment.authAPIUrl);

export default {
  async ping(): Promise<AxiosResponse> {
    return await apiClient.get("auth/user");
  },
  isAuthenticated,
  logout,
  login,
  loginPage,
  refreshToken,
};

export interface LoginRequest {
  email?: string;
  tenantId?: string;
  type?: string;
  path?: string;
  companyId?: string;
}

export interface LoginResponse {
  success: boolean;
  tenants?: Tenant[];
  redirectUrl?: string;
  error?: string;
}
