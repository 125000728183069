import type { Ref } from "vue";
import { onUnmounted, ref, watch } from "vue";
import type { TippyOptions } from "vue-tippy";
import { useTippy } from "vue-tippy";

export type TooltipProp = TippyOptions | TippyOptions["content"] | undefined;

type Props = Readonly<{
  el: Ref<HTMLElement | undefined>;
  tooltip: Ref<TooltipProp>;
  aria?: TippyOptions["aria"];
}>;

export function useTooltip({ el, tooltip, aria }: Props) {
  const tippyRef = ref<ReturnType<typeof useTippy>>();

  onUnmounted(() => {
    tippyRef.value?.destroy();
  });

  watch(
    [tooltip, el],
    ([newTooltip, newEl]) => {
      if (!tooltip.value) return;

      // note: even though typscript tells us that the value of
      // tippyRef.value.state is a 'Ref' this is not the case.

      const opts = getTippyOpts(newTooltip, aria);

      // To safely update a tooltip, we have to destroy the old one and create a new one
      // (just calling setProps on the old tooltip sometimes causes 2 tooltips
      // to be rendered, when the el it's attached to is updated at the same time)
      tippyRef.value?.destroy();

      if (newEl && newTooltip) {
        tippyRef.value = useTippy(el, opts);
      }
    },
    { immediate: true, deep: true },
  );

  return tippyRef.value;
}

const getTippyOpts = (tooltip: TooltipProp, aria?: TippyOptions["aria"]) => {
  const opts: TippyOptions = { delay: [500, 0], animation: false };
  if (typeof tooltip === "object" && "content" in tooltip) {
    Object.assign(opts, tooltip);
  } else {
    Object.assign(opts, { content: tooltip });
  }

  if (aria) {
    opts.aria = aria;
  }

  return opts;
};
