import MirrorModal from "@/components/modal/MirrorModal.vue";
import TeamModal from "@/components/modal/TeamModal.vue";
import StickyLinkModal from "@/components/modal/stickyLink/StickyLinkModal.vue";
import {
  alignCards,
  distributeCards,
  lineupCards,
} from "@/components/utils/layout";
import { fullUrl } from "@/router/navigation";
import { useBoardStore } from "@/store/board";
import { useClientSettingsStore } from "@/store/clientSettings";
import {
  moveCards,
  resetCardPositions,
  saveCardPositions,
} from "@/store/helpers/selection";
import { useSelectionStore } from "@/store/selection";
import { i18n } from "@/translations/i18n";

import { action, ctxAction, defineActions, previewAction } from "./actions";
import { navigationActions } from "./navigationActions";
import type { ActionContext } from "./types";

export const selectionActions = defineActions("selection", {
  selectOnClick: action(() => useSelectionStore().toggleSelectOnClick(), {
    name: /*$t*/ "action.selectStickies",
    icon: "selection/multi-select",
  }),
  alignHorizontal: previewAction(alignHorizontal, {
    name: /*$t*/ "action.alignHorizontal",
    icon: "selection/align-horizontal",
  }),
  alignVertical: previewAction(alignVertical, {
    name: /*$t*/ "action.alignVertical",
    icon: "selection/align-vertical",
  }),
  distributeHorizontal: previewAction(distributeHorizontal, {
    name: /*$t*/ "action.distributeHorizontal",
    icon: "selection/distribute-horizontal",
  }),
  distributeVertical: previewAction(distributeVertical, {
    name: /*$t*/ "action.distributeVertical",
    icon: "selection/distribute-vertical",
  }),
  lineup: previewAction(lineup, {
    name: /*$t*/ "action.lineup",
    icon: "selection/grid",
  }),
  link: ctxAction(link, {
    name: /*$t*/ "action.linkMultiple",
    icon: "action/link",
  }),
  mirror: ctxAction((ctx) => navigationActions.openModal(ctx, MirrorModal), {
    name: /*$t*/ "action.mirrorMultiple",
    icon: "action/mirror",
  }),
  setTeam: ctxAction((ctx) => navigationActions.openModal(ctx, TeamModal), {
    name: /*$t*/ "action.team",
    icon: "org/team",
  }),
  undo: action(() => useSelectionStore().undo(), { icon: "arrow/undo" }),
  share: action(
    () =>
      navigator.clipboard.writeText(
        fullUrl({ stickyId: useBoardStore().selectedStickyIds.join(",") }),
      ),
    { name: /*$t*/ "action.share", icon: "action/share" },
  ),
});

function alignHorizontal(ctx: ActionContext) {
  return useSelectionStore().execute(ctx.mode, {
    name: i18n.global.t(ctx.data.name),
    execute(mode) {
      const cards = useBoardStore().selectedStickies;
      moveCards(alignCards(cards, "horizontal"), mode);
    },
    saveState: saveCardPositions,
    resetToState: resetCardPositions,
  });
}

function alignVertical(ctx: ActionContext) {
  return useSelectionStore().execute(ctx.mode, {
    name: i18n.global.t(ctx.data.name),
    execute(mode) {
      const cards = useBoardStore().selectedStickies;
      moveCards(alignCards(cards, "vertical"), mode);
    },
    saveState: saveCardPositions,
    resetToState: resetCardPositions,
  });
}

function distributeHorizontal(ctx: ActionContext) {
  return useSelectionStore().execute(ctx.mode, {
    name: i18n.global.t(ctx.data.name),
    execute(mode) {
      const cards = useBoardStore().selectedStickies;
      moveCards(
        distributeCards(alignCards(cards, "horizontal"), "horizontal"),
        mode,
      );
    },
    saveState: saveCardPositions,
    resetToState: resetCardPositions,
  });
}

function distributeVertical(ctx: ActionContext) {
  return useSelectionStore().execute(ctx.mode, {
    name: i18n.global.t(ctx.data.name),
    execute(mode) {
      const cards = useBoardStore().selectedStickies;
      moveCards(
        distributeCards(alignCards(cards, "vertical"), "vertical"),
        mode,
      );
    },
    saveState: saveCardPositions,
    resetToState: resetCardPositions,
  });
}

function lineup(ctx: ActionContext) {
  return useSelectionStore().execute(ctx.mode, {
    name: i18n.global.t(ctx.data.name),
    execute(mode) {
      moveCards(
        lineupCards(
          useBoardStore().selectedStickies,
          useBoardStore().currentBoard().cardSize,
          0.01 * useClientSettingsStore().initialLineupDistance,
          (pos) => useBoardStore().boardLocation(pos).bounds,
        ),
        mode,
      );
    },
    saveState: saveCardPositions,
    resetToState: resetCardPositions,
  });
}

function link(ctx: ActionContext) {
  const cardIds = useBoardStore().selectedStickyIds;
  if (cardIds.length) {
    navigationActions.openModal(ctx, StickyLinkModal, {
      attrs: { cardIds },
    });
  }
}
