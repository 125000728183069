import type { StickyType } from "@/model/stickyType";

import { BaseBoard } from "./BaseBoard";

export class ProgramObjectivesBoard extends BaseBoard<"objectives"> {
  get possibleStickyTypes(): StickyType[] {
    return [];
  }

  contextActions = {};
}
