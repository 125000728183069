<script setup lang="ts">
import SelectableList from "@/components/ui/SelectableList/SelectableList.vue";
import TypeDot from "@/components/ui/TypeDot/TypeDot.vue";
import type { Color, StatusClass } from "@/model/baseTypes";

export interface StatusClassItem {
  id: StatusClass;
  name: string;
  color: Color | string;
}

const props = defineProps<{
  modelValue?: StatusClassItem | StatusClassItem[];
  statusClasses: StatusClassItem[];
}>();

const emit = defineEmits<{
  "update:model-value": [value: StatusClassItem | StatusClassItem[]];
}>();
</script>

<template>
  <SelectableList
    v-keyboard-nav
    :header="$t('general.statusClasses')"
    :model-value="props.modelValue"
    :values="props.statusClasses"
    @update:model-value="emit('update:model-value', $event)"
  >
    <template #before="{ value }"><TypeDot :color="value.color" /> </template>
    <template #default="{ value }">{{ $t(value.name) }} </template>
  </SelectableList>
</template>
