import { defineStore } from "pinia";

import type { Team } from "@/model/session";
import { i18n } from "@/translations/i18n";

import { useArtStore } from "./art";
import { useUserStore } from "./user";

export const NO_TEAM = { id: "", name: "" };

interface State {
  teams: Team[];
  current: Team;
}

export const useTeamStore = defineStore("team", {
  state: (): State => ({ teams: [], current: NO_TEAM }),
  getters: {
    findTeam(state) {
      return (team: { id?: string; name?: string }) => {
        if (team.id) {
          return state.teams.find((t) => t.id === team.id);
        }
        if (team.name) {
          return state.teams.find((t) => t.name === team.name);
        }
      };
    },
    teamsInArt(state) {
      return (artId: string): Team[] => {
        // REN-9638 backward compatibility fix
        if (artId === "") {
          return state.teams;
        }
        return state.teams.filter((team) => team.artId === artId);
      };
    },
    teamsInCurrentArt(state): Team[] {
      const artId = useArtStore().current.id;
      return artId ? this.teamsInArt(artId) : state.teams;
    },
    noTeam: () => ({ id: NO_TEAM.id, name: i18n.global.t("general.noTeam") }),
  },
});

export function userTeamsFirst() {
  const userTeams = useUserStore().technicalUser.teams;
  return (t1: Team, t2: Team) => {
    const t1InUserTeams = userTeams.some((team) => team.id === t1.id);
    const t2InUserTeams = userTeams.some((team) => team.id === t2.id);

    if (t1InUserTeams && !t2InUserTeams) return -1;
    if (!t1InUserTeams && t2InUserTeams) return 1;

    return 0;
  };
}
