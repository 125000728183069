<template>
  <div :id="board.id" class="planning-board board">
    <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
    <div class="backdrop" @dblclick="overviewGlobal">
      <LoadingIndicator v-if="!board.loaded" global />
      <LinkLayers :board="board" :color="linkColor" />

      <!-- eslint-disable-next-line vuejs-accessibility/no-redundant-roles -->
      <table role="table" class="backdrop">
        <!-- Column headers -->
        <thead>
          <tr class="iteration-header-row">
            <!-- Column 'In planning' -->
            <th
              scope="col"
              class="column clickable"
              :style="{
                width: fieldWidth / 2 + 'px',
                height: fieldHeight / 2 + 'px',
              }"
            >
              <BasePlanningBoardSection
                :aria-label="$t('programBoard.inPlanning')"
                :location="location(-2, -1)"
                :board="board"
                class="aria-wrapper"
                @overview="overview"
              >
                <div class="top-text in-planning">
                  <div class="fit-font">
                    <div class="title-col h3">
                      {{ $t("programBoard.inPlanning") }}
                    </div>
                  </div>
                </div>
              </BasePlanningBoardSection>
            </th>

            <!-- Empty column header (above the row headers) -->
            <th
              scope="col"
              style="position: absolute"
              :style="{
                left: fieldWidth / 2 + 'px',
                width: fieldWidth / 2 + 'px',
                height: fieldHeight / 2 + 'px',
              }"
            >
              <BasePlanningBoardSection
                :aria-label="$t('label.planningBoard.boardSection')"
                :location="location(-1, -1)"
                :board="board"
                class="aria-wrapper"
                @overview="overview"
              />
            </th>
            <!-- Iteration column headers -->
            <th
              v-for="(iter, iterIndex) in iterations"
              :key="`row-${iterIndex}`"
              scope="col"
              style="position: absolute"
              :style="{
                left: fieldWidth * (iterIndex + 1) + 'px',
                width: fieldWidth + 'px',
                height: fieldHeight / 2 + 'px',
              }"
            >
              <BasePlanningBoardSection
                :aria-label="iter.name"
                :location="location(iterations[iterIndex].id, -1)"
                :board="board"
                class="aria-wrapper"
                @overview="overview"
              >
                <div class="top-text">
                  <div class="fit-font">
                    <div class="h3">{{ iter.name }}</div>
                    <div class="h4">{{ dates(iter) }}</div>
                  </div>
                  <StatusDistribution
                    v-if="isExecutionMode"
                    :iteration="iter"
                    :data-testid="`iteration-status-distribution-${iterIndex}`"
                    class="iteration-distribution"
                    :value="getIterationStatusDistribution(iterIndex)"
                    source-item-type="iteration"
                  />
                </div>
              </BasePlanningBoardSection>
            </th>
          </tr>
        </thead>

        <!-- Rows -->
        <tbody>
          <tr
            v-for="(group, groupIndex) in groups"
            :key="groupKey('name', group, groupIndex)"
            class="field"
            :class="[fieldClass(group)]"
            :style="{
              top: fieldHeight * (groupIndex + 0.5) + 'px',
              left: fieldWidth * 0.5 + 'px',
              height: fieldHeight + 'px',
              width: fieldWidth / 2 + 'px',
            }"
          >
            <!--
Section under 'In Planning' (need 1 per row, because the cards are still sorted per group
and VO doesn't recognize cells that span multiple rows)
-->
            <td
              :style="{
                position: 'absolute',
                left: -fieldWidth / 2 + 'px',
                width: fieldWidth / 2 + 'px',
                height: ' 100%',
              }"
            >
              <BasePlanningBoardSection
                :aria-label="$t('label.planningBoard.boardSection')"
                :location="location(-2, groupIndex)"
                :board="board"
                class="aria-wrapper"
                @overview="overview"
              />
            </td>
            <!-- Row headers (team names) -->
            <th scope="row" class="group-name">
              <BasePlanningBoardSection
                :aria-label="group.name || $t('label.planningBoard.extraGroup')"
                :location="location(-1, groupIndex)"
                :board="board"
                class="aria-wrapper"
                @overview="overview"
              >
                <div class="fit-font">
                  <div
                    class="team-name"
                    :class="{ 'current-team': group.id === currentTeam.id }"
                  >
                    {{ group.name }}
                  </div>
                </div>
                <div v-if="groupIndex === 0" class="milestones">
                  <SvgIcon
                    v-if="groups.length < 15"
                    name="general/milestone"
                    alt=""
                  />
                </div>
                <StatusDistribution
                  v-else-if="group.id && isExecutionMode"
                  :data-testid="`group-status-distribution-${group.id}`"
                  class="iteration-distribution"
                  :value="getGroupStatusDistribution(group.id)"
                  :source-item-type="groupType"
                />
              </BasePlanningBoardSection>
            </th>
            <!-- Cells in the row, one per iteration -->
            <td
              v-for="(iter, iterIndex) in group.iterations"
              :key="iterIndex"
              class="field"
              :class="[fieldClass(group, iter)]"
              :style="{
                top: 0,
                left: fieldWidth * (iterIndex + 0.5) + 'px',
                height: fieldHeight + 'px',
                width: fieldWidth + 'px',
              }"
            >
              <BasePlanningBoardSection
                :aria-label="$t('label.planningBoard.boardSection')"
                :location="location(iterations[iterIndex].id, groupIndex)"
                :board="board"
                class="aria-wrapper"
                @overview="overview"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Backdrop (presentation, not content) -->
      <div
        class="backdrop"
        :style="{ fontSize: fixedFontSize + '%' }"
        style="pointer-events: none"
      >
        <div
          v-if="isExecutionMode"
          class="time-line"
          :style="{
            width: iterationNow.iterationsPassed * fieldWidth + 'px',
            left: fieldWidth + 'px',
            top: fieldHeight * 0.5 + 'px',
          }"
        >
          <svg>
            <line x1="0" y1="0" x2="0" y2="100%" />
          </svg>
        </div>
        <div
          v-for="(group, groupIndex) in groups"
          :key="groupKey('iter', group, groupIndex)"
          class="field horizontal-line"
          :style="{
            lineHeight: fieldHeight + 'px',
            top: fieldHeight * (groupIndex + 0.5) + 'px',
            left: fieldWidth * 0.5 + 'px',
            height: fieldHeight + 'px',
          }"
        >
          <div
            v-for="(_, iterIndex) in zoomedInIterations"
            :key="iterIndex"
            class="legend group-legend"
            :style="{
              left: (iterIndex + 0.5) * fieldWidth - fieldHeight / 2 + 'px',
              width: fieldHeight + 'px',
            }"
          >
            <div>{{ group.name }}&nbsp;</div>
          </div>
        </div>
        <div
          v-for="(iter, iterIndex) in iterations"
          :key="iterIndex"
          class="column"
          :style="{
            left: fieldWidth * (iterIndex + 1) + 'px',
            width: fieldWidth + 'px',
          }"
        >
          <div
            v-for="groupIndex in zoomedInGroups"
            :key="groupIndex"
            class="legend iter-legend"
            :style="{ top: (groupIndex - 0.5) * fieldHeight + 'px' }"
          >
            <div>{{ iter.name }}</div>
          </div>
        </div>
      </div>

      <!-- Iteration columns -->
      <div
        v-for="(_, iterIndex) in iterations"
        :key="`row1-${iterIndex}`"
        class="column"
        :style="{
          left: fieldWidth * (iterIndex + 1) + 'px',
          width: fieldWidth + 'px',
        }"
      ></div>

      <!-- Cards -->
      <StickyNote
        v-for="card in board.cards"
        :key="card.data.id"
        :card="card.data"
        :card-meta="card.meta"
        :level-of-details="levelOfDetails"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { clamp } from "lodash-es";
import { Options as Component, mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import LinkLayers from "@/components/LinkLayer/LinkLayers.vue";
import {
  iterationStatusDistribution,
  teamStatusDistribution,
} from "@/components/StatusDistribution/StatusDistribution";
import StatusDistribution from "@/components/StatusDistribution/StatusDistribution.vue";
import StickyNote from "@/components/StickyNote/StickyNote.vue";
import type { ActionType } from "@/components/card/actions";
import { loadState } from "@/components/utils/capacity";
import { relativeClientCoord } from "@/math/coordinate-systems";
import type { InfoLevel } from "@/model/baseTypes";
import type { Board, GroupIteration } from "@/model/board";
import type { Card } from "@/model/card";
import { normalLinkColors } from "@/model/colors";
import type { RelativeCoordinate } from "@/model/coordinates";
import type { Group, Iteration } from "@/model/session";
import { isRisk } from "@/model/stickyType";
import type { ActionSource } from "@/model/trigger";
import { useBoardStore } from "@/store/board";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useSessionStore } from "@/store/session";
import { useTeamStore } from "@/store/team";
import { useWorkModeStore } from "@/store/workMode";
import { plusDays } from "@/utils/date";
import { formatShortDate } from "@/utils/dateFormat";

import LoadingIndicator from "../ui/LoadingIndicator/LoadingIndicator.vue";
import type { BasePlanningBoard as BasePlanningBoardLogic } from "./BasePlanningBoard";
import BasePlanningBoardSection from "./BasePlanningBoardSection.vue";
import FluidBoard from "./FluidBoard";
import type {
  GroupIterations,
  PlanningBoardLocation,
} from "./location/PlanningBoardLocation";

@Component({
  components: {
    LinkLayers,
    LoadingIndicator,
    StatusDistribution,
    StickyNote,
    BasePlanningBoardSection,
  },
})
export default class BasePlanningBoard extends mixins(FluidBoard) {
  @Prop(Object) readonly planningBoard!: Board;
  @Prop(Array) readonly cardsForDistribution!: Card[];

  legendZoomLimit = 2;
  linkColor = normalLinkColors.program;

  actions: ActionType[] = [
    "delete",
    "close",
    "almSource",
    "mirror",
    "link",
    "dragLink",
    "priority",
  ];
  riskActions: ActionType[] = [...this.actions, "risk"];
  defaultActions: ActionType[] = [...this.actions, "points"];

  activated() {
    setTimeout(() => {
      this.recalcLegendFontSizes();
      this.recalcHeadingFontSizes();
    }, 10);
  }

  get board() {
    return this.planningBoard;
  }

  get groupType() {
    return this.board?.type === "solution" ? "art" : "team";
  }

  get groups() {
    return useBoardStore().baseBoard.groups;
  }

  fieldClass(group: GroupIterations, iter?: GroupIteration) {
    const teamColor = group.current ? "group-current" : "";
    if (!iter || !useClientSettingsStore().overload) {
      return teamColor;
    }

    const state = loadState(iter.load!, iter.velocity!);

    const customStates: Record<InfoLevel, string> = {
      default: teamColor,
      ok: teamColor,
      warn: "warn",
      error: "overload",
    };

    return customStates[state];
  }

  location(c: RelativeCoordinate | number, top?: number) {
    return useBoardStore().boardLocation(c, top) as PlanningBoardLocation;
  }

  eventLocation(e: MouseEvent) {
    return this.location(relativeClientCoord(e));
  }

  get cards(): Card[] {
    return Object.values(this.board.cards).map((boardCard) => boardCard.data);
  }

  get iterationNow() {
    return useSessionStore().iterationProgress(new Date());
  }

  get iterations() {
    return useSessionStore().iterations;
  }

  @Watch("zoomFactor", { immediate: true })
  zoomChanged(zoom: number, old: number) {
    if (this.active) {
      setTimeout(() => {
        if (old <= this.legendZoomLimit) {
          this.recalcLegendFontSizes();
        }
      }, 10);
    }
  }

  @Watch("planningBoard")
  planningBoardChange() {
    setTimeout(() => {
      this.recalcLegendFontSizes();
      this.recalcHeadingFontSizes();
    }, 10);
  }

  recalcLegendFontSizes() {
    if (this.zoomFactor > this.legendZoomLimit) {
      this.recalcFontSize({
        selector: ".planning-board .iter-legend",
        minSize: 30,
        maxSize: 80,
      });
      this.recalcFontSize({
        selector: ".planning-board .group-legend",
        minSize: 30,
        maxSize: 80,
      });
    }
  }

  recalcHeadingFontSizes() {
    const idealWidth = 0.8; // Ideally, font should be adjusted so title width is 80% of the container
    this.recalcFontSize({
      selector: ".planning-board .group-name .fit-font",
      minSize: 30,
      maxSize: 100,
      idealWidth,
    });
    this.recalcFontSize({
      selector: ".planning-board div.top-text .fit-font",
      minSize: 30,
      maxSize: 100,
      idealWidth,
    });
  }

  get zoomedIn() {
    return this.active && this.zoomFactor > this.legendZoomLimit;
  }

  get zoomedInIterations() {
    return this.zoomedIn ? this.iterations : [];
  }

  get zoomedInGroups() {
    return this.zoomedIn ? this.groups.length + 1 : 0;
  }

  get isExecutionMode() {
    return useWorkModeStore().isExecutionMode;
  }

  getIterationStatusDistribution(index: number) {
    return iterationStatusDistribution(this.cardsForDistribution, index);
  }

  getGroupStatusDistribution(teamId: string) {
    // TODO needs some renaming/refactoring when it's also supported for solution board
    // see https://rentouch.atlassian.net/browse/REN-11477
    return teamStatusDistribution(this.cardsForDistribution, teamId);
  }

  recalcFontSize({
    selector,
    minSize,
    maxSize,
    idealWidth = 1,
  }: {
    selector: string;
    minSize: number;
    maxSize: number;
    idealWidth?: number;
  }) {
    const els = document.querySelectorAll<HTMLElement>(selector);

    if (els.length > 0) {
      let minQ = 1000;
      els.forEach((el) => {
        el.style.fontSize = "100%";
        el.childNodes.forEach((n) => {
          if (
            n.nodeType !== 8 /* Node.COMMENT_NODE */ &&
            n.nodeName !== "SVG"
          ) {
            const e = n as HTMLElement;
            minQ = Math.min(minQ, idealWidth * (e.offsetWidth / e.scrollWidth));
          }
        });
      });
      const size = clamp(100 * minQ, minSize, maxSize);
      els.forEach((el) => {
        el.classList.toggle("min-size", size === minSize);
        el.style.fontSize = size + "%";
      });
    }
  }

  cardActions(card: Card): ActionType[] {
    return isRisk(card) ? this.riskActions : this.defaultActions;
  }

  get currentTeam() {
    return useTeamStore().current;
  }

  get baseBoard() {
    return useBoardStore().baseBoard as BasePlanningBoardLogic<any>;
  }

  overviewGlobal(e: MouseEvent) {
    this.baseBoard.overview(e);
  }

  overview(
    loc: PlanningBoardLocation,
    source: ActionSource = "mouse",
    setReturnFocus?: string, // Selector of element to be focused when overview is closed
  ) {
    this.baseBoard.overviewAction(loc, setReturnFocus)?.(source);
  }

  get fieldWidth() {
    return this.width / (useSessionStore().iterations.length + 1);
  }

  get fieldHeight() {
    return this.height / (this.groups.length + 0.5);
  }

  groupKey(prefix: string, group: Group, index: number) {
    return (
      "group-" + prefix + (group.id ? "-id-" + group.id : "-index-" + index)
    );
  }

  dates(iter: Iteration) {
    return this.$t("date.range", {
      from: formatShortDate(iter.start),
      to: formatShortDate(plusDays(iter.end, -1)),
    });
  }
}
</script>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/board";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/time-line" as *;
@use "@/styles/z-index";
@use "@/styles/mixins/a11y";

$header-size: board.len(14px);

.planning-board {
  @include a11y.board;

  .team-name {
    font-weight: font.$weight-medium;
    font-size: $header-size;
    margin: 0;
    overflow: hidden;

    &.current-team {
      font-weight: font.$weight-extra-bold;
    }
  }

  .aria-wrapper {
    @include a11y.board-section;

    position: relative;
    height: 100%;
    width: 100%;

    .inner-wrapper {
      padding: 8%;

      .wrapper {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .field,
  .group-name {
    .grid-cell {
      @include a11y.board-section;

      position: relative;
      height: 100%;
      width: 100%;
    }
  }

  .field {
    &.overload {
      background-color: colors-old.$error-back-color;
    }

    &.warn {
      background-color: colors-old.$warn-back-color;
    }
  }

  .column {
    position: absolute;
    height: 100%;

    &:not(.clickable) {
      pointer-events: none;
    }
  }

  .horizontal-line {
    width: 100%;
    height: 100%;
  }

  .time-line {
    margin-top: board.len(-3px);
    margin-left: board.len(3px);
  }

  .milestones {
    display: inline-flex;
    align-items: flex-end;
    flex-grow: 1;

    svg {
      height: board.len(20px);
      width: board.len(20px);
      color: colors.$icon-subtle;
    }
  }

  .top-text {
    position: absolute;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    inset: 8% calc(6% / 2);

    & > div.fit-font {
      display: flex;
      overflow: hidden;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      gap: board.len(14px);

      .h3 {
        font-weight: font.$weight-bold;
        font-size: $header-size;
      }

      .h4 {
        font-weight: font.$weight-normal;
        color: colors-old.$text-secondary-color;
      }
    }
  }

  .group-current {
    background-color: colors-old.$primary-back-color;
  }

  .group-name {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .fit-font {
      margin-bottom: board.len(10px);

      &.min-size {
        white-space: normal;
        word-break: break-word;
      }
    }
  }

  .in-planning {
    inset: unset;

    .title-col.h3 {
      margin-bottom: board.len(25px) !important;
      font-size: $header-size;
    }
  }

  .legend {
    position: absolute;
    text-align: center;
    z-index: z-index.$board;

    div {
      display: inline-block;
      height: board.len(60px);
      line-height: board.len(60px);
      padding: 0 board.len(15px);
      border-radius: board.len(15px);
      max-width: 80%;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .iter-legend {
    left: 0;
    margin-top: board.len(-36px);
    width: 100%;
    font-size: 20%;

    div {
      background-color: colors-old.$divider-color;
    }
  }

  .group-legend {
    top: 0;
    transform: rotate(-90deg);
    margin-left: board.len(21px);
    font-size: 20%;

    div {
      background-color: colors-old.$divider-color;
    }
  }

  .iteration-distribution {
    height: board.len(8px);
    width: 100%;

    .stacked-bar-chart {
      .empty-bar rect {
        fill: colors-old.$progress-empty-fill-color;
      }

      .bordered {
        border-width: board.len(1px);
      }
    }
  }
}
</style>
