<template>
  <div>
    <ConnectionStatus />
    <SkipButton @click="boardComponent?.focus">
      {{ $t("label.skipToBoard") }}
    </SkipButton>
    <ToastMessage v-bind="toast" @close="closeToast" />
    <MenuContainer />
    <MousePointers />
    <div id="boards" class="boards">
      <div id="boards-border" />
      <SelectionLayer v-if="showSelectionLayer" />
      <DrawLayer v-if="showDrawLayer" />
      <ZoomLayer ref="zoomLayer" />
      <PaintLayer v-show="hasPaintLayer" />
      <transition name="fade">
        <KeepAlive
          v-if="!!board"
          role="tabpanel"
          :aria-labelledby="boardType + '_tab'"
        >
          <component
            :is="board"
            :id="boardType + '_panel'"
            ref="boardElem"
            :class="{
              'pan-mode': canUsePanMode,
            }"
          />
        </KeepAlive>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";

import DrawLayer from "@/components/Draw/DrawLayer.vue";
import SelectionLayer from "@/components/Selection/SelectionLayer.vue";
import SkipButton from "@/components/a11y/SkipButton.vue";
import BacklogBoard from "@/components/board/BacklogBoard.vue";
import type BoardBase from "@/components/board/BoardBase";
import FlexBoard from "@/components/board/FlexBoard.vue";
import ProgramBoard from "@/components/board/ProgramBoard.vue";
import ProgramObjectivesBoard from "@/components/board/ProgramObjectivesBoard.vue";
import RiskBoard from "@/components/board/RiskBoard.vue";
import SolutionBacklogBoard from "@/components/board/SolutionBacklogBoard.vue";
import SolutionBoard from "@/components/board/SolutionBoard.vue";
import TeamBoard from "@/components/board/TeamBoard.vue";
import MenuContainer from "@/components/menu/MenuContainer.vue";
import ToastMessage from "@/components/ui/ToastMessage/ToastMessage.vue";
import EventBusUser from "@/mixins/EventBusUser";
import ShortcutUser from "@/mixins/ShortcutUser";
import type { BoardType } from "@/model/baseTypes";
import { isObjectivesBoard } from "@/model/board";
import { useBoardStore } from "@/store/board";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useDrawStore } from "@/store/draw";
import { usePanModeStore } from "@/store/panMode";
import { useSessionStore } from "@/store/session";
import { useToastStore } from "@/store/toast";
import { useZoomStore } from "@/store/zoom";
import { tabVisibilityStateChanged } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { isFeatureEnabled, parseUrlWithoutRouter } from "@/utils/env/feature";
import { clearCache } from "@/utils/text/fontSizeCache";

import ConnectionStatus from "./components/ConnectionStatus.vue";
import PaintLayer from "./components/DefaultPaintLayer.vue";
import ZoomLayer from "./components/DefaultZoomLayer.vue";
import MousePointers from "./components/MousePointers.vue";
import { zoomHandler } from "./components/ZoomHandler";
import { pointerTrail } from "./pointerTrail";

@Component({
  components: {
    MenuContainer,
    ToastMessage,
    DrawLayer,
    SelectionLayer,
    ConnectionStatus,
    ZoomLayer,
    PaintLayer,
    MousePointers,
    SkipButton,
  },
})
export default class AppPage extends mixins(ShortcutUser, EventBusUser) {
  boardComponent: BoardBase = null!;
  zoomHandler: ReturnType<typeof zoomHandler> | null = null;
  clientSettingsStore = useClientSettingsStore();

  get board() {
    // components refs are not reactive, so update it manually
    void this.$nextTick(
      () => (this.boardComponent = this.$refs.boardElem as BoardBase),
    );
    return useSessionStore().isSessionLoading
      ? null
      : this.boardFor(useBoardStore().currentBoard().type);
  }

  get boardType() {
    return useBoardStore().currentBoard().type;
  }

  get hasPaintLayer() {
    return useBoardStore().isCurrentBoardFluid;
  }

  beforeCreate() {
    // temp solution to fix the font size cache issue
    clearCache();
  }

  mounted() {
    pointerTrail(this);
    this.zoomHandler = zoomHandler(
      this.$refs.zoomLayer as ZoomLayer,
      () => this.boardComponent,
    );

    document.addEventListener("visibilitychange", this.trackVisibilityChange);
  }

  beforeUnmount(): void {
    document.removeEventListener(
      "visibilitychange",
      this.trackVisibilityChange,
    );
  }

  // Track visibility state changes
  trackVisibilityChange() {
    trackEvent(tabVisibilityStateChanged(document.visibilityState));
  }

  get isZooming() {
    return useZoomStore().zooming;
  }

  get showDrawLayer() {
    const isZoomActive = this.isZooming && useZoomStore().useZoomLayer;
    return !isZoomActive && useDrawStore().isSupportedBoard(this.boardType);
  }

  get showSelectionLayer() {
    return !isObjectivesBoard(this.boardType);
  }

  get canUsePanMode() {
    if (!isFeatureEnabled({ query: parseUrlWithoutRouter() }, "pan-mode"))
      return false;
    return usePanModeStore().active && !isObjectivesBoard(this.boardType);
  }

  @Watch("isZooming")
  zooming(zooming: boolean) {
    if (zooming) {
      void this.zoomHandler?.startZoom();
    } else {
      this.zoomHandler?.endZoom();
    }
  }

  boardFor(type: BoardType) {
    switch (type) {
      case "backlog":
        return BacklogBoard;
      case "program":
        return ProgramBoard;
      case "solution_backlog":
        return SolutionBacklogBoard;
      case "solution":
        return SolutionBoard;
      case "risk":
        return RiskBoard;
      case "team":
        return TeamBoard;
      case "flex":
        return FlexBoard;
      case "objectives":
        return ProgramObjectivesBoard;
    }
  }

  get toast() {
    return useToastStore().toast;
  }

  // Optional mode to meet accessibility standards
  @Watch("clientSettingsStore.highContrastMode", { immediate: true })
  highContrastMode(useMode: boolean, oldValue: boolean) {
    if (useMode === oldValue || !document?.body) return;

    // Apply to the body so it also affects dropdown menus
    if (useMode) {
      document.body.classList.add("high-contrast-mode");
    } else {
      document.body.classList.remove("high-contrast-mode");
    }
  }

  closeToast() {
    useToastStore().hide(true);
  }
}
</script>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/variables";
@use "@/styles/board";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/darkMode" as *;
@use "@/styles/variables/a11y" as *; // for high-contrast mode

.boards {
  transform-origin: top left;
  position: absolute;

  #boards-border {
    position: absolute;
    left: variables.$fake-zoom * 100%;
    top: variables.$fake-zoom * 100%;
    transform-origin: top left;
    border: 1px solid transparent; // to avoid border not being drawn when width or height is 0
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.faster-fade-enter-active,
.faster-fade-leave-active {
  transition: opacity 0.15s;
}

.fade-enter-from,
.fade-leave-to,
.faster-fade-enter-from,
.faster-fade-leave-to {
  opacity: 0;
}

.board {
  background-color: colors-old.$back-color;
  position: absolute;
  width: variables.$fake-zoom * 100%;
  height: variables.$fake-zoom * 100%;
  font-size: variables.$fake-zoom * 100%;

  &.pan-mode {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  // all sizes inside .backdrop should be measured in board.len
  .backdrop {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    /* stylelint-disable-next-line */
    font-size: variables.$horizontal-board-font-size + 0vw;

    .high-contrast-mode & {
      border: board.len(2px) solid $board-border;
    }

    h1,
    .h1 {
      font-weight: font.$weight-black;
      font-size: board.len(180px);
      color: colors-old.$divider-color;
      text-align: center;
      margin: 0;
    }

    h2,
    .h2 {
      font-weight: font.$weight-extra-light;
      font-size: board.len(60px);
      text-align: center;
      margin: 0;
    }

    h3,
    .h3 {
      font-weight: font.$weight-black;
      font-size: board.len(22px);
      margin: 0;
      overflow: hidden;
    }

    h4,
    .h4 {
      font-weight: font.$weight-bold;
      font-size: board.len(14px);
      margin: 0;
      overflow: hidden;
    }
  }

  @media (aspect-ratio >= 16/9) {
    .backdrop {
      /* stylelint-disable-next-line */
      font-size: variables.$vertical-board-font-size + 0vh;
    }
  }

  .field {
    position: absolute;

    &.right {
      text-align: right;
    }
  }

  .top {
    top: 0;
    width: 100%;
    height: 15%;
  }
}

a,
a:visited {
  color: inherit;
}

input {
  font-size: 14px;
  padding: 0.5em 0;
  border: none;
  border-bottom: 0.1em solid colors-old.$input-border-color;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:read-only {
    cursor: default;
  }
}

.board input {
  font-size: inherit;
}

.scrollable {
  position: absolute;
  inset: 0;
  overflow: hidden auto;

  &.scrollable-relative {
    position: relative;
  }

  &.scrollable-content {
    overflow: hidden auto;
    height: calc(100vh - 260px);
    padding-bottom: 30px;
    padding-right: 15px;
    padding-top: 15px;
  }
}

.input {
  cursor: pointer;

  &.disabled {
    cursor: help;

    &.no-help {
      cursor: inherit;
    }
  }
}

.item {
  text-align: left;
  overflow: hidden;
  cursor: pointer;
}

.entry {
  position: relative;
  font-size: 150%;
  padding-right: 2.2em;
  margin-left: 1.7em;
  padding-left: 0.5em;
  width: 100%;
  line-height: 1.6em;
  display: inline-block;
  border-bottom: 0.1em solid colors-old.$divider-color;
  cursor: pointer;
  word-break: break-word;
}

.board-elem:focus-within,
.flag-icon.current,
.item.hoverable:hover,
.item.current,
.entry.hoverable:hover,
.entry.current {
  background-color: colors-old.$divider-color;
}

.item.selected,
.entry.selected {
  font-weight: font.$weight-extra-bold;

  input {
    font-weight: inherit;
  }
}

.home-overview {
  hr.separator {
    margin-left: 0;
    width: calc(100% - 15px);
    border: 0;
    border-top: 1px solid colors-old.$divider-color;
  }
}
</style>
