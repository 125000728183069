<script setup lang="ts">
import { v4 as uuid } from "uuid";
import { computed, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";

import TreeView from "@/components/TreeView/TreeView.vue";
import type { TreeNodeData } from "@/components/TreeView/types";
import type { Art } from "@/model/session";
import { useArtStore } from "@/store/art";
import { useTeamStore } from "@/store/team";

import type { OrganizationTreeNodeData, SelectedNode } from "./types";

const { t } = useI18n();

const props = defineProps<{
  selected: SelectedNode;
}>();

const emit = defineEmits<{ select: [node: OrganizationTreeNodeData] }>();

const treeId = ref<string>();
onBeforeMount(() => {
  treeId.value = uuid();
});

const arts = computed(() => useArtStore().arts);

const getTeamsInArt = (artId: string) => useTeamStore().teamsInArt(artId);

const handleSelect = (node: TreeNodeData) => {
  emit("select", node as OrganizationTreeNodeData);
};

function createTeamNodes(artId: string): OrganizationTreeNodeData[] {
  return getTeamsInArt(artId).map((team) => ({
    id: `${treeId.value}-team-${team.id}`,
    label: team.name,
    icon: "org/team",
    disabled: false,
    selected: props.selected.level === "team" && props.selected.id === team.id,
    levelContext: {
      level: "team",
      id: team.id,
    },
    children: [],
  }));
}

function createArtNode(art: Art): OrganizationTreeNodeData {
  return {
    id: `${treeId.value}-art-${art.id}`,
    label: art.name,
    icon: "org/art",
    disabled: false,
    selected: props.selected.level === "art" && props.selected.id === art.id,
    levelContext: {
      level: "art",
      id: art.id,
    },
    children: createTeamNodes(art.id),
  };
}

function createSolutionTrainNode(): OrganizationTreeNodeData {
  return {
    id: `${treeId.value}-solution-train`,
    label: t("treeView.solutionTrain"),
    icon: "org/train",
    disabled: false,
    selected: props.selected.level === "solution-train",
    levelContext: {
      level: "solution-train",
    },
    children: arts.value.map(createArtNode),
  };
}

const nodes = computed<OrganizationTreeNodeData[]>(() => {
  if (arts.value.length > 1) {
    return [createSolutionTrainNode()];
  } else {
    // No ART or Single ART
    const isNoArt = useArtStore().current.id === "";
    const art = isNoArt
      ? { id: "", name: t("treeView.art") }
      : { id: arts.value[0].id, name: arts.value[0].name };

    return [createArtNode(art)];
  }
});
</script>

<template>
  <div class="tree-view-container">
    <TreeView :nodes="nodes" @select="handleSelect" />
  </div>
</template>

<style lang="scss" scoped>
.tree-view-container {
  outline: revert;
}
</style>
