import { Vue } from "vue-class-component";

import type { Action } from "@/action/types";
import type { Key } from "@/components/shortcut/key";
import { accept, key, noAccept } from "@/components/shortcut/key";
import type { ShortcutOptions } from "@/components/shortcut/shortcuts";
import {
  doRegisterShortcut,
  doUnregisterShortcuts,
} from "@/components/shortcut/shortcuts";
import { useModalStore } from "@/store/modal";

// TODO a stack of focuses instead?
let focus: ShortcutUser | null = null;

export default class ShortcutUser extends Vue {
  private focusObj = {};

  globalShortcut(
    key: Key,
    handler: (e: KeyboardEvent) => void,
    options?: ShortcutOptions,
  ) {
    doRegisterShortcut(
      this,
      (e) => !useModalStore().isModalOpen() && accept(key.accept)(e),
      handler,
      options,
    );
  }

  globalActionShortcut(
    action: Action | Action<[e: KeyboardEvent]>,
    options?: ShortcutOptions,
  ) {
    if (action.data.shortcut.accept !== noAccept) {
      this.globalShortcut(
        action.data.shortcut,
        (e: KeyboardEvent) =>
          options?.withEvent && action.length === 2
            ? action("keyboard-shortcut", e)
            : (action as Action)("keyboard-shortcut"),
        options,
      );
    }
  }

  shortcut(
    k: string,
    handler: (e: KeyboardEvent) => void,
    options?: ShortcutOptions,
  ) {
    if (focus && focus !== this) {
      doUnregisterShortcuts(focus.focusObj);
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    focus = this;
    doRegisterShortcut(this.focusObj, key(k).accept, handler, options);
  }

  unmounted() {
    this.unregisterShortcuts();
  }

  unregisterShortcuts() {
    doUnregisterShortcuts(this);
    doUnregisterShortcuts(this.focusObj);
    if (focus === this) {
      focus = null;
    }
  }
}
