import { times } from "@/math/coordinates";
import { zoomFactor } from "@/model/Settings";
import type { BoardData } from "@/model/board";
import type { FlexType } from "@/model/flexboard";
import { generateId } from "@/utils/general";

import { useArtStore } from "../art";

export function objectivesBoard(): BoardData<"objectives"> {
  return {
    ...frontendBoard(objectivesBoardId()),
    type: "objectives",
  };
}

export function flexBoard(
  id: string,
  name: string,
  flexType: FlexType,
): BoardData<"flex"> {
  return {
    ...frontendBoard(id),
    type: "flex",
    name,
    flexType,
  };
}

export function emptyFlexBoard(): BoardData<"flex"> {
  return flexBoard(generateId("2"), "", {
    id: "",
    name: "",
    background: "BLANK",
  });
}

function frontendBoard(id: string) {
  return {
    id,
    stickyTypes: [],
    almSources: [],
    cards: {},
    maxZ: 0,
    loaded: 1,
    cardSize: { factor: 3, ...times(zoomFactor, 3) },
    selected: {},
    shapes: [],
  };
}

export function objectivesBoardId() {
  return generateId("1", useArtStore().current.id);
}
