<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import { cardActions } from "@/action/cardActions";
import { getBoardIdsOfGroupedStickies } from "@/backend/Backend";
import { actionSourceKey } from "@/components/action/injectKeys";
import {
  cardKey,
  cardMetaKey,
  cardMethodsKey,
} from "@/components/card/injectKeys";
import { dropdownKey } from "@/components/ui/DropdownMenu/injectKeys";
import MenuList from "@/components/ui/MenuList/MenuList.vue";
import MenuListHeader from "@/components/ui/MenuList/components/MenuListHeader.vue";
import MenuListItem from "@/components/ui/MenuList/components/MenuListItem.vue";
import MenuListItems from "@/components/ui/MenuList/components/MenuListItems.vue";
import MenuListTitle from "@/components/ui/MenuList/components/MenuListTitle.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { boardIcon, boardTypeName } from "@/model/baseTypes";
import type { Board } from "@/model/board";
import { useBoardsStore } from "@/store/boards";
import { injectStrict } from "@/utils/vue";

const boardsStickyMirroredTo = ref<string[]>([]);

const card = injectStrict(cardKey);
const cardMeta = injectStrict(cardMetaKey);
const dropdown = injectStrict(dropdownKey);
const cardMethods = injectStrict(cardMethodsKey);
const actionSource = injectStrict(actionSourceKey);

const emit = defineEmits<{ mirror: [] }>();

const { t } = useI18n();

onMounted(async () => {
  boardsStickyMirroredTo.value = await getBoardIdsOfGroupedStickies(card.id);
});

const boards = computed(() => useBoardsStore().mirrorTargetBoards([card]));

const boardName = (board: Board): string => {
  return board.type === "team" ? board.team.name : t(boardTypeName(board.type));
};

const mirrorToBoard = async (targetBoard: Board) => {
  if (cardMeta.isReadonly) return;
  if (isAlreadyMirrored(targetBoard.id)) return;

  boardsStickyMirroredTo.value.push(targetBoard.id);
  await cardActions.mirror(actionSource, card.id, card.teamId, targetBoard);
  emit("mirror");

  dropdown.close();
  void cardMethods.animate?.("mirroring");
};

const isAlreadyMirrored = (boardId: string) => {
  return boardsStickyMirroredTo.value.includes(boardId);
};
</script>

<template>
  <MenuList
    class="mirror-sticky"
    role="dialog"
    aria-labelledby="mirror-sticky-title"
  >
    <MenuListHeader id="mirror-sticky-title">
      <MenuListTitle>{{ $t("cardAction.mirror") }}</MenuListTitle>
    </MenuListHeader>
    <MenuListItems role="menu" aria-labelledby="mirror-sticky-title">
      <MenuListItem
        v-for="(targetBoard, i) in boards"
        :key="targetBoard.id"
        v-autofocus="i === 0"
        role="menuitem"
        :data-testid="`mirror-sticky-list-item-${targetBoard.id}`"
        :class="{ disabled: isAlreadyMirrored(targetBoard.id) }"
        :static="cardMeta.isReadonly || isAlreadyMirrored(targetBoard.id)"
        :aria-selected="null"
        @click="mirrorToBoard(targetBoard)"
      >
        <template #before>
          <SvgIcon :name="boardIcon(targetBoard.type)" width="20" height="20" />
        </template>
        <span>{{ boardName(targetBoard) }}</span>
        <template #after>
          <template v-if="isAlreadyMirrored(targetBoard.id)">
            <SvgIcon name="base/check" width="20" height="20" />
            {{ $t("actionMenu.mirrored") }}
          </template>
          <template v-else-if="!cardMeta.isReadonly">
            <span class="mirror">
              {{ $t("cardAction.mirror") }}
            </span>
          </template>
        </template>
      </MenuListItem>
    </MenuListItems>
  </MenuList>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.mirror-sticky {
  .disabled {
    pointer-events: none;
    cursor: default;
  }

  .after {
    display: flex;
    align-items: center;
  }

  .mirror {
    color: colors-old.$primary-color;
  }
}
</style>
