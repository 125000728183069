<script setup lang="ts">
import { computed, ref } from "vue";

import { objectiveActions } from "@/action/objectiveActions";
import BaseTextarea from "@/components/input/BaseTextarea.vue";
import MaxLengthTextarea from "@/components/input/MaxLengthTextarea.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import type { Board } from "@/model/board";
import type { Objective, ObjectiveType } from "@/model/objective";

const props = defineProps<{ type: ObjectiveType; board: Board }>();
const emit = defineEmits<{ cancel: []; added: [objective: Objective] }>();

const rootRef = ref<HTMLElement>();

const text = ref("");
const description = ref("");

const isAddButtonEnabled = computed(() => text.value.length !== 0);

function unfocus(event: FocusEvent) {
  if (
    !text.value.length &&
    !rootRef.value?.contains(event.relatedTarget as Node)
  ) {
    cancel();
  }
}

function saveObjective() {
  const isCommitted = props.type === "committed";
  const newObj = objectiveActions.add(
    "team-objectives-modal",
    {
      boardId: props.board.id,
      text: text.value,
      description: description.value,
    },
    isCommitted,
  );
  text.value = "";
  description.value = "";
  cancel();
  emit("added", newObj);
}

function cancel() {
  emit("cancel");
}
</script>

<template>
  <div
    ref="rootRef"
    v-focus-trap
    class="add-objective-card"
    data-testid="add-objective"
    tabindex="0"
    @focusout="unfocus"
    @pointerdown.prevent
  >
    <MaxLengthTextarea
      ref="textarea"
      v-model="text"
      class="add-title-objective-textarea"
      autofocus
      :placeholder="$t('objectives.title.placeholder')"
      :maxlength="260"
    />
    <BaseTextarea
      v-bind="$attrs"
      v-model="description"
      :placeholder="$t('objectives.description.placeholder')"
      class="add-description-objective-textarea"
    />
    <div class="buttons-container">
      <BaseButton :disabled="!isAddButtonEnabled" @click="saveObjective">
        {{ $t("objectivesModal.add") }}
      </BaseButton>
      <BaseButton variant="outlined" color="grey" @click="cancel"
        >{{ $t("general.cancel") }}
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins/shadow";

.add-objective-card {
  margin-bottom: 12px;
  border: 1px solid colors-old.$objective-border-color;
  padding: 16px;
  width: 100%;

  @include shadow.objective-card;

  border-radius: variables.$objective-card-border-radius;

  :deep(.add-title-objective-textarea) {
    user-select: auto;
    resize: none;
    overflow: hidden;
    width: 100%;
    font-size: font.$size-normal;
    border: 1px solid colors-old.$hover-color;
    padding: 10px 65px 10px 6px;

    &:focus {
      outline: none;
    }
  }

  :deep(.add-title-objective-textarea),
  .add-description-objective-textarea {
    background-color: colors-old.$light-background-color;
    border-radius: variables.$border-radius;

    &::placeholder {
      color: colors-old.$placeholder-color;
      font-weight: font.$weight-normal;
    }
  }

  .add-description-objective-textarea {
    min-height: 100px !important;
    margin-top: 10px;
    font-weight: font.$weight-normal;
  }

  &:hover {
    border: 1px solid colors-old.$objective-border-color-hovered;
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 10px;

    > * {
      min-width: 100px;
    }
  }
}
</style>
