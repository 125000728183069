import type {
  AlmItemStatus,
  AlmItemType,
  AlmItemTypeMap,
  TargetStatus,
  TransitionField,
} from "@/model/status";
import { mapObjectValues } from "@/utils/general";

import type {
  ServerAlmItemStatus,
  ServerAlmItemTransition,
  ServerAlmItemTransitionField,
  ServerAlmItemType,
  ServerAlmItemTypeMap,
} from "../serverModel";

export function mapAlmItemTypes(
  almItemTypes: ServerAlmItemTypeMap,
): AlmItemTypeMap {
  return mapObjectValues(almItemTypes, (teamType) =>
    mapObjectValues(teamType, mapAlmItemType),
  );
}

export function mapAlmItemType(almItemType: ServerAlmItemType): AlmItemType {
  const transitions = transitionFilter(almItemType);
  return {
    statuses: almItemType.statuses.map((status) =>
      mapStatus(almItemType, status),
    ),
    globalTargetStatuses: mapTargetStatus(
      almItemType.statuses,
      transitions.globalFromStatus(),
    ),
    dynamic: almItemType.dynamic,
  };
}

function mapStatus(
  almItemType: ServerAlmItemType,
  status: ServerAlmItemStatus,
): AlmItemStatus {
  const transitions = transitionFilter(almItemType);
  return {
    id: status.id,
    name: status.name,
    initial: transitions.isStatusInitial(status),
    statusClass: status.category,
    order: status.order,
    next: mapTargetStatus(almItemType.statuses, [
      ...transitions.fromStatus(status),
      ...transitions.globalFromStatus(status),
    ]).sort((a, b) => {
      return a.status.order - b.status.order;
    }),
    dynamic: almItemType.dynamic,
  };
}

function mapTargetStatus(
  statuses: ServerAlmItemStatus[],
  transitions: ServerAlmItemTransition[],
): TargetStatus[] {
  return transitions.map((transition) => {
    const target = statuses.find(
      (status) => status.id === transition.to_status,
    )!;
    return {
      transition: { id: transition.id, name: transition.name },
      status: {
        id: target.id,
        name: target.name,
        statusClass: target.category,
        order: target.order,
      },
      fields: mapFields(transition.fields),
    };
  });

  function mapFields(
    fields: ServerAlmItemTransitionField[],
  ): TransitionField[] {
    return fields.map((field) => ({
      id: field.id,
      name: field.name,
      required: field.required,
      type: field.type,
      itemType: field.item_type,
      values: field.values,
    }));
  }
}

function transitionFilter(almItemType: ServerAlmItemType) {
  return {
    // status is initial when there's an initial transition to it
    isStatusInitial(status: ServerAlmItemStatus) {
      return almItemType.transitions.some(
        (transition) =>
          transition.type === "initial" && transition.to_status === status.id,
      );
    },
    // statuses that can be reached from a given status by normal transitions
    fromStatus(status: ServerAlmItemStatus) {
      return almItemType.transitions.filter(
        (transition) =>
          transition.type === "normal" &&
          transition.from_statuses.includes(status.id),
      );
    },
    // statuses that can be reached by global transitions (excluding the given one)
    globalFromStatus(fromStatus?: ServerAlmItemStatus) {
      return almItemType.transitions.filter(
        (transition) =>
          transition.type === "global" &&
          (!fromStatus || transition.to_status !== fromStatus.id),
      );
    },
  };
}
