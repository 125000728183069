import type { RelativeCoordinate } from "@/model/coordinates";
import type { Art } from "@/model/session";
import { useArtStore } from "@/store/art";
import { useSessionStore } from "@/store/session";
import { useStickyTypeStore } from "@/store/stickyType";
import { i18n } from "@/translations/i18n";

import { BasePlanningBoard } from "./BasePlanningBoard";
import { PlanningBoardLocation } from "./location/PlanningBoardLocation";

/* eslint-disable @typescript-eslint/class-literal-property-style */
export class SolutionBoard extends BasePlanningBoard<"solution"> {
  get groups() {
    // don't set load and capacity as we'd have to load all teams boards for it
    const artIterations = Object.fromEntries(
      useArtStore().arts.map((art) => [art.id, useSessionStore().iterations]),
    );
    return [
      this.nonTeamGroup(i18n.global.t("programBoard.milestoneEvents")),
      ...useArtStore().arts.map(artGroup),
      this.nonTeamGroup(""),
    ];

    function artGroup(art: Art) {
      return {
        id: art.id,
        name: art.name,
        current: art.id === useArtStore().current.id,
        iterations: artIterations[art.id],
      };
    }
  }

  location(pos: RelativeCoordinate | number, top?: number) {
    return PlanningBoardLocation.ofArts(this.groups, pos, top);
  }

  positionalCardProperties(pos: RelativeCoordinate) {
    const loc = this.location(pos);
    return { iterationId: loc.iterationId, artId: loc.groupId };
  }

  get showPermanentLinks() {
    return true;
  }

  get possibleStickyTypes() {
    return useStickyTypeStore().nonFlexBoardStickyTypes("solution");
  }

  get creatableStickyTypes() {
    // we can't select a team on the solution-planning board, hence we should not allow creating team board stickies (dependencies)
    // also we should not allow creating art backlog stickies on the solution-planning board, because we do not automatically create
    // the origin on the art backlog board (not implemented in the backend)
    return this.possibleStickyTypes.filter(
      (type) => type.origin !== "team" && type.origin !== "backlog",
    );
  }
}
