<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import { deleteEvent, updateTimer } from "@/backend/Backend";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { serverTime } from "@/components/utils/serverTime";
import { useEventBus } from "@/composables/useEventBus";
import { keepEndedTimer } from "@/model/Settings";
import type { ServerTime, TimerEvent, TimerState } from "@/model/timer";
import { timerStarted } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import Duration from "../Duration";
import ActiveTimer from "./ActiveTimer.vue";
import InactiveTimer from "./InactiveTimer.vue";

const props = defineProps<{ timer: TimerEvent }>();

const name = ref(props.timer.data.name);
const state = ref(props.timer.data.state);
const duration = ref(Duration.fromTimer(props.timer.data, serverTime));

const { onServerTick } = useEventBus();

onMounted(() => {
  if (timerFinished()) {
    remove();
  } else {
    onServerTick(update);
  }
});

const { t } = useI18n();
const stateInfo = computed(() =>
  isState("paused")
    ? ({
        icon: "timer/play",
        label: t("label.timerPanel.resume"),
        next: "running",
      } as const)
    : ({
        icon: "timer/pause",
        label: t("label.timerPanel.pause"),
        next: "paused",
      } as const),
);

function timerFinished() {
  return (
    isState("running") &&
    Duration.fromEnd(props.timer.data.end, serverTime).value <
      -keepEndedTimer / 1000
  );
}

function update(serverTime: ServerTime) {
  if (isState("running") && duration.value.value > 0) {
    duration.value = Duration.fromEnd(props.timer.data.end, serverTime);
  }
}

function isState(s: TimerState) {
  return state.value === s;
}

async function setState(s: TimerState) {
  if (!isState(s) && s === "running") {
    const scope = props.timer.artId ? "art" : "board";
    trackEvent(timerStarted(scope));
  }

  if (!isState(s)) {
    state.value = s;
    await updateTimer(props.timer.id, {
      name: name.value,
      state: s,
      duration: duration.value.value,
      end: duration.value.end(serverTime),
    });
  }
}

function remove() {
  deleteEvent(props.timer.id);
}
</script>

<template>
  <div class="edit-timer">
    <InactiveTimer
      v-if="isState('stopped')"
      :name="name"
      :duration="duration"
      @name="name = $event"
      @duration="duration = $event"
      @start="setState('running')"
      @cancel="remove"
    />
    <ActiveTimer
      v-else
      :name="name"
      :duration="duration"
      :user="timer.updatedByUser!"
      :paused="isState('paused')"
    >
      <!-- Play/Pause button (depending on state) -->
      <IconButton
        :icon="stateInfo.icon"
        :aria-label="stateInfo.label"
        @click="setState(stateInfo.next)"
      />
      <!-- Stop button -->
      <IconButton
        icon="timer/stop"
        :aria-label="$t('label.timerPanel.stop')"
        @click="setState('stopped')"
      />
    </ActiveTimer>
  </div>
</template>

<style lang="scss">
@use "@/styles/variables/a11y" as colors-a11y;
@use "@/styles/variables/colors";

.edit-timer {
  border-top: 1px solid colors-a11y.$divider-color;
  border-bottom: 1px solid colors-a11y.$divider-color;
  padding: 2em;
}
</style>
