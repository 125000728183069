import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  "data-testid": "placeholder-icon",
  viewBox: "0 0 24 24",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M3 5.778A2.78 2.78 0 0 1 5.778 3h3.555a1 1 0 0 1 0 2H5.778A.78.78 0 0 0 5 5.778v3.555a1 1 0 0 1-2 0zM13.667 4a1 1 0 0 1 1-1h3.555A2.78 2.78 0 0 1 21 5.778v3.555a1 1 0 1 1-2 0V5.778A.78.78 0 0 0 18.222 5h-3.555a1 1 0 0 1-1-1M4 13.667a1 1 0 0 1 1 1v3.555c0 .43.348.778.778.778h3.555a1 1 0 1 1 0 2H5.778A2.78 2.78 0 0 1 3 18.222v-3.555a1 1 0 0 1 1-1m16 0a1 1 0 0 1 1 1v3.555A2.78 2.78 0 0 1 18.222 21h-3.555a1 1 0 1 1 0-2h3.555c.43 0 .778-.348.778-.778v-3.555a1 1 0 0 1 1-1",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }