<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

import StickyLinkRow from "@/components/StickyNote/components/StickyListItem/StickyLinkRow.vue";
import ScreenReaderMessage from "@/components/a11y/ScreenReaderMessage.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { isObjective } from "@/components/utils/linkableItems";
import type { LinkableItem } from "@/model/link";
import { useArtStore } from "@/store/art";
import { useCardStore } from "@/store/card";
import { useTeamStore } from "@/store/team";

import type {
  FilterItem,
  GroupedLinkableItems,
  Level,
  LinkableItemGroup,
  SearchContext,
} from "../types";
import { EMPTY_GROUPED_LINKABLE_ITEMS } from "../types";
import TypeFilter from "./TypeFilter.vue";
import { groupedLinkableObjectives } from "./groupedLinkableObjectives";
import { groupedLinkableStickies } from "./groupedLinkableStickies";

const props = defineProps<{
  cardIds: string[];
  searchContext: SearchContext;
  label: string;
}>();

const filters = ref<FilterItem[]>([]);

const linkableItems = ref<GroupedLinkableItems>(EMPTY_GROUPED_LINKABLE_ITEMS);
const filteredLinkableItems = computed(() => filterByType(linkableItems.value));

const noCurrentLevelItems = computed(
  () => filteredLinkableItems.value.currentLevel.items.length === 0,
);
const noOtherLevelsItems = computed(
  () => Object.keys(filteredLinkableItems.value.otherLevels).length === 0,
);

const resultsCount = computed(
  () =>
    filteredLinkableItems.value.currentLevel.items.length +
    Object.values(filteredLinkableItems.value.otherLevels).reduce(
      (acc, group) => acc + group.items.length,
      0,
    ),
);

const noItems = computed(
  () =>
    noCurrentLevelItems.value &&
    noOtherLevelsItems.value &&
    props.searchContext.query.length === 0,
);

const noSearchResults = computed(
  () =>
    noCurrentLevelItems.value &&
    noOtherLevelsItems.value &&
    props.searchContext.query.length !== 0,
);

const cards = computed(
  () =>
    props.cardIds
      .map((id) => useCardStore().cards[id])
      .filter((card) => !!card), //cards might be loading
);

watchEffect(async () => {
  const groupedObjectives = groupedLinkableObjectives(
    cards.value,
    props.searchContext,
  );

  const groupedStickies = await groupedLinkableStickies(
    cards.value,
    props.searchContext,
  );

  linkableItems.value = mergeGroupedLinkableItems(
    groupedObjectives,
    groupedStickies,
  );
});

function filterByType(
  groupedItems: GroupedLinkableItems,
): GroupedLinkableItems {
  if (filters.value.length === 0) {
    return groupedItems;
  }

  const filterInObjectives = filters.value.some(
    (type) => type.id === "objective",
  );
  const isSelectedType = (item: LinkableItem) =>
    isObjective(item)
      ? filterInObjectives
      : filters.value.some((type) => type.id === item.type.id);

  const filteredOtherLevels = Object.fromEntries(
    Object.entries(groupedItems.otherLevels)
      .map(([key, group]): [string, LinkableItemGroup] => [
        key,
        { ...group, items: group.items.filter(isSelectedType) },
      ])
      .filter(([, group]) => group.items.length > 0), // filter out empty groups
  );

  return {
    currentLevel: {
      ...groupedItems.currentLevel,
      items: groupedItems.currentLevel.items.filter(isSelectedType),
    },
    otherLevels: filteredOtherLevels,
  };
}

function mergeGroupedLinkableItems(
  group1: GroupedLinkableItems,
  group2: GroupedLinkableItems,
): GroupedLinkableItems {
  const otherLevels = { ...group1.otherLevels };

  Object.keys(group2.otherLevels).forEach((key) => {
    otherLevels[key] = otherLevels[key]
      ? {
          ...otherLevels[key],
          items: [...otherLevels[key].items, ...group2.otherLevels[key].items],
        }
      : group2.otherLevels[key];
  });

  return {
    currentLevel: {
      level: group1.currentLevel.level,
      items: [...group1.currentLevel.items, ...group2.currentLevel.items],
    },
    otherLevels,
  };
}

const headerByLevel = (level: Level, id: string) =>
  level === "team"
    ? useTeamStore().findTeam({ id })?.name
    : level === "art"
    ? useArtStore().artById(id)?.name
    : useI18n().t("linkModal.solutionTrain");

const subHeaderByLevel = (level: Level) =>
  level === "team"
    ? useI18n().t("linkModal.team")
    : level === "art"
    ? useI18n().t("linkModal.art")
    : "";
</script>

<template>
  <div class="search-results-header">
    <div class="search-results-text">
      {{ label }}
    </div>
    <div class="search-results-filter">
      <TypeFilter v-model="filters" :linkable-items="linkableItems" />
    </div>
  </div>
  <div ref="searchResultContentRef" class="search-results-content">
    <ScreenReaderMessage>
      {{ $t("searchResultsTs.results", { count: resultsCount }) }}
    </ScreenReaderMessage>
    <div v-if="noItems" class="empty">
      <SvgIcon
        class="empty-icon"
        name="general/sticky"
        width="20"
        height="20"
      />
      <div class="empty-text">{{ $t("linkModal.noItems") }}</div>
    </div>
    <div v-else-if="noSearchResults" class="empty">
      <SvgIcon
        class="empty-icon"
        name="general/search-results"
        width="20"
        height="20"
      />
      <div class="empty-text">{{ $t("linkModal.noSearchResults") }}</div>
    </div>
    <div
      v-else
      role="list"
      class="search-content"
      :aria-label="$t('linkModal.searchResults')"
    >
      <div
        v-if="searchContext.query && !noCurrentLevelItems"
        class="search-results-subheader"
      >
        {{ $t("linkModal.searchResults") }}
      </div>
      <StickyLinkRow
        v-for="(link, id) in filteredLinkableItems.currentLevel.items"
        :key="id + 'currentLevel'"
        :target-card-ids="cardIds"
        :link="link"
      />
      <div
        v-if="searchContext.query && !noOtherLevelsItems"
        role="list"
        class="search-other-arts"
      >
        <div class="search-other-arts-divider-container">
          <SvgIcon
            class="search-other-arts-icon"
            name="general/search-results"
            width="20"
            height="20"
          />
          <div class="search-other-arts-text">
            {{ $t("linkModal.searchResultsInOtherARTs") }}
          </div>
        </div>
        <div
          v-for="(group, groupId) in filteredLinkableItems.otherLevels"
          :key="groupId"
          class="search-other-arts"
        >
          <div class="other-header-container">
            <div class="other-arts-header">
              {{ headerByLevel(group.level, groupId) }}
            </div>
            <div class="other-arts-subheader">
              {{ subHeaderByLevel(group.level) }}
            </div>
          </div>
          <StickyLinkRow
            v-for="(link, id) in group.items"
            :key="id + 'otherLevels'"
            :target-card-ids="cardIds"
            :link="link"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/mixins/typography";
@use "@/styles/mixins/utils";

.search-results-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 8px 16px 8px 24px;
  border-bottom: 1px solid colors-old.$divider-color;

  .search-results-text {
    @include typography.medium("bold");
  }
}

.search-results-content {
  flex-grow: 1;
  overflow-y: auto;

  @include utils.hide-scrollbar;

  .search-results-subheader {
    margin-bottom: 4px;

    @include typography.medium("bold");
  }

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 100%;

    .empty-icon {
      color: colors-old.$placeholder-color;
    }

    .empty-text {
      @include typography.small("bold");

      color: colors-old.$placeholder-color;
    }
  }

  .search-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px;
    outline: revert;

    .search-results-header {
      @include typography.medium("bold");

      margin-bottom: 8px;
    }

    .search-other-arts {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 20px;
      margin-top: 20px;

      .search-other-arts-divider-container {
        display: flex;
        gap: 10px;

        .search-other-arts-icon {
          color: colors-old.$text-secondary-color;
        }

        .search-other-arts-text {
          @include typography.medium("bold");

          color: colors-old.$text-secondary-color;
        }
      }

      .other-header-container {
        .other-arts-header {
          @include typography.medium("bold");

          margin-bottom: 4px;
        }

        .other-arts-subheader {
          @include typography.small("semi-bold");

          color: colors-old.$text-secondary-color;
        }
      }
    }
  }
}
</style>
