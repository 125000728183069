import { reactive } from "vue";

import { hasMappedAlmUser, isAlmSync } from "@/backend/Backend";
import { MINUTES } from "@/model/Settings";
import type { Team } from "@/model/session";
import type { AuthUser } from "@/model/user";
import { SimpleCache } from "@/utils/SimpleCache";

import type { UserWithTeams } from "./userAdmin.service";

const almMappingCache = new SimpleCache<boolean>({ timeout: 60 * MINUTES });

async function almMapping(id: string) {
  if (!isAlmSync()) {
    return "complete";
  }
  const isMapped = await almMappingCache.loadItem(id, () =>
    hasMappedAlmUser(id),
  );
  return isMapped ? "complete" : "pip-only";
}

export async function addAlmMapping(
  user: AuthUser,
  teams?: Team[],
): Promise<UserWithTeams> {
  return {
    ...user,
    almMapping: await almMapping(user.id),
    teams: teams || [],
  };
}

export function addReactiveAlmMapping<T extends AuthUser>(user: T): T {
  if (user.almMapping === "alm-only") {
    return user;
  }
  const res = reactive({ ...user, almMapping: "complete" }) as T;
  void almMapping(user.id).then((mapping) => (res.almMapping = mapping));
  return res;
}
