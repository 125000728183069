<script setup lang="ts">
import { ref } from "vue";

import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import DropdownMenu from "@/components/ui/DropdownMenu/DropdownMenu.vue";
import SelectableList from "@/components/ui/SelectableList/SelectableList.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import type { Icon } from "@/model/icon";

interface BreadCrumbItem {
  id: string;
  name: string;
  mark?: Icon;
}

defineProps<{
  icon?: Icon;
  terminal?: boolean;
  tooltip: string;
  title: string;
  items: BreadCrumbItem[];
  value: BreadCrumbItem;
}>();
const emit = defineEmits<{ change: [item: BreadCrumbItem] }>();

defineExpose({ open });

const dropdownRef = ref();

function open() {
  dropdownRef.value?.open();
}
</script>

<template>
  <span class="bread-crumb-selector">
    <DropdownMenu
      ref="dropdownRef"
      :style="{ maxHeight: '300px' }"
      :offset="{ top: 12 }"
      width="md"
    >
      <template #trigger="{ isOpen }">
        <BaseTooltip class="tooltip">
          <template #content>
            {{ tooltip }}
          </template>
          <BaseButton
            variant="ghost"
            color="grey"
            data-dropdown-trigger
            :icon-before="icon"
            :activated="isOpen"
            :aria-expanded="isOpen"
            aria-controls="bread-crumb-selector"
            aria-haspopup="menu"
          >
            <span class="text">{{ value.name }}</span>
          </BaseButton>
        </BaseTooltip>
      </template>
      <SelectableList
        id="bread-crumb-selector"
        v-keyboard-nav
        role="dialog"
        :header="title"
        :values="items"
        :model-value="value"
        @update:model-value="emit('change', $event)"
      >
        <template #default="{ value }">
          {{ value.name }}
        </template>
        <template #after="{ value }">
          <SvgIcon v-if="value.mark" :name="value.mark" />
        </template>
      </SelectableList>
    </DropdownMenu>

    <SvgIcon
      v-if="!terminal"
      name="chevron/right"
      width="20"
      height="20"
      aria-hidden="true"
    />
  </span>
</template>

<style lang="scss">
@use "@/styles/font";

.bread-crumb-selector {
  display: flex;
  align-items: center;
  overflow: hidden;

  .dropdown-menu {
    overflow: hidden;
  }

  .base-button {
    overflow: hidden;
    font-weight: font.$weight_normal;
  }

  .tooltip {
    display: flex;
  }

  & > svg {
    flex-shrink: 0;
  }
}
</style>
