import OverviewModal from "@/components/modal/OverviewModal.vue";
import ShortcutsModal from "@/components/modal/ShortcutsModal.vue";
import TeamObjectivesModal from "@/components/modal/TeamObjectivesModal.vue";
import MetricsModal from "@/components/modal/metrics/MetricsModal.vue";
import OverallMetricsModal from "@/components/modal/metrics/OverallMetricsModal.vue";
import SettingsModal from "@/components/modal/settings/SettingsModal.vue";
import { useActivityStore } from "@/store/activity";
import { useBoardStore } from "@/store/board";
import { useBoardMenuStore } from "@/store/boardMenu";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useDrawStore } from "@/store/draw";
import { usePanModeStore } from "@/store/panMode";
import { usePointerStore } from "@/store/pointer";
import { useSearchMenuStore } from "@/store/searchMenu";
import { useSwitchSessionStore } from "@/store/switchSession";
import { useTimerStore } from "@/store/timer";
import { useWorkModeStore } from "@/store/workMode";
import {
  boardDiffClosed,
  boardDiffOpened,
  searchClosed,
  searchOpened,
} from "@/utils/analytics/events";

import { action, ctxAction, defineActions } from "./actions";
import { navigationActions } from "./navigationActions";
import type { ActionContext } from "./types";

export const toggleActions = defineActions("toggle", {
  showTrailingPointer: action(
    () => usePointerStore().togglePointerActivation(),
    { name: /*$t*/ "action.pointer", icon: "menu-bottom/pointer" },
  ),
  togglePanMode: action(() => usePanModeStore().toggleActive(), {
    name: /*$t*/ "action.panMode",
    icon: "action/pan",
  }),
  toggleWorkMode: ctxAction(
    (ctx) => {
      useWorkModeStore().toggle();
      ctx.track({ mode: useWorkModeStore().workingMode });
    },
    { name: /*$t*/ "action.toggleWorkMode" },
  ),
  showTimer: action(toggleTimer, {
    name: /*$t*/ "action.timer",
    icon: "menu-top/timer",
  }),
  toggleBoardDiff: ctxAction(toggleBoardDiff, {
    name: /*$t*/ "boardDiff.boardTitle",
    icon: "action/activity",
  }),
  showSwitchSession: ctxAction(
    (ctx) => {
      if (useSwitchSessionStore().active) {
        ctx.track(null);
      }
      useSwitchSessionStore().toggleActive();
    },
    { name: /*$t*/ "switchSession.title" },
  ),
  showSearch: ctxAction(toggleSearch, { name: /*$t*/ "action.search" }),
  showTeamObjectives: ctxAction(showTeamObjectives, {
    name: /*$t*/ "action.teamObjectives",
  }),
  showOverview: ctxAction(
    (ctx, attrs) => navigationActions.openModal(ctx, OverviewModal, { attrs }),
    { name: /*$t*/ "action.showOverview" },
  ),
  showProgramMetrics: ctxAction(
    (ctx) => navigationActions.openModal(ctx, OverallMetricsModal),
    {
      name: /*$t*/ "action.programMetrics",
      icon: "menu-top/metrics",
    },
  ),
  showTeamMetrics: ctxAction(
    (ctx) => navigationActions.openModal(ctx, MetricsModal),
    {
      name: /*$t*/ "action.teamMetrics",
      icon: "menu-top/metrics",
    },
  ),
  shiftMenu: action(() => useBoardMenuStore().toggleMenuPosition(), {
    name: /*$t*/ "action.shiftMenu",
    icon: "arrow/right-shift",
  }),
  showStats: action(() => useClientSettingsStore().toggleShowStats(1)),
  showStatGraphs: action(() => useClientSettingsStore().toggleShowStats(2)),
  showDraw: action(() => useDrawStore().toggleActive(), {
    name: /*$t*/ "contextMenu.drawModeOn",
  }),
  hideDraw: action(() => useDrawStore().toggleActive(), {
    name: /*$t*/ "contextMenu.drawModeOff",
  }),
  showSettings: ctxAction(
    (ctx) =>
      navigationActions.openModal(ctx, SettingsModal, {
        attrs: { isUserOnBoard: true },
      }),
    { name: /*$t*/ "action.settings" },
  ),
  showShortcuts: ctxAction(
    (ctx) => navigationActions.openModal(ctx, ShortcutsModal),
    { name: /*$t*/ "action.shortcuts" },
  ),
});

function toggleTimer() {
  useTimerStore().toggleTimer();
  useBoardMenuStore().setMenuPosition(
    useTimerStore().active ? "left" : "default",
  );
}

function toggleBoardDiff(ctx: ActionContext) {
  const isActive = useActivityStore().isBoardActivityOpen;
  ctx.track(
    isActive ? boardDiffClosed(ctx.source) : boardDiffOpened(ctx.source),
  );
  useActivityStore().toggleBoard();
}

function toggleSearch(ctx: ActionContext, keepFilters?: boolean) {
  const isActive = useSearchMenuStore().isSearchSidebarOpen;
  ctx.track(
    isActive ? searchClosed(keepFilters, ctx.source) : searchOpened(ctx.source),
  );
  useSearchMenuStore().toggleSearch();
}

function showTeamObjectives(ctx: ActionContext) {
  const board = useBoardStore().currentBoard();
  if (board.type === "team") {
    navigationActions.openModal(ctx, TeamObjectivesModal, {
      attrs: { boardId: board.id },
    });
  }
}
