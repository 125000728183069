import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M1 4.857A3.85 3.85 0 0 1 4.848 1h12.814a3.85 3.85 0 0 1 3.848 3.857v2.857a1 1 0 1 1-2 0V4.857C19.51 3.828 18.68 3 17.662 3H4.848A1.85 1.85 0 0 0 3 4.857v14.286C3 20.172 3.83 21 4.848 21h7.118a1 1 0 1 1 0 2H4.848A3.85 3.85 0 0 1 1 19.143zM17.084 13a2.565 2.565 0 0 0-2.56 2.572 2.565 2.565 0 0 0 2.56 2.57 2.564 2.564 0 0 0 2.559-2.57A2.566 2.566 0 0 0 17.083 13m-4.56 2.572A4.565 4.565 0 0 1 17.084 11c2.52 0 4.559 2.05 4.559 4.572a4.56 4.56 0 0 1-.683 2.407l1.674 1.373a1 1 0 1 1-1.268 1.546l-1.81-1.484a4.53 4.53 0 0 1-2.472.729 4.565 4.565 0 0 1-4.56-4.571",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }