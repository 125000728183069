<script setup lang="ts">
import { nextTick, ref, watch } from "vue";

import DropdownMenu from "@/components/ui/DropdownMenu/DropdownMenu.vue";
import {
  makeBoardRegionsFocusable,
  makeBoardRegionsUnfocusable,
} from "@/directives/boardRegion";
import { windowCoord } from "@/model/coordinates";
import type {
  EventHandlers,
  PositionProps,
  VueComponent,
} from "@/store/contextMenu";
import { useContextMenuStore } from "@/store/contextMenu";
import { useZoomStore } from "@/store/zoom";

const contextMenuStore = useContextMenuStore();
const zoomStore = useZoomStore();

const triggerRef = ref<HTMLElement>();
const dropdownRef = ref<HTMLElement>();
const dropdownMenuRef = ref<typeof DropdownMenu>();

const component = ref<VueComponent<unknown> | null>(null);
const menuProps = ref<PositionProps>({
  position: windowCoord(0, 0),
});
const handlers = ref<EventHandlers>({});

const handleHideContextMenu = () => {
  contextMenuStore.close();
};

watch(
  () => zoomStore.zooming,
  (val) => {
    if (val && contextMenuStore.menu) {
      handleHideContextMenu();
      dropdownMenuRef.value?.close();
    }
  },
);

watch(
  () => contextMenuStore.menu,
  async (menu) => {
    if (menu) {
      // opening the context menu may trigger focus on a board region
      // which may be autoscrolled into view. Avoid this.
      makeBoardRegionsUnfocusable();
      component.value = menu.component;
      menuProps.value = menu.props;
      handlers.value = menu.eventHandlers || {};

      await nextTick();

      if (!dropdownRef.value) {
        triggerRef.value?.click();
      }
    }
    if (!menu) {
      handlers.value.close?.(null);
      makeBoardRegionsFocusable();
    }
  },
);
</script>

<template>
  <DropdownMenu ref="dropdownMenuRef" no-flip @close="handleHideContextMenu">
    <template #trigger>
      <div
        ref="triggerRef"
        style="position: fixed"
        :style="{
          left: menuProps.position.x + 'px',
          top: menuProps.position.y + 'px',
        }"
      />
    </template>
    <!-- @vue-ignore -->
    <Component
      :is="component"
      ref="dropdownRef"
      v-bind="menuProps"
      v-on="handlers"
    />
  </DropdownMenu>
</template>
