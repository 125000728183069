import { noop } from "lodash-es";

import { sender } from "@/backend/Sender";
import type CardFlag from "@/model/CardFlag";
import type { AlmSourceId, RiskType } from "@/model/baseTypes";
import type { Board } from "@/model/board";
import type { Card, Reaction } from "@/model/card";
import type { RelativeCoordinate } from "@/model/coordinates";
import type { Team } from "@/model/session";
import type { TargetStatus } from "@/model/status";
import type { StickyType } from "@/model/stickyType";
import type { AuthUser } from "@/model/user";
import { fullUrl } from "@/router/navigation";
import { useActivityStore } from "@/store/activity";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useCardStore } from "@/store/card";
import { useSelectionStore } from "@/store/selection";
import { NO_TEAM, useTeamStore } from "@/store/team";
import {
  stickyNoteDependOnTeamChanged,
  stickyNoteMirrored,
  stickyNoteProjectChanged,
  stickyNoteStatusChanged,
  stickyNoteStoryPointsChanged,
  stickyNoteTypeChanged,
  stickyNoteWsjfChanged,
} from "@/utils/analytics/events";

import { action, ctxAction, defineActions } from "./actions";
import type { CardCreateProps } from "./addCard";
import { addCard, cardCreateProps, startAddCard } from "./addCard";
import { boardActions } from "./boardActions";
import type { ActionContext } from "./types";

export const cardActions = defineActions("card", {
  add: ctxAction(startAddCard, { name: /*$t*/ "contextMenu.createNew" }),
  togglePalette: action(noop, {
    icon: "action/add-new",
    name: /*$t*/ "action.addSticky",
  }),
  copy: action(noop),
  paste: ctxAction(startAddCard),
  pasteMultiple: action(addMultipleCards, { name: /*$t*/ "action.paste" }),
  pasteText: ctxAction(startAddCard),
  duplicate: ctxAction(duplicate, { name: /*$t*/ "action.duplicate" }),
  delete: action((id: string, boardId: string) => {
    if (useCardStore().delete({ id, boardId })) {
      sender.deleteCard(boardId, id);
    }
  }),

  mirror: ctxAction((ctx, id: string, teamId: string | null, board: Board) => {
    ctx.track(
      stickyNoteMirrored(
        useCardStore().cards[id].type.functionality,
        useBoardStore().currentBoard().type,
        board.type,
        ctx.source,
      ),
    );
    if (!teamId && board.type === "team") {
      cardActions.setTeam("internal", id, board.team.id);
    }
    return sender.mirror(id, board.id);
  }),

  move: action((id: string, teamId: string) => sender.move(id, teamId)),

  toProgram: action((id: string) =>
    sender.mirror(id, useBoardsStore().boardByType("program").id),
  ),

  toSolution: action((id: string) =>
    sender.mirror(id, useBoardsStore().boardByType("solution").id),
  ),

  toRisk: action((id: string, teamId: string) => {
    const boardId = useBoardsStore().boardByType("risk", {
      artId: useTeamStore().current.artId,
    }).id;
    const card = useCardStore().cards[id];
    sender.toRisk(id, boardId, card.text, card.type.id, teamId);
  }),

  toggleReaction: action(async (cardId: string, reaction: Reaction) => {
    if (useCardStore().hasCurrentUserReaction(cardId, reaction)) {
      await sender.removeReaction(cardId, reaction);
    } else {
      await sender.addReaction(cardId, reaction);
    }
  }),

  startAlter: action((id: string) =>
    sender.startAlterCard(useBoardStore().currentBoard().id, id),
  ),

  stopAlter: action((id: string) => {
    const card = useBoardStore().currentBoard().cards[id];
    // could have just been deleted
    // don't stop alter when card is selected
    if (card && !useBoardStore().isStickySelected(id)) {
      sender.stopAlterCard(useBoardStore().currentBoard().id, id, {
        type: card.data.type,
        x: card.meta.pos.x,
        y: card.meta.pos.y,
      });
    }
  }),

  setAlmSource: ctxAction(
    (ctx, card: Card, almSourceId: AlmSourceId | null) => {
      useCardStore().setAlmSource({ id: card.id, almSourceId });
      sender.alterCard(useBoardStore().currentBoard().id, card.id, {
        almSourceId,
      });
      ctx.track(stickyNoteProjectChanged(card.type.functionality, ctx.source));
    },
  ),

  setPriority: ctxAction(
    (ctx, card: Card, priority: number, priorities?: boolean) => {
      ctx.track(stickyNoteWsjfChanged(card.type.functionality, ctx.source));
      const { use, priority: prio } = useCardStore().setPriority({
        id: card.id,
        priority,
        priorities,
      });
      alterCard(
        useBoardStore().currentBoard().id,
        card.id,
        { id: card.id, priority: prio },
        use,
      );
    },
  ),

  setPoints: ctxAction((ctx, card: Card, points: number) => {
    useCardStore().setPoints({ id: card.id, points });
    alterCard(useBoardStore().currentBoard().id, card.id, { points });
    ctx.track(
      stickyNoteStoryPointsChanged(card.type.functionality, ctx.source),
    );
  }),

  setFlag: action((id: string, flagType: CardFlag) => {
    useCardStore().setFlag({ id, flagType });
    alterCard(useBoardStore().currentBoard().id, id, { flagType });
  }),

  setRisk: action((id: string, risk: RiskType) => {
    useCardStore().setRisk({ id, risk });
    alterCard(useBoardStore().currentBoard().id, id, { risk });
  }),

  setText: action(
    (id: string, text: string) => {
      useCardStore().setText({ id, text });
      sender.alterCard(useBoardStore().currentBoard().id, id, { text });
    },
    {
      history: {
        merge: true,
        saveState: (id) => ({
          id,
          text: useCardStore().cards[id].text,
        }),
      },
    },
  ),

  setIteration: action((id: string, iterationId: number | null) => {
    if (useCardStore().setIteration({ id, iterationId })) {
      sender.alterCard(useBoardStore().currentBoard().id, id, { iterationId });
    }
  }),

  setTeam: action((cardId: string, teamId: string | null) => {
    useCardStore().setTeam({ id: cardId, teamId });
    alterCard(useBoardStore().currentBoard().id, cardId, { teamId });
  }),

  setTeamAction: action((id: string, teamId: string) => {
    if (teamId === NO_TEAM.id) {
      cardActions.setTeam("internal", id, null);
      cardActions.setIteration("internal", id, null);
    } else {
      cardActions.setTeam("internal", id, teamId);
    }
  }),

  setDepend: ctxAction((ctx, id: string, team: Team | null) => {
    const { board, card } = useCardStore().setDependAction({
      id,
      teamId: team?.id || NO_TEAM.id,
    });
    sender.mirror(id, board.id, {
      flagType: card.flagType,
      precondTeam: card.precondTeam,
      dependTeam: card.dependTeam,
    });
    alterCard(useBoardStore().currentBoard().id, id, {
      flagType: card.flagType,
      precondTeam: card.precondTeam,
      dependTeam: card.dependTeam,
    });

    ctx.track(
      stickyNoteDependOnTeamChanged(
        card.type.functionality,
        card.artId === team?.artId,
        ctx.source,
      ),
    );
  }),

  setArt: action((id: string, artId: string | null) => {
    useCardStore().setArt({ id, artId });
    alterCard(useBoardStore().currentBoard().id, id, { artId });
  }),

  setAssignee: action((id: string, assignee: AuthUser | null) => {
    useCardStore().setAssignee(id, assignee);
    alterCard(useBoardStore().currentBoard().id, id, { assignee });
  }),

  setType: ctxAction((ctx, card: Card, type: StickyType) => {
    const fromType = card.type.functionality;
    const toType = type.functionality;
    ctx.track(stickyNoteTypeChanged(fromType, toType, ctx.source));

    const { board, props, priorities } = useCardStore().setType({
      id: card.id,
      type: type.id,
    });
    sender.alterCard(board.id, card.id, props, priorities);
  }),

  setStatus: ctxAction(
    (ctx, board: Board, card: Card, target: TargetStatus) => {
      const from = card.status?.statusClass || "undefined";
      const to = target.status.statusClass;
      ctx.track(
        stickyNoteStatusChanged(from, to, card.type.functionality, ctx.source),
      );
      const status = useCardStore().setStatus(
        board,
        card.id,
        target.status.name,
        { transition: target.transition.id },
      );
      alterCard(board.id, card.id, {
        status,
        transition: target.transition.id,
      });
    },
  ),

  // the actual moving is done continuously by mouse dragging
  // we need this here to track the action and to save the state for undo
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPosition: action((_: string[]) => {}, {
    history: {
      saveState: (ids) =>
        ids.map((id) => ({
          id,
          pos: { ...useBoardStore().currentBoard().cards[id].meta.pos },
        })),
    },
  }),

  toggleActivity: action((id: string) => useActivityStore().toggleCard(id)),

  /**
   * When 1 card is selected, open the assignee dropdown
   * by clicking on the chip (if it exists)
   */
  openAssigneeDropdown: action(
    () => {
      const cardIds = useBoardStore().selectedStickyIds;
      if (cardIds.length === 1) {
        const chipEl = document.getElementById(assigneeChipId(cardIds[0]));
        chipEl?.click();
      }
    },
    { name: /*$t*/ "action.assign" },
  ),
  shareLink: action((card: Card) =>
    navigator.clipboard.writeText(fullUrl({ stickyId: card.id })),
  ),
  shareAlmLink: action((card: Card) =>
    navigator.clipboard.writeText(card.almIssueUrl!),
  ),
  shareAlmId: action((card: Card) =>
    navigator.clipboard.writeText(card.almId!),
  ),
});

async function duplicate(ctx: ActionContext) {
  const cards = useBoardStore().selectedOrActiveStickies;
  if (cards.length === 1) {
    ctx.track({ stickyType: cards[0].data.type.functionality });
    startAddCard(ctx, cardCreateProps(cards[0], { findFreePos: true }), true);
  } else {
    const ids = await addMultipleCards(
      cards.map((card) => cardCreateProps(card)),
    );
    useSelectionStore().duplicated(ids);
  }
}

export const assigneeChipId = (cardId: string) => `${cardId}-assignee-chip`;

async function addMultipleCards(cards: CardCreateProps[]) {
  boardActions.clearCardSelection("internal");
  return await Promise.all(
    cards.map(async (card) => {
      const id = await addCard(card, false);
      useBoardStore().selectSticky(id);
      return id;
    }),
  );
}

/**
 * If the card is currently locked by the user (editing or selected),
 * send alterCard. Otherwise, send stopAlterCard.
 *
 * This ensures that the changes are persisted, but the card isn't unlocked accidentally
 */
export function alterCard(
  boardId: string,
  id: string,
  props: Partial<Card & RelativeCoordinate>,
  priorities?: boolean,
) {
  if (useBoardStore().isStickyIdSelectedOrActive(id)) {
    sender.alterCard(boardId, id, props, priorities);
  } else {
    sender.stopAlterCard(boardId, id, props);
  }
}
