import type { Rectangle, RelativeCoordinate } from "@/model/coordinates";
import { relativeCoord } from "@/model/coordinates";

import { insideRectangle, minus } from "./coordinates";

export default class QuadraticSpline {
  static forLink(from: RelativeCoordinate, to: RelativeCoordinate) {
    return new QuadraticSpline(
      from,
      to,
      relativeCoord(
        (from.x + to.x) / 2,
        Math.max(from.y, to.y) + Math.abs(from.x - to.x) / 3,
      ),
    );
  }

  constructor(
    private from: RelativeCoordinate,
    private to: RelativeCoordinate,
    private via: RelativeCoordinate,
  ) {}

  interpolate(t: number): RelativeCoordinate {
    return relativeCoord(
      (1 - t) * (1 - t) * this.from.x +
        2 * (1 - t) * t * this.via.x +
        t * t * this.to.x,

      (1 - t) * (1 - t) * this.from.y +
        2 * (1 - t) * t * this.via.y +
        t * t * this.to.y,
    );
  }

  tAtX(x: number): number | undefined {
    return tAtVal(this.from.x, this.via.x, this.to.x, x);
  }

  tAtY(y: number): number | undefined {
    return tAtVal(this.from.y, this.via.y, this.to.y, y);
  }

  tAtMiddle(aspectRatio: number) {
    // try to find t where the distance to this.from and this.to is the same
    // this.interpolate(0.5) does not work because spline length is not proportional to t
    // so find t at middle x and middle y and return a wighted mean of them, depending on dx/dy
    // not perfect, but okish
    const tMiddleX = this.tAtX((this.from.x + this.to.x) / 2)!;
    const tMiddleY = this.tAtY((this.from.y + this.to.y) / 2)!;
    const d = minus(this.to, this.from);
    if (d.x === 0) {
      return tMiddleY;
    }
    const slope = Math.abs(d.y / (d.x * aspectRatio));
    return (tMiddleX + slope * tMiddleY) / (slope + 1);
  }

  tMaxAtRectangle(a: RelativeCoordinate, b: RelativeCoordinate): number {
    const x0 = this.tAtX(a.x) || 0;
    const x1 = this.tAtX(b.x) || 0;
    const y0 = this.tAtY(a.y) || 0;
    const y1 = this.tAtY(b.y) || 0;
    return Math.max(x0, x1, y0, y1);
  }

  angleAtT(t: number): number {
    const dx =
      -(1 - t) * this.from.x + (1 - 2 * t) * this.via.x + t * this.to.x;
    const dy =
      -(1 - t) * this.from.y + (1 - 2 * t) * this.via.y + t * this.to.y;
    return Math.atan2(dy, dx);
  }

  asPath(width: number, height: number) {
    return (
      `M ${this.from.x * width} ${this.from.y * height}` +
      ` Q ${this.via.x * width} ${this.via.y * height} ${this.to.x * width} ${
        this.to.y * height
      }`
    );
  }

  iconPosition(
    width: number,
    height: number,
    aspectRatio: number,
  ): RelativeCoordinate {
    const radius = 10;
    const middle = this.interpolate(this.tAtMiddle(aspectRatio));
    return relativeCoord(middle.x * width - radius, middle.y * height - radius);
  }

  /**
   * Find a point on the spline this is outside 2 given rectangles.
   */
  pointOutsideRectangles(
    from: Rectangle<RelativeCoordinate>,
    to: Rectangle<RelativeCoordinate>,
  ): RelativeCoordinate | undefined {
    for (let t = 0; t < 1; t += 0.005) {
      const pos = this.interpolate(t);
      if (!insideRectangle(pos, from)) {
        return pos;
      }
      if (insideRectangle(pos, to)) {
        return;
      }
    }
  }
}

function ok(val: number) {
  return val >= 0 && val <= 1 ? val : undefined;
}

function tAtVal(
  from: number,
  via: number,
  to: number,
  val: number,
): number | undefined {
  const a = from - 2 * via + to;
  const b = 2 * (via - from);
  const c = from - val;
  if (Math.abs(a) < 1e-6) {
    return ok(-c / b);
  }
  const d = b * b - 4 * a * c;
  if (d < 0) {
    return;
  }
  const r = Math.sqrt(d);
  return ok((-b + r) / (2 * a)) || ok((-b - r) / (2 * a));
}
