<script lang="ts" setup>
import { computed } from "vue";

import { cardActions } from "@/action/cardActions";
import ActionMenuItem from "@/components/ActionMenu/ActionMenuItem.vue";
import { isActionVisible } from "@/components/StickyNote/components/ActionMenu/actions";
import { boardKey } from "@/components/board/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { injectStrict } from "@/utils/vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);

const isVisible = computed(() =>
  isActionVisible("activity", card, board.value),
);
</script>

<template>
  <ActionMenuItem
    v-if="isVisible"
    name="activity"
    :tooltip-text="$t('cardAction.activity')"
    :aria-label="$t('label.actionMenu.activity')"
    @click="cardActions.toggleActivity('action-menu', card.id)"
  >
    <SvgIcon name="action/activity" width="20" height="20" />
  </ActionMenuItem>
</template>
