<script lang="ts" setup></script>

<template>
  <div class="menu-list-title">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/mixins/typography";
@use "@/styles/variables/colors";

.menu-list-title {
  @include typography.small("bold");

  color: colors.$text;
}
</style>
