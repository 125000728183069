<script lang="ts" setup>
import { inject, ref } from "vue";

import { keyboardNavigation } from "@/directives/keyboardNavigation";

import { MenuListConfigKey } from "../types";

const props = defineProps<{
  static?: boolean; // static makes the whole list readonly/non-focusable
  multiselect?: boolean;
  label?: string; // accessibility label
}>();

const vKeyboardNavLocal = keyboardNavigation();
const MenuListItemsContainer = ref<HTMLDivElement | null>(null);

const focusin = () => {
  const active = MenuListItemsContainer.value?.querySelector<HTMLElement>(
    ".selected:not(.disabled):not(.static)",
  );
  if (active) {
    MenuListItemsContainer?.value?.setAttribute("tabindex", "-1");
    active.focus();
  } else {
    const firstMenuListItem =
      MenuListItemsContainer?.value?.querySelector<HTMLElement>(
        ".menu-list-item:not(.disabled):not(.static)",
      );

    if (firstMenuListItem) {
      MenuListItemsContainer.value?.setAttribute("tabindex", "-1");
      firstMenuListItem.focus();
    }
  }
};

const focusout = (e: FocusEvent) => {
  if (
    !MenuListItemsContainer.value?.contains(e.relatedTarget as Node) &&
    !props.static
  ) {
    MenuListItemsContainer?.value?.setAttribute("tabindex", "0");
  }
};

const config = inject(MenuListConfigKey);
</script>

<template>
  <ul v-if="static" tabindex="-1" class="menu-list-items" role="listbox">
    <slot></slot>
  </ul>

  <ul
    v-else
    ref="MenuListItemsContainer"
    v-keyboard-nav-local.ignore-trigger="{
      selector: '.menu-list-item',
    }"
    tabindex="0"
    class="menu-list-items"
    role="listbox"
    :aria-label="label || $t('list.menuList')"
    :aria-multiselectable="config?.multiselect ? 'true' : undefined"
    @focus="focusin"
    @focusout="focusout"
  >
    <slot></slot>
  </ul>
</template>

<style lang="scss" scoped>
.menu-list-items {
  all: unset;
  position: relative;
  flex-grow: 1;
  padding: 2px 4px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden auto;
  max-height: 100%;
}
</style>
