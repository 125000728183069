import type {
  NormalizedServerAlmItemType,
  NormalizedServerAlmItemTypeMap,
  ServerAlmItemTransition,
  ServerAlmItemType,
  ServerAlmItemTypeMap,
  TransitionHash,
} from "./serverModel";

export function denormalizedServerAlmItemTypeMap(
  normalizedServerAlmItemTypeMap: NormalizedServerAlmItemTypeMap,
): ServerAlmItemTypeMap {
  const { transitions, alm_item_types_by_board_key } =
    normalizedServerAlmItemTypeMap;

  const denormalizeNormalizedServerAlmItemTypeEntry = ([
    source_id,
    alm_item_type_response,
  ]: [string, NormalizedServerAlmItemType]): [string, ServerAlmItemType] => {
    return [
      source_id,
      {
        ...alm_item_type_response,
        transitions:
          alm_item_type_response.transitions.map<ServerAlmItemTransition>(
            (transition_hash: TransitionHash) => transitions[transition_hash],
          ),
      },
    ];
  };

  return Object.fromEntries(
    Object.entries(alm_item_types_by_board_key).map(
      ([board_key, alm_item_types_by_source]) => {
        return [
          board_key,
          Object.fromEntries(
            Object.entries(alm_item_types_by_source).map(
              denormalizeNormalizedServerAlmItemTypeEntry,
            ),
          ),
        ];
      },
    ),
  );
}
