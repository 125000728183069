<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { TippyOptions } from "vue-tippy";

import type { Action } from "@/action/types";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import type { Icon } from "@/model/icon";
import { injectStrict } from "@/utils/vue";

import { actionSourceKey } from "./injectKeys";

const props = defineProps<{
  action: Action;
  icon?: Icon;
  tooltip?: string;
  position?: TippyOptions["placement"];
  tooltipOffset?: TippyOptions["offset"];
}>();

defineOptions({ inheritAttrs: false });

const actionSource = injectStrict(actionSourceKey);

const { t } = useI18n();
const tooltip = computed(() => {
  const name = props.action.data.name;
  return props.tooltip || (name ? t(name) : "");
});
const shortcut = computed(() => props.action.data.shortcut.name());
</script>

<template>
  <IconButton
    :icon="icon || action.data.icon || 'base/placeholder'"
    :position="position"
    :offset="tooltipOffset"
    :tooltip="tooltip"
    :shortcut="shortcut"
    v-bind="$attrs"
    @click="action(actionSource)"
    @pointerenter="action.data.startPreview?.()"
    @pointerleave="action.data.stopPreview?.()"
  />
</template>
