import { defineStore } from "pinia";

import type { Art } from "@/model/session";
import { i18n } from "@/translations/i18n";

import { useBoardsStore } from "./boards";

export const NO_ART = { id: "", name: "" };

interface State {
  arts: Art[];
  current: Art;
}

export const useArtStore = defineStore("art", {
  state: (): State => ({ arts: [], current: NO_ART }),
  getters: {
    isMultiArt: (state) => {
      return state.arts.length > 1;
    },
    artById: (state) => (artId?: Art["id"] | null) => {
      return state.arts.find((a) => a.id === artId);
    },
    noArt: () => {
      return { id: NO_ART.id, name: i18n.global.t("general.noArt") };
    },
  },
  actions: {
    setArt(art: Art) {
      this.current = art;
      useBoardsStore().updateObjectiveBoardId();
    },
    isCurrentArt(artId?: string) {
      return !this.isMultiArt || artId === this.current.id;
    },
  },
});
