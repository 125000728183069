<script setup lang="ts">
import { computed } from "vue";

import { drawActions } from "@/action/drawActions";
import { toggleActions } from "@/action/toggleActions";
import ActionListItem from "@/components/action/ActionListItem.vue";
import ActionSource from "@/components/action/ActionSource.vue";
import ListItemTitle from "@/components/ui/ListItem/ListItemTitle.vue";
import type { WindowCoordinate } from "@/model/coordinates";
import { useDrawStore } from "@/store/draw";

defineProps<{ position: WindowCoordinate }>();

defineEmits<{ dummy: [] }>();

const shape = computed(() => useDrawStore().selected?.shape.id);
</script>

<template>
  <ActionSource v-keyboard-nav value="context-menu" style="width: 300px">
    <ListItemTitle :text="$t('contextMenu.general')" />
    <ActionListItem :action="toggleActions.hideDraw" />

    <template v-if="shape">
      <ListItemTitle :text="$t('shape.line')" />
      <ActionListItem :action="drawActions.removeShape" :params="[shape]" />
    </template>
  </ActionSource>
</template>
