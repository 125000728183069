<script setup lang="ts">
import { onMounted, ref } from "vue";

import { useNativeEvents } from "@/composables/useNativeEvents";

import DropdownMenu from "./DropdownMenu.vue";

defineProps<{ offset?: { left?: number; top?: number } }>();

const isOpen = ref(false);
const trigger = ref<HTMLElement>();
let parent: HTMLElement | undefined;
let parentFocus: HTMLElement | undefined;

const { addEventListener } = useNativeEvents();

onMounted(() => {
  parent = trigger.value?.closest<HTMLElement>(".base-popup") ?? undefined;
  // close when mouse enters parent menu somewhere
  addEventListener(parent, "pointerenter", () => (isOpen.value = false));
  if ((parentFocus = parent?.children[0] as HTMLElement)) {
    parentFocus.tabIndex = -1; //make it focusable
  }
});

function open() {
  isOpen.value = true;
}

function closeSelf() {
  isOpen.value = false;
  parentFocus?.focus();
}

function closeParent() {
  isOpen.value = false;
  parent?.click();
}

function triggerLeft(e: PointerEvent) {
  //close when mouse leaves trigger for another element in the parent menu
  if (parent?.contains(e.relatedTarget as HTMLElement)) {
    isOpen.value = false;
  }
}
</script>

<template>
  <DropdownMenu :is-open="isOpen" :offset="offset" style="width: 100%">
    <template #trigger>
      <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
      <div
        ref="trigger"
        @click.stop="open"
        @keydown.right.prevent="open"
        @pointerenter="open"
        @pointerleave="triggerLeft"
      >
        <slot name="trigger" />
      </div>
    </template>
    <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
    <div @click="closeParent" @keydown.left.prevent="closeSelf">
      <slot />
    </div>
  </DropdownMenu>
</template>
