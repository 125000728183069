<script setup lang="ts">
import FlexBackground from "@/components/background/FlexBackground.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import type { FlexType } from "@/model/flexboard";

defineProps<{ types: FlexType[]; cockpitUrl?: string }>();

const emit = defineEmits<{ close: []; add: [type: FlexType] }>();
</script>

<template>
  <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions-->
  <div
    v-focus-trap
    role="dialog"
    aria-modal="true"
    aria-labelledby="add-canvas-title"
    tabindex="-1"
    @click="emit('close')"
    @keydown.esc.stop="emit('close')"
    @keydown.stop
  >
    <div class="type-dialog">
      <div class="message">
        <h2 id="add-canvas-title">{{ $t("flexModal.chooseCanvas") }}</h2>
        <IconButton
          icon="base/close"
          class="close-button"
          :aria-label="$t('general.close')"
        />
      </div>
      <div class="scrollable">
        <div v-if="types.length === 0" class="message">
          <I18nT
            v-if="cockpitUrl"
            keypath="flexModal.noCanvasConfigure"
            scope="global"
          >
            <template #cockpit>
              <a target="_blank" :href="cockpitUrl">
                {{ $t("flexModal.cockpit") }}
              </a>
            </template>
          </I18nT>
          <span v-else>{{ $t("flexModal.noCanvasAsk") }}</span>
        </div>
        <button
          v-for="(type, index) in types"
          :key="type.id"
          v-autofocus="index === 0"
          class="action flex-button"
          @click="emit('add', type)"
        >
          <FlexBackground :type="type.background" class="background" />
          <div class="type-name">{{ type.name }}</div>
        </button>
      </div>
    </div>
  </div>
</template>
