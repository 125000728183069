import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M1 2a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1v11.472q0 .42-.021.821.021.1.021.207c0 .381-.052.732-.148 1.052-.342 2.254-1.275 4.013-2.809 5.261-1.569 1.277-3.651 1.92-6.073 2.119a5 5 0 0 1-.698.068h-.02l-.002-.023V23a1 1 0 0 1-.176-.015q-.42.015-.852.015H2a1 1 0 0 1-1-1zm16.098 18.23a6.4 6.4 0 0 0 1.683-.968 5.3 5.3 0 0 0 1.186-1.36 5.6 5.6 0 0 1-.822.097h-.04l-.013.001h-.007s-.002 0-.002-1v1h-1.666a.25.25 0 0 0-.25.25v1.25q0 .385-.069.73m3.802-5.15q.1-.742.1-1.608V3H3v18h9.222q.784 0 1.494-.054a3 3 0 0 0 .598-.178c.26-.11.467-.256.608-.437.129-.166.245-.416.245-.831v-1.25a2.25 2.25 0 0 1 2.25-2.25h1.67l.06-.003q.089-.004.25-.026c.218-.031.492-.091.75-.203.26-.11.467-.256.608-.437q.08-.1.145-.251",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }