import { useStickyTypeStore } from "@/store/stickyType";

import { BaseBoard } from "./BaseBoard";

export class FlexBoard extends BaseBoard<"flex"> {
  get possibleStickyTypes() {
    return useStickyTypeStore().flexBoardStickyTypes(this.board.flexType.id);
  }

  get contextActions() {
    return {
      board: { draw: this.drawAction() },
      selection: { stickyMove: true },
    };
  }
}
