import clamp from "lodash/clamp";
import { computed, ref, watch } from "vue";

import { scrollNearBoarder } from "@/components/utils/Gestures";
import { useNativeEvents } from "@/composables/useNativeEvents";
import { relativeClientCoord } from "@/math/coordinate-systems";
import {
  divided,
  insideRectangle,
  max,
  min,
  minus,
  plus,
  rectangle,
  rectangleSize,
  times,
  toSize,
} from "@/math/coordinates";
import type { Rectangle, RelativeCoordinate } from "@/model/coordinates";
import { clientCoord, relativeCoord } from "@/model/coordinates";
import { useBoardStore } from "@/store/board";
import { useDrawStore } from "@/store/draw";
import { useModalStore } from "@/store/modal";
import { usePanModeStore } from "@/store/panMode";

const { addEventListener, removeEventListeners } = useNativeEvents();

export function useMultiSelectRect(width: number, height: number) {
  const isDrawing = ref(false);
  const start = ref<RelativeCoordinate>(relativeCoord(0, 0));
  const current = ref<RelativeCoordinate>(relativeCoord(0, 0));
  const rect = ref<Rectangle<RelativeCoordinate> | null>(null);

  const addPadding = (coord: RelativeCoordinate): RelativeCoordinate => {
    const padding = {
      x: 2 / width,
      y: 2 / height,
    };

    return relativeCoord(
      clamp(coord.x, padding.x, 1 - padding.x),
      clamp(coord.y, padding.y, 1 - padding.y),
    );
  };

  const startDrawingRectangle = (event: PointerEvent) => {
    if (useDrawStore().active) return;
    const relativeCoord = addPadding(relativeClientCoord(event));

    start.value = current.value = relativeCoord;
    isDrawing.value = true;
    // reset selection
    useBoardStore().currentBoard().selected = {};
  };

  const updateRectangle = (event: PointerEvent) => {
    if (!isDrawing.value) return;
    scrollNearBoarder(clientCoord(event));

    current.value = addPadding(relativeClientCoord(event));
    rect.value = rectangle({
      from: min(start.value, current.value),
      to: max(start.value, current.value),
    });
  };

  const selectOverlappingCards = () => {
    if (!rect.value) return;
    const halfCardSize = divided(useBoardStore().currentBoard().cardSize, 2);
    const coverRect = rectangle({
      from: minus(rect.value.p0, halfCardSize),
      to: plus(rect.value.p1, halfCardSize),
    });

    const { cards } = useBoardStore().currentBoard();
    Object.entries(cards).forEach(([id, card]) => {
      if (!card.data.editor && insideRectangle(card.meta.pos, coverRect)) {
        useBoardStore().selectSticky(id);
      }
    });
  };

  const finishDrawingRectangle = () => {
    if (isDrawing.value) {
      isDrawing.value = false;
      selectOverlappingCards();
      rect.value = null;
    }
  };

  const addEventListeners = () => {
    addEventListener(document, "pointerdown", startDrawingRectangle);
    addEventListener(document, "pointermove", updateRectangle);
    addEventListener(document, "pointerup", finishDrawingRectangle);
  };

  const isMultiSelectRectActive = () => {
    return !usePanModeStore().active && !useModalStore().isModalOpen();
  };

  watch(
    isMultiSelectRectActive,
    (isActive) => {
      isActive ? addEventListeners() : removeEventListeners();
    },
    { immediate: true },
  );

  const multiSelectRect = computed(() => {
    if (!rect.value) return;
    const from = times(rect.value.p0, width, height);
    const to = times(rectangleSize(rect.value), width, height);
    return { ...from, ...toSize(to) };
  });

  return { multiSelectRect };
}
