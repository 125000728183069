import { defineStore } from "pinia";
import type { Component, ComponentOptionsBase } from "vue";
import { computed, markRaw, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { getRouter } from "@/router";
import {
  deserializeObject,
  firstQueryParam,
  removePropsWithPrefix,
  serializeObject,
} from "@/utils/vue/queryParam";

interface Modal {
  component: Component | null;
  attrs?: Record<string, unknown>;
  openedAt: ReturnType<typeof Date.now>;
}

const queryPrefix = "modal";

export const useModalStore = defineStore("modal", () => {
  const modal = ref<Modal>({
    component: null,
    attrs: {},
    openedAt: 0,
  });

  const isModalOpen = (component?: Modal["component"]) => {
    return (
      !!modal.value.component &&
      (!component || component === modal.value.component)
    );
  };

  const wasModalJustOpened = computed(
    () => Date.now() - modal.value.openedAt < 100,
  );

  const route = useRoute();

  function open(
    component: Component,
    options?: { attrs?: Record<string, unknown> },
  ) {
    updateQuery(component, options?.attrs || {});
  }

  function changeAttrs(attrs: Record<string, unknown>) {
    if (modal.value.component) {
      updateQuery(modal.value.component, { ...modal.value.attrs, ...attrs });
    }
  }

  function updateQuery(component: Component, props: Record<string, unknown>) {
    void getRouter().syncPush(() => ({
      query: {
        ...route.query,
        modal: componentName(component),
        ...serializeObject(props, queryPrefix),
      },
    }));
  }

  function close() {
    void getRouter().syncPush(() => ({
      query: removePropsWithPrefix(route.query, queryPrefix),
    }));
  }

  watch(route, async (route) => {
    const queryModal = firstQueryParam(route.query, "modal");
    if (!queryModal && modal.value.component) {
      modal.value = {
        component: null,
        attrs: {},
        openedAt: 0,
      };
    } else if (queryModal) {
      const component = await findModal(queryModal);
      if (component) {
        modal.value = {
          component: markRaw(component),
          attrs: deserializeObject(route.query, queryPrefix),
          openedAt: Date.now(),
        };
      }
    }
  });

  let modals = new Array<Component>();

  async function findModal(name: string) {
    if (modals.length === 0) {
      modals = (await import("./helpers/modals")).modals;
    }
    return modals.find((comp) => componentName(comp) === name);
  }

  return { modal, isModalOpen, wasModalJustOpened, open, close, changeAttrs };
});

export function componentName(comp: Component) {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  return (
    comp.name ||
    (comp as ComponentOptionsBase<any, any, any, any, any, any, any, any>)
      .__name
  );
  /* eslint-enable */
}
