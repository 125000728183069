<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import sound from "@/assets/timer.mp3";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import { serverTime } from "@/components/utils/serverTime";
import { useEventBus } from "@/composables/useEventBus";
import { keepEndedTimer, timerEnding } from "@/model/Settings";
import type { ServerTime, TimerData } from "@/model/timer";
import { LocalStore } from "@/utils/LocalStore";

import Duration from "../Duration";
import ProgressTimerIcon from "./ProgressTimerIcon.vue";

const props = defineProps<{
  timer: TimerData;
  timerScope: string;
  isEditing: boolean;
}>();

const emit = defineEmits<{ delete: [] }>();

const duration = ref(Duration.fromTimer(props.timer, serverTime));
const audio = new Audio(sound);

const { onServerTick } = useEventBus();

onMounted(() => {
  onServerTick(setDuration);
});

const showDuration = computed(() => duration.value.nonNegative());
const ratio = computed(() => showDuration.value.hourFraction);
const finished = computed(() => duration.value.hasEnded(keepEndedTimer));
const endSoon = computed(() => duration.value.value <= timerEnding / 1000);
const lastSoundStore = new LocalStore<number>("lastSound");

function setDuration(st: ServerTime) {
  if (props.timer.state === "running") {
    duration.value = Duration.fromEnd(props.timer.end, st);
    if (duration.value.value === 0) {
      const lastSound = lastSoundStore.load() ?? 0;
      if (Date.now() - lastSound > keepEndedTimer) {
        lastSoundStore.save(Date.now());
        audio.currentTime = 0;
        void audio.play();
      }
    } else if (duration.value.value <= -keepEndedTimer / 1000) {
      emit("delete");
    }
  }
}
</script>

<template>
  <BaseTooltip position="bottom">
    <template #content>
      <div class="timer-tooltip">
        <div class="time-info">
          <span v-if="finished" class="time">
            {{ $t("timer.timerMessage") }}
          </span>
          <div v-else>
            <div class="name">{{ timer.name }}</div>
            <div v-if="timerScope" class="timer-scope">
              {{ timerScope }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="timer-btn" :class="{ isEditing }">
      <ProgressTimerIcon
        :end-soon="endSoon"
        :ratio="ratio"
        :is-active="isEditing"
      />
      <span
        role="timer"
        :aria-label="
          $t('timer.ariaDuration', {
            h: showDuration.hourStr,
            m: showDuration.minuteStr,
            s: showDuration.secondStr,
          })
        "
      >
        <span aria-hidden="true">
          <template v-if="showDuration.hasHours">
            {{
              $t("timer.longDuration", {
                h: showDuration.hourStr,
                m: showDuration.minuteStr,
                s: showDuration.secondStr,
              })
            }}
          </template>
          <template v-else>
            {{
              $t("timer.shortDuration", {
                m: showDuration.minuteStr,
                s: showDuration.secondStr,
              })
            }}
          </template>
        </span>
      </span>
    </div>
  </BaseTooltip>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/variables";

.timer-tooltip {
  position: relative;
  text-align: left;

  .name {
    font-weight: font.$weight-bold;
    margin-bottom: 5px;
  }
}

.timer-btn {
  position: relative;
  cursor: pointer;
  gap: 10px;
  display: flex;
  align-items: center;
  border-radius: variables.$button-icon-radius;
  padding: 5px;

  span {
    font-size: 14px;
    color: var(--text-secondary-color);
    font-weight: font.$weight-semi-bold;
  }

  .timer-icon-progress {
    height: 20px;
  }
}
</style>
