<script setup lang="ts">
import { computed } from "vue";

import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { infoLevelInfo } from "@/model/baseTypes";
import type { Toast } from "@/model/toast";

const props = defineProps<Toast>();

const emit = defineEmits<{ close: [] }>();

const iconName = computed(
  () => props.icon || infoLevelInfo[props.level!]?.icon,
);
</script>

<template>
  <div v-if="messageKey" id="message" data-no-screenshot :class="{ invisible }">
    <SvgIcon
      v-if="closeable"
      name="base/close"
      class="close"
      width="15"
      height="15"
      @click="emit('close')"
    />
    <SvgIcon v-if="iconName" :name="iconName" width="20" height="20" />
    <div v-if="imageUrl">
      <img :src="imageUrl" alt="" />
    </div>
    <div role="alert">
      <div v-if="titleKey" class="title">{{ $t(titleKey) }}</div>
      {{ $t(messageKey) }}
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/mixins/shadow";
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/z-index";

#message {
  display: flex;
  align-items: center;
  gap: 0.5em;
  position: fixed;
  bottom: 4em;
  left: 50%;
  transform: translateX(-50%);
  max-width: 35em;
  padding: 0.5em 1.5em;
  z-index: z-index.$popup-menu;
  background-color: colors-old.$back-color;
  border-radius: 10px;

  @include shadow.default;

  // Notifications intended only for screen reader users
  &.invisible {
    z-index: -1;
    opacity: 0;
  }

  .title {
    font-weight: font.$weight-bold;
    margin-bottom: 0.5em;
  }

  img {
    width: 40px;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
}
</style>
