import { captureException } from "@/error/sentry";
import type { BoardType, IdMap } from "@/model/baseTypes";
import type { Board, BoardData } from "@/model/board";

import { useArtStore } from "../art";
import { useFlexStore } from "../flex";
import { useTeamStore } from "../team";
import { emptyFlexBoard } from "./frontendBoard";

export function boardByType<T extends BoardType>(
  boards: IdMap<Board>,
  type: T,
  { teamId, artId }: { teamId?: string; artId?: string } = {},
): BoardData<T> {
  const board = Object.values(boards).find((board) => {
    const isTypeMatch = board.type === type;
    const isTeamMatch = boardBelongsToTeam(
      board,
      teamId || useTeamStore().current.id,
    );

    const isArtMatch = boardBelongsToArt(
      board,
      artId || useArtStore().current.id,
    );
    const isFlexMatch = boardIsCurrentFlex(
      board,
      useFlexStore().currentFlexBoard,
    );
    return isTypeMatch && isTeamMatch && isArtMatch && isFlexMatch;
  });

  if (board) {
    return board as BoardData<T>;
  }

  if (type === "flex" && useFlexStore().currentFlexBoard === null) {
    // boards are <keep-alive> so FlexBoard is still alive even when the user deletes the last flex board
    return emptyFlexBoard() as unknown as BoardData<T>;
  }

  void captureException(new Error(`board type '${type}' not found`), {
    data: {
      info: {
        type,
        teamId,
        artId,
        boards: Object.values(boards).map(({ id, type }) => ({
          id,
          type,
        })),
        team: useTeamStore().current,
        art: useArtStore().current,
      },
    },
  });

  // NOTE: Loading of boards may take a few seconds, especially if the board is large.
  // It's okay to return an empty object here, as it will be reactively updated once the missing board is loaded.
  // TODO not sure this is true
  return {} as BoardData<T>;
}

function boardBelongsToTeam(board: Board, teamId?: string | null) {
  return board.type !== "team" || !teamId || board.team.id === teamId;
}

function boardBelongsToArt(board: Board, artId?: string) {
  return (
    ["team", "flex", "objectives"].includes(board.type) ||
    !board.artId ||
    !artId ||
    useArtStore().arts.length <= 1 ||
    board.artId === artId
  );
}

function boardIsCurrentFlex(board: Board, flexBoard: BoardData<"flex"> | null) {
  return board.type !== "flex" || !flexBoard || board.id === flexBoard.id;
}
