<script setup lang="ts">
import { clamp } from "lodash-es";
import { ref } from "vue";

import { cardActions } from "@/action/cardActions";
import { actionSourceKey } from "@/components/action/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import BoardNumberInput from "@/components/input/BoardNumberInput.vue";
import BasePopupTitle from "@/components/ui/BasePopup/components/BasePopupTitle/BasePopupTitle.vue";
import { dropdownKey } from "@/components/ui/DropdownMenu/injectKeys";
import MenuList from "@/components/ui/MenuList/MenuList.vue";
import MenuListItem from "@/components/ui/MenuList/components/MenuListItem.vue";
import MenuListItems from "@/components/ui/MenuList/components/MenuListItems.vue";
import { injectStrict } from "@/utils/vue";

const card = injectStrict(cardKey);
const dropdown = injectStrict(dropdownKey);
const actionSource = injectStrict(actionSourceKey);

const priority = ref(card.priority);

const selectPriority = (priority: number): void => {
  cardActions.setPriority(actionSource, card, priority);
  dropdown.close();
};

const setInput = (val: number) => {
  if (!isFinite(val)) return 0;
  priority.value = clamp(+val.toFixed(1), 0, 999.9);
};
</script>

<template>
  <div
    class="wsjf"
    role="dialog"
    aria-labelledby="set-wsjf-title"
    :aria-description="$t('label.stickyNote.WSJFDialogDescription')"
    @keydown.tab.stop
  >
    <BasePopupTitle id="set-wsjf-title">
      {{ $t("actions.wsjf") }}
    </BasePopupTitle>
    <MenuList v-if="card.type.priorities" selectable>
      <MenuListItems aria-labelledby="set-wsjf-title">
        <MenuListItem
          v-for="prio in card.type.priorities"
          :key="prio.value"
          :selected="prio.value === card.priority"
          @click="selectPriority(prio.value)"
        >
          {{ prio.text }}
        </MenuListItem>
      </MenuListItems>
    </MenuList>
    <span v-else class="wsjf-input-wrap">
      <BoardNumberInput
        v-autofocus
        class="wsjf-input"
        allow-float
        :value="priority"
        :maxlength="5"
        data-testid="wsjf-input"
        aria-labelledby="set-wsjf-title"
        @input="setInput"
        @blur="selectPriority(priority)"
        @keydown.enter="$event.target.blur()"
      />
    </span>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.wsjf {
  width: 120px;
}

.wsjf-input-wrap {
  display: flex;
  height: 40px;
  margin: 6px;

  .wsjf-input {
    text-align: center;
    font-size: font.$size-normal;
    font-weight: font.$weight-medium;
    color: colors-old.$inactive-text-color;
  }

  .wsjf-input:focus {
    color: colors-old.$text-primary-color;
  }
}
</style>
