<script setup lang="ts">
import { v4 as uuid } from "uuid";

import SearchInput from "@/components/SearchInput/SearchInput.vue";
import MenuList from "@/components/ui/MenuList/MenuList.vue";
import MenuListHeader from "@/components/ui/MenuList/components/MenuListHeader.vue";
import MenuListItems from "@/components/ui/MenuList/components/MenuListItems.vue";
import MenuListTitle from "@/components/ui/MenuList/components/MenuListTitle.vue";

defineProps<{
  title: string;
  placeholder?: string;
  iconTitle: string;
  listLen?: number;
}>();

const id = uuid();

const model = defineModel<string>({ required: true });
</script>

<template>
  <div @keydown.tab.stop>
    <MenuListHeader :id="id">
      <MenuListTitle>{{ title }}</MenuListTitle>
    </MenuListHeader>
    <div class="search-container">
      <SearchInput
        v-model="model"
        autofocus
        icon-position="after"
        size="small"
        :placeholder="placeholder"
        :icon-title="iconTitle"
        :results-count="listLen"
        @focusout.stop
      />
    </div>
    <MenuList
      v-keyboard-nav.soft-focus.soft-focus-initial
      selectable
      class="select-list"
      :aria-labelledby="id"
      role="dialog"
    >
      <MenuListItems :aria-labelledby="id">
        <slot />
      </MenuListItems>

      <p v-if="!listLen" class="list-item static">
        {{ $t("searchResults.noResults") }}
      </p>
    </MenuList>
  </div>
</template>

<style lang="scss" scoped>
.search-container {
  padding: 8px;
}

.select-list {
  // the max-height of the select-list combined with the header should be equal to or
  // smaller than base-popup max-height, otherwise, scrolling does not work as intended
  max-height: 260px;

  .after svg {
    display: block;
  }
}
</style>
