import { defineStore } from "pinia";

import type { BoardType } from "@/model/baseTypes";

export const useServerSettingsStore = defineStore("serverSettings", {
  state: () => {
    return {
      isBacklogAlmStickyDeletable: true,
      isTeamAlmStickyDeletable: true,
      isPriorityEditable: true,
      confirmDelete: true,
      moveBetweenTeams: true,
      mirrorAssignedBacklogStickiesToTeamBoard: true,
      iterationLoadWarn: null as number | null,
      iterationLoadCritical: null as number | null,
    };
  },
  getters: {
    isStickyDeletable: (state) => (boardType: BoardType) => {
      switch (boardType) {
        case "backlog":
          return state.isBacklogAlmStickyDeletable;
        case "team":
          return state.isTeamAlmStickyDeletable;
        default:
          return true;
      }
    },
  },
  actions: {},
});
