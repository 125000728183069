<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";

import { getBoardIdsOfGroupedStickies } from "@/backend/Backend";
import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useCardStore } from "@/store/card";
import { injectStrict } from "@/utils/vue";

import AttributeChip from "../../../AttributeChip/AttributeChip.vue";
import MirrorSticky from "../../../MirrorSticky/MirrorSticky.vue";

const card = injectStrict(cardKey);
const cardMeta = injectStrict(cardMetaKey);

// TODO: this will need to be cached
const boardsStickyMirroredTo = ref<string[] | undefined>();

const refetchMirroredBoards = async () => {
  const boardIds = await getBoardIdsOfGroupedStickies(card.id);
  boardsStickyMirroredTo.value = boardIds.filter(
    (id) => id !== useBoardStore().currentBoard().id,
  );
};

onMounted(() => refetchMirroredBoards());

watch(
  () => Object.keys(useCardStore().cards),
  (newKeys, oldKeys) => {
    const symmetricDifference = (
      keysA: string[],
      keysB: string[],
    ): string[] => [
      ...keysA.filter((x) => !keysB.includes(x)),
      ...keysB.filter((x) => !keysA.includes(x)),
    ];

    if (newKeys.length < oldKeys.length) return;

    const diff = symmetricDifference(newKeys, oldKeys);
    const cardCopyId = diff.find((cardId: string) => {
      const groupId = useCardStore().cards[cardId]?.groupId;
      return groupId && [card.id, card.groupId].includes(groupId);
    });
    if (cardCopyId) {
      void refetchMirroredBoards();
    }
  },
);

const mirrorCount = computed(() => {
  const count = boardsStickyMirroredTo.value?.length || 0;
  return count === 0 ? "" : count.toString();
});

const boards = computed(() => useBoardsStore().mirrorTargetBoards([card]));
</script>

<template>
  <AttributeChip
    v-if="boardsStickyMirroredTo && boards.length !== 0"
    name="mirror-chip"
    text-placeholder="0"
    :tooltip="$t('cardAction.mirror')"
    :text="mirrorCount"
    :readonly="cardMeta.isReadonly && boardsStickyMirroredTo.length === 0"
  >
    <template #icon>
      <SvgIcon name="action/mirror" />
    </template>
    <MirrorSticky @mirror="refetchMirroredBoards" />
  </AttributeChip>
</template>
