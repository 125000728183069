import { type MenuActionType } from "@/components/card/actions";
import type { Board } from "@/model/board";
import type { Card } from "@/model/card";
import { isDependency, isRisk } from "@/model/stickyType";
import { useBoardsStore } from "@/store/boards";
import { useServerSettingsStore } from "@/store/serverSettings";

export type ActionMenuAction = Omit<MenuActionType, "close">;

export const defaultActions = new Array<ActionMenuAction>();

export const flexBoardActions: ActionMenuAction[] = [
  "delete",
  "link",
  "activity",
];

// actions visible on the ART Risk board
export const artRiskBoardActions: ActionMenuAction[] = [
  "delete",
  "almSource", // project
  "mirror",
  "link",
  "activity",
];

// actions visible on the ART Backlog board
export const artBacklogBoardActions: ActionMenuAction[] = [
  "delete",
  "almSource", // project
  "mirror",
  "team",
  "link",
  "activity",
];

// actions visible on the ART Planning board
export const artPlanningBoardActions: ActionMenuAction[] = [
  "delete",
  "almSource", // project
  "mirror",
  "link",
  "activity",
];

// actions visible on the Solution Planning board
export const solutionPlanningBoardActions: ActionMenuAction[] = [
  "delete",
  "almSource", // project
  "mirror",
  "link",
  "activity",
];

// actions visible on the Solution Backlog board
export const solutionBacklogBoardActions: ActionMenuAction[] = [
  "delete",
  "almSource",
  "mirror",
  "art",
  "link",
  "activity",
];

export const teamBaseActions: ActionMenuAction[] = [
  "delete",
  "almSource",
  "move",
  "link",
  "activity",
];
export const teamBoardDefaultActions: ActionMenuAction[] = [
  ...teamBaseActions,
  "mirror",
];
export const teamRiskActions: ActionMenuAction[] = [
  ...teamBaseActions,
  "mirror",
];
export const teamDependActions: ActionMenuAction[] = [
  ...teamBaseActions,
  "program",
];
export const teamProgramActions: ActionMenuAction[] = [
  ...teamBaseActions,
  "depend",
];

export const cardWasCreatedOnDifferentTeamBoard = (
  card: Card,
  board: Board,
) => {
  return (
    card.precondTeam &&
    (board.type !== "team" || card.precondTeam.id !== board.team.id)
  );
};

export const cardWasCreatedOnDifferentBoard = (card: Card, board: Board) => {
  return (
    card.type.origin !== board.type ||
    cardWasCreatedOnDifferentTeamBoard(card, board)
  );
};

/**
 * Actions that a sticky note can have depending on the board type
 */
export const getActionsByBoardType = (
  card: Card,
  board: Board,
): ActionMenuAction[] => {
  switch (board.type) {
    case "solution":
      return solutionPlanningBoardActions;
    case "solution_backlog":
      return solutionBacklogBoardActions;
    case "risk":
      return artRiskBoardActions;
    case "backlog":
      return artBacklogBoardActions;
    case "flex":
      return flexBoardActions;
    case "program":
      return artPlanningBoardActions;
    case "team":
      return teamBoardActions(card);
    default:
      return defaultActions;
  }
};

const teamBoardActions = (card: Card): ActionMenuAction[] => {
  switch (card.type.functionality) {
    case "risk":
      return teamRiskActions;
    case "dependency":
      return card.dependTeam ? teamDependActions : teamProgramActions;
    default:
      return teamBoardDefaultActions;
  }
};

export const isStickyMovable = (card: Card, board: Board) => {
  const isOnOriginBoard = card.type.origin === board.type; // sticky note was created on this board
  const moveBetweenTeams = useServerSettingsStore().moveBetweenTeams; // is allowed to be moved in the cockpit

  return (
    isOnOriginBoard && (moveBetweenTeams || isRisk(card)) && !isDependency(card)
  );
};

export const isStickyDeletable = (card: Card, board: Board) => {
  return !card.almId || useServerSettingsStore().isStickyDeletable(board.type);
};

export const isStickyMirrorable = (card: Card) => {
  return useBoardsStore().mirrorTargetBoards([card]).length;
};

/**
 * Determines if specific action should be visible in the action menu
 */
export const isActionVisible = (
  action: ActionMenuAction,
  card: Card,
  board: Board,
): boolean => {
  if (!card || !board) return false;
  return getActionsByBoardType(card, board).includes(action);
};
