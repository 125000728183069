<script setup lang="ts">
import SearchResultRow from "@/components/StickyNote/components/StickyListItem/SearchResultRow.vue";
import type { BaseStickyInfo } from "@/model/card";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { searchItemSelected } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { terminateEvent } from "@/utils/dom/dom";

import type { StickyInfoKey } from "./SearchResult";

const emit = defineEmits<{
  select: [item: StickyInfoKey, focus: boolean];
  pointerenter: [item: BaseStickyInfo];
  pointerleave: [item: BaseStickyInfo];
}>();

const props = defineProps<{
  results: BaseStickyInfo[];
  selected?: StickyInfoKey;
  current: StickyInfoKey | null;
  boardId: string;
}>();

function isCurrentBoard(boardId: string) {
  return useBoardStore().boardId() === boardId;
}

function isSelected(item: BaseStickyInfo) {
  return (
    props.selected &&
    item.id === props.selected.id &&
    item.team?.id === props.selected.teamId
  );
}

function isHighlighted(item: BaseStickyInfo) {
  return (
    props.current &&
    item.id === props.current.id &&
    item.team?.id === props.current.teamId
  );
}

function select(item: BaseStickyInfo, focus?: boolean) {
  const otherBoardId = isCurrentBoard(props.boardId)
    ? undefined
    : props.boardId;

  trackItemSelected(item, otherBoardId);
  emit(
    "select",
    {
      id: item.id,
      boardId: otherBoardId,
      teamId: item.team?.id,
    },
    !!focus,
  );
}

// used for analytics
function trackItemSelected(item: BaseStickyInfo, boardId?: string) {
  const currentBoardType = useBoardStore().currentBoard().type;
  const targetBoardType = boardId
    ? useBoardsStore().boards[boardId].type
    : undefined;

  trackEvent(searchItemSelected(currentBoardType, targetBoardType));
}

/**
 * Selects the item and focuses on it for editing.
 */
function selectAndFocus(item: BaseStickyInfo, event: KeyboardEvent) {
  select(item, true);
  terminateEvent(event); // so it doesn't trigger a click too
}
</script>

<template>
  <div class="search-results-container">
    <SearchResultRow
      v-for="item in results"
      :id="`result-${item.id}`"
      :key="item.id"
      :data-id="item.id"
      aria-keyshortcuts="enter"
      role="option"
      tabindex="0"
      class="base-search-item result-card"
      :class="{
        selected: isSelected(item),
        current: isHighlighted(item),
      }"
      :item="item"
      @click.stop="select(item)"
      @keydown.enter.prevent="selectAndFocus(item, $event)"
      @pointerenter="emit('pointerenter', item)"
      @pointerleave="emit('pointerleave', item)"
    />
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.search-results-container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .base-search-item {
    padding: 8px 16px;

    &.current {
      background-color: colors-old.$light-background-color;
      cursor: pointer;

      .high-contrast-mode & {
        // In high-contrast mode, the background color alone has too low contrast
        outline: 2px solid colors.$outline;
        outline-offset: -2px;
      }
    }

    &.selected {
      :deep(.text) {
        font-weight: font.$weight-bold;
      }
    }
  }
}
</style>
