<script setup lang="ts">
import { computed } from "vue";

import { toggleActions } from "@/action/toggleActions";
import drawLines from "@/assets/draw-lines.gif";
import ActionButton from "@/components/action/ActionButton.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { usePanModeStore } from "@/store/panMode";
import { useToastStore } from "@/store/toast";
import {
  drawLineModeSelected,
  drawModeClosed,
  drawMoveModeSelected,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import MenuItemDelimiter from "../../MenuItemDelimiter.vue";

const isPanModeActive = computed(() => usePanModeStore().active);

function onToggle() {
  if (isPanModeActive.value) {
    trackEvent(drawMoveModeSelected());
  } else {
    trackEvent(drawLineModeSelected());
    useToastStore().show(/*$t*/ "message.lineTool", {
      titleKey: /*$t*/ "action.lineTool",
      imageUrl: drawLines,
      closeable: true,
    });
  }
}

function hideDraw() {
  trackEvent(drawModeClosed("board-actions-menu"));
  toggleActions.showDraw("board-actions-menu");
}
</script>

<template>
  <ActionButton
    :action="toggleActions.togglePanMode"
    icon="draw/line"
    :tooltip="$t('action.lineTool')"
    :activated="!isPanModeActive"
    @click="onToggle"
  />
  <ActionButton
    :action="toggleActions.togglePanMode"
    :activated="isPanModeActive"
    @click="onToggle"
  />
  <MenuItemDelimiter />
  <BaseButton variant="ghost" @click="hideDraw">
    {{ $t("contextMenu.drawModeDone") }}
  </BaseButton>
</template>
