import type {
  AlmSourceId,
  BoardType,
  Color,
  RiskType,
  StatusClass,
} from "@/model/baseTypes";
import type { Reaction } from "@/model/card";
import type { Line, RelativeCoordinate } from "@/model/coordinates";
import type { LicenseType } from "@/model/session";
import type { ShapeType } from "@/model/shape";
import type { Functionality } from "@/model/stickyType";
import type { ServerTime, TimerState } from "@/model/timer";

export interface ServerIteration {
  id: number;
  name: string;
  start: number;
  end: number;
  order: number;
}

export interface ServerLink {
  id: string;
  from_sticky_id: string;
  to_sticky_id: string;
  link_type_id?: string;
}

export interface ServerLinkType {
  _id: string;
  from_sticky_type: string;
  to_sticky_type: string;
}

export interface ServerBoardIteration {
  board_iteration_id: number;
  velocity: string | number;
}

export interface ServerBoard {
  board_id: string;
  board_type: Exclude<BoardType, "flex">;
  team_name: string;
  user_id: number | null;
  art_id?: number;
  shapes?: ServerShape[];
}

export interface ServerFlexBoard {
  board_id: string;
  board_type: "flex";
  name: string;
  flexboard_type: string;
  shapes?: ServerShape[];
}

interface ServerShape extends Line<RelativeCoordinate> {
  id: string;
  type: ShapeType;
  fixed: boolean;
}

export interface ServerStickyType {
  step_estimate?: boolean;
  step_estimate_values?: Array<{ value: number; text: string }>;
  id: string;
  name: string;
  alm_type: string | null;
  color: Color;
  alt_color?: Color;
  index?: number;
  functionality: Functionality;
  origin_board_type: BoardType;
  origin_flexboard_type?: string;
  available_board_types: BoardType[];
  available_flexboard_types?: string[];
}

/* eslint-disable @typescript-eslint/consistent-indexed-object-style */
export interface ServerAlmItemTypeMap {
  [mappingId: string]: { [sourceId: string]: ServerAlmItemType };
}

export type TransitionHash = string;

export interface NormalizedServerAlmItemTypeMap {
  transitions: Record<TransitionHash, ServerAlmItemTransition>;
  alm_item_types_by_board_key: {
    [mappingId: string]: { [sourceId: string]: NormalizedServerAlmItemType };
  };
}

/* eslint-enable */

export function isNormalizedServerAlmItemTypeMap(
  obj: ServerAlmItemTypeMap | NormalizedServerAlmItemTypeMap,
): obj is NormalizedServerAlmItemTypeMap {
  return "transitions" in obj;
}

export interface ServerAlmItemType {
  id: string;
  statuses: ServerAlmItemStatus[];
  transitions: ServerAlmItemTransition[];
  dynamic: boolean;
}

export interface NormalizedServerAlmItemType {
  id: string;
  statuses: ServerAlmItemStatus[];
  transitions: TransitionHash[];
  dynamic: boolean;
}

export interface ServerAlmItemStatus {
  id: string;
  name: string;
  category: StatusClass;
  order: number;
}

export interface ServerAlmItemTransition {
  id: string;
  name: string;
  // initial: when a sticky is created, from_statuses is ignored
  // global: can be used from every status, from_statuses is ignored
  // normal: can be used when status is in from_statuses
  type: "initial" | "global" | "normal";
  from_statuses: string[];
  to_status: string;
  fields: ServerAlmItemTransitionField[];
}

export interface ServerAlmItemTransitionField {
  id: string;
  name: string;
  required: boolean;
  type: string;
  item_type?: string;
  values?: ServerAlmItemTransitionFieldValue[];
}

interface ServerAlmItemTransitionFieldValue {
  id: string;
  name: string;
}

export interface ServerObjectives {
  objectives: ServerObjective[];
  stretches: ServerObjective[];
}

interface ServerLinkedSticky {
  id: string;
  is_origin: boolean;
}

export interface ServerObjective {
  text: string;
  description: string;
  bv: number;
  av: number | null;
  objective_id: string;
  stickies: ServerLinkedSticky[] | undefined;
}

interface ServerLinkedObjective {
  id: ServerObjective["objective_id"];
  board_id: ServerBoard["board_id"];
}

export interface ServerSticky {
  _id: string;
  sticky_group?: string;
  board_id: string;
  type_id: string;
  alm_issue_id?: string | number;
  alm_issue_url?: string;
  alm_source?: AlmSourceId | null;
  text: string;
  story_points?: number;
  WSJF_value?: number;
  step_value?: number;
  team_id?: number | null;
  precond_team?: string;
  precond_team_id?: number | null;
  depend_team?: string;
  depend_team_id?: number | null;
  iteration_number: number | null;
  a_center: [number, number];
  stack_pos: number;
  flag_type?: string | null;
  risk?: RiskType;
  // if sticky is workitem
  status?: string;
  // for dynamic status
  status_class?: StatusClass;
  transition?: string;
  objectives?: ServerLinkedObjective[];
  reactions?: { [reaction in Reaction]?: string[] };
  art_id?: string | null;
  assignee?: ServerUser;
  reporter?: ServerUser;
}

export interface ServerUser {
  pip_id: string | null;
  alm_id: string | null;
}

export interface ServerStickiesUpdateCacheHit {
  timestamp: number;
  updates: ServerStickyUpdate[];
}

export interface ServerStickiesUpdateCacheMiss {
  timestamp: number;
  stickies: ServerSticky[];
}

export type ServerStickiesUpdate =
  | ServerStickiesUpdateCacheHit
  | ServerStickiesUpdateCacheMiss;

interface ServerStickyUpdate {
  event: "alter_sticky" | "delete_sticky";
  obj: ServerSticky;
}

export interface ServerSettings {
  backlog_forbid_JIRA_sticky_delete?: boolean;
  backlog_forbid_WSJF_edit?: boolean;
  confirm_sticky_delete?: boolean;
  hide_program_backlog?: boolean;
  team_forbid_sticky_delete?: boolean;
  team_move_unmapped_to_backlog?: boolean;
  backlog_mirror_assigned_stickies_to_team_board?: boolean;
  move_activated?: boolean;
  iteration_load_warn?: number | null;
  iteration_load_critical?: number | null;
}

export interface ServerCompanySettings {
  auto_logout_after?: number; // in minutes
}

export interface ServerAlmInfo {
  alm_connection_type?: ServerAlmType;
  sync_session_id?: string;
  alm_connection_id?: string;
}

export interface ServerAlmSource {
  user_id?: number;
  sources: Array<{ name: string; id: AlmSourceId }>;
  art_id?: number;
  type: "team" | "art";
}

export interface ServerTeam {
  name: string;
  user_id: number;
  art_id?: number;
}

export interface ServerUserTeam {
  id: number;
  name: string;
  art_id?: number;
}

export interface ServerArt {
  id: number;
  name: string;
}

export interface ServerSession {
  session_id: string;
  name: string;
  creation_date: number;
  archived: boolean;
  planning_dates: { start?: number; end?: number } | null | undefined;
}

export interface ServerInfo {
  major_API_version: number;
  minor_API_version: number;
  time: number;
}

export interface ServerStatusWithData {
  timestamp_in: number;
  timestamp_out: number;
  data: string;
}

export interface ServerLicense {
  expiryDate: number;
  plan: "standard" | "premium" | "enterprise";
  type: LicenseType;
  tracking: boolean;
  onprem: boolean;
}

export type ServerAlmType = "jira" | "ac" | "ado" | undefined;

export interface ServerIterationSubsState {
  team_id: number;
  status: ServerSyncStatus;
  pi_iteration_id: number;
  error: string;
}

export interface ServerIterationSyncState {
  success: boolean;
  status: ServerSyncStatus;
  error: string;
}

export type ServerSyncStatus = "syncing" | "synced";

interface SuccessServerBacklogSyncState {
  success: true;
  status: ServerSyncStatus;
}

interface FailedServerBacklogSyncState {
  success: false;
  status?: "synced";
  error: string;
  error_short: string;
}

export type ServerBacklogSyncState =
  | SuccessServerBacklogSyncState
  | FailedServerBacklogSyncState;

export interface ServerFlexType {
  id: string;
  name: string;
  background: string;
}

export interface ServerFlexBackground {
  id: string;
  name: string;
  info_link: string;
}

export interface ServerCategory {
  _id: string;
  name: string;
  board_ids: string[];
}

export interface ServerAuthUser {
  id: string;
  name: string;
  email: string;
  image_url?: string;
  color?: string;
  preferred_language: string;
  hash?: string;
}

export interface ServerSearchResult {
  board_id: string;
  id: string;
  text: string;
  alm_issue_id?: string;
  flag_type?: string | null;
  team_id?: number;
  art_id?: string;
  type_id: string;
  sticky_group?: string;
  alm_source_id?: AlmSourceId;
  status?: string;
  status_class?: StatusClass;
  iteration_number?: number;
  depend_team_id?: number;
  precond_team_id?: number;
  story_points?: number;
}

export interface ServerEvent {
  id: string;
  session_id: string;
  board_id?: string;
  art_id?: string;
  type: string;
  created_by: string;
  created_at: number;
  updated_by: string;
  updated_at: number;
  data: ServerTimerEvent;
}

interface ServerTimerEvent {
  name: string;
  state: TimerState;
  duration: number;
  end: ServerTime;
}

export interface ServerMirrorResponse {
  mirrored_id: string;
  created: boolean;
}

export interface UserResponse {
  success: boolean;
  company: string;
  user_id: string;
  role: string;
}

export interface ErrorResponse {
  success: boolean;
  error?: string;
  redirectUrl?: string;
  errorCode?: number;
}

export interface ServerAlmStickyTypeData {
  id: string;
  is_mapped: boolean;
}

export interface ServerStickyGroupBoardIds {
  board_ids: string[];
}

interface ServerStickyChangeBase {
  sticky_id: string;
  timestamp: number;
  user_id: string;
}

interface ServerStickyChangeCreate extends ServerStickyChangeBase {
  kind: "create";
}

export interface ServerStickyChangeDelete extends ServerStickyChangeBase {
  kind: "delete";
  changes: ServerFieldChange[];
}

export interface ServerStickyChangeUpdate extends ServerStickyChangeBase {
  kind: "update";
  changes: ServerFieldChange[];
}

export interface ServerStickyChangeMirror extends ServerStickyChangeBase {
  kind: "mirror" | "unmirror";
  from_board: string;
  to_board: string;
}

export interface ServerStickyChangeLink extends ServerStickyChangeBase {
  kind: "link" | "unlink";
  linked_to: string; // ID of the (un)linked sticky
}

export type ServerStickyChange =
  | ServerStickyChangeCreate
  | ServerStickyChangeDelete
  | ServerStickyChangeUpdate
  | ServerStickyChangeLink
  | ServerStickyChangeMirror;

export interface ServerFieldChange<T = unknown> {
  key: keyof ServerSticky;
  old?: T;
  new?: T;
}

export type ServerReactionField = Record<Reaction, string[]> | undefined;
