<script setup lang="ts">
import { computed, provide, ref, watch } from "vue";

import { keyboardNavigation } from "@/directives/keyboardNavigation";

import BaseModal from "./BaseModal.vue";
import type { TabState } from "./injectKeys";
import { tabStateKey } from "./injectKeys";

defineProps<{
  title: string;
  width?: string;
  fullscreen?: boolean;
  description: string; // (aria-description) Necessary for JAWS (screen reader), see BaseModal for details
}>();

const vKeyboardNavLocal = keyboardNavigation();

const tabState = ref<TabState>({
  activeIndex: -1,
  titles: [],
});

provide(tabStateKey, tabState.value);

watch(
  () => tabState.value.titles,
  (titles) => {
    if (tabState.value.activeIndex < 0 && titles.length > 0) {
      tabState.value.activeIndex = 0;
    }
  },
  { deep: true },
);

function selectTab(i: number) {
  tabState.value.activeIndex = i;
}

const showTabs = computed(() => tabState.value.titles.length > 1);
</script>

<template>
  <BaseModal
    :title="title"
    :fullscreen="fullscreen"
    :width="width"
    :description="description"
  >
    <template #header-content>
      <slot name="tab-header"></slot>
      <div
        v-if="showTabs"
        v-keyboard-nav-local.horizontal.select.soft-focus.ignore-trigger="{
          selector: '.tab-button',
        }"
        class="tabs-header"
        role="tablist"
      >
        <button
          v-for="(tabTitle, index) in tabState.titles"
          :id="'tab-' + index"
          :key="tabTitle"
          class="tab-button"
          :aria-controls="'panel-' + index"
          :aria-selected="index === tabState.activeIndex"
          :class="{ 'tab-active': index === tabState.activeIndex }"
          role="tab"
          :tabindex="index === tabState.activeIndex ? 0 : -1"
          @keydown.space.stop
          @click="selectTab(index)"
        >
          {{ tabTitle }}
        </button>
      </div>
    </template>
    <div>
      <slot></slot>
    </div>
  </BaseModal>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/colors" as *;

div.tabs-header {
  padding-left: 1rem;

  button {
    color: colors.$text-subtle;
    font-size: font.$size-small;
    font-weight: font.$weight-semi-bold;
    display: inline-block;
    cursor: pointer;
    margin-right: 16px;
    padding-bottom: 8px;
    outline: revert;

    &:hover {
      color: colors.$text;
    }

    &.tab-active {
      color: colors.$primary-brand;
      border-bottom: solid 2px colors.$primary-brand;
    }
  }
}
</style>
