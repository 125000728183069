<script setup lang="ts">
import { computed } from "vue";

import ReactionIcon from "@/components/card/ReactionIcon.vue";
import type { Reaction } from "@/model/card";
import { reactions } from "@/model/card";
import type { FieldChange, ReactionField } from "@/model/change";

const props = defineProps<{ field: FieldChange<ReactionField> }>();

const counts = computed(() => reactions.flatMap(countDiff));

function countDiff(reaction: Reaction) {
  const diff = props.field.new![reaction] - props.field.old![reaction];
  return diff === 0 ? [] : { reaction, value: (diff > 0 ? "+" : "") + diff };
}
</script>

<template>
  <div class="change horizontal">
    <span v-for="count in counts" :key="count.reaction" class="change-value">
      <ReactionIcon :reaction="count.reaction" />
      {{ count.value }}
    </span>
  </div>
</template>
