<script setup lang="ts">
import { debounce } from "lodash-es";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import type { OrganizationTreeNodeData } from "@/components/OrganizationBaseTree/types";
import SearchInput from "@/components/SearchInput/SearchInput.vue";
import ActionSource from "@/components/action/ActionSource.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import type { Icon } from "@/model/icon";
import {
  linkModalOrgTreeLevelSelected,
  linkModalOrgTreeToggled,
  linkModalSearchUsed,
} from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import BaseModal from "../BaseModal.vue";
import OrganizationTree from "./components/OrganizationTree.vue";
import SearchResults from "./components/SearchResults.vue";
import type { Level, SearchContext } from "./types";

const { t } = useI18n();

defineProps<{ cardIds: string[]; setReturnFocus?: string }>();

const searchContext = ref<SearchContext>({ query: "", level: "none" });

const isOrganisationTreeCollapsed = ref(false);
const organisationNodeIcon = ref<Icon>("general/side-panel");
const searchLabel = ref<string>(t("linkModal.searchResults"));

const levelIconMap: Record<Level, Icon> = {
  "solution-train": "org/train",
  art: "org/art",
  team: "org/team",
  none: "general/side-panel",
};

const handleOrganisationNodeSelect = (node: OrganizationTreeNodeData) => {
  searchContext.value = { ...searchContext.value, ...node.levelContext };
  searchLabel.value = node.label;
  organisationNodeIcon.value = levelIconMap[node.levelContext.level];
  trackEvent(linkModalOrgTreeLevelSelected(searchContext.value.level));
};

const toggleOrgTree = () => {
  isOrganisationTreeCollapsed.value = !isOrganisationTreeCollapsed.value;
  trackEvent(linkModalOrgTreeToggled());
};

watch(
  () => searchContext.value.query,
  debounce((newQuery) => {
    if (newQuery) {
      trackEvent(linkModalSearchUsed());
    }
  }, 3000),
);
</script>

<template>
  <BaseModal
    :title="$t('linkModal.header')"
    :description="$t('linkModal.description')"
    :set-return-focus="setReturnFocus"
    class="modal-container"
    width="900px"
  >
    <template #header-content>
      <div class="link-modal-search-input">
        <SearchInput
          v-model="searchContext.query"
          autofocus
          icon-position="before"
          :icon-title="$t('label.linkModal.searchTitle')"
          :placeholder="$t('linkModal.searchPlaceholder')"
          set-return-focus="base-modal-close-button"
        />
      </div>
    </template>
    <div class="sticky-link-modal-container">
      <div
        class="organisation-tree-container"
        :class="{ collapsed: isOrganisationTreeCollapsed }"
      >
        <div class="organisation-tree-header">
          <div
            v-show="!isOrganisationTreeCollapsed"
            class="organisation-tree-text"
          >
            {{ $t("linkModal.organization") }}
          </div>
          <button
            data-testid="collapse-button"
            class="collapse-icon-container"
            :aria-label="t('linkModal.toggleOrganizationTree')"
            @click="toggleOrgTree"
          >
            <SvgIcon
              :name="
                isOrganisationTreeCollapsed
                  ? organisationNodeIcon
                  : 'general/side-panel'
              "
              class="collapse-icon"
              width="16"
              height="18"
            />
          </button>
        </div>
        <div
          v-show="!isOrganisationTreeCollapsed"
          class="organisation-tree-content"
        >
          <OrganizationTree @select="handleOrganisationNodeSelect" />
        </div>
      </div>
      <ActionSource
        value="linking-modal"
        class="search-results-container"
        :class="{ 'search-results-expanded': isOrganisationTreeCollapsed }"
      >
        <SearchResults
          :card-ids="cardIds"
          :search-context="searchContext"
          :label="searchLabel"
        />
      </ActionSource>
    </div>
  </BaseModal>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/mixins/utils";
@use "@/styles/mixins/typography";

$organisation-tree-width: 300px;
$modal-height: 550px;
$search-results-container-width: 600px;
$collapsed-organization-tree-width: 56px;

.modal-container :deep(.modal .body.scrollable) {
  padding: unset;
  max-height: unset;
  height: $modal-height;
}

.link-modal-search-input {
  padding-right: 24px;
  padding-left: 12px;
  margin-bottom: 16px;
  width: $organisation-tree-width;
}

.sticky-link-modal-container {
  display: flex;
  flex-grow: 1;
  height: 100%;

  .organisation-tree-container {
    display: flex;
    flex-direction: column;
    width: $organisation-tree-width;
    transition: width 0.3s ease;
    border-right: 1px solid colors-old.$divider-color;
    overflow-x: hidden;

    .organisation-tree-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 8px 8px 24px;
      transition: padding 0.3s ease;
      border-bottom: 1px solid colors-old.$divider-color;

      .organisation-tree-text {
        @include typography.medium("bold");
      }

      .collapse-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 32px;
        width: 32px;
        outline: revert;

        .collapse-icon {
          color: colors-old.$text-secondary-color;
        }

        &:hover {
          background-color: colors-old.$hover-input-border-color;
          border-radius: 6px;

          .collapse-icon {
            color: unset;
          }
        }
      }
    }

    .organisation-tree-content {
      margin-top: 8px;
      padding: 4px;
      width: $organisation-tree-width;
      overflow-y: auto;

      @include utils.hide-scrollbar;
    }

    &.collapsed {
      width: $collapsed-organization-tree-width;

      .organisation-tree-header {
        justify-content: center;
        padding: 12px;
        border-bottom: unset;
      }
    }
  }

  .search-results-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: $search-results-container-width;
    transition: width 0.3s ease;
    overflow-x: hidden;

    &.search-results-expanded {
      width: calc(100% - $collapsed-organization-tree-width);
    }
  }
}
</style>
