<script lang="ts" setup>
import type { ComponentPublicInstance } from "vue";
import { computed, ref } from "vue";

import StickyLinkRow from "@/components/StickyNote/components/StickyListItem/StickyLinkRow.vue";
import { cardMetaKey } from "@/components/card/injectKeys";
import { dropdownKey } from "@/components/ui/DropdownMenu/injectKeys";
import MenuList from "@/components/ui/MenuList/MenuList.vue";
import MenuListFooter from "@/components/ui/MenuList/components/MenuListFooter.vue";
import MenuListHeader from "@/components/ui/MenuList/components/MenuListHeader.vue";
import MenuListItems from "@/components/ui/MenuList/components/MenuListItems.vue";
import MenuListTitle from "@/components/ui/MenuList/components/MenuListTitle.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { sortLinkableItems } from "@/components/utils/linkableItems";
import type { LinkableItem } from "@/model/link";
import { injectStrict } from "@/utils/vue";

const props = defineProps<{ targetCardIds: string[]; links: LinkableItem[] }>();
const emit = defineEmits<{ add: [] }>();

const titleRef = ref<ComponentPublicInstance | null>(null);

const sortedLinks = computed(() => sortLinkableItems(props.links));

const dropdown = injectStrict(dropdownKey);
const cardMeta = injectStrict(cardMetaKey);

const handleAdd = () => {
  emit("add");
  dropdown.close();
};

const focusTitle = () => {
  titleRef.value?.$el?.focus();
};
</script>

<template>
  <MenuList
    class="sticky-links"
    role="dialog"
    aria-labelledby="sticky-links-title"
    @keydown.tab.stop
  >
    <MenuListHeader id="sticky-links-title" ref="titleRef" v-autofocus>
      <MenuListTitle>{{ $t("general.links") }}</MenuListTitle>
    </MenuListHeader>
    <MenuListItems
      class="menu-list-items"
      :static="true"
      :aria-label="$t('general.links')"
      role="list"
    >
      <div v-if="!sortedLinks.length" class="empty">
        <div class="empty-links-content">
          <SvgIcon name="action/link" height="20" width="20" />
          <span>{{ $t("general.noLinkedStickies") }}</span>
        </div>
      </div>
      <StickyLinkRow
        v-for="(link, id) in sortedLinks"
        :key="id"
        :target-card-ids="targetCardIds"
        :link="link"
        @unlink="focusTitle"
      />
    </MenuListItems>
    <MenuListFooter
      class="list-item"
      :label="$t('linkChip.addLinks')"
      :disabled="cardMeta.isReadonly"
      @click="handleAdd"
    />
  </MenuList>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.sticky-links {
  font-size: 12px;

  .menu-list-items {
    display: flex;
    gap: 12px;
    padding: 12px;
  }

  .empty {
    color: colors-old.$placeholder-color;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .empty-links-content {
      display: flex;
      align-items: center;
      height: 20px;
      white-space: nowrap;
      gap: 8px;
    }
  }
}
</style>
