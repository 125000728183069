import type { Ref } from "vue";
import { computed, ref } from "vue";

import { useActionMenu } from "@/components/ActionMenu/useActionMenu";
import { minus } from "@/math/coordinates";
import type { Shape } from "@/model/shape";

export function useDrawActionMenu(shape: Ref<Shape | undefined>) {
  const selectedEl = computed(() => {
    return shape.value
      ? document.getElementById(`shape-${shape.value.id}`) ?? undefined
      : undefined;
  });

  const horizontal = computed(() => {
    if (!shape.value) {
      return true;
    }
    const size = minus(shape.value.p1, shape.value.p0);
    return Math.abs(size.x) >= Math.abs(size.y);
  });

  return useActionMenu({
    referenceEl: selectedEl,
    open: computed(() => !!shape.value),
    disabled: ref(false),
    config: computed(() =>
      horizontal.value
        ? { placement: "top", offset: [0, 5] }
        : { placement: "right", offset: [0, 5] },
    ),
  });
}
