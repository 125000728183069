import type { FlagColor, FlagIcon } from "@/model/CardFlag";
import type CardFlag from "@/model/CardFlag";
import type { StickerIcon } from "@/model/icon";
import { i18n } from "@/translations/i18n";

export const iconMap: Record<FlagIcon, { icon: StickerIcon; key: string }> = {
  // fuck you prettier
  none: { icon: "sticker/none", key: /*$t*/ "label.icon.stickers/none" },
  flag: { icon: "sticker/flag", key: /*$t*/ "label.icon.stickers/flag" },
  check: {
    icon: "sticker/checkmark",
    key: /*$t*/ "label.icon.stickers/checkmark",
  },
  round: { icon: "sticker/dot", key: /*$t*/ "label.icon.stickers/dot" },
  star: { icon: "sticker/star", key: /*$t*/ "label.icon.stickers/star" },
  question: {
    icon: "sticker/question-mark",
    key: /*$t*/ "label.icon.stickers/question-mark",
  },
  exclam: {
    icon: "sticker/exclamation-mark",
    key: /*$t*/ "label.icon.stickers/exclamation-mark",
  },
};

export const colorMap: Record<number, { color: FlagColor; key: string }> = {
  0: { color: "red", key: /*$t*/ "label.color.red" },
  1: { color: "orange", key: /*$t*/ "label.color.orange" },
  2: { color: "yellow", key: /*$t*/ "label.color.yellow" },
  3: { color: "blue", key: /*$t*/ "label.color.blue" },
  4: { color: "purple", key: /*$t*/ "label.color.purple" },
  5: { color: "green", key: /*$t*/ "label.color.green" },
};

/**
 * Returns the name of the flag (color + type)
 */
export const flagName = (flag: CardFlag): string => {
  const type = i18n.global.t(iconMap[flag.icon].key);
  const color =
    flag.icon !== "none" ? i18n.global.t(colorMap[flag.colorIndex].key) : "";
  return i18n.global.t("label.stickyNote.sticker", { type, color });
};
