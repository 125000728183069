<script lang="ts" setup>
import Fuse from "fuse.js";
import { computed, ref } from "vue";

import MenuList from "@/components/ui/MenuList/MenuList.vue";
import MenuListHeader from "@/components/ui/MenuList/components/MenuListHeader.vue";
import MenuListItem from "@/components/ui/MenuList/components/MenuListItem.vue";
import MenuListItems from "@/components/ui/MenuList/components/MenuListItems.vue";
import MenuListSearch from "@/components/ui/MenuList/components/MenuListSearch.vue";
import MenuListTitle from "@/components/ui/MenuList/components/MenuListTitle.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import type { Art } from "@/model/session";

const props = defineProps<{ arts: Art[]; selected?: Art }>();
const emit = defineEmits<{ select: [art: Art | null] }>();

const filter = ref<string>("");
const arts = computed(() => {
  if (!filter.value) return props.arts;
  const fuse = new Fuse(props.arts, { keys: ["name"], isCaseSensitive: false });
  return fuse.search(filter.value).map((r) => r.item);
});

const handleArtChange = (art: Art | null) => {
  emit("select", art);
};
</script>

<template>
  <MenuList>
    <MenuListHeader>
      <MenuListTitle>{{ $t("actions.art") }}</MenuListTitle>
      <MenuListSearch
        :placeholder="$t('action.searchArts')"
        :results-count="arts?.length"
        :icon-title="$t('label.artSelector.searchTitle')"
        @search="filter = $event"
      />
    </MenuListHeader>
    <MenuListItems>
      <MenuListItem
        v-if="selected"
        class="list-item"
        @click="handleArtChange(null)"
      >
        <template #before>
          <div style="width: 20px"></div>
        </template>
        {{ $t("general.noArt") }}
      </MenuListItem>

      <MenuListItem
        v-for="art in arts"
        :key="art.id"
        :selected="art.id === selected?.id"
        class="list-item"
        @click="handleArtChange(art)"
      >
        <template #before>
          <SvgIcon name="org/art" width="20" height="20" />
        </template>
        {{ art.name }}
      </MenuListItem>
    </MenuListItems>
  </MenuList>
</template>
