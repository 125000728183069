<script setup lang="ts">
import { computed } from "vue";

import colors from "@/model/color.module.scss";
import type { AuthUser } from "@/model/user";
import {
  fromCssColor,
  isDarkColor,
  shadeColor,
  toCssColor,
} from "@/utils/color";

import UserAvatar from "./UserAvatar.vue";

const props = defineProps<{ user: AuthUser; black?: boolean }>();

const userColor = computed(() => fromCssColor(props.user.color));
const backgroundColor = computed(() =>
  props.black ? colors.menu : toCssColor(shadeColor(userColor.value, -0.2)),
);
const color = computed(() =>
  props.black || isDarkColor(userColor.value)
    ? colors.backColor
    : colors.textPrimary,
);
</script>

<template>
  <div class="avatar-named" :style="{ backgroundColor }">
    <UserAvatar :user="user" size="small" />
    <span class="name" :style="{ color }">{{ user.name }}</span>
  </div>
</template>

<style lang="scss">
@use "@/styles/variables/colors";
@use "@/styles/mixins/typography";
@use "@/styles/mixins/utils";

.avatar-named {
  display: flex;
  align-items: center;
  height: 28px;
  padding-left: 4px;
  padding-right: 6px;
  gap: 6px;
  border-radius: 14px;
  max-width: 15em;

  .name {
    @include typography.small("semi-bold");
    @include utils.ellipsis;
  }
}
</style>
