import type { StatusDistributionSource } from "@/components/StatusDistribution/StatusDistribution";
import type { Level } from "@/components/modal/stickyLink/types";
import type { BoardType, StatusClass } from "@/model/baseTypes";
import type { Board } from "@/model/board";
import type { ObjectiveType } from "@/model/objective";
import type { Functionality } from "@/model/stickyType";
import type {
  ActionSource,
  BaseTrigger,
  StickyDragTrigger,
  StickyEnlargeTrigger,
  Trigger,
} from "@/model/trigger";

import { toSnakeCase } from "../text/text";

export type AnalyticsEvent = {
  readonly event: string;
  trigger?: Trigger;
} & TrackingParams;

export type TrackingParams = Record<string, string | number | boolean | null>;

/**
 * Handler to transform 'action' events into amplitude format
 *
 * NOTE: these events are more generic, so may be duplicated by other, more specific events
 */
export const actionEvent = ({
  eventName,
  source,
  params,
}: {
  eventName: string;
  source?: ActionSource;
  params?: TrackingParams;
}): AnalyticsEvent => {
  // Transform event name to snake case (action events are in dot.separated.camelCase)
  // eg. "board.workModeChanged" -> "board.work_mode_changed"
  const event = `web.action.${toSnakeCase(eventName)}`;
  return {
    event,
    ...(source ? { trigger: source } : {}),
    ...params,
  };
};

/**
 * Generic event fired anytime the modal is opened
 */
export const modalOpened = (
  trigger?: Trigger,
  modalName?: string,
  params?: TrackingParams,
): AnalyticsEvent => ({
  event: "web.modal.opened",
  ...(trigger ? { trigger } : {}),
  ...(modalName ? { modalName } : {}),
  ...params,
});

/**
 * Generic event fired anytime the modal is closed
 */
export const modalClosed = (
  trigger?: Trigger,
  modalName?: string,
): AnalyticsEvent => ({
  event: "web.modal.closed",
  ...(trigger ? { trigger } : {}),
  ...(modalName ? { modalName } : {}),
});

/**
 * Called when the user leaves the app (eg. closes the tab)
 */
export const appClosed = (): AnalyticsEvent => ({
  event: "web.app.closed",
});

/**
 * Fired when a board is activated (equivalent to a pageview)
 * NOTE: This generally won't fire when switching between two boards
 *       of the same type, eg. from one team board to another
 */
export const boardActivated = ({
  type,
  id,
  teamId,
  artId,
}: {
  type: BoardType;
  id: Board["id"];
  artId: Board["artId"];
  teamId?: string;
}): AnalyticsEvent => ({
  event: "web.board.activated",
  board_type: type,
  board_id: id,
  art_id: artId || null,
  team_id: teamId || null,
});

/**
 * Fired when a board is deactivated (ie. user is leaving the board)
 * NOTE: This generally won't fire when switching between two boards
 *       of the same type, eg. from one team board to another
 */
export const boardDeactivated = ({
  type,
  id,
  teamId,
  artId,
}: {
  type: BoardType;
  id: Board["id"];
  artId: Board["artId"];
  teamId?: string;
}): AnalyticsEvent => ({
  event: "web.board.deactivated",
  board_type: type,
  board_id: id,
  art_id: artId || null,
  team_id: teamId || null,
});

/**
 * Triggered when the user switches tabs in the plan readout modal
 *
 * @param newTab Tab user clicked (tabId)
 * @param previousTab Current tab (tabId)
 */
export const planReadoutTabClicked = (
  newTab: string,
  previousTab: string,
): AnalyticsEvent => {
  return {
    event: "web.plan_readout_modal.tab_clicked",
    newTab,
    previousTab,
  };
};

/**
 * Triggered when the user switches teams in the plan readout modal
 *
 * @param teamId ID of the team the user switched to
 * @param source Whether the user used the dropdown or the arrows to switch teams
 */
export const planReadoutTeamChanged = (
  teamId: string,
  source: "dropdown" | "arrows",
): AnalyticsEvent => {
  return {
    event: "web.plan_readout_modal.team_changed",
    teamId,
    source,
  };
};

/**
 * Triggered when the user clicks on an objective in the plan readout modal
 * @param objectiveId
 */
export const planReadoutSelectObjective = (
  objectiveId: string,
  objectiveType: ObjectiveType,
) => {
  return {
    event: "web.app.plan_readout_modal.objective_selected",
    objectiveId,
    objectiveType,
  };
};

/**
 * Triggered when the user clicks on a risk in the plan readout modal
 */
export const planReadoutSelectRisk = (riskId: string) => {
  return {
    event: "web.plan_readout_modal.risk_selected",
    riskId,
  };
};

/**
 * Triggered when the expands/shrinks the 'linked stickies' section
 *
 * @param open True when the section is opened
 */
export const planReadoutObjectiveLinksToggled = (
  open: boolean,
): AnalyticsEvent => {
  return {
    event: "web.plan_readout_modal.objective_links_toggled",
    open,
  };
};

/***
 * OBJECTIVES
 ***/

// Triggered when the user marks an objective as committed / uncommmitted
export const objectiveMarkedAs = (
  id: string,
  stretch: boolean,
): AnalyticsEvent => {
  return {
    event: "web.team_objectives_modal.objective_marked_as",
    objectiveId: id,
    stretch,
  };
};

/***
 * GENERAL SETTINGS
 ***/

/**
 * Triggered when the tab's visibility state changes
 * https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event
 */
export const tabVisibilityStateChanged = (
  state: "hidden" | "visible",
): AnalyticsEvent => ({
  event: "web.tab_visibility_state_changed",
  state,
});

/***
 * STICKY NOTE
 ***/

/**
 * Triggered after user create a sticky note
 */
export const stickyNoteCreated = (
  type: Functionality,
  originBoard: BoardType,
  trigger?: Trigger,
  fromDefaultType?: boolean,
): AnalyticsEvent => ({
  event: "web.sticky_note.created",
  type,
  originBoard,
  ...(trigger ? { trigger } : {}),
  ...(fromDefaultType ? { fromDefaultType } : {}),
});

// Triggered when the user pins the sticky note
export const stickyNotePinned = (
  stickyType: Functionality,
  trigger: Extract<BaseTrigger, "action-menu" | "long-press">,
): AnalyticsEvent => ({
  event: "web.sticky_note.pinned",
  stickyType,
  trigger,
});

// Triggered when the user unpins the sticky note
export const stickyNoteUnpinned = (
  stickyType: Functionality,
  trigger: Extract<BaseTrigger, "action-menu" | "long-press">,
): AnalyticsEvent => ({
  event: "web.sticky_note.unpined",
  stickyType,
  trigger,
});

/**
 * Triggered when the user changes the board
 */
export const boardChanged = (
  prevBoard: BoardType,
  targetBoard: BoardType,
  trigger?: Trigger,
): AnalyticsEvent => ({
  event: "web.board.changed",
  prevBoard,
  targetBoard,
  ...(trigger ? { trigger } : {}),
});

/**
 * Triggered when the user opens a chip in the StickyNote
 */
export const stickyNoteAttributeChipClicked = (
  chipName: string,
  stickyType: Functionality,
  isStickyActive: boolean,
): AnalyticsEvent => ({
  event: "web.sticky_note.attribute_chip_clicked",
  stickyType,
  chipName,
  isStickyActive,
});

// Triggered when the user opens an item in the action menu of the StickyNote
export const stickyNoteActionMenuItemClicked = (
  itemName: string,
  stickyType: Functionality,
): AnalyticsEvent => ({
  event: "web.sticky_note.action_menu_item_clicked",
  stickyType,
  chipName: itemName,
});

export const shapeActionMenuItemClicked = (
  itemName: string,
): AnalyticsEvent => ({
  event: "web.shape.action_menu_item_clicked",
  chipName: itemName,
});

// Triggered when the user mirrors a sticky note
export const stickyNoteMirrored = (
  stickyType: Functionality,
  fromBoard: BoardType,
  toBoard: BoardType,
  trigger: Trigger,
): AnalyticsEvent => ({
  event: "web.sticky_note.mirrored",
  stickyType,
  fromBoard,
  toBoard,
  trigger,
});

// Triggered when the user changes the sticky type
export const stickyNoteTypeChanged = (
  fromType: Functionality,
  toType: Functionality,
  trigger: Trigger,
) => ({
  event: "web.sticky_note.type_changed",
  fromType,
  toType,
  trigger,
});

// Triggered when the user changes the sticky status
export const stickyNoteStatusChanged = (
  fromStatus: StatusClass,
  toStatus: StatusClass,
  stickyType: Functionality,
  trigger: Trigger,
) => ({
  event: "web.sticky_note.status_changed",
  from: fromStatus,
  to: toStatus,
  stickyType,
  trigger,
});

// Triggered when the status distribution popup is shown
export const statusDistributionShown = (
  itemType: StatusDistributionSource,
) => ({ event: "web.status_distribution.shown", itemType });

// Triggered when the status distribution popup is hidden
export const statusDistributionHidden = (
  itemType: StatusDistributionSource,
) => ({ event: "web.status_distribution.hidden", itemType });

// Triggered when the user links a sticky note to an objective
export const objectiveLinkCreated = (
  fromId: string,
  fromType: Functionality,
  fromBoardType: BoardType,
  toId: string,
  toBoardType: BoardType,
  trigger?: Trigger,
) => ({
  event: "web.sticky_note.link_to_objective_created",
  fromId,
  fromType,
  fromBoardType,
  toId,
  toBoardType,
  ...(trigger ? { trigger } : {}),
});

// Triggered when the user removes a link between sticky note and objective
export const objectiveLinkRemoved = (
  fromId: string,
  fromType: Functionality,
  fromBoardType: BoardType,
  toId: string,
  toBoardType: BoardType,
  trigger?: Trigger,
) => ({
  event: "web.sticky_note.link_to_objective_removed",
  fromId,
  fromType,
  fromBoardType,
  toId,
  toBoardType,
  ...(trigger ? { trigger } : {}),
});

// Triggered when the user links a sticky note to a sticky note
export const stickyLinkCreated = (
  fromId: string,
  fromType: Functionality,
  fromBoardType: BoardType,
  toId: string,
  toType: Functionality,
  trigger?: Trigger,
): AnalyticsEvent => ({
  event: "web.sticky_note.link_to_sticky_created",
  fromId,
  fromType,
  fromBoardType,
  toId,
  toType,
  ...(trigger ? { trigger } : {}),
});

// Triggered when the user removes a link between two stickies
export const stickyLinkRemoved = (
  fromId: string,
  fromType: Functionality,
  fromBoardType: BoardType,
  toId: string,
  toType: Functionality,
  trigger?: Trigger,
): AnalyticsEvent => ({
  event: "web.sticky_note.link_to_sticky_removed",
  fromId,
  fromType,
  fromBoardType,
  toId,
  toType,
  ...(trigger ? { trigger } : {}),
});

// Triggered when the user changes the wsjf value
export const stickyNoteWsjfChanged = (
  stickyType: Functionality,
  trigger?: Trigger,
): AnalyticsEvent => ({
  event: "web.sticky_note.wsjf_changed",
  stickyType,
  ...(trigger ? { trigger } : {}),
});

// Triggered when the user changes the storypoints of a sticky note
export const stickyNoteStoryPointsChanged = (
  stickyType: Functionality,
  trigger: Trigger,
): AnalyticsEvent => ({
  event: "web.sticky_note.storypoints_changed",
  stickyType,
  trigger,
});

// Triggered when the user changes the project of a sticky note
export const stickyNoteProjectChanged = (
  stickyType: Functionality,
  trigger: Trigger,
): AnalyticsEvent => ({
  event: "web.sticky_note.project_changed",
  stickyType,
  trigger,
});

// Triggered when the user changes the depend on team of a dependency sticky note
export const stickyNoteDependOnTeamChanged = (
  stickyType: Functionality,
  isSameArt: boolean,
  trigger?: Trigger,
): AnalyticsEvent => ({
  event: "web.sticky_note.depend_on_team_changed",
  stickyType,
  isSameArt,
  ...(trigger ? { trigger } : {}),
});

// Triggered when the user adds a timer
export const timerAdded = (scope: "board" | "art"): AnalyticsEvent => ({
  event: "web.timer.added",
  type: scope,
});

// Triggered when the user starts a timer
export const timerStarted = (scope: "board" | "art"): AnalyticsEvent => ({
  event: "web.timer.started",
  scope,
});

// Triggered when the user opens the search sidebar
export const searchOpened = (trigger?: Trigger): AnalyticsEvent => ({
  event: "web.search.opened",
  ...(trigger ? { trigger } : {}),
});

// Triggered when the user closes the search sidebar
export const searchClosed = (
  keepFilters?: boolean,
  trigger?: Trigger,
): AnalyticsEvent => ({
  event: "web.search.closed",
  ...(keepFilters ? { keepFilters } : {}),
  ...(trigger ? { trigger } : {}),
});

// Triggered when the user opens the board diff sidebar
export const boardDiffOpened = (trigger: Trigger): AnalyticsEvent => ({
  event: "web.board_diff.opened",
  trigger,
});

// Triggered when the user closes the board diff sidebar
export const boardDiffClosed = (trigger: Trigger): AnalyticsEvent => ({
  event: "web.board_diff.closed",
  trigger,
});

// Triggered when the user opens a sticky note diff
export const boardDiffItemOpened = (): AnalyticsEvent => ({
  event: "web.board_diff.item_opened",
});

// Triggered when the user closes a sticky note diff
export const boardDiffItemClosed = (): AnalyticsEvent => ({
  event: "web.board_diff.item_closed",
});

// Triggered when the user changes the timespan in the board diff dropdown
export const boardDiffTimespanChanged = (value: string): AnalyticsEvent => ({
  event: "web.board_diff.timespan_changed",
  value,
});

// Triggered when the user changes the tab in the metrics modal
export const metricsModalTabChanged = (tab: string): AnalyticsEvent => ({
  event: "web.metrics_modal.tab_changed",
  tab,
});

/**
 * Triggered when the user hovers over the team iteration metrics popup
 */
export const iterationMetricsPopupHovered = (
  shown: boolean,
): AnalyticsEvent => ({
  event: "web.iteration_metrics_popup.hovered",
  shown,
});

// Triggered when the user opens a search filter
export const searchFilterOpened = (filterName: string): AnalyticsEvent => ({
  event: "web.search.filter_opened",
  filterName,
});

// when the user opens the app zoom menu in the top right corner of the app
export const appZoomOpened = (): AnalyticsEvent => ({
  event: "web.topbar-menu.app_zoom_opened",
});

// Triggered when the user changes the zoom level using the app zoom slider
// or the +/- buttons at the top right corner of the app
export const appZoomZoomChanged = (): AnalyticsEvent => ({
  event: "web.topbar-menu.app_zoom_changed",
});

// triggered when the user deactivates a StickyNote.
export const stickyNoteDeactivated = (
  stickyType: Functionality,
): AnalyticsEvent => ({
  event: "web.sticky_note.deactivated",
  stickyType,
});

// triggered when the user activates a StickyNote.
export const stickyNoteActivated = (
  stickyType: Functionality,
): AnalyticsEvent => ({
  event: "web.sticky_note.activated",
  stickyType,
});

// triggered when the user changes the text of a StickyNote. Triggered only once per focus
export const stickyNoteTextChanged = (
  stickyType: Functionality,
  textLengthBeforeEdit: number,
): AnalyticsEvent => ({
  event: "web.sticky_note.text_changed",
  stickyType,
  textLengthBeforeEdit,
});

// triggered when the user enlarges the StickyNote.
export const stickyNoteEnlarged = (
  stickyType: Functionality,
  trigger?: StickyEnlargeTrigger,
): AnalyticsEvent => ({
  event: "web.sticky_note.enlarged",
  stickyType,
  ...(trigger ? { trigger } : {}),
});

// triggered when the user shrinks the StickyNote.
export const stickyNoteShrunk = (
  stickyType: Functionality,
): AnalyticsEvent => ({
  event: "web.sticky_note.shrunk",
  stickyType,
});

// triggered when the user changes the search text. Triggered once per focus
export const searchTextChanged = (): AnalyticsEvent => ({
  event: "web.search.text_changed",
});

// triggered when the user select an item in the search sidebar
export const searchItemSelected = (
  currentBoardType: BoardType,
  targetBoardType?: BoardType,
): AnalyticsEvent => ({
  event: "web.search.item_selected",
  currentBoardType,
  ...(targetBoardType ? { targetBoardType } : {}),
});

// triggers when the user clicks the link drag on the sticky nore
export const stickyNoteLinkDragStarted = (
  stickyType: Functionality,
): AnalyticsEvent => ({
  event: "web.sticky_note.link_drag_started",
  stickyType,
});

// triggereted when the user clicks the rte cockpit button
export const rteCockpitButtonClicked = (): AnalyticsEvent => ({
  event: "web.session_select_page.cockpit_button_clicked",
});

// triggereted when the user clicks the "show archived sessions" checkbox
export const showArchivedSessionClicked = (value: boolean): AnalyticsEvent => ({
  event: "web.session_select_page.show_archived_clicked",
  value,
});

// when the user select a session in the session page
export const sessionSelected = (): AnalyticsEvent => ({
  event: "web.session_select_page.session_selected",
});

// when the user opens the user actions menu at the top right corner of the app
export const userActionsMenuOpened = (): AnalyticsEvent => ({
  event: "web.app.user_actions_menu_opened",
});

/**
 * Drag Events
 */
export const stickyNoteDragStarted = (
  trigger: StickyDragTrigger,
): AnalyticsEvent => ({
  event: "web.sticky_note.keyboard_drag_started",
  trigger,
});
export const stickyNoteDragMoved = (
  trigger: StickyDragTrigger,
): AnalyticsEvent => ({
  event: "web.sticky_note.keyboard_drag_moved",
  trigger,
});
export const stickyNoteDragEnded = (
  trigger: StickyDragTrigger,
): AnalyticsEvent => ({
  event: "web.sticky_note.keyboard_drag_ended",
  trigger,
});

/**
 * STATUS PAGE & ERROR MODAL
 */
// When a globalError is set (opening the error dialog)
export const errorDialogSeen = (): AnalyticsEvent => ({
  event: "web.error_dialog.seen",
});

// When the user clicks the reload button in the error dialog
export const errorDialogReloadClicked = (): AnalyticsEvent => ({
  event: "web.error_dialog.reload_clicked",
});

// When the user clicks the status link in the error dialog
export const errorDialogStatusLinkClicked = (): AnalyticsEvent => ({
  event: "web.error_dialog.status_link_clicked",
});

// When the user clicks the connection status link in the user menu
export const userMenuStatusLinkClicked = (): AnalyticsEvent => ({
  event: "web.user_menu.status_link_clicked",
});

/**
 * LINK MODAL
 */

// Triggered when search is used
export const linkModalSearchUsed = (): AnalyticsEvent => ({
  event: "web.link_modal.search_used",
});

// Triggered when expand/collapse Org Tree button is used
export const linkModalOrgTreeToggled = (): AnalyticsEvent => ({
  event: "web.link_modal.org_tree_toggled",
});

// Triggered when Org Tree level is selected
export const linkModalOrgTreeLevelSelected = (
  level: Level,
): AnalyticsEvent => ({
  event: "web.link_modal.org_tree_level_selected",
  level,
});

// Triggered when filter is opened
export const linkModalFilterOpened = (): AnalyticsEvent => ({
  event: "web.link_modal.filter_opened",
});

// Triggered when filter type is selected
export const linkModalFilterTypeSelected = (
  type: Functionality | "objective",
): AnalyticsEvent => ({
  event: "web.link_modal.filter_type_selected",
  type,
});

// Triggered when filter type is deselected
export const linkModalFilterTypeDeselected = (
  type: Functionality | "objective",
): AnalyticsEvent => ({
  event: "web.link_modal.filter_type_deselected",
  type,
});

/**
 * DRAW MODE
 */

export const drawModeClosed = (
  trigger: Extract<BaseTrigger, "context-menu" | "board-actions-menu">,
): AnalyticsEvent => ({
  event: "web.draw_mode.closed",
  trigger,
});

export const drawLineModeSelected = (): AnalyticsEvent => ({
  event: "web.draw_mode.line_mode_selected",
});

export const drawMoveModeSelected = (): AnalyticsEvent => ({
  event: "web.draw_mode.move_mode_selected",
});

export const drawShapeSelected = (): AnalyticsEvent => ({
  event: "web.draw_mode.shape_selected",
});

export const drawTextEdited = (): AnalyticsEvent => ({
  event: "web.draw_mode.text_edited",
});
export const drawAlignEdited = (): AnalyticsEvent => ({
  event: "web.draw_mode.align_edited",
});

/**
 * SWITCH SESSION
 */

export const switchSessionOrgTreeLevelSelected = (
  level: Level,
): AnalyticsEvent => ({
  event: "web.switch_session.org_tree_level_selected",
  level,
});
