<script setup lang="ts">
import { computed } from "vue";

import SelectStatusClass from "@/components/ui/SelectStatusClass/SelectStatusClass.vue";
import type { StatusClass } from "@/model/baseTypes";
import { statusClassItems, useSearchMenuStore } from "@/store/searchMenu";

import StatusClassItemTooltip from "../tooltips/StatusClassItemTooltip.vue";
import SearchDropdown from "./SearchDropdown.vue";

const store = useSearchMenuStore();

const selection = computed({
  get: () => store.selectedStatusClassItems,
  set: (values: Array<{ id: StatusClass }>) =>
    (store.selectedStatusClasses = new Set(values.map((val) => val.id))),
});
</script>

<template>
  <SearchDropdown
    name="status"
    icon="general/status"
    label-key="general.statusClasses"
    :selected="selection.length > 0"
  >
    <template #tooltip>
      <StatusClassItemTooltip :status-class-items="selection" />
    </template>
    <SelectStatusClass v-model="selection" :status-classes="statusClassItems" />
  </SearchDropdown>
</template>
