<script setup lang="ts">
import { cardActions } from "@/action/cardActions";
import { actionSourceKey } from "@/components/action/injectKeys";
import TeamSelector from "@/components/card/components/TeamSelector/TeamSelector.vue";
import { cardKey, cardMethodsKey } from "@/components/card/injectKeys";
import { dropdownKey } from "@/components/ui/DropdownMenu/injectKeys";
import type { Team } from "@/model/session";
import { injectStrict } from "@/utils/vue";

const card = injectStrict(cardKey);
const dropdown = injectStrict(dropdownKey);
const cardMethods = injectStrict(cardMethodsKey);
const actionSource = injectStrict(actionSourceKey);

const handleChangeDependentOn = (team: Team | null) => {
  cardActions.setDepend(actionSource, card.id, team);
  if (team?.id) {
    void cardMethods.animate?.("mirroring");
  }
  dropdown.close();
};
</script>

<template>
  <TeamSelector
    show-other-arts
    :title="$t('actionMenu.dependentOn')"
    @select="handleChangeDependentOn"
  />
</template>
