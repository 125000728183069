import type { InjectionKey, Ref } from "vue";

import type { Board } from "@/model/board";
import type { BoardCoordinate } from "@/model/coordinates";

export const boardKey: InjectionKey<Readonly<Ref<Board>>> = Symbol("board");
export const boardMetaKey: InjectionKey<Readonly<BoardMeta>> =
  Symbol("boardMeta");

// provided from the FluidBoard down to each child
export interface BoardMeta {
  size: BoardCoordinate;
}
