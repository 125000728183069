import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M12.126 4.08a8.002 8.002 0 1 0 6.42 10.587 1 1 0 0 1 1.887.667A10.002 10.002 0 1 1 18.066 4.92L21 7.684V3.998a1 1 0 1 1 2 0V10a1 1 0 0 1-1 1h-6.001a1 1 0 0 1 0-2h3.48l-2.795-2.633-.022-.022a8 8 0 0 0-4.536-2.266",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }