<script lang="ts" setup>
import { computed } from "vue";

import { cardActions } from "@/action/cardActions";
import { actionSourceKey } from "@/components/action/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import { dropdownKey } from "@/components/ui/DropdownMenu/injectKeys";
import MenuList from "@/components/ui/MenuList/MenuList.vue";
import MenuListHeader from "@/components/ui/MenuList/components/MenuListHeader.vue";
import MenuListItem from "@/components/ui/MenuList/components/MenuListItem.vue";
import MenuListItems from "@/components/ui/MenuList/components/MenuListItems.vue";
import MenuListTitle from "@/components/ui/MenuList/components/MenuListTitle.vue";
import type { AlmSourceId } from "@/model/baseTypes";
import { useBoardsStore } from "@/store/boards";
import { injectStrict } from "@/utils/vue";

const card = injectStrict(cardKey);
const dropdown = injectStrict(dropdownKey);
const actionSource = injectStrict(actionSourceKey);

const sources = computed(() => {
  return useBoardsStore().boardByType(card.type.origin)?.almSources;
});

const handleProjectChange = (id: AlmSourceId) => {
  cardActions.setAlmSource(actionSource, card, id);
  dropdown.close();
};
</script>

<template>
  <MenuList role="list" class="change-project" selectable>
    <MenuListHeader>
      <MenuListTitle>{{ $t("cardAction.project") }}</MenuListTitle>
    </MenuListHeader>
    <MenuListItems :aria-label="$t('cardAction.project')">
      <MenuListItem
        v-for="source in sources"
        :key="source.id"
        role="option"
        :selected="source.id === card.almSourceId"
        @click="handleProjectChange(source.id)"
      >
        {{ source.name }}
      </MenuListItem>
    </MenuListItems>
  </MenuList>
</template>
