<script setup lang="ts">
import { useI18n } from "vue-i18n";

import BaseFlag from "@/components/card/BaseFlag.vue";
import StatusDot from "@/components/ui/StatusDot/StatusDot.vue";
import TypeDot from "@/components/ui/TypeDot/TypeDot.vue";
import type { Card } from "@/model/card";
import { useSessionStore } from "@/store/session";
import { useTeamStore } from "@/store/team";

defineProps<{
  item?: Partial<Card>;
  showStatus?: boolean;
  showTeam?: boolean;
}>();

const getTeam = (id: string | null | undefined) =>
  id ? useTeamStore().findTeam({ id })?.name : useI18n().t("general.noTeam");

const getIteration = (id: number) => useSessionStore().getIterationById(id);
</script>

<template>
  <div v-if="item" class="result-card item" tabindex="-1">
    <div v-if="item.type">
      <TypeDot size="large" :color="item.type.color" />
    </div>
    <div class="result-text">
      <div class="main">{{ item.text }}</div>
      <div class="details">
        <div v-if="showTeam" class="shrinkable">
          {{ getTeam(item.teamId) }}
        </div>
        <div v-if="item.type" class="shrinkable">{{ item.type.name }}</div>
        <div v-if="item.almId">{{ item.almId }}</div>
        <div v-if="item.iterationId">
          {{ getIteration(item.iterationId)?.name }}
        </div>
        <div v-if="item.flagType && !item.flagType.isEmpty()">
          <div class="flag-container">
            <BaseFlag :flag="item.flagType" />
          </div>
        </div>
        <div
          v-if="showStatus && item.status"
          class="status-dot-container"
          data-testid="status"
        >
          <StatusDot :status-class="item.status.statusClass" radar-type />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins/utils";

.result-text {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  overflow: hidden;

  .main {
    @include utils.ellipsis;

    line-height: initial;
    font-size: font.$size-normal;
  }

  .details {
    display: flex;
    color: colors-old.$text-secondary-color;
    white-space: nowrap;
    align-items: center;
    gap: 0.125rem;
    vertical-align: center;
    height: 1rem;
    font-size: font.$size-small;

    .shrinkable {
      @include utils.ellipsis;

      display: block;
    }

    div:not(:last-child)::after,
    span:not(:last-child)::after {
      content: "\00a0|\00a0";
      color: colors-old.$divider-color;
      padding-left: 0.125rem;
    }

    .flag-container {
      height: 1rem;
      width: 1rem;
      display: inline-block;
      vertical-align: bottom;
    }

    .flag-icon {
      height: 1rem;
      width: 1rem;
      padding: 0;
    }

    & > div {
      display: flex;
      align-items: center;
    }

    .status-dot-container {
      align-content: center;
      height: 1rem;
      width: 1rem;
    }
  }
}

.result-card {
  display: flex;
  align-items: center;
  padding: 10px 12px;

  &.current {
    background-color: colors-old.$light-background-color;

    .high-contrast-mode & {
      // In high-contrast mode, the background color alone has too low contrast
      outline: 2px solid colors.$outline;
      outline-offset: -2px;
    }

    .result-text {
      .details {
        div:not(:last-child)::after,
        span:not(:last-child)::after {
          content: "\00a0|\00a0";
          color: colors-old.$divider-color;
        }
      }
    }
  }

  .card-icon {
    width: 2rem;
    height: 2rem;
  }
}
</style>
