import type { InjectionKey } from "vue";
import { inject, nextTick } from "vue";

import { performanceLog } from "../general";

export function nextTicks(ticks: number, callback: () => void) {
  const start = performance.now();
  if (ticks === 0) {
    callback();
  } else {
    void nextTick(() => {
      performanceLog(`tick ${ticks}`, start);
      nextTicks(ticks - 1, callback);
    });
  }
}

/**
 * Injects a value from the Vue composition API's injection system.
 * If the value is not found, an error is thrown.
 */
export function injectStrict<T>(key: InjectionKey<T>, fallback?: T) {
  const resolved = inject(key, fallback);
  if (!resolved) {
    throw new Error(`Could not find "${key.description}" in the context.`);
  }
  return resolved;
}
