import type { RelativeCoordinate } from "@/model/coordinates";
import type { Team } from "@/model/session";
import { useArtStore } from "@/store/art";
import { useBoardsStore } from "@/store/boards";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useSessionStore } from "@/store/session";
import { useStickyTypeStore } from "@/store/stickyType";
import { useTeamStore } from "@/store/team";
import { i18n } from "@/translations/i18n";

import { BasePlanningBoard } from "./BasePlanningBoard";
import { PlanningBoardLocation } from "./location/PlanningBoardLocation";

export class ProgramBoard extends BasePlanningBoard<"program"> {
  get groups() {
    const teamIterations = Object.fromEntries(
      useBoardsStore()
        .artTeamBoards()
        .map((board) => [
          board.team.id,
          board.iterations.map((bi, index) => ({
            load: bi.load,
            velocity: bi.velocity,
            ...useSessionStore().iterations[index],
          })),
        ]),
    );

    return [
      this.nonTeamGroup(i18n.global.t("programBoard.milestoneEvents")),
      ...useTeamStore()
        .teamsInArt(this.board.artId || "")
        .map(teamGroup),
      ...(useArtStore().isMultiArt
        ? [this.nonTeamGroup(i18n.global.t("programBoard.otherArts"))]
        : []),
      this.nonTeamGroup(""),
    ];

    function teamGroup(team: Team) {
      return {
        id: team.id,
        name: team.name,
        current: team.id === useTeamStore().current.id,
        iterations: teamIterations[team.id],
      };
    }
  }

  location(pos: RelativeCoordinate | number, top?: number) {
    return PlanningBoardLocation.ofTeams(this.groups, pos, top);
  }

  positionalCardProperties(pos: RelativeCoordinate) {
    const loc = this.location(pos);
    return { iterationId: loc.iterationId, teamId: loc.groupId };
  }

  get showPermanentLinks() {
    return useClientSettingsStore().permanentProgramLinks;
  }

  get possibleStickyTypes() {
    return useStickyTypeStore().nonFlexBoardStickyTypes("program");
  }
}
