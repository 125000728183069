<script setup lang="ts">
import { ref } from "vue";

import hot from "@/assets/reactions/reaction-hot.svg";
import increment from "@/assets/reactions/reaction-increment.svg";
import love from "@/assets/reactions/reaction-love.svg";
import type { Reaction } from "@/model/card";

defineProps<{ reaction: Reaction }>();

const rootRef = ref();
defineExpose({ rootRef });

const image: { [reaction in Reaction]: string } = { hot, increment, love };

const label: { [reaction in Reaction]: string } = {
  hot: /*$t*/ "label.reaction.hot",
  increment: /*$t*/ "label.reaction.increment",
  love: /*$t*/ "label.reaction.love",
};
</script>

<template>
  <img ref="rootRef" :src="image[reaction]" :alt="$t(label[reaction])" />
</template>

<style lang="scss" scoped>
@use "@/styles/board";

img {
  height: board.len(40px);
}
</style>
