<script lang="ts" setup>
import { computed } from "vue";

import SetWsjf from "@/components/card/components/SetWsjf/SetWsjf.vue";
import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
import { useServerSettingsStore } from "@/store/serverSettings";
import { injectStrict } from "@/utils/vue";

import AttributeChip from "../../../AttributeChip/AttributeChip.vue";

const card = injectStrict(cardKey);
const cardMeta = injectStrict(cardMetaKey);

const priority = computed(() =>
  card.type.priorities
    ? card.type.priorities[card.priority]?.text || ""
    : card.priority.toString(),
);

const readonly = computed(
  () => cardMeta.isReadonly || !useServerSettingsStore().isPriorityEditable,
);

const tooltipKey = useServerSettingsStore().isPriorityEditable
  ? /*$t*/ "actions.wsjf"
  : /*$t*/ "actions.wsjfWarning";
</script>

<template>
  <AttributeChip
    name="wsjf-chip"
    :text="priority"
    :tooltip="$t(tooltipKey)"
    :readonly="readonly"
    :dropdown-props="{ width: 'auto' }"
  >
    <SetWsjf />
  </AttributeChip>
</template>
