import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M15.444 3.685a9 9 0 1 0-4.749 17.22l-.29-1.163a1 1 0 0 1-.026-.155 8.5 8.5 0 0 1 9.208-9.208 1 1 0 0 1 .155.026l1.163.29a9 9 0 0 0-5.46-7.01m4.6 8.857-.707-.177a6.5 6.5 0 0 0-6.972 6.972l.177.707zM9.854 1.212A11 11 0 0 1 23 12a1 1 0 0 1-.293.707l-10 10A1 1 0 0 1 12 23 11 11 0 0 1 9.854 1.211",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }