<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { toggleActions } from "@/action/toggleActions";
import { actionSourceKey } from "@/components/action/injectKeys";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { useShortcuts } from "@/composables/useShortcuts";
import { useWorkModeStore } from "@/store/workMode";
import { injectStrict } from "@/utils/vue";

const { isExecutionMode, isPlanningMode } = storeToRefs(useWorkModeStore());
const { t } = useI18n();
const actionSource = injectStrict(actionSourceKey);

const tooltip = computed(() =>
  t("action.switchWorkMode", {
    mode: isExecutionMode.value
      ? t("action.planMode")
      : t("action.executionMode"),
  }),
);

const { registerShortcut } = useShortcuts();
registerShortcut(toggleActions.toggleWorkMode, { prevent: true });
</script>

<template>
  <BaseTooltip>
    <template #content>
      {{ tooltip }}
      <span class="shortcut">
        {{ toggleActions.toggleWorkMode.data.shortcut.name() }}
      </span>
    </template>
    <button
      class="mode-toggle-button"
      :class="{ dark: isExecutionMode, execution: isExecutionMode }"
      :aria-label="tooltip"
      @click="toggleActions.toggleWorkMode(actionSource)"
    >
      <div class="side" :class="{ active: isPlanningMode }">
        <SvgIcon name="menu-top/pi" height="20" width="20" />
      </div>
      <div class="side" :class="{ active: isExecutionMode }">
        <SvgIcon name="menu-top/progress" height="20" width="20" />
      </div>
    </button>
  </BaseTooltip>
</template>

<style lang="scss" scoped>
@use "@/styles/variables/colors";
@use "@/styles/global/theme-dark";

//  todo, finish after design tokens are done
.mode-toggle-button {
  cursor: pointer;
  outline: revert;
  margin-left: 8px;
  outline-offset: 2px;
  display: flex;
  gap: 2px;
  border: 1px solid colors.$border-subtle;
  border-radius: 6px;
  padding: 2px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 32px;
    height: 32px;
    background-color: colors.$primary-brand-light;
    border-radius: 4px;
    transition: transform 0.3s ease;
    left: 2px;
  }

  &.execution::before {
    transform: translateX(34px);
  }

  .side {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: colors.$icon-subtle;
    position: relative;

    &.active {
      color: colors.$palette-brand-60;
    }
  }
}
</style>
