import { nextTick } from "vue";

import { isAlmSync } from "@/backend/Backend";
import { sender } from "@/backend/Sender";
import CardCreationMenu from "@/components/card/CardCreationMenu.vue";
import { findFreePosition } from "@/components/utils/layout";
import { sendStickyNoteAction } from "@/composables/useEventBus";
import { relativeToWindow, windowToRelative } from "@/math/coordinate-systems";
import { minus, plus, times } from "@/math/coordinates";
import CardFlag from "@/model/CardFlag";
import type { AlmSourceId } from "@/model/baseTypes";
import type { BoardCard } from "@/model/card";
import type { WindowCoordinate } from "@/model/coordinates";
import { centerCoord, scrollCoord, windowCoord } from "@/model/coordinates";
import type { StickyType } from "@/model/stickyType";
import type { Trigger } from "@/model/trigger";
import { useAlmItemTypeStore } from "@/store/almItemType";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import type { CardEvent } from "@/store/card";
import { useCardStore } from "@/store/card";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useContextMenuStore } from "@/store/contextMenu";
import { useLinkStore } from "@/store/link";
import { usePanModeStore } from "@/store/panMode";
import { useUserStore } from "@/store/user";
import { stickyLinkCreated, stickyNoteCreated } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { isFeatureEnabled, parseUrlWithoutRouter } from "@/utils/env/feature";
import { removeNonPrintable } from "@/utils/general";

import { boardActions } from "./boardActions";
import { linkActions } from "./linkActions";
import { markActions } from "./mark";
import type { ActionContext } from "./types";

export interface CardCreateProps {
  type: StickyType;
  pos: WindowCoordinate;
  text?: string;
  almSourceId?: AlmSourceId;
  onClose?: () => void;
}

export async function startAddCard(
  ctx: ActionContext,
  props: Partial<CardCreateProps> = {},
  focus = true,
) {
  if (!useUserStore().isAllowed("edit")) return;

  assurePos(props);
  props.type ??= getDefaultStickyNoteType();

  if (props.type && isAlmSourceOk(props) && !props.text?.includes("\n")) {
    return addAndTrack(props.type, focus, {});
  }

  useContextMenuStore().open(
    CardCreationMenu,
    { position: props.pos!, card: props },
    { select, close: () => props.onClose?.() },
  );

  async function select(
    type: StickyType,
    almSourceId: AlmSourceId | undefined,
    multiCreate: boolean,
  ) {
    if (multiCreate) {
      const addPromises = props
        .text!.split("\n")
        .map((line) => line.trim())
        .filter((line) => line.length > 0)
        .map((text, index) => {
          const pos = plus(
            props.pos!,
            relativeToWindow(times(cardPosStep(), index)),
          );
          return addAndTrack(type, false, { almSourceId, text, pos });
        });
      const boardId = useBoardStore().currentBoard().id;
      for (const id of await Promise.all(addPromises)) {
        boardActions.cardToFront("internal", id, boardId);
        useBoardStore().selectSticky(id);
      }
    } else {
      addAndTrack(type, focus, { almSourceId });
    }
  }

  function addAndTrack(
    type: StickyType,
    focus: boolean,
    additional: Record<string, unknown>,
  ) {
    trackCreateStickyNoteEvent(type, !!getDefaultStickyNoteType(), ctx.source);
    return addCard({ ...props, type, ...additional } as CardCreateProps, focus);
  }
}

function getDefaultStickyNoteType() {
  const id = useClientSettingsStore().defaultStickyTypeId;
  return useBoardStore().creatableStickyTypes.find((type) => type.id === id);
}

function assurePos(props: Partial<CardCreateProps>) {
  if (!props.pos) {
    const activeElement = document.activeElement;

    // If the user is focused on a board element, create the new sticky at that location
    if (activeElement?.closest(".board")) {
      const { left, top, width, height } =
        activeElement.getBoundingClientRect();
      const f = activeElement.classList.contains("sticky-note") ? 1.5 : 2; // Avoid creating a sticky note on top of another one

      props.pos = windowCoord(left + width / f, top + height / f);
    } else {
      // Otherwise, create the new sticky in the center of the board
      props.pos = centerCoord();
    }
  }
}

function trackCreateStickyNoteEvent(
  type: StickyType,
  defaultStickyType: boolean,
  trigger?: Trigger,
) {
  trackEvent(
    stickyNoteCreated(
      type.functionality,
      type.origin,
      trigger,
      defaultStickyType,
    ),
  );
}

function isAlmSourceOk(props: Partial<CardCreateProps>) {
  const { type, pos } = props;
  if (!pos || !type) return false;
  const originBoard = useBoardsStore().stickyTypeOriginBoard(type, pos);
  return props.almSourceId !== undefined || originBoard.almSources.length <= 1;
}

export async function addCard(props: CardCreateProps, focus = true) {
  if (isFeatureEnabled({ query: parseUrlWithoutRouter() }, "pan-mode")) {
    usePanModeStore().inactivate();
  }
  const board = useBoardStore().currentBoard();
  props = sanitize(props);
  const originBoard = useBoardsStore().stickyTypeOriginBoard(
    props.type,
    props.pos,
  );
  const almSourceId = props.almSourceId ?? originBoard.almSources[0]?.id;
  const status = useAlmItemTypeStore().calcStatus(
    undefined,
    props.type,
    board.type === "team" ? board.team.id : undefined,
    board.artId,
    almSourceId,
  );
  const pos = windowToRelative(props.pos);
  const ev: CardEvent = {
    id: "",
    boardId: board.id,
    pos,
    text: props.text,
    type: props.type,
    status,
    priority: 0,
    iterationId: null,
    teamId: null,
    zIndex: 0,
    flagType: CardFlag.emptyFlag(),
    objectives: [],
    almSourceId,
    artId: board.artId || null,
    ...useBoardStore().positionalCardProperties(pos),
  };
  ev.id = await sender.addCard(board.id, { ...ev, ...ev.pos });
  useCardStore().add(ev);
  // Make sure the new card matches the current faded state (eg. when a card is pinned)
  linkActions.updateCardLinkedMarks("internal");
  const pinned = useLinkStore().markingCardLinkedCards;
  if (pinned) {
    trackEvent(
      stickyLinkCreated(
        pinned.data.id,
        pinned.data.type.functionality,
        useBoardStore().currentBoard().type,
        ev.id,
        ev.type.functionality,
        "pin-create",
      ),
    );
    void linkActions.add("internal", { fromId: pinned.data.id, toId: ev.id });
    markActions.linkHighlight(board.cards[ev.id], true);
  }

  if (focus) {
    await nextTick();
    sendStickyNoteAction(ev.id, {
      action: "enlarge",
      focus: true,
      trigger: "after-create",
    });
  }
  return ev.id;
}

function sanitize(event: CardCreateProps): CardCreateProps {
  const newlines = !isAlmSync();
  if (event.text && !newlines) {
    event.text = removeNonPrintable(event.text);
  }
  return event;
}

export function cardCreateProps(
  card: BoardCard,
  options?: { findFreePos?: boolean },
): CardCreateProps {
  return {
    text: card.data.text,
    type: card.data.type,
    pos: minus(
      relativeToWindow(
        options?.findFreePos
          ? findFreePosition(card.meta.pos, cardPosStep())
          : plus(card.meta.pos, cardPosStep()),
      ),
      scrollCoord(),
    ),
    almSourceId: card.data.almSourceId ?? undefined,
  };
}

export function cardPosStep() {
  const cardSize = useBoardStore().currentBoard().cardSize;
  return times(cardSize, 0.4);
}
