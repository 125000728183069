<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import SelectableList from "@/components/ui/SelectableList/SelectableList.vue";
import type { RiskType } from "@/model/baseTypes";
import { riskTitle, riskTypes } from "@/model/baseTypes";
import type { Card } from "@/model/card";
import type { Team } from "@/model/session";
import { planReadoutSelectRisk } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import ListFooter from "./ListFooter.vue";
import ListHeader from "./ListHeader.vue";
import PresentationRiskListItem from "./PresentationRiskListItem.vue";

interface RiskList {
  type: RiskType;
  label: string;
  items: Card[];
}

interface Props {
  team: Team;
  risks: Card[];
  selectedRisk: Card | null;
}

const props = defineProps<Props>();

const emit = defineEmits<{ select: [risk: Card | null] }>();

// Expand all lists when switching between teams
watch(
  () => props.team,
  () => resetListView(),
);

const scrollContainerEl = ref<HTMLElement | null>(null);

// Local state controlling which lists are collapsed
const collapsedLists = ref(
  Object.fromEntries(riskTypes.map((type) => [type, false])),
);

const { t } = useI18n();

/**
 * Sorts the risks by type and returns an array of lists
 * (every type will be returned, even if there are no risks of that type)
 */
const riskLists = computed(() => {
  const filterRisks = (riskType: RiskType) =>
    props.risks.filter((risk) => (risk.risk || "") === riskType);

  return riskTypes.map((riskType) => ({
    type: riskType,
    label: t(riskTitle(riskType)),
    items: filterRisks(riskType),
  }));
});

/**
 * Returns all lists, in the order that they will be shown in the
 * categorized lists (i.e. sorted by ROAM* categories)
 */
const risksSorted = computed(() => {
  const sortedRisks = riskLists.value.flatMap((list) => list.items);

  checkSelectedRisk(sortedRisks);

  return sortedRisks;
});

/**
 * Verify that the selected risk is in the current data set
 */
const checkSelectedRisk = (sortedRisks: Card[]) => {
  if (!sortedRisks.find((risk) => risk === props.selectedRisk)) {
    selectFirstRisk(sortedRisks);
  }
};

/**
 * Select the first risk in the lists (or null if there are no risks)
 */
const selectFirstRisk = (sortedRisks: Card[]) => {
  const firstRisk = sortedRisks[0];
  emit("select", firstRisk || null);
};

const selectRisk = (risk: Card) => {
  emit("select", risk);
  trackEvent(planReadoutSelectRisk(risk.id));
};

/**
 * Toggle the list's collapsed state (unless the list has no items)
 */
const toggleList = (list: RiskList) => {
  if (!isListDisabled(list)) {
    collapsedLists.value[list.type] = !collapsedLists.value[list.type];
  }
};

/**
 * Show all lists as expanded and scroll to top
 */
const resetListView = () => {
  scrollContainerEl.value?.scrollTo({ top: 0, behavior: "smooth" });
  for (const type in collapsedLists.value) {
    collapsedLists.value[type] = false;
  }
};

/**
 * The risk-list should be 'disabled' if it has no items
 */
const isListDisabled = (list: RiskList) => !list.items.length;
</script>

<template>
  <div class="list-container">
    <div ref="scrollContainerEl" class="scroll-container">
      <header>
        <h2 class="title">
          {{ $t("planReadoutModal.risks.title", { teamName: team.name }) }}
        </h2>
        <div class="subtitle">
          {{ $t("planReadoutModal.risks.count", risksSorted.length) }}
        </div>
      </header>

      <template v-for="list in riskLists" :key="list.type">
        <div class="collapsible-container">
          <ListHeader
            :id="`${list.type}-list-header`"
            :collapsed="collapsedLists[list.type]"
            :disabled="isListDisabled(list)"
            :title="list.label"
            :subtitle="$t('planReadoutModal.risks.count', list.items.length)"
            @click.stop="toggleList(list)"
          />
          <SelectableList
            v-if="!collapsedLists[list.type]"
            variant="cards"
            :aria-labelledby="`${list.type}-list-header`"
            :values="list.items"
            :model-value="selectedRisk"
            @update:model-value="selectRisk"
          >
            <template #default="{ value, selected }">
              <PresentationRiskListItem
                v-if="value"
                role="button"
                :item="value"
                :class="{ selected }"
              />
            </template>
          </SelectableList>
        </div>
      </template>
    </div>

    <ListFooter keypath="planReadoutModal.listFooter.risks" />
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.list-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .scroll-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: 32px;
    overflow-y: auto;
  }
}

.title,
.subtitle {
  padding-left: 32px;
}

.title {
  font-weight: font.$weight-extra-bold;
  font-size: font.$size-large;
  line-height: 20px;
  margin-top: unset;
  margin-bottom: 8px;
}

.subtitle {
  color: colors-old.$text-secondary-color;
  font-weight: font.$weight-semi-bold;
  font-size: font.$size-small;
  line-height: 15px;
}

.collapsible-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

// Remove the default SelectableList list-item padding
:deep(.list-item) {
  padding: 0;
}
</style>
