<script setup lang="ts">
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import type { InfoLevel } from "@/model/baseTypes";
import { infoLevelInfo } from "@/model/baseTypes";

const props = withDefaults(defineProps<{ level?: InfoLevel }>(), {
  level: "ok",
});
</script>

<template>
  <div
    class="info-message"
    :style="{ backgroundColor: infoLevelInfo[props.level].backColor }"
  >
    <SvgIcon :name="infoLevelInfo[props.level].icon" width="20" height="20" />
    <br />
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;

.info-message {
  width: 100%;
  font-weight: font.$weight-normal;
  color: colors-old.$text-primary-color;
  border-radius: 6px;
  padding: 12px;

  :slotted(a) {
    text-decoration: none;
    color: colors-old.$primary-color;
  }
}
</style>
