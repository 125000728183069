<script lang="ts" setup>
import { assigneeChipId } from "@/action/cardActions";
import UserAvatar from "@/components/UserAvatar/UserAvatar.vue";
import SetAssignee from "@/components/card/components/SetAssignee/SetAssignee.vue";
import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
import { useUser } from "@/composables/useUser";
import IconUser from "@/icons/general/user.svg?component";
import { injectStrict } from "@/utils/vue";

import AttributeChip from "../../../AttributeChip/AttributeChip.vue";

const card = injectStrict(cardKey);
const cardMeta = injectStrict(cardMetaKey);

const { userName } = useUser();
</script>

<template>
  <AttributeChip
    :id="assigneeChipId(card.id)"
    name="assignee-chip"
    :readonly="cardMeta.isReadonly"
    :active="!!card.assignee"
    :tooltip="userName(card.assignee, $t('general.assignee'))"
    :dropdown-props="{ width: 'auto' }"
  >
    <SetAssignee />
    <template #icon>
      <UserAvatar
        v-if="card.assignee"
        :user="card.assignee"
        size="small"
        neutral-color
      />
      <IconUser v-else />
    </template>
  </AttributeChip>
</template>
