<script setup lang="ts">
import { sortBy } from "lodash-es";
import { onMounted, ref, watch } from "vue";

import MenuList from "@/components/ui/MenuList/MenuList.vue";
import MenuListHeader from "@/components/ui/MenuList/components/MenuListHeader.vue";
import MenuListItem from "@/components/ui/MenuList/components/MenuListItem.vue";
import MenuListItems from "@/components/ui/MenuList/components/MenuListItems.vue";
import MenuListSearch from "@/components/ui/MenuList/components/MenuListSearch.vue";
import MenuListTitle from "@/components/ui/MenuList/components/MenuListTitle.vue";
import type { AuthUser } from "@/model/user";
import type { UserWithTeams } from "@/services/userAdmin.service";
import { searchUsers, usersInTeam } from "@/services/userAdmin.service";
import { addReactiveAlmMapping } from "@/services/userAlmMapping.service";
import { useTeamStore } from "@/store/team";
import { useUserStore } from "@/store/user";

import NoUserEntry from "./components/NoUserEntry.vue";
import type { User } from "./components/UserEntry.vue";
import UserEntry from "./components/UserEntry.vue";

const props = defineProps<{ selected: AuthUser | null; title?: string }>();

const currentTeam = useTeamStore().current;

const emit = defineEmits<{ select: [user: AuthUser | null] }>();
const users = ref<User[]>([]);

const searchVal = ref<string>("");
const teamUsers = ref<User[]>([]);

const selected = props.selected
  ? addReactiveAlmMapping(makeUser(props.selected))
  : null;

const current = makeUser(useUserStore().currentUser!);

onMounted(async () => {
  const rawUsers = await usersInTeam(currentTeam);
  teamUsers.value = rawUsers.map((raw) => makeUser(raw));
});

watch(
  [searchVal, teamUsers],
  async () => {
    const searchUsers =
      searchVal.value.length < 2
        ? currentAndTeamUsers()
        : [...filteredTeamUsers(), ...(await searchNonTeamUsers())];

    const currentUserFirst = (user: User) => user.id !== current.id;

    users.value = sortBy(searchUsers.filter(notSelected), [
      currentUserFirst,
      "foreign",
      "name",
    ]);
  },
  { immediate: true },
);

/**
 * Returns the team users, with the current user added if they aren't already in the team.
 */
function currentAndTeamUsers() {
  const userOnCurrentTeam = teamUsers.value.some((u) => u.id === current.id);
  return userOnCurrentTeam ? teamUsers.value : [current, ...teamUsers.value];
}

function filteredTeamUsers() {
  return teamUsers.value.filter((user) =>
    user.name.toLowerCase().includes(searchVal.value.toLowerCase()),
  );
}

async function searchNonTeamUsers() {
  return (await searchUsers(searchVal.value))
    .filter((user) => !filteredTeamUsers().find((tu) => tu.id === user.id))
    .map((user) => makeUser(user, isForeign(user)));
}

function notSelected(user: AuthUser) {
  return user.id !== props.selected?.id;
}

function isForeign(user: UserWithTeams) {
  return !user.teams.find((team) => team.id === currentTeam.id);
}

function makeUser(user: AuthUser, foreign = false): User {
  return { ...user, foreign };
}

function select(user: AuthUser | null) {
  emit("select", user);
}
</script>

<template>
  <MenuList>
    <MenuListHeader @search="searchVal = $event">
      <MenuListTitle>{{ title || $t("general.assignee") }}</MenuListTitle>
      <MenuListSearch
        :placeholder="$t('action.searchUsers')"
        :icon-title="$t('label.userSelector.searchTitle')"
        :results-count="users.length"
        @search="searchVal = $event"
      />
    </MenuListHeader>
    <MenuListItems>
      <UserEntry v-if="selected" :user="selected" active static />
      <NoUserEntry v-if="selected" @select="select" />
      <UserEntry
        v-for="user in users"
        :key="user.id"
        :user="user"
        :active="selected?.id === user.id"
        @select="select"
      />
      <MenuListItem v-if="!users.length" static>
        {{ $t("searchResults.noResults") }}
      </MenuListItem>
    </MenuListItems>
  </MenuList>
</template>
