import type { PaintFunctions } from "./PaintLayer";
import { thePaintLayer } from "./PaintLayer";
import type { Lines } from "./lineBreaker";
import { breakLines } from "./lineBreaker";

const shortText = { maxLen: 50, smallestFontSize: 0.23, minWordLen: 4 };

interface TextMeasure {
  height: number;
  lines: Lines;
}

export default class TextMeasurer {
  static of(elem: SVGElement | HTMLElement) {
    const paintFunctions = thePaintLayer.init(elem);
    return paintFunctions ? new TextMeasurer(paintFunctions) : null;
  }

  private constructor(private paintFunctions: PaintFunctions) {}

  setFontSize(size: number) {
    this.paintFunctions.setElementFontSize(size);
  }

  maxFontSize() {
    return this.paintFunctions.fontSizeForOneLine;
  }

  maxFontSizeForText(): (s: string) => number {
    this.paintFunctions.setCanvasFont(this.maxFontSize());
    return (s) =>
      this.paintFunctions.widthInCanvas / this.paintFunctions.stringWidth(s);
  }

  measureLines(size: number, text: string, debug?: boolean): TextMeasure {
    this.paintFunctions.setCanvasFont(size);
    const lines = breakLines(
      text,
      this.paintFunctions.widthInCanvas,
      (chars, from, to) => {
        let width = 0;
        // widths of single characters
        for (let i = from; i < to; i++) {
          width += this.paintFunctions.charWidth(chars[i]);
        }
        // plus width diff of character pairs (e.g. 'AV' takes less space than a 'A' and a 'V')
        for (let i = from; i < to - 1; i++) {
          width += this.paintFunctions.charWidth(chars[i], chars[i + 1]);
        }
        return width;
      },
    );
    if (debug) {
      this.paintFunctions.displayLines(200, 200, text, lines);
    }
    return {
      height: lines.length * this.paintFunctions.lineHeight,
      lines,
    };
  }
}

// downsize fontSize to assure long words in short texts are not wrapped
export function wordDownsize(fontSize: (s: string) => number, text: string) {
  if (text.length > shortText.maxLen) {
    return 1;
  }
  const minWordFontSize = minFontSize(text.split(/\s+/));
  return Math.max(shortText.smallestFontSize, minWordFontSize);

  function minFontSize(ss: string[]): number {
    const wordFontSize = (s: string) =>
      s.length < shortText.minWordLen ? 1 : fontSize(s);
    return ss.reduce((min, word) => Math.min(min, wordFontSize(word)), 1);
  }
}
