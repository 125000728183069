<script lang="ts" setup>
import type { FunctionalComponent } from "vue";
import { ref } from "vue";

import { cardActions } from "@/action/cardActions";
import { cardKey } from "@/components/card/injectKeys";
import { keyboardNavigation } from "@/directives/keyboardNavigation";
import IconHot from "@/icons/reaction/hot.svg?component";
import IconIncrement from "@/icons/reaction/increment.svg?component";
import IconLove from "@/icons/reaction/love.svg?component";
import type { Reaction } from "@/model/card";
import { getReactionLabel, reactions } from "@/model/card";
import { useCardStore } from "@/store/card";
import { eventTarget } from "@/utils/dom/dom";
import { injectStrict } from "@/utils/vue";

const vKeyboardNavLocal = keyboardNavigation();

const card = injectStrict(cardKey);

const reactionsContainer = ref<HTMLDivElement>();

const handleFocusin = (event: FocusEvent) => {
  const target = eventTarget(event);
  const siblings = target?.parentElement?.children || [];

  Array.from(siblings).forEach((sibling) => {
    if (sibling === target) {
      sibling.classList.add("active");
      return;
    }
    sibling.classList.add("transparent");
  });
};

const handleFocusout = (event: FocusEvent) => {
  const siblings = eventTarget(event)?.parentElement?.children || [];

  Array.from(siblings).forEach((sibling) => {
    sibling.classList.remove("active", "transparent");
  });
};

const handleToggleReaction = async (reaction: Reaction) => {
  await cardActions.toggleReaction("action-menu", card.id, reaction);
};

const icons: { [key in Reaction]: FunctionalComponent } = {
  hot: IconHot,
  increment: IconIncrement,
  love: IconLove,
};

const focusoutReactions = (event: FocusEvent) => {
  if (!reactionsContainer.value?.contains(event.relatedTarget as Node)) {
    const hotReaction = document.getElementById("reaction-hot-" + card.id);
    hotReaction?.focus();
  }
};
</script>

<template>
  <div
    ref="reactionsContainer"
    v-autofocus
    v-keyboard-nav-local.soft-focus-initial.ignore-trigger.horizontal="{
      selector: '.reaction',
    }"
    class="sticky-reactions"
    role="listbox"
    multiselectable="true"
    :aria-label="$t('cardAction.reactions')"
    @focusout="focusoutReactions"
  >
    <button
      v-for="reaction in reactions"
      :key="reaction"
      role="option"
      :aria-label="$t(getReactionLabel(reaction))"
      :aria-selected="
        useCardStore().hasCurrentUserReaction(card.id, reaction) === true
          ? 'true'
          : 'false'
      "
      tabindex="-1"
      class="reaction"
      @focusin="handleFocusin"
      @focusout="handleFocusout"
      @click="handleToggleReaction(reaction)"
    >
      <component :is="icons[reaction]" width="20" height="20" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
@use "sass:color";
@use "@/styles/colors";

.sticky-reactions {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 10px;
  border-radius: 30px;
  overflow: hidden;

  .reaction {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    outline: revert;

    &.active {
      display: inline-block;
      transform: scale(1.4);
    }

    &.transparent::before {
      content: "";
      position: absolute;
      inset: 0;
      background-color: color.change(colors.$back-color, $alpha: 0.3);
    }

    transform: scale(1.2);
  }
}
</style>
