import { defineStore } from "pinia";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

import EventBus from "@/composables/EventBus";
import { sendStickyNoteAction } from "@/composables/useEventBus";
import type { QueryImpl, SearchQuery } from "@/router/types";

import { useBoardStore } from "./board";

export const useUrlStore = defineStore("url", () => {
  const search = ref<SearchQuery>({});

  const route = useRoute();

  let stickyIdsToEnlarge = new Array<string>();
  EventBus.on("boardLoaded", (boardId: string) => {
    if (boardId === useBoardStore().board?.id) {
      if (stickyIdsToEnlarge.length === 1) {
        sendStickyNoteAction(stickyIdsToEnlarge[0], {
          action: "enlarge",
          trigger: "url",
        });
      } else if (stickyIdsToEnlarge.length > 1) {
        stickyIdsToEnlarge.forEach(useBoardStore().selectSticky);
      }
      stickyIdsToEnlarge = [];
    }
  });

  let fromQuery: QueryImpl = {};
  watch(
    route,
    () => {
      const toQuery = route.query as QueryImpl;
      if (
        toQuery.searchText !== fromQuery.searchText ||
        toQuery.searchFlag !== fromQuery.searchFlag ||
        toQuery.searchAssignee !== fromQuery.searchAssignee ||
        toQuery.searchTeam !== fromQuery.searchTeam ||
        toQuery.searchArt !== fromQuery.searchArt ||
        toQuery.searchIteration !== fromQuery.searchIteration ||
        toQuery.searchType !== fromQuery.searchType ||
        toQuery.searchDepLink !== fromQuery.searchDepLink ||
        toQuery.searchStatusClass !== fromQuery.searchStatusClass ||
        toQuery.searchLink !== fromQuery.searchLink ||
        toQuery.searchPos !== fromQuery.searchPos
      ) {
        search.value = toSearchQuery(toQuery);
      }
      if (toQuery.stickyId) {
        stickyIdsToEnlarge = toQuery.stickyId.split(",");
      }
      fromQuery = { ...toQuery };
    },
    { immediate: true },
  );

  return { search };
});

function toSearchQuery(q: QueryImpl): SearchQuery {
  return {
    text: q.searchText,
    flag: q.searchFlag,
    assignee: q.searchAssignee,
    team: q.searchTeam,
    art: q.searchArt,
    iteration: q.searchIteration,
    type: q.searchType,
    depLink: q.searchDepLink,
    link: q.searchLink,
    statusClass: q.searchStatusClass,
    pos: q.searchPos,
  };
}
