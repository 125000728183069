import { isString } from "lodash-es";

import { i18n } from "@/translations/i18n";
import { isFirefox, isMac } from "@/utils/dom/dom";

export function key(k: string, ...modifiers: Modifier[]): Key {
  const names = keyName(k, modifiers);
  return {
    name: () => names().join(""),
    names,
    accept: (e) => accept(k)(e) && hasModifiers(k, e, modifiers),
    modifiers,
  };
}

export function keys(...ks: string[]): Key {
  const keys = ks.map((k) => key(k));
  return {
    name: () => keys.map((k) => k.name()).join(", "),
    names: () => keys.flatMap((k) => k.name()),
    accept: (e) => keys.some((k) => accept(k.accept)(e)),
    modifiers: [],
  };
}

export const noAccept = () => false;

export const noKey: Key = {
  name: () => "",
  names: () => [],
  accept: noAccept,
  modifiers: [],
};

export function dummyKey(
  name: string | (() => string),
  ...modifiers: Modifier[]
): Key {
  const names = isString(name)
    ? keyName(name, modifiers)
    : keyName(name(), modifiers);
  return {
    name: () => names().join(""),
    names,
    accept: noAccept,
    modifiers,
  };
}

export interface Key {
  name: () => string;
  names: () => string[];
  accept: KeyAccept;
  modifiers: Modifier[];
}

export type KeyAccept = (e: SimpleKeyEvent) => boolean;

export interface SimpleKeyEvent {
  code: string;
  key: string;
  keyCode: number;
  shiftKey: boolean;
  altKey: boolean;
  ctrlKey: boolean;
  metaKey: boolean;
}

type Modifier = "shift" | "altCtrl";

function hasModifiers(k: string, event: SimpleKeyEvent, modifiers: Modifier[]) {
  // shift is ok when it's not a letter as some chars are entered with shift, e.g. / on DE keyboard
  let shiftOk = !event.code.startsWith("Key") || !event.shiftKey;
  let altCtrlOk =
    (!event.altKey && !event.ctrlKey && !event.metaKey) ||
    ["Alt", "Control", "Meta"].includes(k);
  for (const m of modifiers) {
    switch (m) {
      case "shift":
        shiftOk = event.shiftKey;
        break;
      case "altCtrl":
        altCtrlOk = event.altKey || event.ctrlKey || event.metaKey;
        break;
    }
  }
  return shiftOk && altCtrlOk;
}

function modKey() {
  return isMac ? "⌘" : i18n.global.t("key.ctrl") + " ";
}

const keyNames: Record<string, () => string> = {
  Escape: () =>
    isMac ? i18n.global.t("key.escapeMac") : i18n.global.t("key.escape"),
  Backspace: () => "⌫",
  Enter: () => (isMac ? "↵" : i18n.global.t("key.enter")),
  Shift: () => (isMac ? "⇧" : i18n.global.t("key.shift") + " "),
  ArrowUp: () => (isMac ? "⏶" : "↑"),
  ArrowDown: () => (isMac ? "⏷" : "↓"),
  ArrowLeft: () => (isMac ? "⏴" : "←"),
  ArrowRight: () => (isMac ? "⏵" : "→"),
};

function keyName(k: string, modifiers: Modifier[]) {
  return () => {
    const key =
      keyNames[k]?.() ||
      (k.startsWith("Key")
        ? k.substring(3)
        : k.startsWith("Digit")
        ? k.substring(5)
        : k);
    return [
      ...(modifiers.includes("altCtrl") ? [modKey()] : []),
      ...(modifiers.includes("shift") ? [keyNames["Shift"]()] : []),
      key,
    ];
  };
}

export function accept(k: string | KeyAccept): KeyAccept {
  if (!isString(k)) {
    return k;
  }

  // There is no perfect cross-keyboard way to detect '+' key, so we have to test all 3 possibilities (for our supported languages)
  // We'll have to do this manually, until https://developer.mozilla.org/en-US/docs/Web/API/Keyboard/getLayoutMap is widely used
  // (there doesn't seem to be any API to identify the keyboard layout either)
  if (k === "+") {
    return (e) =>
      e.key === "+" || // '+' on DE/ES/PT keyboard
      (e.shiftKey && e.key === "=") || // '+' on EN/JP/CH/FR keyboard
      (e.shiftKey && e.key === "1"); // '+'' on CH keyboard
  }

  // Similarly, for '-' key we should test the keyCode to avoid inconsistencies in the key property
  if (k === "-") {
    return isFirefox ? (e) => e.keyCode === 173 : (e) => e.keyCode === 189;
  }

  return k.startsWith("Key") || k.startsWith("Digit")
    ? (e) => e.code === k
    : (e) => e.key === k;
}
