import { applyActionParams } from "@/action/actions";
import { toggleActions } from "@/action/toggleActions";
import type { Action } from "@/action/types";
import { relativeClientCoord } from "@/math/coordinate-systems";
import type { RelativeCoordinate } from "@/model/coordinates";
import { useStickyTypeStore } from "@/store/stickyType";

import { BaseBoard } from "./BaseBoard";
import { RiskBoardLocation } from "./location/RiskBoardLocation";

export class RiskBoard extends BaseBoard<"risk"> {
  location(pos: RelativeCoordinate | number) {
    return RiskBoardLocation.of(pos);
  }

  get possibleStickyTypes() {
    return useStickyTypeStore().nonFlexBoardStickyTypes("risk");
  }

  get creatableStickyTypes() {
    // we can’t select a team on the risk board, hence we should not allow creating team board stickies on the risk board
    return this.possibleStickyTypes.filter((type) => type.origin !== "team");
  }

  get contextActions() {
    return {
      board: { draw: this.drawAction() },
      selection: {
        stickyMove: true,
        link: true,
      },
    };
  }

  positionalActions(c: RelativeCoordinate) {
    const loc = this.location(c);
    return {
      region: {
        name: loc.name,
        arrange: this.arrangeAction(loc),
        overview: this.overviewAction(loc),
        zoom: this.zoomAction(loc),
      },
    };
  }

  overview(e: MouseEvent) {
    this.overviewAction(this.location(relativeClientCoord(e)))?.("mouse");
  }

  overviewAction(loc: RiskBoardLocation): Action | undefined {
    return applyActionParams(toggleActions.showOverview, {
      boardId: this.board.id,
      location: loc.index(),
    });
  }
}
