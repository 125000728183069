<script setup lang="ts">
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { boardIcon } from "@/model/baseTypes";
import type { FieldChange } from "@/model/change";
import { useBoardsStore } from "@/store/boards";

defineProps<{ field: FieldChange<string> }>();

function boardName(id: string) {
  return useBoardsStore().boardTitle(id);
}

function iconName(id: string) {
  return boardIcon(useBoardsStore().boardById(id).type);
}
</script>

<template>
  <div class="change horizontal">
    <span v-if="field.old" class="change-value">
      <SvgIcon :name="iconName(field.old)" width="20" height="20" />
      {{ boardName(field.old) }}
    </span>
    <SvgIcon
      v-if="field.old || field.new"
      name="arrow/right"
      width="20"
      height="20"
      role="img"
      :aria-label="$t('label.activityPanel.changedTo')"
    />
    <span v-if="field.new" class="change-value">
      <SvgIcon :name="iconName(field.new)" width="20" height="20" />
      {{ boardName(field.new) }}
    </span>
  </div>
</template>
