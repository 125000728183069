<template>
  <canvas
    id="paint-canvas"
    ref="canvas"
    width="6400"
    height="3600"
    aria-hidden="true"
    tabindex="-1"
  />
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Ref } from "vue-property-decorator";

import EventBusUser from "@/mixins/EventBusUser";
import { fakeZoom } from "@/model/Settings";
import color from "@/model/color.module.scss";
import { useZoomStore } from "@/store/zoom";
import { clearStyles, getStyleInfo } from "@/utils/dom/styleCache";
import type { PaintFunctions, PaintLayer } from "@/utils/text/PaintLayer";
import { registerPaintLayer } from "@/utils/text/PaintLayer";

@Component({})
export default class DefaultPaintLayer
  extends mixins(EventBusUser)
  implements PaintLayer
{
  @Ref("canvas") readonly canvasElem!: HTMLCanvasElement;
  ctx!: CanvasRenderingContext2D;
  charWidthCache = new Map<string, number>();

  mounted() {
    registerPaintLayer(this);
    this.ctx = this.canvasElem.getContext("2d")!;
    this.onSetting("stickyFont", () => {
      this.charWidthCache.clear();
      clearStyles();
    });
  }

  init(elem: SVGElement | HTMLElement): PaintFunctions | null {
    const ctx = this.ctx;
    const style = getStyleInfo(elem);
    const { size: elemFontSize, unit: elemFontUnit } = parseFont(elem);
    const unscaledCanvasUnitsPerPixel =
      this.canvasElem.width / this.canvasElem.offsetWidth;
    const zoomFactor = fakeZoom / useZoomStore().factor;

    const sizes = calcElementSize(elem);
    if (!sizes) {
      return null;
    }

    const { elementScale, boxWidth, boxHeight, canvasUnitsPerPixel } = sizes;
    const pixelPerFontUnit = measurePixelPerFontUnit(
      parseFloat(elemFontSize),
      style.minFontSize,
      style.maxFontSize,
    );

    const makeVisible = () => {
      const elStyle = (this.$el as HTMLElement).style;
      elStyle.visibility = "visible";
      elStyle.zIndex = "3";
    };

    function parseFont(elem: SVGElement | HTMLElement) {
      const style = elem.style.fontSize;
      if (style) {
        const [input, size, unit] = /([0-9.]+)(.*)/.exec(style) || [];
        if (!input) {
          throw Error(`unparseable font size '${style}'`);
        }
        return { size, unit };
      }
      const attr = elem.getAttribute("font-size");
      if (attr) {
        return { size: attr, unit: "px" };
      }
      throw Error(
        "unparseable font size: neither style.fontSize nor font-size attribute found.",
      );
    }

    function calcElementSize(elem: SVGElement | HTMLElement) {
      if (elem instanceof SVGElement) {
        const svg = findSvgRoot(elem);
        if (!svg.clientWidth) {
          return null;
        }
        const svgUnitsPerPixel = svg.clientWidth / +svg.getAttribute("width")!;
        const width = +elem.getAttribute("width")!;
        const height = +elem.getAttribute("height")!;

        return {
          elementScale: 1,
          boxWidth: (width / zoomFactor) * svgUnitsPerPixel,
          boxHeight: (height / zoomFactor) * svgUnitsPerPixel,
          canvasUnitsPerPixel: unscaledCanvasUnitsPerPixel * svgUnitsPerPixel,
        };
      }

      // offsetWidth does not take into account "CSS transformation: scale", but getBoundingClientRect does.
      // getBoundingClientRect has higher precision but to use it, the possible scalings have to be inversed.
      // there are 3 possible CSS scalings: fakeZoom, InputText's editZoom and board zoom.
      const box = elem.getBoundingClientRect();
      return {
        elementScale: zoomFactor * (box.width / elem.offsetWidth),
        boxWidth: box.width,
        boxHeight: box.height,
        canvasUnitsPerPixel: unscaledCanvasUnitsPerPixel,
      };
    }

    function findSvgRoot(el: SVGElement): SVGElement {
      while (el.nodeName !== "svg") {
        el = el.parentElement as unknown as SVGElement;
      }
      return el;
    }

    function setElementFontSize(size: number) {
      elem.style.fontSize = size + elemFontUnit;
    }

    function measurePixelPerFontUnit(
      fontSize: number,
      minFontSize: number,
      maxFontSize: number,
    ) {
      setElementFontSize(fontSize);
      let pixel = parseFloat(window.getComputedStyle(elem).fontSize);
      // getComputedStyle may respect browser's minimum/maximum font size
      // therefore adjust font size if it's outside the limits
      if (pixel <= minFontSize || pixel >= maxFontSize) {
        const fontSizeFactor = 100 / pixel;
        setElementFontSize(fontSize * fontSizeFactor);
        pixel =
          parseFloat(window.getComputedStyle(elem).fontSize) / fontSizeFactor;
        setElementFontSize(fontSize);
      }
      return pixel / fontSize;
    }

    const charWidthCache = this.charWidthCache;

    return {
      canvasFontSize: 0,
      canvasFont: "",
      lineHeight: 0,
      widthInCanvas: Math.floor(
        boxWidth * unscaledCanvasUnitsPerPixel * zoomFactor,
      ),
      heightInCanvas: Math.floor(
        boxHeight * unscaledCanvasUnitsPerPixel * zoomFactor,
      ),

      setCanvasFont(size: number) {
        const pixelSize = size * pixelPerFontUnit;
        this.lineHeight = pixelSize * style.lineHeightFactor;
        this.canvasFontSize = pixelSize * elementScale * canvasUnitsPerPixel;
        this.canvasFont = `${style.fontWeight} ${style.fontFamily}`;
        // NB: fontWeight is not working on chrome
        ctx.font = `${style.fontWeight} ${this.canvasFontSize}px ${style.fontFamily}`;
      },

      setElementFontSize,

      // so that line height = height in canvas
      get fontSizeForOneLine() {
        return (
          this.heightInCanvas /
          (elementScale *
            canvasUnitsPerPixel *
            pixelPerFontUnit *
            style.lineHeightFactor)
        );
      },

      stringWidth(s: string): number {
        return ctx.measureText(s).width;
      },

      charWidth(char1: string, char2?: string): number {
        const key = this.canvasFont + char1 + (char2 || "");
        const val = charWidthCache.get(key);
        if (val !== undefined) {
          return val * this.canvasFontSize;
        }
        const width = char2
          ? this.stringWidth(char1 + (char2 || "")) -
            this.charWidth(char1) -
            this.charWidth(char2)
          : this.stringWidth(char1);
        charWidthCache.set(key, width / this.canvasFontSize);
        return width;
      },

      displayLines(
        x: number,
        y: number,
        text: string,
        lines: Array<[number, number]>,
      ) {
        makeVisible();
        ctx.clearRect(x, y, this.widthInCanvas, this.heightInCanvas * 2);
        ctx.strokeStyle = color.textPrimary;
        ctx.strokeRect(x, y, this.widthInCanvas, this.heightInCanvas);
        ctx.strokeRect(
          x,
          y +
            lines.length * this.lineHeight * canvasUnitsPerPixel * elementScale,
          this.widthInCanvas,
          3,
        );
        let i = 0;
        for (const [startPos, endPos] of lines) {
          ctx.fillText(
            text.substring(startPos, endPos),
            x,
            y + this.canvasFontSize * (1 + style.lineHeightFactor * i),
          );
          i++;
        }
      },
    };
  }
}
</script>

<style lang="scss">
@use "@/styles/variables";

#paint-canvas {
  visibility: hidden;
  position: absolute;
  pointer-events: none;
  width: 100% * variables.$fake-zoom;
  height: 100% * variables.$fake-zoom;
  font-size: 1rem;
}
</style>
