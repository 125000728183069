<script lang="ts" setup>
import { isArray } from "lodash-es";
import { computed } from "vue";

import { cardActions } from "@/action/cardActions";
import SelectStickyType from "@/components/SelectStickyType/SelectStickyType.vue";
import { actionSourceKey } from "@/components/action/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import { dropdownKey } from "@/components/ui/DropdownMenu/injectKeys";
import type { StickyType } from "@/model/stickyType";
import { useBoardStore } from "@/store/board";
import { injectStrict } from "@/utils/vue";

const card = injectStrict(cardKey);
const dropdown = injectStrict(dropdownKey);
const actionSource = injectStrict(actionSourceKey);

const stickyTypes = computed(() => useBoardStore().creatableStickyTypes);

const onChange = (targetType: StickyType | StickyType[]) => {
  const type = isArray(targetType) ? targetType[0] : targetType;
  cardActions.setType(actionSource, card, type);

  dropdown.close();
};
</script>

<template>
  <SelectStickyType
    :sticky-types="stickyTypes"
    @update:model-value="onChange"
  />
</template>
