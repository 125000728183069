import { noop } from "lodash-es";
import { createPinia } from "pinia";
import "the-new-css-reset/css/reset.css";
import type { App } from "vue";
import { createApp } from "vue";

import AppBase from "@/components/AppBase/AppBase.vue";
import type { IntercomStatic } from "@/declare/windowIntercom";
import { autofocus } from "@/directives/autofocus";
import { boardRegion } from "@/directives/boardRegion";
import { focusTrap } from "@/directives/focusTrap";
import { keyboardNavigation } from "@/directives/keyboardNavigation";
import { ownedCards } from "@/directives/ownedCards";
import { scrollableOnBoard } from "@/directives/scrollableOnBoard";
import { handleError } from "@/error/errorHandler";
import { captureException, initSentry } from "@/error/sentry";
import { setLanguage } from "@/language";
import { goToLogout } from "@/router/navigation";
import { router } from "@/router/router";
import { setUnauthorizedHandler } from "@/services/api.config";
import { assignShortcuts } from "@/shortcuts";
import { useClientSettingsStore } from "@/store/clientSettings";
import "@/styles/global/theme-light.scss";
import { i18n } from "@/translations/i18n";
import { wrapMutationObserver } from "@/utils/dom/mutationObserver";

import { showLogo } from "./console";
import { registerCopyPaste } from "./copyPaste";
import { registerGlobalActions } from "./globalActions";
import { broadcastFeatures } from "./utils/env/feature";

wrapMutationObserver();

broadcastFeatures();

const app = createApp(AppBase)
  .use(router)
  .use(createPinia())
  .use(i18n)
  .directive("focus-trap", focusTrap())
  .directive("autofocus", autofocus())
  .directive("scrollable-on-board", scrollableOnBoard())
  .directive("owned-cards", ownedCards())
  .directive("keyboard-nav", keyboardNavigation())
  .directive("board-region", boardRegion());

void initSentry(app).then(() => {
  initErrorListeners(app);
  app.mount("#app").$watch("$route.query.lang", setLanguage);
});

useClientSettingsStore().init();

// real Intercom will be inited after login if enabled
// dummy init here, so we can just call Intercom without always checking if it's available or not
window.Intercom = noop as IntercomStatic;

showLogo();
assignShortcuts();
registerGlobalActions();
registerCopyPaste();
setUnauthorizedHandler(async () => void (await goToLogout()));

function initErrorListeners(app: App) {
  window.onunhandledrejection = (event) => {
    event.preventDefault();
    handleError(event.reason);
  };
  window.onerror = (msg, url, line, col, error) => {
    void captureException(error);
    return true;
  };
  window.addEventListener("vite:preloadError", (event) => {
    event.preventDefault();
    handleError(event.payload);
  });
  app.config.errorHandler = (err) => {
    handleError(err);
  };
  app.config.warnHandler = (err) => {
    handleError(err);
  };
}
