<script lang="ts" setup>
import { computed, inject } from "vue";

import { cardActions } from "@/action/cardActions";
import { markActions } from "@/action/mark";
import ActionMenuItem from "@/components/ActionMenu/ActionMenuItem.vue";
import {
  cardWasCreatedOnDifferentBoard,
  isActionVisible,
  isStickyDeletable,
} from "@/components/StickyNote/components/ActionMenu/actions";
import { boardKey } from "@/components/board/injectKeys";
import { cardMethodsKey } from "@/components/card/injectKeys";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { sendStickyNoteAction } from "@/composables/useEventBus";
import type { BoardCard } from "@/model/card";
import { useBoardStore } from "@/store/board";
import { useLinkStore } from "@/store/link";
import { useServerSettingsStore } from "@/store/serverSettings";
import { injectStrict } from "@/utils/vue";

import DeleteStickies from "./DeleteStickies.vue";

const props = defineProps<{ cards: BoardCard[] }>();

const board = injectStrict(boardKey);
const cardMethods = inject(cardMethodsKey, null);

const canDelete = computed(() =>
  props.cards.every((card) =>
    isActionVisible("delete", card.data, board.value),
  ),
);
const isRemove = computed(() => {
  return props.cards.every((card) =>
    cardWasCreatedOnDifferentBoard(card.data, board.value),
  );
});
const isDisabled = computed(() =>
  props.cards.some((card) => !isStickyDeletable(card.data, board.value)),
);
const confirm = computed(
  () =>
    props.cards.length > 1 ||
    (useServerSettingsStore().confirmDelete &&
      props.cards.some((card) => card.data.text)),
);

async function remove(confirmed: boolean) {
  if (!confirmed && confirm.value) {
    return;
  }
  useBoardStore().activeCardId = null;
  await cardMethods?.animate?.("deleting");

  for (const card of props.cards) {
    markActions.hide(useBoardStore().currentBoard().cards[card.data.id]);
    useLinkStore().removeCardLinkedMarks(card.data.id);
    sendStickyNoteAction(card.data.id, { action: "changed" }); // Re-highlights search results if needed
    cardActions.delete("action-menu", card.data.id, board.value.id);
  }
}
</script>

<template>
  <ActionMenuItem
    v-if="canDelete && !isRemove"
    width="auto"
    name="delete"
    :disabled="isDisabled"
    :tooltip-text="
      isDisabled
        ? $t('stickyNote.deleteStickyNoteDisabled')
        : $t('cardAction.delete')
    "
    :aria-label="$t('label.actionMenu.delete')"
    @click="remove(false)"
  >
    <SvgIcon name="action/delete" width="20" height="20" />
    <template v-if="confirm" #dropdown>
      <DeleteStickies
        :count="cards.length"
        mode="delete"
        @delete="remove(true)"
      />
    </template>
  </ActionMenuItem>

  <ActionMenuItem
    v-if="canDelete && isRemove"
    width="auto"
    name="remove"
    :tooltip-text="$t('cardAction.remove')"
    :aria-label="$t('label.actionMenu.remove')"
    @click="remove(false)"
  >
    <SvgIcon name="action/remove" width="20" height="20" />
    <template v-if="confirm" #dropdown>
      <DeleteStickies
        :count="cards.length"
        mode="remove"
        @delete="remove(true)"
      />
    </template>
  </ActionMenuItem>
</template>
