import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M9.41 9.099a3.76 3.76 0 0 0-5.31 0 3.746 3.746 0 0 0 0 5.302 3.76 3.76 0 0 0 5.31 0 3.746 3.746 0 0 0 0-5.302M9.276 6.58a5.76 5.76 0 0 0-6.591 1.104 5.746 5.746 0 0 0 0 8.132 5.76 5.76 0 0 0 6.59 1.104 5.77 5.77 0 0 0 5.26-.111q.073.054.157.096a5.76 5.76 0 0 0 6.621-1.089 5.746 5.746 0 0 0 0-8.132 5.76 5.76 0 0 0-6.792-1 5.77 5.77 0 0 0-5.245-.104m6.991 1.55a5.75 5.75 0 0 1 0 7.242 3.76 3.76 0 0 0 3.633-.97 3.746 3.746 0 0 0 0-5.303 3.76 3.76 0 0 0-3.633-.97m-5.106 7.316a5.75 5.75 0 0 0 .001-7.392 3.75 3.75 0 0 1 3.29 1.045 3.746 3.746 0 0 1 0 5.302 3.75 3.75 0 0 1-3.29 1.045",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }