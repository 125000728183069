import { defineStore } from "pinia";

import type { Id } from "@/model/baseTypes";
import type { Category } from "@/model/flexboard";

export const useCategoryStore = defineStore("category", {
  state: () => ({
    categories: [] as Category[],
  }),

  getters: {
    getCategoryById: (state) => (id: string) => {
      return state.categories.find((c) => c.id === id);
    },
    getCategoryByName: (state) => (name?: string) => {
      return name && state.categories.find((c) => c.name === name);
    },
    getCategories: (state) => {
      return state.categories;
    },
  },

  actions: {
    add(id: string, name: string) {
      this.categories.push({
        id,
        name,
        boardIds: [],
        fresh: true,
      });
    },

    update(payload: Id & { name?: string; position?: number }) {
      const i = this.categories.findIndex((c) => c.id === payload.id);
      const cat = this.categories[i];
      if (payload.name !== undefined) {
        cat.name = payload.name;
      }
      if (payload.position !== undefined) {
        this.categories.splice(i, 1);
        this.categories.splice(payload.position, 0, cat);
      }
    },

    remove(e: Id) {
      const index = this.categories.findIndex((c) => c.id === e.id);
      if (index !== -1) {
        this.categories.splice(index, 1);
      }
    },

    addBoard(e: { boardId: string; categoryId: string }) {
      const cat = this.categories.find((c) => c.id === e.categoryId)!;
      cat.boardIds.push(e.boardId);
    },

    removeBoard(payload: { boardId: string; categoryId: string }) {
      const cat = this.categories.find((c) => c.id === payload.categoryId)!;
      const index = cat.boardIds.findIndex((b) => b === payload.boardId);
      if (index !== -1) {
        cat.boardIds.splice(index, 1);
      }
    },
  },
});
