<script setup lang="ts">
import { computed, nextTick, ref } from "vue";
import { useI18n } from "vue-i18n";

import InputField from "@/components/input/InputField.vue";
import LabelInput from "@/components/input/LabelInput.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";

import type Duration from "../Duration";
import InputTime from "./InputTime.vue";

const props = defineProps<{ duration: Duration; name: string }>();
const emit = defineEmits<{
  name: [name: string];
  duration: [duration: Duration];
  cancel: [];
  start: [];
}>();

const validating = ref(false);

const { t } = useI18n();

const nameError = computed(() =>
  !validating.value || props.name ? "" : t("timer.missingDescription"),
);

const durationError = computed(() =>
  !validating.value || props.duration.value ? "" : t("timer.missingDuration"),
);

async function start() {
  validating.value = true;
  await nextTick();
  if (!nameError.value && !durationError.value) {
    emit("start");
  }
}
</script>

<template>
  <div class="inactive-timer">
    <LabelInput :label="$t('timer.nameLabel')" :error="nameError">
      <InputField
        id="description"
        v-autofocus
        :placeholder="$t('inactiveTimer.description')"
        :value="name"
        :max-length="32"
        @input="$emit('name', $event)"
      />
    </LabelInput>

    <LabelInput :label="$t('inactiveTimer.duration')" :error="durationError">
      <InputTime
        :duration="duration"
        aria-live="polite"
        aria-atomic="true"
        @duration="$emit('duration', $event)"
      />
    </LabelInput>

    <div class="add">
      <BaseButton
        color="grey"
        variant="filled"
        size="small"
        :aria-label="$t('timer.addMinuteAria', { m: 1 })"
        @click="$emit('duration', duration.plusMinutes(1))"
      >
        {{ $t("timer.addMinute", { m: 1 }) }}
      </BaseButton>
      <BaseButton
        color="grey"
        variant="filled"
        size="small"
        :aria-label="$t('timer.addMinuteAria', { m: 5 })"
        @click="$emit('duration', duration.plusMinutes(5))"
      >
        {{ $t("timer.addMinute", { m: 5 }) }}
      </BaseButton>
      <BaseButton
        color="grey"
        variant="filled"
        size="small"
        :aria-label="$t('timer.addMinuteAria', { m: 15 })"
        @click="$emit('duration', duration.plusMinutes(15))"
      >
        {{ $t("timer.addMinute", { m: 15 }) }}
      </BaseButton>
      <BaseButton
        color="grey"
        variant="filled"
        size="small"
        :aria-label="$t('timer.addMinuteAria', { m: 30 })"
        @click="$emit('duration', duration.plusMinutes(30))"
      >
        {{ $t("timer.addMinute", { m: 30 }) }}
      </BaseButton>
    </div>
    <div class="buttons">
      <BaseButton full-width @click="start">
        {{ $t("inactiveTimer.startTimer") }}
      </BaseButton>
      <BaseButton
        variant="outlined"
        color="grey"
        full-width
        @click="$emit('cancel')"
      >
        {{ $t("general.cancel") }}
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.inactive-timer {
  display: flex;
  flex-direction: column;
  gap: 1em;

  .add {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    button {
      overflow: hidden;
    }
  }

  .label-input {
    label {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }

  .buttons {
    display: flex;
    gap: 10px;

    .success {
      flex-grow: 1;
    }
  }
}
</style>
