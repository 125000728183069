<script setup lang="ts">
import { getAlmInfo } from "@/backend/Backend";
import UserAvatar from "@/components/UserAvatar/UserAvatar.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import MenuListItem from "@/components/ui/MenuList/components/MenuListItem.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { useUser } from "@/composables/useUser";
import type { AuthUser } from "@/model/user";

export type User = AuthUser & { foreign: boolean };

defineProps<{ user: User; active?: boolean }>();
const emit = defineEmits<{ select: [user: AuthUser] }>();

const { userName } = useUser();

function unmappedMessage(user: AuthUser) {
  switch (user.almMapping) {
    case "alm-only":
      return /*$t*/ "assignee.userNotMappedInPip";
    case "pip-only":
      return /*$t*/ "assignee.userNotMappedInAlm";
    default:
      return "";
  }
}
</script>

<template>
  <MenuListItem
    class="user-entry list-item"
    :class="{ selected: active }"
    @click.stop="emit('select', user)"
  >
    <template #before>
      <UserAvatar :user="user" size="small" />
    </template>
    <template v-if="unmappedMessage(user)" #after>
      <BaseTooltip position="right">
        <template #content>
          {{ $t(unmappedMessage(user), { almType: getAlmInfo().name }) }}
        </template>
        <SvgIcon name="message/warn" width="16" height="16" />
      </BaseTooltip>
    </template>
    <span :class="{ 'team-user': !user.foreign }">
      {{ userName(user) }}
    </span>
  </MenuListItem>
</template>

<style lang="scss" scoped>
@use "@/styles/font";

.user-entry {
  .team-user {
    font-weight: font.$weight-bold;
    display: flex;
    align-items: center;
  }
}
</style>
