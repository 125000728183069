<script setup lang="ts">
import { computed } from "vue";

import type { Team } from "@/model/session";
import { useBoardsStore } from "@/store/boards";

import MetricsModalBodyLayout from "./MetricsModalBodyLayout.vue";
import LoadCapacityChart from "./components/LoadCapacityChart.vue";
import { toLoadCapacityItem } from "./metrics";

const columnGapPercent = 2;

export interface LoadCapacityItem {
  id: number | string;
  capacity: number;
  load: number;
  title: string;
}

const props = defineProps<{
  team?: Team;
  overrides?: { items?: LoadCapacityItem[] };
}>();

const items = computed(
  () => props.overrides?.items || toLoadCapacityItem(board.value.iterations),
);

function sum(items: number[]) {
  return items.reduce((a, b) => a + b, 0);
}

const chartBasis = computed(() => {
  const cols = Math.ceil(items.value.length / 2);
  return Math.floor((100 - (columnGapPercent - 1) * cols) / cols);
});

const board = computed(() =>
  useBoardsStore().boardByType("team", { teamId: props.team?.id }),
);
</script>

<template>
  <MetricsModalBodyLayout>
    <template #left>
      <LoadCapacityChart
        class="summary-chart"
        :capacity="sum(items.map((item) => item.capacity))"
        :load="sum(items.map((item) => item.load))"
        :title="$t('metricsModal.allIterations')"
      />
    </template>
    <template #right>
      <div class="full-size">
        <div class="chart-container scrollable">
          <LoadCapacityChart
            v-for="item in items"
            :key="item.id"
            :style="`flex: 0 1 ${chartBasis}%;`"
            :capacity="item.capacity"
            :load="item.load"
            :title="item.title"
          />
        </div>
      </div>
    </template>
  </MetricsModalBodyLayout>
</template>

<style lang="scss">
.summary-chart {
  width: 50%;
  height: 73.5%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);

  :deep {
    .chart {
      max-width: unset;
    }

    .subtitle {
      font-size: 28px;
    }
  }
}

.full-size {
  width: 100%;
  height: 100%;
}

.chart-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 4% 2%;
  overflow: auto;
}
</style>
