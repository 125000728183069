<script setup lang="ts">
import MenuListItem from "@/components/ui/MenuList/components/MenuListItem.vue";
import IconUser from "@/icons/general/user.svg?component";

const emit = defineEmits<{ select: [user: null] }>();
</script>

<template>
  <MenuListItem class="list-item" @click.stop="emit('select', null)">
    <template #before>
      <div class="unassign-container">
        <IconUser />
      </div>
    </template>
    <span>{{ $t("general.unassigned") }}</span>
  </MenuListItem>
</template>

<style lang="scss" scoped>
.unassign-container {
  width: 24px;
  text-align: center;

  svg {
    width: 20px;
  }
}
</style>
