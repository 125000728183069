<script lang="ts" setup>
import { computed, onMounted } from "vue";

import { cardActions } from "@/action/cardActions";
import { actionSourceKey } from "@/components/action/injectKeys";
import { boardKey } from "@/components/board/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import { dropdownKey } from "@/components/ui/DropdownMenu/injectKeys";
import MenuList from "@/components/ui/MenuList/MenuList.vue";
import MenuListHeader from "@/components/ui/MenuList/components/MenuListHeader.vue";
import MenuListItem from "@/components/ui/MenuList/components/MenuListItem.vue";
import MenuListItems from "@/components/ui/MenuList/components/MenuListItems.vue";
import MenuListTitle from "@/components/ui/MenuList/components/MenuListTitle.vue";
import StatusDot from "@/components/ui/StatusDot/StatusDot.vue";
import { type Status } from "@/model/status";
import { useAlmItemTypeStore } from "@/store/almItemType";
import { useCardStore } from "@/store/card";
import { injectStrict } from "@/utils/vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);
const dropdown = injectStrict(dropdownKey);
const actionSource = injectStrict(actionSourceKey);

onMounted(async () => {
  if (!card.status) return; // Ignore stickies without statuses (e.g. notes)

  // In case the status is dynamic, we potentially (our backend caches) don't know the next statuses yet (Jira server)
  // thus we fetch the possible statuses from the backend for this particular sticky.
  if (card.status.next.length === 0 && card.status.dynamic) {
    await useAlmItemTypeStore().updateAlmItemType(card, board.value);
    useCardStore().updateCardsStatus(card, board.value);
  }
});

const statuses = computed(() => {
  if (!card.status) return [];

  // Make sure the current status is in order with all the other next statuses
  const statuses = [
    card.status,
    ...card.status.next.map((next) => next.status),
  ];
  return statuses.sort((a, b) => a.order - b.order);
});

const handleStatusChange = (statusId: Status["id"]) => {
  const target = card.status?.next.find((next) => next.status.id === statusId);
  if (!target) return;

  cardActions.setStatus(actionSource, board.value, card, target);

  dropdown.close();
};
</script>

<template>
  <MenuList role="dialog" aria-labelledby="sticky-status-title" selectable>
    <MenuListHeader id="sticky-status-title">
      <MenuListTitle>{{ $t("stickyStatus.status") }}</MenuListTitle>
    </MenuListHeader>
    <MenuListItems aria-labelledby="sticky-status-title">
      <MenuListItem
        v-for="(status, i) in statuses"
        :key="status.id"
        v-autofocus="i === 0"
        :selected="status.id == card.status?.id"
        :static="status.id == card.status?.id"
        @click="handleStatusChange(status.id)"
      >
        <template #before>
          <StatusDot
            class="status-dot-list-item"
            :status-class="status.statusClass || 'undefined'"
          />
        </template>
        {{ status.name }}
      </MenuListItem>
    </MenuListItems>
  </MenuList>
</template>

<style lang="scss" scoped>
.status-dot-list-item {
  height: 20px;
  width: 20px;
}
</style>
