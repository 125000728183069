import type { Directive } from "vue";

const regions = new Set<HTMLElement>();

export function makeBoardRegionsUnfocusable() {
  for (const region of regions) {
    region.removeAttribute("tabindex");
  }
}

export function makeBoardRegionsFocusable() {
  for (const region of regions) {
    region.setAttribute("tabindex", "0");
  }
}

export function boardRegion(): Directive<HTMLElement> {
  return {
    mounted(el) {
      el.setAttribute("tabindex", "0");
      regions.add(el);
    },
    beforeUnmount(el) {
      regions.delete(el);
    },
  };
}
