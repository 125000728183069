import { defineStore } from "pinia";

import type { TimerEvent } from "@/model/timer";

import { useArtStore } from "./art";
import { useBoardStore } from "./board";

export const useTimerStore = defineStore("timer", {
  state: () => ({
    timers: [] as TimerEvent[],
    active: false,
  }),
  getters: {
    boardTimers(): TimerEvent[] {
      return this.timers.filter(
        (timer) => timer.boardId === useBoardStore().currentBoard().id,
      );
    },
    artTimers(): TimerEvent[] {
      return this.timers.filter(
        (timer) => !timer.boardId && useArtStore().isCurrentArt(timer.artId),
      );
    },
  },
  actions: {
    toggleTimer() {
      this.active = !this.active;
    },
  },
});
