<script lang="ts" setup>
import { computed } from "vue";

import { navigationActions } from "@/action/navigationActions";
import ActionMenuItem from "@/components/ActionMenu/ActionMenuItem.vue";
import { isActionVisible } from "@/components/StickyNote/components/ActionMenu/actions";
import { boardKey } from "@/components/board/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import StickyLinkModal from "@/components/modal/stickyLink/StickyLinkModal.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import type { Card } from "@/model/card";
import { injectStrict } from "@/utils/vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);

const visible = computed(() => isActionVisible("link", card, board.value));

const handleLinkModalOpen = (card: Card) => {
  const stickyType = card.type.functionality;
  navigationActions.openModal("action-menu", StickyLinkModal, {
    attrs: { cardIds: [card.id], "data-ignore-click": true },
    trackingParams: { stickyType },
  });
};
</script>

<template>
  <ActionMenuItem
    v-if="visible"
    name="link"
    :tooltip-text="$t('cardAction.link')"
    :aria-label="$t('label.actionMenu.link')"
    @click="handleLinkModalOpen(card)"
  >
    <SvgIcon name="action/link" width="20" height="20" />
  </ActionMenuItem>
</template>
