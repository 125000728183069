import { Vue } from "vue-class-component";

import EventBus from "@/composables/EventBus";
import type { EventType, StickyNoteAction } from "@/composables/useEventBus";
import type { Board } from "@/model/board";
import type { Card } from "@/model/card";

export default class EventBusUser extends Vue {
  callbacks: Record<string, (e: unknown) => void> = {};

  onSetting(name: string, callback: (e: unknown) => void) {
    this._onKeyEvent("setting", name, callback);
  }

  onBoardSwitch(callback: () => void) {
    this._onEvent("boardSwitch", callback);
  }

  onBoardLoaded(callback: (boardId: Board["id"]) => void) {
    this._onEvent("boardLoaded", (boardId: Board["id"]) => callback(boardId));
  }

  onStickyNoteAction<T extends StickyNoteAction["action"]>(
    id: Card["id"] | null,
    action: T,
    callback: (action: Extract<StickyNoteAction, { action: T }>) => void,
  ) {
    this._onEvent("stickyNoteAction", (e: StickyNoteAction) => {
      if (id === null && e.action === action) {
        callback(e as Extract<StickyNoteAction, { action: T }>);
        return;
      }

      if (e.id === id && e.action === action) {
        callback(e as Extract<StickyNoteAction, { action: T }>);
      }
    });
  }

  _onEvent(name: EventType, callback: (e?: any) => void) {
    this.callbacks[name] = callback;
    EventBus.on(name, callback);
  }

  _onKeyEvent(name: EventType, key: string, callback: (e: unknown) => void) {
    this._onEvent(name, (e) => {
      if (e.key === key) {
        callback(e.value);
      }
    });
  }

  unmounted() {
    for (const name in this.callbacks) {
      EventBus.off(name, this.callbacks[name]);
    }
  }
}
