<script setup lang="ts">
import { ref } from "vue";

import DeleteMenuItem from "@/components/DeleteMenuItem/DeleteMenuItem.vue";
import ActionSource from "@/components/action/ActionSource.vue";
import { cardKey } from "@/components/card/injectKeys";
import type { BoardCard } from "@/model/card";
import { injectStrict } from "@/utils/vue";

import ActivityMenuItem from "./components/ActivityMenuItem/ActivityMenuItem.vue";
import AlmMenuItem from "./components/AlmMenuItem/AlmMenuItem.vue";
import ChangeArtMenuItem from "./components/ChangeArtMenuItem/ChangeArtMenuItem.vue";
import ChangeProjectMenuItem from "./components/ChangeProjectMenuItem/ChangeProjectMenuItem.vue";
import DependOnTeamMenuItem from "./components/DependOnTeamMenuItem/DependOnTeamMenuItem.vue";
import DuplicateMenuItem from "./components/DuplicateMenuItem/DuplicateMenuItem.vue";
import LinkMenuItem from "./components/LinkMenuItem/LinkMenuItem.vue";
import MirrorMenuItem from "./components/MirrorMenuItem/MirrorMenuItem.vue";
import MirrorToArtBoardMenuItem from "./components/MirrorToArtBoardMenuItem/MirrorToArtBoardMenuItem.vue";
import MoveMenuItem from "./components/MoveMenuItem/MoveMenuItem.vue";
import PinMenuItem from "./components/PinMenuItem/PinMenuItem.vue";
import ShareMenuItem from "./components/ShareMenuItem/ShareMenuItem.vue";
import StickyStatusMenuItem from "./components/StickyStatusMenuItem/StickyStatusMenuItem.vue";
import StickyTypeMenuItem from "./components/StickyTypeMenuItem/StickyTypeMenuItem.vue";
import StoryPointsMenuItem from "./components/StoryPointsMenuItem/StoryPointsMenuItem.vue";
import TeamMenuItem from "./components/TeamMenuItem/TeamMenuItem.vue";
import WsjfMenuItem from "./components/WsjfMenuItem/WsjfMenuItem.vue";

const card = injectStrict(cardKey);

const trapOptions = {
  returnFocusOnDeactivate: false,
  // Focus trap should contain the card as well as the action menu
  extraElements: ref([card.id]),
  // Fixes bug when card+dialog close at the same time (eg. when deleting a card)
  fallbackFocus: `[id='${card.id}']`,
};
</script>

<template>
  <ActionSource
    :id="'action-menu-' + card.id"
    v-focus-trap="trapOptions"
    value="action-menu"
    style="display: contents"
  >
    <StickyTypeMenuItem />
    <StickyStatusMenuItem />
    <WsjfMenuItem />
    <StoryPointsMenuItem />
    <ChangeProjectMenuItem />
    <ChangeArtMenuItem />
    <AlmMenuItem />
    <LinkMenuItem />
    <MoveMenuItem />
    <MirrorMenuItem />
    <MirrorToArtBoardMenuItem />
    <TeamMenuItem />
    <DependOnTeamMenuItem />
    <DeleteMenuItem :cards="[{ data: card } as BoardCard]" />
    <PinMenuItem />
    <DuplicateMenuItem />
    <ShareMenuItem />
    <ActivityMenuItem />
  </ActionSource>
</template>
