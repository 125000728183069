<script setup lang="ts">
import { isEmpty } from "lodash-es";
import { provide } from "vue";

import type { ActionSource } from "@/model/trigger";

import { actionSourceKey } from "./injectKeys";

const props = defineProps<{ value: ActionSource }>();

provide(actionSourceKey, props.value);
</script>

<template>
  <slot v-if="isEmpty($attrs)" />
  <div v-else>
    <slot />
  </div>
</template>
