import { rectangle } from "@/math/coordinates";
import type { Board } from "@/model/board";
import type { BoardCard } from "@/model/card";
import type { Rectangle, RelativeCoordinate } from "@/model/coordinates";
import { relativeCoord } from "@/model/coordinates";
import type { Shape } from "@/model/shape";
import { fixLine } from "@/model/shape";

export const borderLines = [
  fixLine("bv1", relativeCoord(0, 0), relativeCoord(0, 1)),
  fixLine("bv2", relativeCoord(1, 0), relativeCoord(1, 1)),
  fixLine("bh1", relativeCoord(0, 0), relativeCoord(1, 0)),
  fixLine("bh2", relativeCoord(0, 1), relativeCoord(1, 1)),
];

export interface BoardLocation {
  name: string;
  bounds: Rectangle<RelativeCoordinate>;
  shapes: Shape[];

  matches(coord: RelativeCoordinate): boolean;

  index(): number[];
}

export const fullBoardLocation: BoardLocation = {
  name: "",
  bounds: rectangle({ from: relativeCoord(0, 0), to: relativeCoord(1, 1) }),
  shapes: [...borderLines],
  matches() {
    return true;
  },
  index() {
    return [];
  },
};

export function cardsInLocation(board: Board, loc: BoardLocation): BoardCard[] {
  const cards = new Array<BoardCard>();
  for (const id in board.cards) {
    if (loc.matches(board.cards[id].meta.pos)) {
      cards.push(board.cards[id]);
    }
  }
  return cards;
}
