<script setup lang="ts">
import { computed, watch } from "vue";

import { toggleActions } from "@/action/toggleActions";
import ActionButton from "@/components/action/ActionButton.vue";
import ActionSource from "@/components/action/ActionSource.vue";
import { useBoardMenuStore } from "@/store/boardMenu";
import { useSidePanelStore } from "@/store/sidePanel";

import MenuItemDelimiter from "../MenuItemDelimiter.vue";
import BoardsItem from "./components/BoardsItem.vue";

const boardStore = useBoardMenuStore();

watch(
  () => useSidePanelStore().panelLeftOpen,
  (open) => {
    boardStore.setMenuPosition(open ? "right" : "default");
  },
);

const menuPosition = computed(() => boardStore.position);
const isButtonDisabled = computed(() => boardStore.disabledShiftButton);
</script>

<template>
  <nav
    v-scrollable-on-board
    class="side-menu"
    :class="menuPosition"
    :aria-label="$t('label.menuSidebar')"
  >
    <ActionSource value="sidebar-menu">
      <div class="turn shift-button">
        <ActionButton
          :action="toggleActions.shiftMenu"
          :disabled="isButtonDisabled"
          position="right"
        />
      </div>
      <MenuItemDelimiter horizontal />
      <BoardsItem class="boards-item" />
    </ActionSource>
  </nav>
</template>

<style lang="scss">
@use "@/styles/z-index";
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/a11y" as colors-a11y;
@use "@/styles/variables/colors";
@use "@/styles/mixins/shadow";

.side-menu {
  position: fixed;
  width: 44px;
  left: 10px;
  font-size: 9px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 50%;
  transform: translateY(-50%);
  background-color: colors-old.$back-color;
  z-index: z-index.$menu-sidebar;

  @include shadow.default;

  border-radius: variables.$common-border-radius;
  padding: 2px;

  .high-contrast-mode & {
    border: 2px solid colors-a11y.$board-border;
  }

  .shift-button {
    display: flex;
  }

  &.right {
    left: auto;
    right: 10px;

    .turn svg {
      transform: rotate(180deg);
    }
  }
}
</style>
