<script setup lang="ts">
import { computed } from "vue";

import { boardActions } from "@/action/boardActions";
import { toggleActions } from "@/action/toggleActions";
import BoardDiffPanel from "@/components/ActivityPanel/BoardDiffPanel.vue";
import StickyActivityPanel from "@/components/ActivityPanel/StickyActivityPanel.vue";
import ContextMenu from "@/components/ContextMenu/ContextMenu.vue";
import SwitchSessionPanel from "@/components/SwitchSessionPanel/SwitchSessionPanel.vue";
import TimerPanel from "@/components/Timer/TimerPanel.vue";
import { useShortcuts } from "@/composables/useShortcuts";
import { useDrawStore } from "@/store/draw";

import BottomMenu from "./BottomMenu/BottomMenu.vue";
import SideMenu from "./SideMenu/SideMenu.vue";
import TopMenu from "./TopMenu/TopMenu.vue";

const { registerShortcut } = useShortcuts();
registerShortcut(boardActions.screenshot, { prevent: false, withEvent: true });
registerShortcut(toggleActions.showShortcuts);
registerShortcut(toggleActions.showSettings);

const drawMode = computed(() => useDrawStore().active);
</script>

<template>
  <div data-no-screenshot>
    <ContextMenu />

    <TopMenu v-if="!drawMode" />
    <SideMenu v-if="!drawMode" />
    <BottomMenu />

    <TimerPanel />
    <StickyActivityPanel />
    <BoardDiffPanel />
    <SwitchSessionPanel />
  </div>
</template>
