<script setup lang="ts">
import { computed } from "vue";

import BaseFlag from "@/components/card/BaseFlag.vue";
import FlagPicker from "@/components/flags/FlagPicker.vue";
import ListItem from "@/components/ui/ListItem/ListItem.vue";
import ListItemSeparator from "@/components/ui/ListItem/ListItemSeparator.vue";
import MenuList from "@/components/ui/MenuList/MenuList.vue";
import MenuListHeader from "@/components/ui/MenuList/components/MenuListHeader.vue";
import MenuListTitle from "@/components/ui/MenuList/components/MenuListTitle.vue";
import { useSearchMenuStore } from "@/store/searchMenu";

import StickersItemTooltip from "../tooltips/StickersItemTooltip.vue";
import SearchDropdown from "./SearchDropdown.vue";

const store = useSearchMenuStore();
const anySelected = computed(() => store.selectedFlagStrings.size > 0);
</script>

<template>
  <SearchDropdown
    name="stickers"
    icon="general/sticker"
    label-key="general.stickers"
    :selected="anySelected"
  >
    <template #tooltip>
      <StickersItemTooltip :stickers="store.flags" />
    </template>
    <MenuList>
      <MenuListHeader>
        <MenuListTitle>{{ $t("general.stickers") }}</MenuListTitle>
      </MenuListHeader>
    </MenuList>
    <ListItem static class="picker">
      <FlagPicker
        v-autofocus
        no-sticker-option
        :selected="store.flags"
        @select="store.toggleFlag"
      />
    </ListItem>
    <template v-if="anySelected">
      <ListItemSeparator />
      <ListItem static class="footer">
        <div class="selected-flags-wrapper" @click="store.clearFlags">
          <div class="header">
            <span>
              {{ $t("stickersItem.selected") }}
            </span>
            <button class="active">
              {{ $t("general.clear") }}
            </button>
          </div>
          <div class="flags">
            <BaseFlag
              v-for="selectedFlag in store.flags"
              :key="selectedFlag.toString()"
              :flag="selectedFlag"
              @click.stop="store.toggleFlag(selectedFlag)"
            />
          </div>
        </div>
      </ListItem>
    </template>
  </SearchDropdown>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.picker {
  font-size: 10px;
}

.footer {
  font-size: 12px;

  .selected-flags-wrapper {
    width: 100%;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .active {
    color: colors-old.$primary-color;
    cursor: pointer;
    outline: revert;
  }

  .flags {
    display: flex;
    height: 2.8em;

    .flag-icon {
      width: auto;
      cursor: pointer;
    }
  }
}
</style>
