import { defineStore } from "pinia";
import type { DefineComponent, EmitsOptions, PropType, Raw } from "vue";
import { markRaw } from "vue";

import type { WindowCoordinate } from "@/model/coordinates";

/* eslint-disable @typescript-eslint/no-explicit-any */
export type VueComponent<
  PropTypes,
  E extends EmitsOptions = Record<string, any>,
> = DefineComponent<
  PropTypes,
  object,
  unknown,
  Record<string, any>,
  Record<string, any>,
  Record<string, any>,
  Record<string, any>,
  E
>;
/* eslint-enable */

type RequiredProps<T> = {
  [K in keyof T]: { type: PropType<T[K]>; required: true };
};

interface ContextMenu {
  component: Raw<VueComponent<unknown>>;
  props: PositionProps;
  eventHandlers?: EventHandlers;
}

export interface PositionProps {
  position: WindowCoordinate;
}

export type EventHandlers = Record<string, (...event: any[]) => void>;

export const useContextMenuStore = defineStore("contextMenu", {
  state: () => ({
    menu: null as ContextMenu | null,
    nextMenu: null as ContextMenu | null,
  }),
  actions: {
    open<T extends PositionProps, E extends EmitsOptions>(
      component: VueComponent<RequiredProps<T>, E>,
      props: T,
      eventHandlers?: EventHandlers,
    ) {
      const newMenu = {
        component: markRaw(component) as Raw<VueComponent<unknown>>,
        props,
        eventHandlers,
      };
      if (!this.menu) {
        this.menu = newMenu;
      } else {
        this.nextMenu = newMenu;
      }
    },
    close() {
      this.menu = null;
      if (this.nextMenu) {
        this.menu = this.nextMenu;
        this.nextMenu = null;
      }
    },
  },
});
