import type { Board } from "@/model/board";
import type { Card } from "@/model/card";
import { isDependency } from "@/model/stickyType";

import { useTeamStore } from "../team";

export function cardsMirrorableToNonFlexBoard(cards: Card[], board: Board) {
  if (!["program", "solution", "risk", "team"].includes(board.type)) {
    return false;
  }
  if (board.artId && board.artId !== useTeamStore().current.artId) {
    return false;
  }
  return (
    cardTypesMirrorableToBoard(cards, board) &&
    anyNonOriginCard(cards, board) &&
    cardsMatchingTeam(cards, board)
  );
}

function cardTypesMirrorableToBoard(cards: Card[], board: Board) {
  return cards.every((card) =>
    card.type.usable.some(
      (boardType) =>
        boardType === board.type &&
        (boardType !== "program" || card.teamId) &&
        (boardType !== "solution" || cardMirrorableToSolutionBoard(card)),
    ),
  );
}

// We can always mirror to the solution board if card has artId, otherwise card functionality should
// be dependency and should depend on the team from another ART
function cardMirrorableToSolutionBoard(card: Card): boolean {
  return (
    !!card.artId ||
    (isDependency(card) && card.precondTeam?.artId !== card.dependTeam?.artId)
  );
}

function anyNonOriginCard(cards: Card[], board: Board) {
  return cards.some((card) => board.type !== card.type.origin);
}

function cardsMatchingTeam(cards: Card[], board: Board) {
  return (
    board.type !== "team" ||
    cards.every((card) => !card.teamId || board.team.id === card.teamId)
  );
}
