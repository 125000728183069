<script lang="ts" setup>
import { cardActions } from "@/action/cardActions";
import { actionSourceKey } from "@/components/action/injectKeys";
import { cardKey } from "@/components/card/injectKeys";
import { dropdownKey } from "@/components/ui/DropdownMenu/injectKeys";
import MenuList from "@/components/ui/MenuList/MenuList.vue";
import MenuListItem from "@/components/ui/MenuList/components/MenuListItem.vue";
import MenuListItems from "@/components/ui/MenuList/components/MenuListItems.vue";
import { useToastStore } from "@/store/toast";
import { injectStrict } from "@/utils/vue";

const card = injectStrict(cardKey);
const dropdown = injectStrict(dropdownKey);
const actionSource = injectStrict(actionSourceKey);

function stickyLinkToClipboard() {
  cardActions.shareLink(actionSource, card);
  useToastStore().show(/*$t*/ "message.stickyLinkCopied");
  dropdown.close();
}

function almLinkToClipboard() {
  cardActions.shareAlmLink(actionSource, card);
  useToastStore().show(/*$t*/ "message.almLinkCopied");
  dropdown.close();
}

function almIdToClipboard() {
  cardActions.shareAlmId(actionSource, card);
  useToastStore().show(/*$t*/ "message.almIdCopied");
  dropdown.close();
}
</script>

<template>
  <MenuList role="dialog">
    <MenuListItems role="menu">
      <MenuListItem v-autofocus role="menuitem" @click="stickyLinkToClipboard">
        {{ $t("label.actionMenu.stickyLinkToClipboard") }}
      </MenuListItem>
      <MenuListItem
        v-if="card.almIssueUrl"
        role="menuitem"
        @click="almLinkToClipboard"
      >
        {{ $t("label.actionMenu.almLinkToClipboard") }}
      </MenuListItem>
      <MenuListItem v-if="card.almId" role="menuitem" @click="almIdToClipboard">
        {{ $t("label.actionMenu.almIdToClipboard") }}
      </MenuListItem>
    </MenuListItems>
  </MenuList>
</template>
