import { computed, ref, watch } from "vue";

import {
  divided,
  max,
  min,
  minus,
  plus,
  times,
  toSize,
} from "@/math/coordinates";
import type { IdMap } from "@/model/baseTypes";
import type { BoardCard } from "@/model/card";
import type { RelativeCoordinate } from "@/model/coordinates";
import { relativeCoord } from "@/model/coordinates";
import { useActionStore } from "@/store/action";
import { useBoardStore } from "@/store/board";
import type { Drag } from "@/store/dragging";
import { useDraggingStore } from "@/store/dragging";

const PADDING = relativeCoord(-0.5, -0.5);

export function useStickyBoundingRect(width: number, height: number) {
  const pos = ref<RelativeCoordinate[]>([]);
  watch(
    selectionAndDrag,
    ([drag, selection]) => {
      if (!useActionStore().previewing) {
        pos.value = dragPos(drag) ?? selectionPos(selection) ?? [];
      }
    },
    { immediate: true, deep: true },
  );

  function selectionAndDrag(): [IdMap<Drag>, BoardCard[]] {
    return [useDraggingStore().dragging, useBoardStore().selectedStickies];
  }

  function dragPos(drag: IdMap<Drag>) {
    const drags = Object.values(drag);
    if (drags.length > 1 && drags[0].type === "card") {
      return drags.map((d) => d.pos);
    }
  }

  function selectionPos(selection: BoardCard[]) {
    if (selection.length > 1) {
      return selection.map((card) => ({ ...card.meta.pos }));
    }
  }

  const boundingRect = computed(() => {
    if (pos.value.length === 0) {
      return;
    }
    const cardSize = divided(useBoardStore().currentBoard().cardSize, 2);
    const start = minus(min(...pos.value), cardSize);
    const end = plus(max(...pos.value), cardSize);
    const scaledStart = times(start, width, height);
    const scaledSize = times(minus(end, start), width, height);
    return {
      ...minus(scaledStart, PADDING),
      ...toSize(plus(scaledSize, times(PADDING, 2))),
    };
  });

  return { boundingRect };
}
