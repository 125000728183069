<template>
  <div :id="board.id" class="board board-backlog">
    <LoadingIndicator v-if="!board.loaded" global />
    <div class="backdrop">
      <KebabMenu
        class="kebab-menu"
        :tooltip="$t('label.backlogBoardMenu')"
        mode="backlog"
        data-testid="kebab-menu"
      />
    </div>
    <StickyNote
      v-for="(card, index) in visibleCards"
      :key="card.data.id"
      :card="card.data"
      :card-meta="card.meta"
      :override="{ size: cardSize, position: cardMiddlePos(index) }"
      :draggable="false"
      :level-of-details="levelOfDetails"
    />
    <BacklogAddNewButton
      v-if="!isReadonly"
      ref="addStickyButton"
      class="add"
      :style="{
        top: top(visibleCards.length) + 'px',
        left: left(visibleCards.length) + 'px',
        height: cardSize + 'px',
        width: cardSize + 'px',
      }"
      @click="add"
    >
    </BacklogAddNewButton>
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";

import { cardActions } from "@/action/cardActions";
import StickyNote from "@/components/StickyNote/StickyNote.vue";
import type { ActionType } from "@/components/card/actions";
import KebabMenu from "@/components/menu/BoardContextMenu/KebabMenu.vue";
import { isKeyDown } from "@/components/shortcut/shortcuts";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import type { Board } from "@/model/board";
import type { BoardCard } from "@/model/card";
import { boundsCoord, clientCoord, relativeCoord } from "@/model/coordinates";
import { useBoardStore } from "@/store/board";
import { cardColor } from "@/store/card";
import { useUserStore } from "@/store/user";

import LoadingIndicator from "../ui/LoadingIndicator/LoadingIndicator.vue";
import type { CardSize } from "./FluidBoard";
import FluidBoard from "./FluidBoard";
import BacklogAddNewButton from "./components/BacklogAddNewButton.vue";

const cardGap = 25;
// used for the new sticky note, since we have a progress bar on the bottom
const gapMultiplier = 0.057;
@Component({
  components: {
    BaseButton,
    LoadingIndicator,
    KebabMenu,
    StickyNote,
    BacklogAddNewButton,
  },
})
export default class BaseBacklogBoard extends mixins(FluidBoard) {
  @Prop(Object) readonly backlogBoard!: Board;
  @Prop(Array) readonly actions!: ActionType[];
  @Ref("addStickyButton") readonly addStickyButton!: HTMLElement;
  gap = cardGap;

  columns = 0;

  get board() {
    return this.backlogBoard;
  }

  @Watch("size", { immediate: true })
  sizeChanges(val: number) {
    // sticky size has changed -> trigger font size recalc after DOM update
    void this.$nextTick(() => {
      if (val > 0) {
        this.gap = (this.cardSize * gapMultiplier) / 2;
      }

      this.gap = (this.cardSize * gapMultiplier) / 2;
      const factor = useBoardStore().currentBoard().cardSize.factor + 0.000001;
      useBoardStore().currentBoard().cardSize = {
        ...relativeCoord(
          this.cardSize / this.width,
          this.cardSize / this.height,
        ),
        factor,
      };
      this.updateCardPos();
    });
  }

  add(e: MouseEvent) {
    const fromKeyboard = isKeyDown("Enter");

    if (!fromKeyboard) {
      const cardProps = { pos: clientCoord(e) };
      cardActions.add("backlog-board-plus-button", cardProps, true);
      return;
    }

    // If the user pressed enter, open the context menu at the same position as the button
    // and return focus to the trigger when the menu is closed
    const onClose = () => setTimeout(() => this.addStickyButton?.focus(), 0);
    const cardProps = { pos: boundsCoord(this.addStickyButton), onClose };
    cardActions.add("backlog-board-plus-button", cardProps, true);
  }

  cardMiddlePos(position: number) {
    const cardMiddle = this.cardSize / 2;
    return relativeCoord(
      (this.left(position) + cardMiddle) / this.width,
      (this.top(position) + cardMiddle) / this.height,
    );
  }

  top(position: number) {
    const rowNumber = Math.floor(position / this.columns);
    const previousCardRowsHeight = rowNumber * this.size;
    const topMargin = this.height * 0.1;
    return topMargin + previousCardRowsHeight;
  }

  left(position: number) {
    const colNumber = position % this.columns;
    const previousCardColsWidth = colNumber * this.size;
    return previousCardColsWidth + this.gap;
  }

  show(card: BoardCard) {
    return card.meta.mark !== "filter-out";
  }

  color(card: BoardCard) {
    return cardColor(card.data, this.board);
  }

  get cards(): BoardCard[] {
    return Object.values(this.board.cards).sort((a, b) => {
      const prio = b.data.priority - a.data.priority;
      // if priorities are equal, assure there's still a defined order
      return prio !== 0 ? prio : a.data.id > b.data.id ? 1 : -1;
    });
  }

  get visibleCards(): BoardCard[] {
    return this.cards.filter((card) => this.show(card));
  }

  get cardSize() {
    return this.size - this.gap * 2;
  }

  get size() {
    const numberOfCards = this.visibleCards.length + 1;
    if (numberOfCards <= 10) {
      this.columns = 5;
      return this.width / 5;
    }
    const h = this.height * 0.8;
    const w = this.width;
    const ac = Math.ceil(Math.sqrt((w / h) * numberOfCards));
    const bc = Math.ceil(Math.sqrt((h / w) * numberOfCards));

    const bw = Math.floor(w / (h / bc));
    const bw2 = Math.floor(w / (h / (bc + 1)));
    const a1 = ac * Math.floor(h / (w / ac));
    const a2 = (ac + 1) * Math.floor(h / (w / (ac + 1)));
    const b1 = bw * bc;
    if (a1 >= numberOfCards && (b1 < numberOfCards || a1 <= b1)) {
      this.columns = ac;
      return w / ac;
    }
    if (a2 >= numberOfCards && (b1 < numberOfCards || a2 <= b1)) {
      this.columns = ac + 1;
      return w / (ac + 1);
    }
    if (b1 >= numberOfCards) {
      this.columns = bw;
      return h / bc;
    }
    this.columns = bw2;
    return h / (bc + 1);
  }

  get isReadonly() {
    return !useUserStore().isAllowed("edit");
  }

  updateCardPos() {
    this.cards.map((card, i) => {
      card.meta.pos = relativeCoord(
        (this.left(i) + this.cardSize / 2) / this.width,
        (this.top(i) + this.cardSize / 2) / this.height,
      );
    });
  }

  getRelativeCardSizes(): CardSize[] {
    return this.cards.map((card, i) =>
      this.calcBacklogboardRelativeCardSize(card, i),
    );
  }

  calcBacklogboardRelativeCardSize(card: BoardCard, index: number): CardSize {
    return {
      id: card.data.id,
      left: this.left(index) / this.width,
      top: this.top(index) / this.height,
      width: this.cardSize / this.width,
      height: this.cardSize / this.height,
    };
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/a11y";
@use "@/styles/board";

.backdrop {
  align-items: baseline;
  display: flex;
  justify-content: right;
  padding-top: 1em;
  padding-right: 1em;

  h2 {
    flex: 1;
  }

  :deep(.kebab-menu) {
    width: board.len(18px);
    height: board.len(18px);
  }
}

.board {
  @include a11y.board;
}
</style>

<style lang="scss">
@use "@/styles/mixins/a11y";
@use "@/styles/colors" as colors-old;

.base-button.medium.add {
  @include a11y.sticky-note;

  position: absolute;

  // The plus icon
  svg {
    width: 20%;
    height: 20%;
  }
}
</style>
