<script setup lang="ts">
import { computed, ref } from "vue";

import { cardActions } from "@/action/cardActions";
import ActionButton from "@/components/action/ActionButton.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { useShortcuts } from "@/composables/useShortcuts";
import { isFrontendBoard } from "@/model/board";
import color from "@/model/color.module.scss";
import type { StickyType } from "@/model/stickyType";
import { useBoardStore } from "@/store/board";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useUserStore } from "@/store/user";
import { contrastCssColor, toCssColor } from "@/utils/color";

import AddItemCard from "./AddItemCard.vue";

const actions = cardActions;

const choose = ref(false);
const readOnly = computed(() => !useUserStore().isAllowed("edit"));

const { registerShortcut } = useShortcuts();
registerShortcut(cardActions.add);

const types = computed(() => useBoardStore().creatableStickyTypes);
const currentType = computed(() =>
  types.value.find(
    (t) => t.id === useClientSettingsStore().defaultStickyTypeId,
  ),
);
const colors = computed(() => {
  const current = currentType.value?.color;
  return current
    ? {
        backgroundColor: toCssColor(current),
        color: contrastCssColor(current),
      }
    : { backgroundColor: color.ok, color: color.back };
});
const disabled = computed(() =>
  isFrontendBoard(useBoardStore().currentBoard().type),
);

function toggle() {
  if (!readOnly.value) {
    choose.value = !choose.value;
  }
}

function select(type: StickyType) {
  useClientSettingsStore().setDefaultStickyType(
    currentType.value === type ? undefined : type,
  );
  choose.value = false;
}

function addCard(type: StickyType) {
  cardActions.add("board-actions-menu-click", { type }, true);
}
</script>

<template>
  <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
  <div class="add-item" :class="{ choose, disabled }" @click="toggle">
    <transition name="grow">
      <div v-if="choose" class="items">
        <div class="popout" role="menu">
          <BaseTooltip v-for="type in types" :key="type.id" position="top">
            <AddItemCard
              :type="type"
              :current="type === currentType"
              @select="select"
              @create="addCard"
            />
            <template #content>{{ type.name }}</template>
          </BaseTooltip>
          <IconButton
            icon="base/close"
            :aria-label="$t('general.close')"
            aria-expanded="true"
          />
        </div>
      </div>
    </transition>
    <div class="toggle-button-wrapper">
      <ActionButton
        v-if="!choose"
        :action="actions.togglePalette"
        :style="colors"
        aria-expanded="false"
        class="toggle-button"
        :tooltip-offset="[0, 22]"
      />
    </div>
  </div>
</template>

<style lang="scss">
.add-item {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  height: 40px;
  min-width: 40px;

  .toggle-button-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 8px;
    overflow: hidden;

    // Button should fill the container (no padding around this one)
    .toggle-button {
      width: 100%;
      height: 100%;
    }
  }

  .items {
    display: grid;
    grid-template-columns: 0fr;
    height: 36px;
  }

  .popout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }

  &.choose {
    padding: 2px;

    .items {
      grid-template-columns: 1fr;
    }
  }
}

.grow-enter-active,
.grow-leave-active {
  transition: grid-template-columns 0.5s ease;

  .popout {
    min-width: 0;
  }
}

.grow-enter-from,
.grow-leave-to {
  grid-template-columns: 0fr;
}

.grow-enter-to,
.grow-leave-from {
  grid-template-columns: 1fr;
}
</style>
