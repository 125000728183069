<script setup lang="ts">
import {
  flagName,
  iconMap,
} from "@/components/StickyNote/components/StickyNoteHeader/components/FlagChip/flagMap";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import type CardFlag from "@/model/CardFlag";
import { flagColors } from "@/model/colors";

const props = defineProps<{ flag: CardFlag }>();
</script>

<template>
  <SvgIcon
    :name="iconMap[props.flag.icon].icon"
    :color="flagColors[props.flag.colorIndex]"
    role="img"
    class="flag-icon"
    :aria-label="flagName(props.flag)"
  />
</template>

<style lang="scss" scoped>
.flag-icon {
  height: 100%;
  width: 100%;
  text-align: center;
  padding: 0.4em;

  :deep(svg) {
    height: 100%;
    width: 100%;
  }
}
</style>
