import { isArray } from "lodash-es";
import type { Events, Ref } from "vue";
import { computed, ref, useAttrs } from "vue";

/**
 *  Provides information about currently processing async event handlers.
 *
 *  const { attrs, processing } = useAsyncHandler("onClick")
 *  async function onClick() {...}
 *
 *  <root-element v-bind="attrs" @click="onClick">
 *    Click handler is running: {{ processing['onClick'} }}
 */
export function useAsyncHandler<T extends Array<keyof Events>>(
  delay: number,
  ...eventNames: T
) {
  type EventName = (typeof eventNames)[number];

  const $attrs = useAttrs();
  const attrs = computed(() => {
    return {
      ...$attrs,
      ...Object.fromEntries(
        eventNames.map((name) => [
          name,
          (event: Event) => handleClick(name, event),
        ]),
      ),
    };
  });

  const processing = ref(
    Object.fromEntries(eventNames.map((name) => [name, false])),
  ) as Ref<{ [event in EventName]: boolean }>;

  function handleClick(name: EventName, event: Event) {
    const handler = $attrs[name];
    if (isArray(handler)) {
      handler.forEach((handle) => void doHandleClick(name, handle(event)));
    } else {
      void doHandleClick(name, (handler as any)?.(event));
    }
  }

  async function doHandleClick(name: EventName, result?: any) {
    if (result?.then) {
      const timeout = window.setTimeout(
        () => (processing.value[name] = true),
        delay,
      );
      try {
        return await result;
      } finally {
        clearTimeout(timeout);
        processing.value[name] = false;
      }
    }
  }

  return { attrs, processing };
}
