<script lang="ts" setup>
import { selectionActions } from "@/action/selectionActions";
import { actionSourceKey } from "@/components/action/injectKeys";
import { dropdownKey } from "@/components/ui/DropdownMenu/injectKeys";
import MenuList from "@/components/ui/MenuList/MenuList.vue";
import MenuListItem from "@/components/ui/MenuList/components/MenuListItem.vue";
import MenuListItems from "@/components/ui/MenuList/components/MenuListItems.vue";
import { useToastStore } from "@/store/toast";
import { injectStrict } from "@/utils/vue";

const dropdown = injectStrict(dropdownKey);
const actionSource = injectStrict(actionSourceKey);

function stickyLinkToClipboard() {
  selectionActions.share(actionSource);
  useToastStore().show(/*$t*/ "message.stickyLinkCopied");
  dropdown.close();
}
</script>

<template>
  <MenuList role="dialog">
    <MenuListItems>
      <MenuListItem @click="stickyLinkToClipboard">
        {{ $t("label.actionMenu.stickyLinkToClipboard") }}
      </MenuListItem>
    </MenuListItems>
  </MenuList>
</template>
