import type {
  ArtContext,
  EmptyContext,
  SolutionTrainContext,
  TeamContext,
} from "@/components/OrganizationBaseTree/types";
import type { Color } from "@/model/baseTypes";
import type { LinkableItem } from "@/model/link";

export const NO_ART = "NO-ART";

export type Level = "solution-train" | "art" | "team" | "none";

interface BaseSearchContext {
  query: string;
}

export type SearchContext = BaseSearchContext &
  (EmptyContext | SolutionTrainContext | ArtContext | TeamContext);

export type LinkableItemGroups = Record<string, LinkableItemGroup>;

export interface LinkableItemGroup {
  level: Level;
  items: LinkableItem[];
}

export interface GroupedLinkableItems {
  currentLevel: LinkableItemGroup;
  otherLevels: LinkableItemGroups;
}

interface StickyFilterItem {
  id: string;
  kind: "sticky";
  name: string;
  color: Color;
}

interface ObjectiveFilterItem {
  id: string;
  kind: "objective";
  name: string;
}

export type FilterItem = StickyFilterItem | ObjectiveFilterItem;

export const EMPTY_GROUPED_LINKABLE_ITEMS = {
  currentLevel: { level: "none" as const, items: [] },
  otherLevels: {},
};
