<script setup lang="ts">
import { computed } from "vue";

import OrganizationBaseTree from "@/components/OrganizationBaseTree/OrganizationBaseTree.vue";
import type {
  OrganizationTreeNodeData,
  SelectedNode,
} from "@/components/OrganizationBaseTree/types";
import { isArtBoard, isSolutionBoard, isTeamBoard } from "@/model/board";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";

const emit = defineEmits<{ select: [node: OrganizationTreeNodeData] }>();

const selected = computed<SelectedNode>(() => {
  const { arts } = useArtStore();
  if (!arts.length) return { level: "art", id: "" };

  const currentBoard = useBoardStore().currentBoard();
  const { type, artId } = currentBoard;
  const isSolutionTrainSession = useBoardsStore().hasSolutionBacklogBoard;

  if (isTeamBoard(type)) {
    const selectedArt = arts.find((art) => art.id === artId);
    return selectedArt
      ? { level: "art", id: selectedArt.id }
      : { level: "art", id: arts[0].id };
  }

  if (isArtBoard(type)) {
    return isSolutionTrainSession
      ? { level: "solution-train" }
      : { level: "art", id: artId || arts[0].id };
  }

  if (isSolutionBoard(type)) {
    return { level: "solution-train" };
  }

  // Flex boards fall back to selecting the first ART
  return { level: "art", id: arts[0].id };
});

const handleSelect = (node: OrganizationTreeNodeData) => {
  emit("select", node);
};
</script>

<template>
  <OrganizationBaseTree :selected="selected" @select="handleSelect" />
</template>
