<script setup lang="ts">
import { computed } from "vue";

import type {
  FieldChange,
  StickyChange,
  StickyChangeKind,
} from "@/model/change";

import { stickyChanges } from "../stickyChanges";
import BoardChange from "./change/BoardChange.vue";
import LinkChange from "./change/LinkChange.vue";

const props = defineProps<{
  changes: Record<StickyChangeKind, StickyChange[]>;
}>();

const linkChanges = computed(() => [
  ...(props.changes.link || []),
  ...(props.changes.unlink || []),
]);
const mirrorChanges = computed(() => [
  ...(props.changes.mirror || []),
  ...(props.changes.unmirror || []),
]);
</script>

<template>
  <div class="diffs action">
    <div class="change-content">
      <div class="change-details fields" aria-live="polite" @click.stop>
        <div v-if="'delete' in changes" class="change-elem multi simple title">
          {{ $t("stickyDiff.deleted") }}
        </div>

        <div
          v-for="(update, index) in changes['update'] || []"
          :key="index"
          class="fields"
        >
          <div
            v-for="field in update.fields"
            :key="field.name"
            class="change-elem multi"
          >
            <div class="title">
              {{ $t(stickyChanges[field.name].keyField) }}
            </div>
            <div class="field">
              <Component
                :is="stickyChanges[field.name].component"
                :field="field"
                :sticky-id="update.stickyId"
              />
            </div>
          </div>
        </div>

        <div v-if="linkChanges.length > 0" class="change-elem multi">
          <div class="title">{{ $t("stickyChange.link.field") }}</div>
          <div v-for="(update, index) in linkChanges" :key="index">
            <div v-for="field in update.fields" :key="field.name">
              <div class="field">
                <LinkChange
                  :field="field as FieldChange<string>"
                  :sticky-id="update.stickyId"
                  show-arrow
                />
              </div>
            </div>
          </div>
        </div>

        <div v-if="mirrorChanges.length > 0" class="change-elem multi">
          <div class="title">{{ $t("stickyChange.mirror.field") }}</div>
          <div v-for="(update, index) in mirrorChanges" :key="index">
            <div v-for="field in update.fields" :key="field.name">
              <div class="field">
                <BoardChange
                  :field="field as FieldChange<string>"
                  :sticky-id="update.stickyId"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-if="'create' in changes" class="change-elem multi simple">
          <div class="title">{{ $t("stickyDiff.created") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
