<script lang="ts" setup>
import { computed } from "vue";

import { cardActions } from "@/action/cardActions";
import { boardKey } from "@/components/board/injectKeys";
import TeamSelector from "@/components/card/components/TeamSelector/TeamSelector.vue";
import { cardKey, cardMethodsKey } from "@/components/card/injectKeys";
import type { Team } from "@/model/session";
import { isRisk } from "@/model/stickyType";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useLinkStore } from "@/store/link";
import { useTeamStore } from "@/store/team";
import { injectStrict } from "@/utils/vue";

import type { SelectEvent } from "./BoardSelector.vue";
import BoardSelector from "./BoardSelector.vue";

const card = injectStrict(cardKey);
const board = injectStrict(boardKey);
const cardMethods = injectStrict(cardMethodsKey);

const teamBoards = computed(() => {
  const currentTeam = useTeamStore().current;
  return useBoardsStore()
    .artTeamBoards()
    .filter((b) => b.team.id !== currentTeam.id);
});

const isRiskCard = computed(() => isRisk(card));

const handleCardMove = async (team: Team | null) => {
  if (!team) return;

  useLinkStore().removeCardLinkedMarks(card.id);
  useBoardStore().setActiveCardId(null);

  await cardMethods.animate?.("moving");
  cardActions.move("action-menu", card.id, team?.id);
};

const handleRiskCardMove = async (event: SelectEvent) => {
  const { id: cardId, teamId: cardTeamId } = card;

  useLinkStore().removeCardLinkedMarks(card.id);
  useBoardStore().setActiveCardId(null);

  await cardMethods.animate?.("moving");

  if (event.type === "team-board") {
    const boardTeamId = event.board.team.id;
    cardActions.move("action-menu", cardId, boardTeamId);
  }

  if (event.type === "risk-board" && cardTeamId) {
    cardActions.toRisk("action-menu", cardId, cardTeamId);
    cardActions.delete("internal", cardId, board.value.id);
  }
};
</script>

<template>
  <div>
    <BoardSelector
      v-if="isRiskCard"
      :boards="teamBoards"
      @select="handleRiskCardMove"
    />
    <TeamSelector
      v-else
      show-other-arts
      :title="$t('action.moveSticky')"
      @select="handleCardMove"
    />
  </div>
</template>
