import type { FieldChangeName } from "@/model/change";

import BoardChange from "./components/change/BoardChange.vue";
import FlagChange from "./components/change/FlagChange.vue";
import IterationChange from "./components/change/IterationChange.vue";
import LinkChange from "./components/change/LinkChange.vue";
import PointsChange from "./components/change/PointsChange.vue";
import PriorityChange from "./components/change/PriorityChange.vue";
import ReactionsChange from "./components/change/ReactionsChange.vue";
import RiskChange from "./components/change/RiskChange.vue";
import StatusChange from "./components/change/StatusChange.vue";
import TeamChange from "./components/change/TeamChange.vue";
import TextChange from "./components/change/TextChange.vue";
import TypeChange from "./components/change/TypeChange.vue";
import UserChange from "./components/change/UserChange.vue";

export const stickyChanges: {
  [name in FieldChangeName]: {
    key: string;
    keyNew: string;
    keyField: string;
    component: unknown;
  };
} = {
  // field changes
  text: {
    key: /*$t*/ "stickyChange.summary",
    keyNew: /*$t*/ "stickyChange.summary.new",
    keyField: /*$t*/ "stickyChange.summary.field",
    component: TextChange,
  },
  type: {
    key: /*$t*/ "stickyChange.stickyType",
    keyNew: /*$t*/ "stickyChange.stickyType.new",
    keyField: /*$t*/ "stickyChange.stickyType.field",
    component: TypeChange,
  },
  points: {
    key: /*$t*/ "stickyChange.storyPoints",
    keyNew: /*$t*/ "stickyChange.storyPoints.new",
    keyField: /*$t*/ "stickyChange.storyPoints.field",
    component: PointsChange,
  },
  priority: {
    key: /*$t*/ "stickyChange.wsjf",
    keyNew: /*$t*/ "stickyChange.wsjf.new",
    keyField: /*$t*/ "stickyChange.wsjf.field",
    component: PriorityChange,
  },
  risk: {
    key: /*$t*/ "stickyChange.risk",
    keyNew: /*$t*/ "stickyChange.risk.new",
    keyField: /*$t*/ "stickyChange.risk.field",
    component: RiskChange,
  },
  iterationId: {
    key: /*$t*/ "stickyChange.iteration",
    keyNew: /*$t*/ "stickyChange.iteration.new",
    keyField: /*$t*/ "stickyChange.iteration.field",
    component: IterationChange,
  },
  teamId: {
    key: /*$t*/ "stickyChange.team",
    keyNew: /*$t*/ "stickyChange.team.new",
    keyField: /*$t*/ "stickyChange.team.field",
    component: TeamChange,
  },
  reactions: {
    key: /*$t*/ "stickyChange.reactions",
    keyNew: "",
    keyField: /*$t*/ "stickyChange.reactions.field",
    component: ReactionsChange,
  },
  flagType: {
    key: /*$t*/ "stickyChange.flag",
    keyNew: /*$t*/ "stickyChange.flag.new",
    keyField: /*$t*/ "stickyChange.flag.field",
    component: FlagChange,
  },
  dependTeamId: {
    key: /*$t*/ "stickyChange.dependsOn",
    keyNew: /*$t*/ "stickyChange.dependsOn.new",
    keyField: /*$t*/ "stickyChange.dependsOn.field",
    component: TeamChange,
  },
  assignee: {
    key: /*$t*/ "stickyChange.assignee",
    keyNew: /*$t*/ "stickyChange.assignee.new",
    keyField: /*$t*/ "stickyChange.assignee.field",
    component: UserChange,
  },
  reporter: {
    key: /*$t*/ "stickyChange.reporter",
    keyNew: /*$t*/ "stickyChange.reporter.new",
    keyField: /*$t*/ "stickyChange.reporter.field",
    component: UserChange,
  },
  status: {
    key: /*$t*/ "stickyChange.status",
    keyNew: /*$t*/ "stickyChange.status.new",
    keyField: /*$t*/ "stickyChange.status.field",
    component: StatusChange,
  },
  // other changes
  mirrorBoard: {
    key: "",
    keyNew: "",
    keyField: "",
    component: BoardChange,
  },
  link: {
    key: "",
    keyNew: "",
    keyField: "",
    component: LinkChange,
  },
} as const;
