<script setup lang="ts">
import { onMounted, ref } from "vue";

import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import { useNativeEvents } from "@/composables/useNativeEvents";

defineOptions({
  inheritAttrs: false,
});

defineProps<{ value: string }>();

const triggerRef = ref<HTMLInputElement>();
const buttonRef = ref<HTMLElement | null>(null);
const focus = () => {
  buttonRef.value?.focus();
};
defineExpose({ focus });

const { addEventListener } = useNativeEvents();

onMounted(() => {
  addEventListener(document, "keydown", keydown, { capture: true });
});

function keydown(e: KeyboardEvent) {
  if (e.key === "Escape") {
    if (triggerRef.value?.contains(e.target as Node)) {
      e.stopImmediatePropagation();
    }
  }
}
</script>

<template>
  <div ref="triggerRef">
    <BaseButton
      ref="buttonRef"
      v-bind="$attrs"
      variant="outlined"
      color="grey"
      class="dropdown-trigger"
      data-dropdown-trigger
      icon-after="chevron/down"
    >
      <span>{{ value }}</span>
    </BaseButton>
  </div>
</template>

<style lang="scss">
.base-button.dropdown-trigger {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
}
</style>
