import { noop, sortBy } from "lodash-es";

import { boardActions } from "@/action/boardActions";
import type { ExecutionMode } from "@/action/types";
import { sendCardMove } from "@/components/card/animator";
import type { PositionedCard } from "@/components/utils/layout";
import { ensureResolve } from "@/utils/async/utils";

import { useBoardStore } from "../board";
import { useBoardsStore } from "../boards";

export function saveCardPositions(): PositionedCard[] {
  return useBoardStore().selectedStickies.map((card) => ({
    data: { id: card.data.id },
    meta: { pos: { ...card.meta.pos } },
  }));
}

export function moveCards(cards: PositionedCard[], mode: ExecutionMode) {
  setCardPositions(cards, mode);
  if (mode !== "preview") {
    toFront(cards);
  }
}

export function resetCardPositions(cardStates: PositionedCard[]) {
  return setCardPositions(cardStates, "normal");
}

function setCardPositions(
  cards: PositionedCard[],
  mode: ExecutionMode,
): Promise<void> {
  const boardId = useBoardStore().currentBoard().id;
  return ensureResolve((resolver) => {
    cards.forEach((card, index) => {
      if (mode !== "confirm") {
        const pos = { id: card.data.id, boardId, ...card.meta.pos };
        useBoardsStore().setCardPos(
          pos,
          index === cards.length - 1 ? resolver() : noop,
        );
      }
      if (mode !== "preview") {
        // TODO this is not clean as we use sender from inside a store, move it into an action
        sendCardMove(card.data.id, boardId, card.meta.pos);
      }
    });
  });
}

function toFront(cards: PositionedCard[]) {
  sortBy(
    cards,
    (card) => card.meta.pos.y,
    (card) => card.meta.pos.x,
  ).forEach((card) => boardActions.cardToFront("internal", card.data.id));
}
