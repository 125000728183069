<script lang="ts" setup>
import { drawActions } from "@/action/drawActions";
import ActionMenuItem from "@/components/ActionMenu/ActionMenuItem.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import type { Shape } from "@/model/shape";
import { drawAlignEdited, drawTextEdited } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { injectStrict } from "@/utils/vue";

import { shapeKey } from "../injectKeys";
import TextDropdown from "./TextDropdown.vue";

const shape = injectStrict(shapeKey);

function update(label: Exclude<Shape["label"], undefined>) {
  const s = shape.value!;
  if (label?.text !== s.label?.text) {
    trackEvent(drawTextEdited());
  }
  if (label?.align !== s.label?.align) {
    trackEvent(drawAlignEdited());
  }
  s.label = { ...label };
  drawActions.editShape("context-menu", s);
}
</script>

<template>
  <ActionMenuItem
    width="auto"
    name="text"
    :tooltip-text="$t('general.label')"
    :aria-label="$t('label.actionMenu.label')"
  >
    <SvgIcon name="action/text" width="20" height="20" />
    <template #dropdown>
      <TextDropdown @update="update" />
    </template>
  </ActionMenuItem>
</template>
