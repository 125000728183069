<script setup lang="ts">
import { computed } from "vue";

import { boardActions } from "@/action/boardActions";
import { cardActions } from "@/action/cardActions";
import { selectionActions } from "@/action/selectionActions";
import ActionListItem from "@/components/action/ActionListItem.vue";
import ActionSource from "@/components/action/ActionSource.vue";
import SubMenu from "@/components/ui/DropdownMenu/SubMenu.vue";
import ListItemTitle from "@/components/ui/ListItem/ListItemTitle.vue";
import { keyboardNavigation } from "@/directives/keyboardNavigation";
import { windowToRelative } from "@/math/coordinate-systems";
import type { WindowCoordinate } from "@/model/coordinates";
import { useBoardStore } from "@/store/board";
import { useUserStore } from "@/store/user";

export type BoardContextMenuMode = "iteration" | "backlog" | "mouse";

const props = defineProps<{
  position: WindowCoordinate;
  mode: BoardContextMenuMode;
}>();

defineEmits<{ dummy: [] }>();

const vKeyboardNavSub = keyboardNavigation();

const selectedCount = computed(() => useBoardStore().selectedStickyIds.length);
const editable = computed(() => useUserStore().isAllowed("edit"));
const context = computed(() => useBoardStore().baseBoard.contextActions);
const positional = computed(() => useBoardStore().baseBoard.positionalActions(windowToRelative(props.position)));
const general = computed(() => ({
  newSticky: editable.value && props.mode === "mouse" ? cardActions.add : undefined,
  screenshot: props.mode === "mouse" || props.mode === "backlog" ? boardActions.screenshot : undefined,
}));
const anyGeneral = computed(() => Object.values(general).some((value) => value));
</script>

<template>
  <ActionSource v-keyboard-nav value="context-menu" style="width: 300px">
    <template v-if="anyGeneral">
      <ListItemTitle :text="$t('contextMenu.general')" />
      <ActionListItem :action="general.newSticky" :params="[{ pos: position }, true]" />
      <ActionListItem :action="general.screenshot" />
      <ActionListItem :action="context.board?.syncProgramBacklog" />
      <ActionListItem :action="context.board?.draw" />
    </template>

    <template v-if="editable && selectedCount > 1 && context.selection">
      <SubMenu :offset="{ left: 250, top: -35 }" width="md">
        <template #trigger>
          <ListItemTitle :text="$t('contextMenu.selectedStickyCount', { selectedCount })" has-sub />
        </template>
        <div v-keyboard-nav-sub>
          <ActionListItem v-if="context.selection.stickyMove" :action="selectionActions.alignHorizontal" />
          <ActionListItem v-if="context.selection.stickyMove" :action="selectionActions.distributeHorizontal" />
          <ActionListItem v-if="context.selection.stickyMove" :action="selectionActions.alignVertical" />
          <ActionListItem v-if="context.selection.stickyMove" :action="selectionActions.distributeVertical" />
          <ActionListItem v-if="context.selection.stickyMove" :action="selectionActions.lineup" />
          <ActionListItem v-if="context.selection.link" :action="selectionActions.link" />
        </div>
      </SubMenu>
    </template>

    <template v-if="positional.region">
      <ListItemTitle :text="positional.region.name" />
      <ActionListItem :action="positional.region.overview" />
      <ActionListItem :action="positional.region.zoom" />
      <ActionListItem :action="positional.region.sync" />
      <ActionListItem :action="positional.region.arrange" />
    </template>
  </ActionSource>
</template>
