<script lang="ts" setup>
import { cardActions } from "@/action/cardActions";
import { cardKey } from "@/components/card/injectKeys";
import { dropdownKey } from "@/components/ui/DropdownMenu/injectKeys";
import MenuList from "@/components/ui/MenuList/MenuList.vue";
import MenuListHeader from "@/components/ui/MenuList/components/MenuListHeader.vue";
import MenuListItem from "@/components/ui/MenuList/components/MenuListItem.vue";
import MenuListItems from "@/components/ui/MenuList/components/MenuListItems.vue";
import MenuListTitle from "@/components/ui/MenuList/components/MenuListTitle.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import type { RiskType } from "@/model/baseTypes";
import { riskIcon, riskTitle, riskTypes } from "@/model/baseTypes";
import { injectStrict } from "@/utils/vue";

const card = injectStrict(cardKey);
const dropdown = injectStrict(dropdownKey);

const setRisk = (risk: RiskType) => {
  cardActions.setRisk("action-menu", card.id, risk);
  dropdown.close();
};
</script>

<template>
  <MenuList class="roam-sticky" selectable>
    <MenuListHeader v-autofocus>
      <MenuListTitle>{{ $t("risk.roam") }}</MenuListTitle>
    </MenuListHeader>
    <MenuListItems :aria-label="$t('risk.roam')">
      <MenuListItem
        v-for="risk in riskTypes"
        :key="risk"
        :data-testid="`roam-list-item-${risk}`"
        class="roam-list-item"
        :selected="card.risk === risk"
        @click="setRisk(risk)"
      >
        <template #before>
          <SvgIcon
            :name="riskIcon(risk)"
            width="20"
            height="20"
            class="roam-icon"
          />
        </template>
        {{ $t(riskTitle(risk)) }}
      </MenuListItem>
    </MenuListItems>
  </MenuList>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.roam-sticky {
  max-height: 350px;

  .roam-list-item:not(:active) {
    .roam-icon {
      color: colors-old.$text-secondary-color;
    }
  }
}
</style>
