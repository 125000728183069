<script setup lang="ts">
import { ref, watch } from "vue";

import { markActions } from "@/action/mark";
import { toggleActions } from "@/action/toggleActions";
import { getBoardDiff } from "@/backend/Backend";
import SidePanel from "@/components/SidePanel/SidePanel.vue";
import SidePanelContent from "@/components/SidePanel/SidePanelContent.vue";
import type { StickyChange } from "@/model/change";
import { useActivityStore } from "@/store/activity";
import { useBoardStore } from "@/store/board";

import LoadingIndicator from "../ui/LoadingIndicator/LoadingIndicator.vue";
import type { DateItem } from "./components/DateFilter.vue";
import DateFilter from "./components/DateFilter.vue";
import DiffsBySticky from "./components/DiffsBySticky.vue";

const store = useActivityStore();
const changes = ref<StickyChange[]>([]);
const loading = ref(false);
const date = ref<DateItem>();

watch(
  () => ({ board: store.board, date: date.value }),
  async (to, from) => {
    const board = useBoardStore().board;
    // somehow the watch triggers sometimes even if neither board nor date change
    // check it to avoid multiple calls to the backend
    if (board && (to.board !== from.board || to.date?.id !== from.date?.id)) {
      if (store.board && date.value) {
        loading.value = true;
        changes.value = [];
        changes.value = await getBoardDiff(
          board.id,
          date.value.date,
          new Date(),
        );
        markActions.fadeOutAll(board);
        for (const change of changes.value) {
          markActions.reset(board.cards[change.stickyId]);
        }
        loading.value = false;
      } else {
        changes.value = [];
        markActions.resetAll(board);
      }
    }
  },
);
</script>

<template>
  <div class="board-diff-panel" data-ignore-click @scroll.stop>
    <SidePanel
      side="left"
      :active="!!store.board"
      name="boardDiff"
      resizable
      @close="toggleActions.toggleBoardDiff('close-button')"
    >
      <SidePanelContent
        v-focus-trap
        v-autofocus
        :title="$t('boardDiff.boardTitle')"
      >
        <template #sub-title>
          <DateFilter v-model="date" />
        </template>

        <DiffsBySticky :changes="changes" />
        <LoadingIndicator v-if="loading" />
        <div v-if="changes.length === 0 && !loading" class="group">
          {{ $t("boardDiff.empty") }}
        </div>
      </SidePanelContent>
    </SidePanel>
  </div>
</template>

<style lang="scss">
@use "@/styles/z-index";
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.board-diff-panel {
  position: relative;
  z-index: z-index.$menu;

  .side-panel .panel-icon {
    color: colors-old.$text-subtle-color;
  }

  .text {
    overflow-wrap: anywhere;
  }

  .group {
    padding: 0 1em;
    margin-top: 2.5em;
    margin-bottom: 1.5em;
  }

  .loading-indicator {
    margin: 2em;
  }
}
</style>
