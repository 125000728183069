import { isArray } from "lodash-es";
import type { LocationQuery, LocationQueryValue } from "vue-router";

type QueryValue = LocationQueryValue | LocationQueryValue[];

export function firstQueryParam(query: LocationQuery, name: string) {
  return firstQueryValue(query[name]);
}

export function serializeObject(obj: object, prefix: string): LocationQuery {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [prefix + key, serialize(value)]),
  );
}

export function deserializeObject(query: LocationQuery, prefix: string) {
  return Object.fromEntries(
    Object.entries(query).flatMap(([key, value]) =>
      key.startsWith(prefix)
        ? [[key.substring(prefix.length), deserialize(firstQueryValue(value))]]
        : [],
    ),
  );
}

export function removePropsWithPrefix(query: LocationQuery, prefix: string) {
  return Object.fromEntries(
    Object.entries(query).flatMap(([key, value]) =>
      key.startsWith(prefix) ? [] : [[key, value]],
    ),
  );
}

function firstQueryValue(value?: QueryValue): LocationQueryValue | undefined {
  return isArray(value) ? value[0] : value;
}

function serialize(value: unknown[] | string): LocationQueryValue {
  if (isArray(value)) {
    return "[" + value.join() + "]";
  }
  return value;
}

function deserialize(value?: LocationQueryValue): QueryValue {
  if (!value) {
    return null;
  }
  return value.startsWith("[") && value.endsWith("]")
    ? value.substring(1, value.length - 1).split(",")
    : value;
}
