import { clamp } from "lodash-es";
import { defineStore } from "pinia";

import { relativeToWindow } from "@/math/coordinate-systems";
import { distance2, interpolate } from "@/math/coordinates";
import {
  pointerPosHide,
  pointerTrailDotDist,
  pointerTrailMaxLen,
} from "@/model/Settings";
import type { Pointer } from "@/model/baseTypes";
import type { Board } from "@/model/board";
import type { RelativeCoordinate, WindowCoordinate } from "@/model/coordinates";
import { isOffScreen, relativeCoord } from "@/model/coordinates";
import type { TechnicalUser } from "@/model/user";
import { loadReactiveUser } from "@/services/user.service";

import { useBoardStore } from "./board";
import { useUserStore } from "./user";

interface PointerEvent {
  id?: TechnicalUser["id"];
  boardId?: Board["id"];
  pos: RelativeCoordinate;
}

export const usePointerStore = defineStore("pointer", {
  state: () => {
    return {
      pointers: {} as Record<Pointer["user"]["id"], Pointer>,
      isPointerActive: false,
      cleanupInterval: undefined as
        | ReturnType<typeof window.setInterval>
        | undefined,
    };
  },

  actions: {
    set(pointerEvent: PointerEvent) {
      this.ensureCleanupIsRunning();
      const currentBoardId = useBoardStore().currentBoard().id;
      const boardId = pointerEvent.boardId || currentBoardId;
      const id = pointerEvent.id || useUserStore().technicalUser.id;
      if (boardId !== currentBoardId) {
        return;
      }
      if (isOffScreen(pointerEvent.pos)) {
        delete this.pointers[id];
        return;
      }

      const pointer = this.getPointer(id);
      pointer.timestamp = Date.now();
      this.addPointerPos(pointer, pointerEvent.pos);
    },
    activatePointer(boolean: boolean) {
      this.isPointerActive = boolean;
    },
    togglePointerActivation() {
      this.isPointerActive = !this.isPointerActive;
    },
    ensureCleanupIsRunning() {
      if (!this.cleanupInterval) {
        this.cleanupInterval = setInterval(
          this.cleanupPointers.bind(this),
          pointerPosHide,
        );
      }
    },

    cleanupPointers() {
      const now = Date.now();
      for (const id in this.pointers) {
        if (now - this.pointers[id].timestamp > pointerPosHide) {
          delete this.pointers[id];
        }
      }
      if (Object.keys(this.pointers).length === 0) {
        clearInterval(this.cleanupInterval);
      }
    },

    getPointer(id: TechnicalUser["id"]) {
      let pointer = this.pointers[id];
      if (!pointer) {
        pointer = {
          user: loadReactiveUser({ id }),
          relativePos: relativeCoord(0, 0),
          windowPos: [],
          timestamp: 0,
        };
        this.pointers = { ...this.pointers, [id]: pointer };
      }
      return pointer;
    },

    addPointerPos(p: Pointer, relativePos: RelativeCoordinate) {
      p.relativePos = { ...relativePos };
      const windowPos = relativeToWindow(relativePos);
      if (p.windowPos.length === 0) {
        p.windowPos.unshift({ index: 0, pos: windowPos });
      } else {
        this.interpolatePointerPos(p, windowPos);
        if (p.windowPos.length > pointerTrailMaxLen) {
          p.windowPos.splice(pointerTrailMaxLen);
        }
      }
    },

    interpolatePointerPos(p: Pointer, windowPos: WindowCoordinate) {
      const lastPos = p.windowPos[0];
      const dist = Math.sqrt(distance2(windowPos, lastPos.pos));
      const steps = clamp(
        Math.round(dist / pointerTrailDotDist),
        1,
        pointerTrailMaxLen,
      );
      const stepLen = 1 / steps;
      for (let i = 1; i <= steps; i++) {
        const pos = interpolate(i * stepLen, lastPos.pos, windowPos);
        p.windowPos.unshift({
          index: lastPos.index + Math.round(100 * i * stepLen),
          pos,
        });
      }
    },

    reset() {
      clearInterval(this.cleanupInterval);
      this.$reset();
    },
  },
});
