import { isFunction } from "lodash-es";

import type { Action } from "@/action/types";
import { eventTarget, isInput } from "@/utils/dom/dom";

import type { Key, KeyAccept, SimpleKeyEvent } from "./key";
import { accept, noAccept } from "./key";

/**
 * Register a shortcut that will never be unregistered.
 */
export function registerGlobalShortcut(
  action: Action | AdHocAction,
  options?: ShortcutOptions,
): void {
  if (isFunction(action)) {
    if (action.data.shortcut.accept !== noAccept) {
      doRegisterShortcut(
        "",
        action.data.shortcut.accept,
        () => action("keyboard-shortcut"),
        options,
      );
    }
  } else {
    doRegisterShortcut("", action.key.accept, action.exec, options);
  }
}

export interface AdHocAction {
  key: Key;
  exec: (e: KeyboardEvent) => void;
}

export interface ShortcutOptions {
  prevent?: boolean;
  repeat?: boolean;
  withEvent?: boolean;
  up?: () => void;
}

/**
 * Don't use directly, but useShortcuts.ts or registerGlobalShortcut
 */
export function doRegisterShortcut<T>(
  component: T,
  k: string | KeyAccept,
  exec: (e: KeyboardEvent) => void,
  options?: ShortcutOptions,
) {
  shortcuts.push({
    component,
    accept: accept(k),
    exec,
    prevent: options?.prevent,
    repeat: options?.repeat,
    up: options?.up?.bind(component),
  });
}

/* Don't use directly, but useShortcuts.ts */
export function doUnregisterShortcuts(component: unknown) {
  shortcuts = shortcuts.filter((shortcut) => shortcut.component !== component);
}

interface Shortcut {
  component: unknown;
  accept: KeyAccept;
  prevent?: boolean;
  repeat?: boolean;
  exec: (e: KeyboardEvent) => void;
  up?: () => void;
}

let shortcuts = new Array<Shortcut>();
const down: Record<string, string> = {};

document.addEventListener("keydown", keyDown);
document.addEventListener("keyup", keyUp);
window.addEventListener("blur", allKeysUp);

export function isKeyDown(codeOrKey: string) {
  return codeOrKey in down || Object.values(down).some((e) => e === codeOrKey);
}

function keyDown(e: KeyboardEvent) {
  const elem = eventTarget<HTMLInputElement>(e)!;

  const isActive = !elem.readOnly && !elem.disabled;
  const isAllowedInput =
    elem.classList.contains("allow-shortcuts") &&
    (e.altKey || e.ctrlKey || e.metaKey || e.key.length > 1) &&
    e.key !== "Backspace";

  const wasDown = e.code in down;
  down[e.code] = e.key;

  if (!isInput(elem) || !isActive || isAllowedInput) {
    if (e.key === " ") {
      // prevent scrolling
      e.preventDefault();
    }
    for (const cut of shortcuts) {
      if (cut.accept(e)) {
        if (cut.prevent !== false) {
          e.preventDefault();
        }
        if (cut.repeat || !wasDown) {
          cut.exec(e);
        }
      }
    }
  }
}

function allKeysUp() {
  for (const code in down) {
    keyUp({
      code,
      key: down[code],
      keyCode: 0,
      shiftKey: false,
      altKey: false,
      ctrlKey: false,
      metaKey: false,
    });
  }
}

function keyUp(e: SimpleKeyEvent) {
  delete down[e.code];
  for (const cut of shortcuts) {
    if (cut.up && cut.accept(e)) {
      cut.up();
    }
  }

  if (e.key === "Meta") {
    // on Mac, releasing any key together with command (=Meta), causes only a keyup event for Meta, but not the key
    // so assume that when releasing Meta every other key is also released
    allKeysUp();
  }
  if (e.key === "Escape") {
    // Adding this as a shortcut to reset the state of down[], because sometimes
    // the keyup event is not received (eg. on mac, after making a screenshot with cmd+shift+4)
    // which causes buggy behaviour since the app thinks some keys are still down
    allKeysUp();
  }
}
