import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M11.4 4a7.4 7.4 0 1 0 0 14.8 7.4 7.4 0 0 0 0-14.8m7.272 13.357A9.36 9.36 0 0 0 20.8 11.4a9.4 9.4 0 1 0-9.4 9.4 9.36 9.36 0 0 0 5.847-2.04l3.058 2.959a1 1 0 0 0 1.39-1.438z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }