import { assignShortcut } from "@/action/actions";
import { boardActions } from "@/action/boardActions";
import { cardActions } from "@/action/cardActions";
import { drawActions } from "@/action/drawActions";
import { selectionActions } from "@/action/selectionActions";
import { toggleActions } from "@/action/toggleActions";
import { dummyKey, key } from "@/components/shortcut/key";
import { i18n } from "@/translations/i18n";

export function assignShortcuts() {
  assignShortcut(boardActions.screenshot, key("KeyE", "altCtrl"));
  assignShortcut(boardActions.smallerCardSize, key("ArrowDown", "altCtrl"));
  assignShortcut(boardActions.biggerCardSize, key("ArrowUp", "altCtrl"));

  assignShortcut(toggleActions.hideDraw, key("Escape"));
  assignShortcut(toggleActions.showStatGraphs, key("KeyJ", "altCtrl"));
  assignShortcut(toggleActions.showStats, key("KeyI", "altCtrl"));
  assignShortcut(
    toggleActions.showOverview,
    dummyKey(() => i18n.global.t("mouse.doubleClick")),
  );
  assignShortcut(toggleActions.showTeamObjectives, key("KeyO", "altCtrl"));
  assignShortcut(toggleActions.showSearch, key("KeyF", "altCtrl"));
  assignShortcut(toggleActions.toggleBoardDiff, key("KeyH"));
  assignShortcut(toggleActions.showTimer, key("KeyT", "altCtrl"));
  assignShortcut(toggleActions.showTrailingPointer, key("KeyA", "shift"));
  assignShortcut(toggleActions.showSettings, key(",", "altCtrl"));
  assignShortcut(toggleActions.showShortcuts, key("?"));
  assignShortcut(toggleActions.toggleWorkMode, key("KeyM", "shift"));

  assignShortcut(selectionActions.undo, key("z", "altCtrl"));
  assignShortcut(selectionActions.link, key("KeyL"));
  assignShortcut(selectionActions.selectOnClick, key("Shift"));

  assignShortcut(drawActions.removeShape, key("Backspace"));

  assignShortcut(cardActions.openAssigneeDropdown, key("KeyA"));
  assignShortcut(cardActions.add, key("KeyN"));
  assignShortcut(cardActions.togglePalette, dummyKey("KeyN"));
  assignShortcut(cardActions.duplicate, key("KeyD", "altCtrl"));
}
