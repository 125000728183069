import { defineStore } from "pinia";

import { Counter } from "@/model/Counter";
import { statHistory } from "@/model/Settings";
import type { Statistics } from "@/model/baseTypes";

export const useStatisticsStore = defineStore("statistics", {
  state: () => ({ statistics: [newEntry(0)] }),
  getters: {
    current: (state) => state.statistics[state.statistics.length - 1],
  },
  actions: {
    addNewEntry(subscriptions: number) {
      this.statistics.push(newEntry(subscriptions, this.current));
      if (this.statistics.length > statHistory) {
        this.statistics.splice(0, 1);
      }
    },
  },
});

function newEntry(subscriptions: number, oldEntry?: Statistics) {
  return {
    receives: new Counter(oldEntry?.receives),
    calls: new Counter(oldEntry?.calls),
    publishes: new Counter(oldEntry?.publishes),
    sends: new Counter(oldEntry?.sends),
    subscriptions,
    reconnects: oldEntry?.reconnects || 0,
  };
}
