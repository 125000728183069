import type { I18nOptions } from "vue-i18n";
import { createI18n } from "vue-i18n";

import en from "./en.json";

// There are 2 default languages:
// English and "none" which means it shows the i18n keys instead of the translations.
// This can be helpful to quickly correct a translation error.
// To quickly switch language, one can use the `lang` URL query parameter.

const DEFAULT_LOCALE = "en";
const FALLBACK_LOCALE = "en";

const none = Object.fromEntries(Object.entries(en).map(([key]) => [key, key]));

export const i18n = createI18n({
  legacy: false,
  allowComposition: true,
  locale: DEFAULT_LOCALE,
  fallbackLocale: FALLBACK_LOCALE,
  messages: { en, none } as I18nOptions["messages"],
  silentFallbackWarn: true,
  silentTranslationWarn: true,
});

export function translate(
  params: [key: string, params?: Record<string, unknown>],
) {
  return i18n.global.t.apply(null, params as any);
}
