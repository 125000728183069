<script lang="ts" setup>
import { provide } from "vue";

import { MenuListConfigKey } from "./types";

const props = defineProps<{
  multiselect?: boolean;
  selectable?: boolean;
}>();

provide(MenuListConfigKey, {
  multiselect: props.multiselect,
  selectable: props.selectable,
});
</script>

<template>
  <div class="menu-list">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.menu-list {
  all: unset;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  gap: 2px;
  height: 100%;
}
</style>
