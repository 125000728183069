import { sender } from "@/backend/Sender";
import type { BoardId } from "@/model/board";
import type { Objective } from "@/model/objective";
import { useObjectiveStore } from "@/store/objective";
import { useToastStore } from "@/store/toast";

import { action, ctxAction, defineActions } from "./actions";

export const objectiveActions = defineActions("objective", {
  add: ctxAction((ctx, e: Partial<Objective> & BoardId, isCommitted = true) => {
    const obj = useObjectiveStore().add(e, isCommitted);
    ctx.track({ objectiveId: obj.id, isStretch: !isCommitted });
    sender.addObjective(e.boardId, obj);
    if (!isCommitted) {
      //todo: this should be refactored by changing addObjective API to cover setting stretch
      setTimeout(() => sender.moveObjective(e.boardId, obj.id, true, 0), 500);
    }
    return obj;
  }),
  remove: action((boardId: string, objectiveId: string) => {
    useObjectiveStore().remove({ id: objectiveId, boardId });
    sender.deleteObjective(boardId, objectiveId);
    useToastStore().show(/*$t*/ "objective.deleted", { invisible: true });
  }),
  move: action(
    (
      boardId: string,
      objectiveId: string,
      data: { stretch: boolean; rank: number },
    ) => {
      useObjectiveStore().move({ id: objectiveId, boardId, ...data });
      sender.moveObjective(boardId, objectiveId, data.stretch, data.rank);
    },
  ),
  setTitle: action(
    (boardId: string, objective: Objective) => {
      useObjectiveStore().update({ boardId, ...objective });
      sender.updateObjective(boardId, objective);
    },
    {
      history: {
        merge: true,
        saveState: (boardId, objective) => ({
          id: objective.id,
          boardId,
          text: objective.text,
        }),
      },
    },
  ),
  setDescription: action(
    (boardId: string, objective: Objective) => {
      useObjectiveStore().update({ boardId, ...objective });
      sender.updateObjective(boardId, objective);
    },
    {
      history: {
        merge: true,
        saveState: (boardId, objective) => ({
          id: objective.id,
          boardId,
          description: objective.description,
        }),
      },
    },
  ),
  setAv: ctxAction(
    (
      ctx,
      boardId: string,
      objective: Objective,
      isCommitted: boolean,
      av: number,
    ) => {
      ctx.track({
        hadPreviousValue: (objective.av ?? 0) !== 0,
        hasNewValue: true,
        objectiveId: objective.id,
        isStretch: !isCommitted,
      });
      objective.av = av;
      useObjectiveStore().update({ boardId, ...objective });
      sender.updateObjective(boardId, objective);
    },
  ),
  setBv: ctxAction(
    (
      ctx,
      boardId: string,
      objective: Objective,
      isCommitted: boolean,
      bv: number,
    ) => {
      ctx.track({
        hadPreviousValue: (objective.bv ?? 0) !== 0,
        hasNewValue: true,
        objectiveId: objective.id,
        isStretch: !isCommitted,
      });
      objective.bv = bv;
      useObjectiveStore().update({ boardId, ...objective });
      sender.updateObjective(boardId, objective);
    },
  ),
});
