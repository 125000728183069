import { boardActions } from "@/action/boardActions";
import type { Action } from "@/action/types";
import { isAlmSync } from "@/backend/Backend";
import type { BoardType } from "@/model/baseTypes";
import { useConnectionStore } from "@/store/connection";
import { useUserStore } from "@/store/user";

import { BaseBoard } from "./BaseBoard";

export abstract class BaseBacklogBoard<
  T extends BoardType,
> extends BaseBoard<T> {
  get contextActions() {
    return {
      board: {
        syncProgramBacklog: this.syncAction(),
        draw: this.drawAction(),
      },
      selection: {
        link: true,
        mirror: true,
        team: true,
      },
    };
  }

  syncAction(): Action | undefined {
    if (
      useUserStore().isAllowed("edit") &&
      isAlmSync() &&
      useConnectionStore().alm
    ) {
      return boardActions.confirmSyncBacklog;
    }
  }
}
