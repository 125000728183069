import { sender } from "@/backend/Sender";
import type { Shape } from "@/model/shape";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useDrawStore } from "@/store/draw";

import { action, ctxAction, defineActions } from "./actions";

export const drawActions = defineActions("draw", {
  addShape: action(async (shape: Shape) => {
    const boardId = useBoardStore().currentBoard().id;
    shape.id = await sender.addShape(boardId, shape);
    return useBoardsStore().addShape(boardId, shape);
  }),
  editShape: ctxAction((ctx, shape: Shape) => {
    const boardId = useBoardStore().currentBoard().id;
    useBoardsStore().editShape(boardId, shape);
    sender.editShape(boardId, shape);
    ctx.track(null);
  }),
  removeShape: action(
    (id: string) => {
      const boardId = useBoardStore().currentBoard().id;
      useBoardsStore().removeShape(boardId, id);
      sender.removeShape(boardId, id);
      useDrawStore().selected = null;
    },
    { name: /*$t*/ "general.delete" },
  ),
});
