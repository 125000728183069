import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M12.001 5a1 1 0 0 1 1 1v5.001h5.001a1 1 0 1 1 0 2h-5v5.002a1 1 0 1 1-2 0V13H6a1 1 0 1 1 0-2h5V6a1 1 0 0 1 1-1",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }