<script lang="ts" setup>
import { onMounted, ref } from "vue";

import ScreenReaderMessage from "@/components/a11y/ScreenReaderMessage.vue";
import BaseInput from "@/components/ui/BaseInput/BaseInput.vue";
import { useNativeEvents } from "@/composables/useNativeEvents";

defineOptions({
  inheritAttrs: false,
});

const modelValue = defineModel<string>({ required: true });

interface Props {
  iconPosition?: "before" | "after"; // Position of the search icon
  iconTitle?: string; // Used as label for the input, unless there's a visual label (attribute 'label')
  autofocus?: boolean;
  placeholder?: string;
  resultsCount?: number; // For screen readers
  setReturnFocus?: string;
}

const props = defineProps<Props>();

const searchRef = ref<HTMLInputElement>();

const { addEventListener } = useNativeEvents();

onMounted(() => {
  addEventListener(document, "keydown", keydown, { capture: true });
});

function keydown(e: KeyboardEvent) {
  if (e.key === "Escape") {
    if (searchRef.value?.contains(e.target as Node)) {
      const searchInput = searchRef.value.querySelector("input");
      searchInput?.blur();
      if (props.setReturnFocus) {
        document.getElementById(props.setReturnFocus)?.focus();
        e.stopImmediatePropagation();
      }
    }
  }
}
</script>

<template>
  <div ref="searchRef">
    <BaseInput
      v-model="modelValue"
      v-autofocus="!!autofocus"
      v-bind="$attrs"
      control-type="text"
      :icon-after="iconPosition === 'after' ? 'general/search' : undefined"
      :icon-after-title="iconTitle"
      :icon-before="iconPosition === 'before' ? 'general/search' : undefined"
      :icon-before-title="iconTitle"
      :placeholder="placeholder || $t('action.search')"
    />
    <ScreenReaderMessage v-if="typeof resultsCount === 'number'">
      {{ $t("searchResultsTs.results", { count: resultsCount }) }}
    </ScreenReaderMessage>
  </div>
</template>
