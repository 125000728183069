import { isArray } from "lodash-es";
import { nextTick } from "vue";
import { Vue } from "vue-class-component";

import type { IdMap } from "@/model/baseTypes";
import { nextIndex } from "@/utils/list";

export default class SearchBase<T extends { id: string }> extends Vue {
  foundIndex = 0;
  found = new Array<T>();
  foundDOM = new Array<T>();

  async search<S>(
    items: S[] | Record<string, S>,
    match: (item: S) => boolean,
    {
      mapResult = (item) => item as unknown as T,
      sortBy,
    }: {
      mapResult?: (item: S) => T;
      sortBy?: (item: T) => number;
    } = {},
  ) {
    const foundItemId = this.foundItem()?.id;
    this.found = [];
    this.foundDOM = [];
    if (isArray(items)) {
      for (const item of items) {
        if (match(item)) {
          this.found.push(mapResult(item));
        }
      }
    } else {
      for (const id in items) {
        if (match(items[id])) {
          this.found.push(mapResult(items[id]));
        }
      }
    }
    if (sortBy) {
      this.found.sort((a, b) => sortBy(b) - sortBy(a));
    }
    const newFound = foundItemId
      ? this.found.findIndex((item) => item.id === foundItemId) + 1
      : 0;
    this.setFound(newFound);
    await nextTick();
    this.findNewElementOrderInDOM();
  }

  findNewElementOrderInDOM() {
    // This function is a workaround for the CurrentBoardSearchResults component.
    // The issue is that the CurrentBoardSearchResults component is responsible for sorting search results,
    // but it doesn't share/update the sorted order with the SearchItem component. The SearchItem component
    // handle key events (e.g., ArrowUp and ArrowDown) based on the y-axis position of items, but rather needs
    // to do that based on how they are sorted by CurrentBoardSearchResults component.

    // To address this, we need to determine the order of the search results as they are currently represented
    // in the DOM. This function updates the `foundDOM` array with the correct order of elements based on their
    // positions in the DOM, allowing us to handle key events properly.
    const searchItemsContainer = document.querySelector(".base-search-items");

    if (searchItemsContainer) {
      const baseSearchItems =
        searchItemsContainer.querySelectorAll(".base-search-item");

      const foundDict = this.found.reduce((acc: IdMap<T>, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      baseSearchItems.forEach((item) => {
        const itemId = item.getAttribute("data-id");
        if (itemId) {
          const matchingElement = foundDict[itemId];

          // If a matching element is found, add it to foundDOM
          if (matchingElement) {
            this.foundDOM.push(matchingElement);
          }
        }
      });
    } else {
      this.foundDOM = this.found;
    }
  }

  findNext(offset: number) {
    if (this.foundDOM.length > 0) {
      this.setFound(nextIndex(this.foundDOM, this.foundIndex - 1, offset) + 1);
    }
  }

  foundItem() {
    return this.foundDOM[this.foundIndex - 1];
  }

  setFound(c: number, focus?: boolean) {
    if (c !== this.foundIndex) {
      this.$markFound(false, false);
      this.foundIndex = c;
      this.$markFound(true, focus);
    }
  }

  $markFound(mark: boolean, focus?: boolean) {
    if (this.foundIndex) {
      this.markFound(this.foundItem(), mark, !!focus);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  markFound(_item: T, _mark: boolean, _focus: boolean) {}
}
