import { isFunction } from "lodash-es";
import { onBeforeUnmount } from "vue";

import type { Action } from "@/action/types";
import { noAccept } from "@/components/shortcut/key";
import type {
  AdHocAction,
  ShortcutOptions,
} from "@/components/shortcut/shortcuts";
import {
  doRegisterShortcut,
  doUnregisterShortcuts,
} from "@/components/shortcut/shortcuts";

export function useShortcuts() {
  const component = Symbol();

  function registerShortcut(
    action: Action | AdHocAction,
    options?: ShortcutOptions,
  ): void {
    if (isFunction(action)) {
      if (action.data.shortcut.accept !== noAccept) {
        doRegisterShortcut(
          component,
          action.data.shortcut.accept,
          () => action("keyboard-shortcut"),
          options,
        );
      }
    } else {
      doRegisterShortcut(component, action.key.accept, action.exec, options);
    }
  }

  onBeforeUnmount(() => {
    doUnregisterShortcuts(component);
  });

  return { registerShortcut };
}
