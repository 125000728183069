<script setup lang="ts">
import type { Component } from "vue";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

import ChooseButton from "@/pages/components/ChooseButton.vue";
import { useUserStore } from "@/store/user";
import { firstQueryParam } from "@/utils/vue/queryParam";

import { login } from "./LoginPage.utils";
import LoginPageEmail from "./LoginPageEmail.vue";

const emit = defineEmits<{ pageSwitch: [page: Component] }>();
const error = ref("");

const loginData = computed(() => useUserStore().loginData);
const { t } = useI18n();
const route = useRoute();

async function loginWithTenant(tenantId: string) {
  error.value = "";
  try {
    await login(
      loginData.value.email,
      firstQueryParam(route.query, "type") || "",
      firstQueryParam(route.query, "path") || "",
      tenantId,
    );
  } catch (err) {
    error.value = "" + err || t("loginPage.notLogin");
  }
}

function switchPageToEmail() {
  emit("pageSwitch", LoginPageEmail);
}
</script>

<template>
  <div v-autofocus tabindex="-1" aria-labelledby="tenants-page-title">
    <h1 id="tenants-page-title" class="login">
      {{ $t("loginPageTenants.selectOrganization") }}
    </h1>
    <p class="description">
      {{
        $t("loginPageTenants.selectOrganizationsAssociated", {
          email: loginData.email,
        })
      }}
    </p>
    <button class="email" @click="switchPageToEmail">
      {{ $t("loginPageTenants.changeEmail") }}
    </button>
    <div class="list scrollable">
      <ChooseButton
        v-for="tenant in loginData.tenants"
        :key="tenant.id"
        size="small"
        @click="loginWithTenant(tenant.id)"
      >
        {{ tenant.name }}
      </ChooseButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";

.login {
  font-weight: font.$weight-extra-bold;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 8px;
}

.description {
  color: colors-old.$text-secondary-color;
  font-size: 14px;
  font-weight: font.$weight-normal;
  margin-bottom: 10px;
  width: 380px;
}

.email {
  text-decoration: none;
  font-size: 14px;
  color: colors-old.$primary-color;
  cursor: pointer;
  outline: revert;
}

.list {
  max-height: 320px;
  overflow: hidden scroll;
  width: 380px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.list::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: colors-old.$overlay-color;
}

.scrollable {
  position: relative;
}
</style>
