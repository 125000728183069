<script lang="ts" setup>
import { computed } from "vue";

import { cardKey, cardMetaKey } from "@/components/card/injectKeys";
import { isBacklogBoard } from "@/model/board";
import type { MarkMode } from "@/model/markMode";
import { isFullyFaded } from "@/model/markMode";
import { isDependency } from "@/model/stickyType";
import { useWorkModeStore } from "@/store/workMode";
import { injectStrict } from "@/utils/vue";

const card = injectStrict(cardKey);
const cardMeta = injectStrict(cardMetaKey);
const props = defineProps<{ mark: MarkMode }>();

const isFiteredOut = computed(() => {
  return (
    isFullyFaded(props.mark) && !cardMeta.isSelected && !cardMeta.isEnlarged
  );
});

const classes = computed(() => ({
  "filtered-out": isFiteredOut.value,
  "with-progress-bar": isProgressBarVisible.value,
}));

const isProgressBarVisible = computed(
  () =>
    (isDependency(card) || isBacklogBoard(card.type.origin)) &&
    useWorkModeStore().isExecutionMode,
);
</script>
<template>
  <div v-if="isFiteredOut" class="sticky-note-filter" :class="classes" />
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/z-index";
@use "../StickyNoteProgress/variables" as sticky-note-progress;

$progress-bar-height: sticky-note-progress.$height;

.sticky-note-filter {
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  &.linking-target {
    background-color: colors.$palette-black-alpha-10;
    z-index: z-index.$board;
  }

  &.filtered-out {
    background-color: colors-old.$lowlight-color;
    z-index: z-index.$board;
  }

  // extend the filter overlay to cover the progress bar
  &.with-progress-bar {
    height: calc(100% + $progress-bar-height);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
</style>
