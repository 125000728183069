<script setup lang="ts">
import { computed, provide, ref } from "vue";

import ActionMenu from "@/components/ActionMenu/ActionMenu.vue";
import { boardKey } from "@/components/board/injectKeys";
import { useBoardStore } from "@/store/board";

import SelectionActionMenu from "./components/SelectionActionMenu.vue";
import { useMultiSelectRect } from "./composables/useMultiSelectRect";
import { useSelectionActionMenu } from "./composables/useSelectionActionMenu";
import { useStickyBoundingRect } from "./composables/useStickyBoundingRect";

const width = 1600;
const height = 900;
const boundingRectElem = ref<HTMLElement>();

const { boundingRect } = useStickyBoundingRect(width, height);
const { multiSelectRect } = useMultiSelectRect(width, height);
const selectionMenu = useSelectionActionMenu(boundingRectElem, boundingRect);

const board = computed(() => useBoardStore().currentBoard());
provide(boardKey, board);

const zIndexRectangleSelection = computed(() => board.value.maxZ + 1);
</script>

<template>
  <ActionMenu :controller="selectionMenu">
    <SelectionActionMenu />
  </ActionMenu>
  <svg
    class="selection-layer"
    :viewBox="`0 0 ${width} ${height}`"
    stroke="currentColor"
    :style="{ zIndex: zIndexRectangleSelection, pointerEvents: 'none' }"
    aria-hidden="true"
  >
    <rect
      v-if="!!boundingRect"
      ref="boundingRectElem"
      v-bind="boundingRect"
      class="bounding-rect"
    />
    <rect
      v-if="!!multiSelectRect"
      v-bind="multiSelectRect"
      class="multi-select-rect"
    />
  </svg>
</template>

<style lang="scss">
@use "@/styles/variables";
@use "@/styles/variables/colors";
@use "@/styles/variables/a11y" as colors-a11y;

.selection-layer {
  position: absolute;
  width: 100% * variables.$fake-zoom;
  height: 100% * variables.$fake-zoom;
  fill: none;

  .bounding-rect {
    stroke-width: 1;
    color: colors.$primary-brand;
  }

  .multi-select-rect {
    outline: 1px solid colors.$primary-brand;
    opacity: 0.1;
    fill: colors.$primary-brand;
  }
}
</style>
