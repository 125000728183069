<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import type { Action } from "@/action/types";
import type { Key } from "@/components/shortcut/key";
import { isMac } from "@/utils/dom/dom";

interface ActionProp {
  action: Action<any[]>;
}

interface NameProp {
  label: string;
  name: Key;
}

interface KeyProp {
  label: string;
  windows?: string[];
  mac?: string[];
}

type Props = (ActionProp | NameProp | KeyProp) & {
  ariaLabel?: string;
  column?: number;
};

const props = defineProps<Props>();

const { t } = useI18n();

function isAction(props: Props): props is ActionProp {
  return (props as any).action;
}

function isName(props: Props): props is NameProp {
  return (props as any).name;
}

const label = computed(() =>
  isAction(props) ? t(props.action.data.name) : props.label,
);
const characters = computed(() =>
  isAction(props)
    ? props.action.data.shortcut.names()
    : isName(props)
    ? props.name.names()
    : (isMac ? props.mac : props.windows) || [],
);

const valueString = computed(() => characters.value.join(", "));

const composedAriaLabel = computed(
  () => props.ariaLabel || t("label.shortcut", { value: valueString.value }),
);
</script>

<template>
  <div class="shortcut-entry" :style="{ gridColumn: column }">
    <dt>{{ label }}</dt>
    <dd :aria-label="composedAriaLabel">
      <slot>
        <span
          v-for="(char, i) in characters"
          :key="i"
          class="key"
          data-t
          aria-hidden="true"
        >
          {{ char }}
        </span>
      </slot>
    </dd>
  </div>
</template>

<style lang="scss" scoped>
.shortcut-entry {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
</style>
