import { defineStore } from "pinia";

import { setLanguage } from "@/language";
import type { Board } from "@/model/board";
import { isPlanningBoard } from "@/model/board";
import type { Tenant } from "@/model/session";
import type { AuthUser, Role } from "@/model/user";
import { baseUser, technicalUser } from "@/model/user";
import { loadUser } from "@/services/user.service";
import { i18n } from "@/translations/i18n";

export function noUser(color: string): AuthUser {
  return {
    ...baseUser,
    type: "no",
    id: "no",
    name: i18n.global.t("general.noAssignee"),
    color,
  };
}

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      loginData: { email: "", tenants: [] as Tenant[] },
      technicalUser: technicalUser("", "", ""),
      currentUser: null as AuthUser | null,
    };
  },
  getters: {
    preferredLanguage(): string | undefined {
      return this.currentUser?.preferredLanguage;
    },
    isAllowed: (state) => (permission: Permission) =>
      permissions[state.technicalUser.role].includes(permission),
    isNonTeamZoomAllowed() {
      return (board: Board): boolean =>
        (!isPlanningBoard(board.type) && board.type !== "risk") ||
        this.isAllowed("nonTeamZoom");
    },
    isTraining: (state) => state.technicalUser.company.startsWith("pips-"),
  },
  actions: {
    async loadCurrentUser() {
      this.currentUser = await loadUser(
        { id: this.technicalUser.id },
        { useCache: false },
      );
      if (this.preferredLanguage) {
        await setLanguage(this.preferredLanguage);
      }
      return this.currentUser;
    },
    resetUser() {
      this.technicalUser = technicalUser("", "", "");
    },
  },
});

type Permission =
  | "edit"
  | "nonTeamZoom"
  | "deleteBoard"
  | "cockpit"
  | "useWithoutLicense"
  | "editBoard"
  | "duplicateFlex";

const permissions: { [role in Role]: Permission[] } = {
  observer: [],
  user: ["edit"],
  admin: [
    "edit",
    "nonTeamZoom",
    "deleteBoard",
    "cockpit",
    "useWithoutLicense",
    "editBoard",
    "duplicateFlex",
  ],
  planning_interval_admin: [
    "edit",
    "nonTeamZoom",
    "deleteBoard",
    "cockpit",
    "editBoard",
    "duplicateFlex",
  ],
};
