import { defineStore } from "pinia";

import { isFeatureEnabled, parseUrlWithoutRouter } from "@/utils/env/feature";

export const usePanModeStore = defineStore("panMode", {
  state: () => ({
    active: !isFeatureEnabled({ query: parseUrlWithoutRouter() }, "pan-mode"),
  }),
  actions: {
    toggleActive() {
      this.active = !this.active;
    },
    activate() {
      this.active = true;
    },
    inactivate() {
      this.active = false;
    },
  },
});
