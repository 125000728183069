import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M18.718 7.304a1 1 0 0 1-.022 1.414l-8.25 8a1 1 0 0 1-1.392 0l-3.75-3.636a1 1 0 1 1 1.392-1.436l3.054 2.961 7.554-7.325a1 1 0 0 1 1.414.022",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }