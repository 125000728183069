import { clamp } from "lodash-es";

import { sender } from "@/backend/Sender";
import ConfirmArrangeModal from "@/components/modal/ConfirmArrangeModal.vue";
import ConfirmSyncBacklogModal from "@/components/modal/ConfirmSyncBacklogModal.vue";
import ConfirmSyncIterationModal from "@/components/modal/ConfirmSyncIterationModal.vue";
import type { PositionedCard } from "@/components/utils/layout";
import { arrangeCards, zoomToRegion } from "@/components/utils/layout";
import type { Board } from "@/model/board";
import type { Rectangle, RelativeCoordinate } from "@/model/coordinates";
import type { Team } from "@/model/session";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useTeamStore } from "@/store/team";

import { action, ctxAction, defineActions } from "./actions";
import { internalActions } from "./internalActions";
import { navigationActions } from "./navigationActions";
import { screenShot } from "./utils/screenshot";

export const boardActions = defineActions("board", {
  screenshot: action(
    (event?: KeyboardEvent) => {
      const board = useBoardStore().currentBoard();

      if (board.type === "objectives") {
        return;
      }

      screenShot();
      event?.preventDefault();
    },
    { name: /*$t*/ "contextMenu.saveBoardImage" },
  ),
  confirmArrange: ctxAction(
    (ctx, boardId: string, location: number[]) =>
      navigationActions.openModal(ctx, ConfirmArrangeModal, {
        attrs: { boardId, location },
      }),
    { name: /*$t*/ "boardBase.arrangeStickies" },
  ),
  arrange: action(
    (
      board: Board,
      cards: PositionedCard[],
      bounds: Rectangle<RelativeCoordinate>,
    ) => {
      arrangeCards(board, cards, bounds);
    },
  ),
  confirmSyncBacklog: ctxAction(
    (ctx) => navigationActions.openModal(ctx, ConfirmSyncBacklogModal),
    { name: /*$t*/ "contextMenu.synchronizeBacklog" },
  ),
  confirmSyncIteration: ctxAction(
    (ctx, iteration: number | null, status: string | null) =>
      navigationActions.openModal(ctx, ConfirmSyncIterationModal, {
        attrs: { iteration, status },
      }),
    { name: /*$t*/ "contextMenu.synchronizeIteration" },
  ),
  zoomToRegion: action(
    (bounds: Rectangle<RelativeCoordinate>) => zoomToRegion(bounds),
    { name: /*$t*/ "contextMenu.zoomToRegion" },
  ),
  toggleCardSelection: action((cardId: string) => {
    const board = useBoardStore().currentBoard();
    if (cardId in board.selected) {
      unselectSticky(cardId);
    } else {
      selectSticky(cardId);
    }
  }),
  clearCardSelection: action(() => {
    useBoardStore().setActiveCardId(null);
    const board = useBoardStore().currentBoard();
    internalActions.clearCardSelection(board);
  }),
  setVelocity: action(
    (boardId: string, iteration: number, velocity: number) => {
      useBoardsStore().setVelocity({ id: boardId, iteration, velocity });
      sender.setVelocity(boardId, iteration, velocity);
    },
  ),
  setCardSize: action((boardId: string, factor: number) =>
    setCardSize(useBoardsStore().boardById(boardId), factor),
  ),
  smallerCardSize: action(
    () => {
      const board = useBoardStore().currentBoard();
      setCardSize(board, board.cardSize.factor - 0.5);
    },
    { name: /*$t*/ "board.smallerCards" },
  ),
  biggerCardSize: action(
    () => {
      const board = useBoardStore().currentBoard();
      setCardSize(board, board.cardSize.factor + 0.5);
    },
    { name: /*$t*/ "board.biggerCards" },
  ),
  cardToFront: action((cardId: string, boardId?: string) => {
    if (useBoardsStore().cardToFront({ id: cardId, boardId })) {
      sender.frontCard(useBoardStore().currentBoard().id, cardId);
    }
  }),
  switchTeam: action((team: Team) => {
    useTeamStore().current = team;
    if (team.artId) {
      useArtStore().setArt(useArtStore().artById(team.artId)!);
    }
    // team and/or art has changed -> switch to board of current type with the new team/art
    internalActions.switchBoard();
  }),
});

function selectSticky(stickyId: string) {
  useBoardStore().selectSticky(stickyId);
  sender.startAlterCard(useBoardStore().currentBoard().id, stickyId);
}

function unselectSticky(stickyId: string) {
  useBoardStore().unselectSticky(stickyId);
  sender.stopAlterCard(useBoardStore().currentBoard().id, stickyId);
}

function setCardSize(board: Board, factor: number) {
  const val = clamp(factor, 1, 8);

  if (useBoardsStore().setCardSize({ boardId: board.id, factor: val })) {
    sender.scaleBoard(board.id, val);
  }
}
