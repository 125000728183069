import { defineStore } from "pinia";

import type { CardCreateProps } from "@/action/addCard";
import { cardActions } from "@/action/cardActions";
import { linkActions } from "@/action/linkActions";
import { selectionActions } from "@/action/selectionActions";
import type { ExecutionMode } from "@/action/types";
import type { IdMap } from "@/model/baseTypes";
import type { Board } from "@/model/board";
import type { Card } from "@/model/card";
import type { Team } from "@/model/session";
import { i18n } from "@/translations/i18n";
import { stickyLinkCreated, stickyLinkRemoved } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";

import { useBoardStore } from "./board";
import { useBoardsStore } from "./boards";
import { useCardStore } from "./card";

export interface SelectionAction<T> {
  name: string;
  execute: (mode: ExecutionMode, state: T) => void | Promise<void>;
  saveState: () => T;
  resetToState: (state: T) => void | Promise<void>;
}

export interface HistoryEntry<T> {
  stateBeforeAction: T;
  action: SelectionAction<T>;
}

interface CardLinking {
  id: string;
  links: Card["links"];
  objectives: Card["objectives"];
}

interface MirroringState {
  id: string;
  teamId: string | null;
  mirroredId: string | undefined;
}

export const useSelectionStore = defineStore("selection", {
  state: () => {
    return {
      selecting: "no" satisfies "no" | "hover" | "click",
      singleCard: false,
      historyPerBoard: {} as IdMap<Array<HistoryEntry<any>>>,
    };
  },
  getters: {
    history: (state) => {
      const boardId = useBoardStore().currentBoard().id;
      if (!(boardId in state.historyPerBoard)) {
        state.historyPerBoard[boardId] = [];
      }
      return state.historyPerBoard[boardId];
    },
    lastHistoryEntry(): HistoryEntry<any> | undefined {
      return this.history[this.history.length - 1];
    },
    mirrorTargets() {
      const cards = useBoardStore().selectedStickyIds.map(
        (id) => useCardStore().cards[id],
      );
      return useBoardsStore().mirrorTargetBoards(cards);
    },
  },
  actions: {
    toggleSelectOnClick() {
      this.selecting = this.selecting === "click" ? "no" : "click";
    },
    async undo() {
      if (this.history.length === 0) {
        return Promise.resolve();
      }
      const [historyEntry] = this.history.splice(-1);
      return (
        historyEntry.action.resetToState(historyEntry.stateBeforeAction) ||
        Promise.resolve()
      );
    },
    async execute<T>(mode: ExecutionMode, action: SelectionAction<T>) {
      if (mode === "undo") {
        return this.undo();
      }
      if (mode !== "confirm") {
        this.history.push({ stateBeforeAction: action.saveState(), action });
      }
      return (
        action.execute(mode, this.lastHistoryEntry!.stateBeforeAction) ||
        Promise.resolve()
      );
    },
    addLinkingToHistory() {
      const stateToBeSaved = this.getCurrentLinkingStateForSelectedCards();
      this.execute("normal", {
        name: i18n.global.t(selectionActions.link.data.name),
        execute() {
          // linking is done in LinkModal
        },
        saveState: () => stateToBeSaved,
        resetToState(cardStates) {
          for (const cardState of cardStates) {
            const card = useCardStore().cards[cardState.id];
            card.links.forEach((newLink) => {
              if (
                !cardState.links.some((oldLink) => oldLink.id === newLink.id)
              ) {
                const from = useCardStore().cards[newLink.from];
                const to = useCardStore().cards[newLink.to];
                trackEvent(
                  stickyLinkRemoved(
                    from.id,
                    from.type.functionality,
                    useBoardStore().currentBoard().type,
                    to.id,
                    to.type.functionality,
                    "linking-modal",
                  ),
                );
                void linkActions.remove("internal", newLink.id);
              }
            });
            cardState.links.forEach((oldLink) => {
              if (!card.links.some((newLink) => oldLink.id === newLink.id)) {
                const from = useCardStore().cards[oldLink.from];
                const to = useCardStore().cards[oldLink.to];
                trackEvent(
                  stickyLinkCreated(
                    from.id,
                    from.type.functionality,
                    useBoardStore().currentBoard().type,
                    to.id,
                    to.type.functionality,
                    "linking-modal",
                  ),
                );
                void linkActions.add("internal", {
                  fromId: oldLink.from,
                  toId: oldLink.to,
                });
              }
            });
            card.objectives.forEach((newObj) => {
              if (
                !cardState.objectives.some((oldObj) => oldObj.id === newObj.id)
              ) {
                void linkActions.removeObjective(
                  "linking-modal",
                  card.id,
                  useBoardStore().currentBoard().id,
                  newObj.id,
                );
              }
            });
            cardState.objectives.forEach((oldObj) => {
              if (!card.objectives.some((newObj) => oldObj.id === newObj.id)) {
                void linkActions.addObjective(
                  "linking-modal",
                  card.id,
                  useBoardStore().currentBoard().id,
                  oldObj.id,
                );
              }
            });
          }
        },
      });
    },
    getCurrentLinkingStateForSelectedCards(): CardLinking[] {
      return useBoardStore().selectedStickies.map((card) => ({
        id: card.data.id,
        links: [...card.data.links],
        objectives: [...card.data.objectives],
      }));
    },
    mirror(board: Board) {
      this.execute("normal", {
        name: i18n.global.t(selectionActions.mirror.data.name),
        execute(mode: ExecutionMode, cardStates) {
          cardStates.forEach((cardState) => {
            void cardActions
              .mirror("internal", cardState.id, cardState.teamId, board)
              .then((mirroredId) => {
                cardState.mirroredId = mirroredId;
              });
          });
        },
        saveState: () =>
          useBoardStore().selectedStickies.map(
            (card): MirroringState => ({
              id: card.data.id,
              teamId: card.data.teamId,
              mirroredId: undefined,
            }),
          ),
        resetToState(cardStates) {
          cardStates.forEach((cardState) => {
            if (cardState.mirroredId) {
              cardActions.delete("internal", cardState.mirroredId, board.id);
            }
            if (!cardState.teamId) {
              cardActions.setTeam("internal", cardState.id, null);
            }
          });
        },
      });
    },
    setTeam(team: Team) {
      this.execute("normal", {
        name: i18n.global.t(selectionActions.setTeam.data.name),
        execute() {
          useBoardStore().selectedStickyIds.forEach((id) => {
            cardActions.setTeamAction("internal", id, team.id);
          });
        },
        saveState: () =>
          useBoardStore().selectedStickies.map((card) => ({
            id: card.data.id,
            teamId: card.data.teamId,
            iterationId: card.data.iterationId,
          })),
        resetToState(cardStates) {
          cardStates.forEach((cardState) => {
            cardActions.setTeam("internal", cardState.id, cardState.teamId);
            cardActions.setIteration(
              "internal",
              cardState.id,
              cardState.iterationId,
            );
          });
        },
      });
    },
    paste(cards: CardCreateProps[]) {
      this.execute("normal", {
        name: i18n.global.t(cardActions.pasteMultiple.data.name),
        async execute(mode, state) {
          state.push(
            ...(await cardActions.pasteMultiple("keyboard-shortcut", cards)),
          );
        },
        saveState: () => new Array<string>(),
        resetToState(cardStates) {
          cardStates.forEach((cardState) => {
            cardActions.delete(
              "internal",
              cardState,
              useBoardStore().currentBoard().id,
            );
          });
        },
      });
    },
    duplicated(cardIds: string[]) {
      this.execute("normal", {
        name: i18n.global.t(cardActions.duplicate.data.name),
        execute() {
          //cards are already duplicated, just register the action for undo
        },
        saveState: () => cardIds,
        resetToState(cardStates) {
          cardStates.forEach((cardState) => {
            cardActions.delete(
              "internal",
              cardState,
              useBoardStore().currentBoard().id,
            );
          });
        },
      });
    },
  },
});
