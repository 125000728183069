import type { StatusClass } from "@/model/baseTypes";
import type { AlmItemStatus, AlmItemType, Status } from "@/model/status";

export function dynamicStatus(status: string, statusClass?: StatusClass) {
  return createAlmItemStatus(namedStatus(status, statusClass), [], {
    dynamic: true,
  });
}

export function fallbackStatus(almItemType: AlmItemType, status: string) {
  return createAlmItemStatus(
    namedStatus(status),
    almItemType.dynamic ? [] : almItemType.globalTargetStatuses,
    { dynamic: almItemType.dynamic },
  );
}

const toDo = createStatus("toDo", "To Do", "todo", 0);
const inProgress = createStatus("inProgress", "In Progress", "in-progress", 1);
const done = createStatus("done", "Done", "done", 2);

export const initialDefaultStatus = createAlmItemStatus(
  toDo,
  [
    { transition: { id: "t1", name: "T1" }, status: inProgress, fields: [] },
    { transition: { id: "t2", name: "T2" }, status: done, fields: [] },
  ],
  { initial: true },
);

export const defaultStatuses = [
  initialDefaultStatus,
  createAlmItemStatus(inProgress, [
    { transition: { id: "t3", name: "T3" }, status: toDo, fields: [] },
    { transition: { id: "t4", name: "T4" }, status: done, fields: [] },
  ]),
  createAlmItemStatus(done, [
    { transition: { id: "t5", name: "T5" }, status: toDo, fields: [] },
    { transition: { id: "t6", name: "T6" }, status: inProgress, fields: [] },
  ]),
];

function namedStatus(status: string, statusClass?: StatusClass) {
  return createStatus(status, status, statusClass || "undefined", 0);
}

function createStatus(
  id: string,
  name: string,
  statusClass: StatusClass,
  order: number,
): Status {
  return { id, name, statusClass, order };
}

function createAlmItemStatus(
  baseStatus: Status,
  next: AlmItemStatus["next"],
  options: { dynamic?: boolean; initial?: boolean } = {},
): AlmItemStatus {
  return {
    ...baseStatus,
    dynamic: !!options.dynamic,
    initial: !!options.initial,
    next,
  };
}
