<script setup lang="ts">
import { sortBy, uniqBy } from "lodash-es";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import { navigationActions } from "@/action/navigationActions";
import FlexModal from "@/components/modal/flex/FlexModal.vue";
import { useEventBus } from "@/composables/useEventBus";
import type { BoardType } from "@/model/baseTypes";
import { boardIcon, boardTypeName } from "@/model/baseTypes";
import { isArtBoard, isFlexBoard, isTeamBoard } from "@/model/board";
import type { Icon } from "@/model/icon";
import type { Art, Team } from "@/model/session";
import { useArtStore } from "@/store/art";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useFlexStore } from "@/store/flex";
import { useNavigationStore } from "@/store/navigation";
import { useTeamStore } from "@/store/team";
import { useUserStore } from "@/store/user";

import BreadCrumbSelector from "./BreadCrumbSelector.vue";

const teamSelector = ref();

onMounted(() => {
  const { onOpenNavigation } = useEventBus();
  onOpenNavigation(() => teamSelector.value.open());
});

const { t } = useI18n();

const currentFlex = computed(() => {
  const board = useBoardStore().currentBoard();
  return board.type === "flex" ? board : undefined;
});
const flexTypes = computed(() => {
  return sortBy(
    uniqBy(
      useFlexStore().flexBoards.map((flex) => flex.flexType),
      (type) => type.id,
    ),
    (type) => type.name,
  );
});
const flexes = computed(() => {
  const flexType = currentFlex.value?.flexType.id;
  return flexType ? useFlexStore().flexBoardsByType(flexType) : [];
});

function selectFlexType(type: { id: string }) {
  const flexId = useNavigationStore().lastSelectedFlexId(type.id);
  // flexId might been deleted
  const flexById = flexId ? useFlexStore().flexBoardById(flexId) : undefined;
  const flex = flexById ?? useFlexStore().flexBoardsByType(type.id)[0];
  navigationActions.changeCanvas("topbar-menu", flex.name);
}

function selectFlex(flex: { name: string }) {
  navigationActions.changeCanvas("topbar-menu", flex.name);
}

const userTeams = computed(() => useUserStore().technicalUser.teams);

const teams = computed(() =>
  useTeamStore().teamsInCurrentArt.map(mark(isUserTeam, "general/user")),
);
const currentTeam = computed(() => useTeamStore().current);

function selectTeam(team: Team) {
  navigationActions.changeTeam("topbar-menu", team);
}

const arts = computed(() =>
  useArtStore().arts.map(mark(isUserArt, "general/user")),
);
const currentArt = computed(() => useArtStore().current);
const hasArtSelector = computed(() => {
  const board = currentBoard.value.id;
  return currentArt.value.name && (isArtBoard(board) || isTeamBoard(board));
});

function selectArt(art: Art) {
  navigationActions.changeArt("topbar-menu", art);
}

const boardTypes = computed(
  () =>
    [
      ...(useBoardsStore().hasSolutionBacklogBoard
        ? ["solution_backlog" as BoardType]
        : []),
      ...(useBoardsStore().hasSolutionBoard ? ["solution" as BoardType] : []),
      "backlog",
      "program",
      "objectives",
      "risk",
      "team",
      "flex",
    ] as const,
);
const boards = computed(() => boardTypes.value.map(makeBoardItem));
const currentBoard = computed(() =>
  makeBoardItem(useBoardStore().currentBoard().type),
);

function selectBoard(board: { id: string }) {
  const boardType = board.id as BoardType;
  if (isFlexBoard(boardType)) {
    navigationActions.openModal("topbar-menu", FlexModal);
  } else {
    navigationActions.goToBoard("topbar-menu", boardType);
  }
}

function makeBoardItem(type: BoardType) {
  return { id: type, name: t(boardTypeName(type)), mark: boardIcon(type) };
}

function mark<T>(prop: (item: T) => boolean, icon: Icon) {
  return (item: T) => ({ ...item, mark: prop(item) ? icon : undefined });
}

function isUserTeam(team: Team) {
  return userTeams.value.some((userTeam) => userTeam.id === team.id);
}

function isUserArt(art: Art) {
  return userTeams.value.some((team) => team.artId === art.id);
}
</script>

<template>
  <div class="bread-crumb">
    <div class="left">
      <BreadCrumbSelector
        v-if="hasArtSelector"
        icon="org/art"
        :title="$t('general.arts')"
        :tooltip="$t('general.art', { name: currentArt.name })"
        :items="arts"
        :value="currentArt"
        @change="selectArt"
      />
    </div>

    <div class="right">
      <BreadCrumbSelector
        :icon="currentBoard.mark"
        :title="$t('general.boards')"
        :tooltip="$t('general.board', { name: currentBoard.name })"
        :terminal="
          !isTeamBoard(currentBoard.id) && !isFlexBoard(currentBoard.id)
        "
        :items="boards"
        :value="currentBoard"
        @change="selectBoard"
      />

      <BreadCrumbSelector
        v-if="isTeamBoard(currentBoard.id)"
        ref="teamSelector"
        :title="$t('general.teams')"
        :tooltip="$t('general.team', { name: currentTeam.name })"
        terminal
        :items="teams"
        :value="currentTeam"
        @change="selectTeam"
      />

      <template v-if="currentFlex">
        <BreadCrumbSelector
          :title="$t('general.canvasTypes')"
          :tooltip="
            $t('general.canvasType', { name: currentFlex.flexType.name })
          "
          :items="flexTypes"
          :value="currentFlex.flexType"
          @change="selectFlexType"
        />

        <BreadCrumbSelector
          :title="$t('general.canvases', { type: currentFlex.flexType.name })"
          :tooltip="$t('general.canvas', { name: currentFlex.name })"
          terminal
          :items="flexes"
          :value="currentFlex"
          @change="selectFlex"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/mixins/utils";

.bread-crumb {
  font-size: 14px;
  color: var(--text-secondary-color);

  & > div {
    display: flex;

    & > span {
      max-width: 15vw;
    }
  }

  .left {
    width: 50%;
    justify-content: flex-end;
  }

  .right {
    width: 50%;
    justify-content: flex-start;
  }

  h1 {
    @include utils.ellipsis;

    margin-left: 8px;
  }

  .select-button {
    font-weight: font.$weight_normal;
  }

  @media (width < 1280px) {
    button > svg {
      display: none;
    }
  }
}
</style>
