import type { Component } from "vue";

import PlanReadoutModal from "@/components/PlanReadout/PlanReadoutModal.vue";
import FlexModal from "@/components/modal/flex/FlexModal.vue";
import { sendOpenNavigation } from "@/composables/useEventBus";
import type { BoardType } from "@/model/baseTypes";
import type { Art, Team } from "@/model/session";
import { currentRoute, goToBoard, navigateBack } from "@/router/navigation";
import { useBoardStore } from "@/store/board";
import { componentName, useModalStore } from "@/store/modal";
import { useNavigationStore } from "@/store/navigation";
import { useTeamStore } from "@/store/team";
import {
  type TrackingParams,
  boardChanged,
  modalClosed,
  modalOpened,
} from "@/utils/analytics/events";

import { action, ctxAction, defineActions } from "./actions";
import type { ActionContext } from "./types";

export const navigationActions = defineActions("navigation", {
  goToBoard: ctxAction((ctx, board) => trackedGoToBoard(ctx, board)),
  goToBacklog: ctxAction((ctx) => trackedGoToBoard(ctx, "backlog"), {
    name: /*$t*/ "action.backlogBoard",
    icon: "board/backlog",
  }),
  goToProgram: ctxAction((ctx) => trackedGoToBoard(ctx, "program"), {
    name: /*$t*/ "action.programBoard",
    icon: "board/planning",
  }),
  goToSolutionBacklog: ctxAction(
    (ctx) => trackedGoToBoard(ctx, "solution_backlog"),
    {
      name: /*$t*/ "action.solutionBacklogBoard",
      icon: "board/solution-backlog",
    },
  ),
  goToSolution: ctxAction((ctx) => trackedGoToBoard(ctx, "solution"), {
    name: /*$t*/ "action.solutionBoard",
    icon: "board/solution",
  }),
  goToRisk: ctxAction((ctx) => trackedGoToBoard(ctx, "risk"), {
    name: /*$t*/ "action.riskBoard",
    icon: "board/risk",
  }),
  goToTeam: ctxAction(
    (ctx) => {
      if (currentRoute().params.board === "team") {
        sendOpenNavigation("team");
      } else {
        trackedGoToBoard(ctx, "team");
      }
    },
    {
      name: /*$t*/ "action.teamBoard",
      icon: "board/team",
    },
  ),

  goToCanvas: ctxAction((ctx) => openModal(ctx, FlexModal), {
    name: /*$t*/ "action.canvases",
    icon: "board/canvas",
  }),
  changeCanvas: action((name: string) => goToBoard({ type: "flex", name })),

  goToObjectives: ctxAction((ctx) => trackedGoToBoard(ctx, "objectives"), {
    name: /*$t*/ "action.objectivesBoard",
    icon: "board/objectives",
  }),
  goBack: action(navigateBack, {
    name: /*$t*/ "userAction.mainMenu",
  }),
  changeArt: action((art: Art) => {
    useNavigationStore().artSelected(art);
    goToBoard({ type: useBoardStore().currentBoard().type, artId: art.id });
  }),
  changeTeam: action((team: Team) => {
    useNavigationStore().teamSelected(team);
    goToBoard({ type: useBoardStore().currentBoard().type, team });
  }),
  selectTeam: action((team: Team) => (useTeamStore().current = team)),
  goToPlanReadout: ctxAction((ctx) => openModal(ctx, PlanReadoutModal), {
    name: /*$t*/ "planReadout.title",
    icon: "menu-top/plan-readout",
  }),
  openModal: ctxAction(openModal),
  closeModal: ctxAction((ctx) => {
    const component = useModalStore().modal.component || { name: "unknown" };
    ctx.track(modalClosed(ctx.source, componentName(component)));
    useModalStore().close();
  }),
});

function trackedGoToBoard(ctx: ActionContext, targetBoard: BoardType) {
  const currentBoard = useBoardStore().currentBoard().type;
  ctx.track(boardChanged(currentBoard, targetBoard, ctx.source));
  goToBoard(targetBoard);
}

function openModal(
  ctx: ActionContext,
  component: Component,
  options?: {
    attrs?: Record<string, unknown>;
    trackingParams?: TrackingParams;
  },
) {
  useModalStore().open(component, options);
  ctx.track(
    modalOpened(ctx.source, componentName(component), options?.trackingParams),
  );
}
