<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { boardActions } from "@/action/boardActions";
import type { Action } from "@/action/types";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import BaseTooltip from "@/components/ui/BaseTooltip/BaseTooltip.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import { useShortcuts } from "@/composables/useShortcuts";
import { isBacklogBoard, isFrontendBoard } from "@/model/board";
import { useBoardStore } from "@/store/board";
import { useToastStore } from "@/store/toast";
import { useUserStore } from "@/store/user";

const actions = boardActions;
const cardZooming = ref(false);
const scaleUp = ref();
const toggleZoom = ref();

let cardZoomTimeout: number | undefined;
let lastCardZoom = 0;

const canNonTeamZoom = computed(() => useUserStore().isAllowed("nonTeamZoom"));
const cardZoomable = computed(() => {
  const board = useBoardStore().board;
  return (
    board &&
    !isBacklogBoard(board.type) &&
    !isFrontendBoard(board.type) &&
    useUserStore().isAllowed("edit") &&
    useUserStore().isNonTeamZoomAllowed(board)
  );
});
const shortcuts = computed(
  () =>
    boardActions.smallerCardSize.data.shortcut.name() +
    " " +
    boardActions.biggerCardSize.data.shortcut.name(),
);

const route = useRoute();

watch(route, () => (cardZooming.value = false), { immediate: true });

const { registerShortcut } = useShortcuts();
registerShortcut(boardActions.smallerCardSize, { repeat: true });
registerShortcut(boardActions.biggerCardSize, { repeat: true });

function cardZoom(action?: Action, rep?: boolean) {
  // Cancel any previous zoom action
  clearTimeout(cardZoomTimeout);

  if (action) {
    const delay = rep
      ? Math.max(0, 75 + lastCardZoom - performance.now())
      : 200;
    lastCardZoom = performance.now();
    action("board-actions-menu");
    cardZoomTimeout = window.setTimeout(() => cardZoom(action, true), delay);
  }
}

function toggleCardZooming() {
  if (cardZoomable.value) {
    cardZooming.value = !cardZooming.value;
    if (cardZooming.value) {
      nextTick(() => scaleUp.value.focus());
    } else {
      toggleZoom.value.focus();
    }
  } else if (
    !useUserStore().isNonTeamZoomAllowed(useBoardStore().currentBoard())
  ) {
    useToastStore().show(/*$t*/ "message.onlyRTEAdminAllowed");
  }
}

function close() {
  cardZooming.value = false;
  toggleZoom.value.focus();
}
</script>

<!-- eslint-disable vuejs-accessibility/no-static-element-interactions-->
<template>
  <div
    v-if="cardZoomable || canNonTeamZoom"
    class="card-zoom"
    :class="{ current: cardZooming, disabled: !cardZoomable }"
    @keydown.esc="close"
  >
    <!-- eslint-enable vuejs-accessibility/no-static-element-interactions-->
    <BaseTooltip position="top">
      <BaseButton
        ref="toggleZoom"
        icon-after="menu-bottom/scale"
        variant="ghost"
        color="grey"
        :activated="cardZooming"
        :aria-label="$t('action.scaleStickies')"
        :disabled="!cardZoomable"
        :shortcut="shortcuts"
        @click="toggleCardZooming"
      />
      <template #content>
        {{ cardZooming ? "" : $t("action.scaleStickies") }}
        <span class="shortcut">{{ shortcuts }}</span>
      </template>
    </BaseTooltip>
    <div
      v-if="cardZooming"
      role="dialog"
      aria-modal="false"
      :aria-label="$t('action.scaleStickies')"
      class="popout"
      @click.stop
    >
      <IconButton
        ref="scaleUp"
        icon="base/plus"
        size="small"
        :aria-label="$t('label.scaleStickies.bigger')"
        @pointerdown="cardZoom(actions.biggerCardSize)"
        @keydown.enter="cardZoom(actions.biggerCardSize)"
        @keydown.space="cardZoom(actions.biggerCardSize)"
        @pointerout="cardZoom()"
        @pointerup="cardZoom()"
        @keyup="cardZoom()"
      />
      <IconButton
        icon="base/minus"
        size="small"
        :aria-label="$t('label.scaleStickies.smaller')"
        @pointerdown="cardZoom(actions.smallerCardSize)"
        @keydown.enter="cardZoom(actions.smallerCardSize)"
        @keydown.space="cardZoom(actions.smallerCardSize)"
        @pointerout="cardZoom()"
        @pointerup="cardZoom()"
        @keyup="cardZoom()"
      />
    </div>
  </div>
</template>

<style lang="scss">
@use "@/styles/variables";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins/shadow";

.card-zoom {
  position: relative;
  display: flex;

  &.current .popout {
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    width: 72px !important;
    height: 40px;
    background-color: colors-old.$back-color;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: variables.$common-border-radius;

    @include shadow.default;

    &::after {
      border-bottom-color: colors-old.$back-color;
    }
  }
}
</style>
