import { AxiosError } from "axios";

import { currentRoute } from "@/router/navigation";
import type { ExtendedRequestConfig } from "@/services/api.config";
import { useGlobalErrorStore } from "@/store/globalError";

import { CancelError } from "./CancelError";
import { UnauthenticatedError } from "./UnauthenticatedError";
import { captureException, captureMessage } from "./sentry";

const UNAUTHENTICATED_MARKER = "reason=unauthenticated";

export function handleError(
  error: unknown,
  message?: string,
): false | undefined {
  if (error instanceof TypeError) {
    // browser specific error messages, one for chrome, one for firefox
    if (
      error.message.startsWith("Failed to fetch dynamically imported module") ||
      error.message.startsWith("error loading dynamically imported module")
    ) {
      window.location.reload();
      return;
    }
  }
  if (error instanceof UnauthenticatedError) {
    if (window.location.search.includes(UNAUTHENTICATED_MARKER)) {
      void captureMessage("Stopped unauthenticated reload cycle.");
    } else {
      window.location.search += "&" + UNAUTHENTICATED_MARKER;
    }
    return;
  }
  if (currentRoute().name !== "app" && currentRoute().name !== "login") {
    void captureException(error, { info: { message } });
    useGlobalErrorStore().setGlobalError(error, message);
    return false;
  }
  if (error instanceof CancelError) {
    handleAxiosError(error.contexts.cause, error.message);
    return;
  }
  if (handleAxiosError(error, message)) {
    return;
  }
  void captureException(error);
}

function handleAxiosError(error?: unknown, message?: string) {
  if (!isAxiosError(error)) {
    return false;
  }
  const config = error.config as ExtendedRequestConfig;
  const request = {
    url: joinUrl(config.baseURL, config.url),
    timeout: config.timeout,
    headers: config.headers,
    params: config.params,
    sentAt: config.sentAt,
    retry: config.retry,
    isGoogleReachable: config.isGoogleReachable,
    correlationId: config.headers?.["correlation-id"],
  };
  const response = {
    data: error.response?.data,
    status: error.response?.status,
    statusText: error.response?.statusText,
    headers: (error.response?.headers || {}) as Record<string, string>,
    receivedAt: new Date().toISOString(),
  };
  void captureMessage((message ? `${message}: ` : "") + error.message, {
    request,
    response,
  });
  return true;
}

function joinUrl(base: string | undefined, path: string | undefined) {
  return (
    (base || "").replace(/\/$/, "") + "/" + (path || "").replace(/^\//, "")
  );
}

export function isAxiosError(error?: unknown): error is AxiosError {
  return (
    error instanceof AxiosError ||
    (error instanceof Object && "isAxiosError" in error)
  );
}
