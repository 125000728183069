<!-- eslint-disable vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
<template>
  <div
    :id="board.id"
    class="board board-team"
    :aria-label="$t('board.team')"
    tabindex="0"
    @click="$el?.blur"
  >
    <LoadingIndicator v-if="!board.loaded" global />
    <LinkLayers :board="board" :color="linkColor" />
    <div class="backdrop" @dblclick="overview">
      <TeamIteration
        v-for="i in columns"
        :key="i"
        v-owned-cards="{ board, location: location(iterations[i - 1].id) }"
        :index="i - 1"
        :read-only="readOnly"
        class="field"
        style="height: 50%"
        :style="{ left: (i - 1) * fieldWidth + 'px', width: fieldWidth + 'px' }"
        @metrics="showMetrics"
      />
      <section
        v-board-region
        v-owned-cards="{
          board,
          location: location(locationIndexes.objectives),
        }"
        class="field team-objectives"
        style="height: 50%; top: 50%"
        :style="{ width: fieldWidth + 'px' }"
        :aria-label="$t('teamObjectives.teamObjectives')"
        @click="blurCurrentTarget"
      >
        <TeamObjectives />
      </section>
      <section
        v-board-region
        v-owned-cards="{ board, location: location(locationIndexes.risks) }"
        class="field risks"
        style="height: 50%; top: 50%"
        :style="{ left: fieldWidth + 'px', width: fieldWidth + 'px' }"
        aria-labelledby="team-board-risks-header"
        @click="blurCurrentTarget"
      >
        <h2 id="team-board-risks-header" class="header h3">
          <SvgIcon
            name="board/risk"
            width="1.25em"
            height="1.25em"
            aria-hidden="true"
          />
          <span>{{ $t("teamBoardLocation.risks") }}</span>
        </h2>
      </section>
      <TeamIteration
        v-for="i in iterations.length - columns"
        :key="i + columns"
        v-owned-cards="{
          board,
          location: location(iterations[i + columns - 1].id),
        }"
        :index="i - 1 + columns"
        :read-only="readOnly"
        class="field"
        style="height: 50%; top: 50%"
        :style="{ left: (i + 1) * fieldWidth + 'px', width: fieldWidth + 'px' }"
        @metrics="showMetrics"
      />
    </div>
    <StickyNote
      v-for="card in stickies"
      :key="card.data.id"
      :card="card.data"
      :card-meta="card.meta"
      :level-of-details="levelOfDetails"
    />
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";

import { navigationActions } from "@/action/navigationActions";
import LinkLayers from "@/components/LinkLayer/LinkLayers.vue";
import StickyNote from "@/components/StickyNote/StickyNote.vue";
import type { ActionType } from "@/components/card/actions";
import MetricsModal from "@/components/modal/metrics/MetricsModal.vue";
import TeamObjectives from "@/components/objectives/TeamObjectives.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import type { IdMap } from "@/model/baseTypes";
import type { BoardCard, Card } from "@/model/card";
import { normalLinkColors } from "@/model/colors";
import type { RelativeCoordinate } from "@/model/coordinates";
import { useBoardsStore } from "@/store/boards";
import { useSessionStore } from "@/store/session";
import { useTeamStore } from "@/store/team";

import LoadingIndicator from "../ui/LoadingIndicator/LoadingIndicator.vue";
import FluidBoard from "./FluidBoard";
import { TeamBoard as TeamBoardLogic } from "./TeamBoard";
import TeamIteration from "./components/TeamIteration.vue";
import {
  EMPTY_INDEX,
  OBJECTIVES_INDEX,
  RISKS_INDEX,
  TeamBoardLocation,
} from "./location/TeamBoardLocation";

@Component({
  components: {
    LinkLayers,
    LoadingIndicator,
    SvgIcon,
    TeamObjectives,
    TeamIteration,
    StickyNote,
  },
})
export default class TeamBoard extends mixins(FluidBoard) {
  linkColor = normalLinkColors.team;
  baseActions: ActionType[] = [
    "delete",
    "close",
    "almSource",
    "move",
    "link",
    "dragLink",
  ];
  defaultActions: ActionType[] = [...this.baseActions, "points", "mirror"];
  riskActions: ActionType[] = [...this.baseActions, "risk", "mirror"];
  dependActions: ActionType[] = [...this.baseActions, "points", "program"];
  programActions: ActionType[] = [...this.baseActions, "points", "depend"];

  // Location Indexes for non-iteration locations
  locationIndexes = {
    risks: RISKS_INDEX,
    objectives: OBJECTIVES_INDEX,
    empty: EMPTY_INDEX,
  };

  // We are using this instead of board.cards since we want to show them
  // in batches to avoid blocking the main thread.
  deferredStickies: IdMap<BoardCard> = {};

  teamStore = useTeamStore();

  @Watch("teamStore.current", { immediate: true })
  onTeamChange() {
    if (this.board.type !== "team" || !this.board.loaded) return;

    // Reset deferred stickies when team changes
    this.deferredStickies = {};
    this.loadStickiesInBatches();
  }

  async loadStickiesInBatches() {
    const cards = Object.entries(this.board.cards);
    let currentIndex = 0;
    const batchSize = 50;

    const processNextBatch = () => {
      return new Promise<void>((resolve) => {
        requestAnimationFrame(() => {
          const batch = cards.slice(currentIndex, currentIndex + batchSize);
          batch.forEach(([id, card]) => {
            this.deferredStickies[id] = card;
          });
          resolve();
        });
      });
    };

    while (currentIndex < cards.length) {
      await processNextBatch();
      currentIndex += batchSize;
    }

    // Once all stickies are loaded, clear deferredStickies so the user interacts with the board stickies again
    this.deferredStickies = {};
  }

  get stickies() {
    const hasDeferredStickies = Object.keys(this.deferredStickies).length > 0;
    return hasDeferredStickies ? this.deferredStickies : this.board.cards;
  }

  get board() {
    return useBoardsStore().boardByType("team");
  }

  showMetrics() {
    navigationActions.openModal("board", MetricsModal);
  }

  location(c: RelativeCoordinate | number) {
    return TeamBoardLocation.of(this.board.iterations, c);
  }

  overview(e: MouseEvent) {
    new TeamBoardLogic(this.board).overview(e);
  }

  actions(card: Card): ActionType[] {
    switch (card.type.functionality) {
      case "risk":
        return this.riskActions;
      case "dependency":
        return card.dependTeam ? this.dependActions : this.programActions;
      default:
        return this.defaultActions;
    }
  }

  get columns() {
    // iterations can be temporarily empty
    const iters = this.iterations.length;
    return iters < 2 ? 0 : Math.ceil(1 + iters / 2);
  }

  get fieldWidth() {
    return this.width / this.columns;
  }

  get iterations() {
    return useSessionStore().iterations;
  }

  blurCurrentTarget(e: MouseEvent) {
    (e.currentTarget as HTMLElement)?.blur();
  }
}
</script>
<style lang="scss">
@use "@/styles/font";
@use "@/styles/board";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/colors";
@use "@/styles/mixins/a11y";

.items-center {
  align-items: center;
}

.board-team {
  @include a11y.board;

  position: relative;

  h2,
  h3,
  h4 {
    display: inline-block;
    vertical-align: middle;
  }

  img.icon {
    height: board.len(28px);
    vertical-align: middle;
    border-radius: board.len(4px);
  }

  .field {
    padding: board.len(12px);
  }

  .field.risks {
    @include a11y.board-section;

    color: colors-old.$alt-error-color;
    font-size: 80%;

    h2 {
      font-weight: font.$weight-bold;
      display: flex;
      gap: 0.5em;
      align-items: center;
      margin-top: 0.5em;
    }

    span {
      vertical-align: middle;
    }
  }

  .team-objectives {
    @include a11y.board-section;

    padding: 0;

    .header {
      h3 {
        font-size: board.len(16px);
        font-weight: font.$weight-bold;
      }
    }
  }

  .cards-skip-button {
    top: board.len(80px) !important;
  }
}
</style>
