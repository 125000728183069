<script setup lang="ts">
import { ref } from "vue";

import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";

const buttonRef = ref<HTMLButtonElement>();

defineExpose({
  focus: () => buttonRef.value?.focus(),
  getBoundingClientRect: () => buttonRef.value?.getBoundingClientRect(),
});
</script>

<template>
  <BaseButton
    ref="buttonRef"
    icon-before="base/plus"
    variant="ghost"
    color="grey"
    class="add-button high-contrast-inverse"
    :aria-label="$t('action.addSticky')"
  >
    <!--
      Draw the dashed border with an SVG to match the designs 
      The stroke-dasharray is set to wrap longer strokes around the corners, to match the design  
    -->
    <svg
      class="dashed-border"
      viewBox="0 0 300 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.980098"
        y="0.579951"
        width="298"
        height="298"
        rx="4"
        stroke="currentColor"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-dasharray="5% 3.5% 5% 3.5% 5% 3.5% 5% 3.5% 5% 3.5% 5% 3.5% 5% 3.5% 5% 3.5% 5% 3.5% 5% 3.5% 5% 3.5% 4.6% 0"
      />
    </svg>
  </BaseButton>
</template>

<style lang="scss" scoped>
@use "@/styles/variables/colors";

.add-button {
  border-radius: 1.333%; // To match the radius of the dashed border (rx=3, viewbox = 300x300)

  svg.dashed-border.dashed-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: colors.$border;
  }
}
</style>
