import { computed, nextTick, reactive, watch } from "vue";
import { Provide, Watch } from "vue-property-decorator";

import { cardActions } from "@/action/cardActions";
import type { BoardContextMenuMode } from "@/components/menu/BoardContextMenu/BoardContextMenu.vue";
import BoardContextMenu from "@/components/menu/BoardContextMenu/BoardContextMenu.vue";
import { key, keys } from "@/components/shortcut/key";
import { isKeyDown } from "@/components/shortcut/shortcuts";
import * as Gestures from "@/components/utils/Gestures";
import { dragScroll } from "@/components/utils/Gestures";
import { calcBoardSize } from "@/math/coordinate-systems";
import { fakeZoom, longClick } from "@/model/Settings";
import type { BoardCard } from "@/model/card";
import { boardCoord, clientCoord } from "@/model/coordinates";
import type { Size } from "@/model/size";
import { useBoardStore } from "@/store/board";
import { useBoardSizeStore } from "@/store/boardSize";
import { useClientSettingsStore } from "@/store/clientSettings";
import { useContextMenuStore } from "@/store/contextMenu";
import { useDrawStore } from "@/store/draw";
import { useLinkStore } from "@/store/link";
import { usePanModeStore } from "@/store/panMode";
import { useSelectionStore } from "@/store/selection";
import { useSessionStore } from "@/store/session";
import { useToastStore } from "@/store/toast";
import { useUserStore } from "@/store/user";
import { useWorkModeStore } from "@/store/workMode";
import { useZoomStore } from "@/store/zoom";
import { eventTarget } from "@/utils/dom/dom";

import { applyFluidBoardSize } from "../utils/boardSize";
import BoardBase from "./BoardBase";
import type { BoardMeta } from "./injectKeys";
import { boardKey, boardMetaKey } from "./injectKeys";

export interface CardSize extends Size {
  id: string;
}

export default class FluidBoard extends BoardBase {
  zoomed = false;
  fixedFS = 0;
  boardSize = { width: 0, height: 0 };
  boardStore = useBoardStore();
  levelOfDetails: 0 | 1 | 2 = 0;
  cleanupDragScroll: (() => void) | null = null;

  @Provide({ to: boardMetaKey as symbol })
  boardMeta: BoardMeta = reactive({
    size: boardCoord(this.width, this.height),
  });

  @Watch("height")
  @Watch("width")
  onPropertyChange() {
    this.boardMeta.size.x = this.width;
    this.boardMeta.size.y = this.height;
  }

  @Provide({ to: boardKey as symbol })
  get boardData() {
    return computed(() => useBoardStore().currentBoard());
  }

  get isFirstLoginAfterIterationStart() {
    const iterStart = useSessionStore().iterations.at(0)?.start.getTime();
    const now = Date.now();
    const lastLoginAt = this.lastLoginAt;

    if (!iterStart || !lastLoginAt) return false;

    return now > iterStart && lastLoginAt < iterStart;
  }

  get lastLoginAt() {
    const val = localStorage.getItem("lastLoginAt") || "0";
    return parseInt(val);
  }

  setWorkingMode() {
    if (this.isFirstLoginAfterIterationStart) {
      useWorkModeStore().setWorkingMode("execution");
      return;
    }

    const workingMode = useClientSettingsStore().workingMode;
    useWorkModeStore().setWorkingMode(workingMode);
  }

  setupPanModeWatcher(el: HTMLElement) {
    watch(
      () => usePanModeStore().active,
      (isActive) => {
        if (isActive) {
          this.cleanupDragScroll = dragScroll(el);
        } else if (this.cleanupDragScroll) {
          this.cleanupDragScroll();
          this.cleanupDragScroll = null;
        }
      },
      { immediate: true },
    );
  }

  mounted() {
    this.setWorkingMode();

    const el = this.$el as HTMLElement;
    Gestures.onLongClick(el, longClick, (e) => {
      if (!useDrawStore().active) {
        if (useBoardStore().enlargedStickyNoteId) return;
        cardActions.add("long-press-on-board", { pos: e.pos }, true);
      }
    });
    this.setupPanModeWatcher(el);
    el.addEventListener("contextmenu", (e) => {
      // don't open the context menu when clicking on the sticky note > textarea
      if (eventTarget(e)?.classList.contains("sticky-note-text-input")) return;

      useContextMenuStore().open(BoardContextMenu, {
        position: clientCoord(e),
        mode: "mouse" as BoardContextMenuMode,
      });
    });
    this.globalShortcut(
      key("Shift"),
      () => {
        // Blur the active element if it was recently dragged (to prevent focus ring from appearing when shift-selecting)
        const activeEl = document.activeElement as HTMLElement;
        if (activeEl?.hasAttribute("data-blur-on-shift")) {
          activeEl.blur();
        }

        useSelectionStore().selecting = "hover";
        el.style.cursor = "copy";

        const timeout = setTimeout(() => {
          if (
            isKeyDown("Shift") &&
            useBoardStore().selectedStickyIds.length === 0
          ) {
            useToastStore().show(/*$t*/ "message.selectStickyHint");
          }
        }, 1000);

        // Don't show toast if user is shift-tabbing
        document.addEventListener(
          "keyup",
          (e) => {
            if (e.key === "Tab") clearTimeout(timeout);
          },
          { once: true },
        );
      },
      {
        up() {
          useSelectionStore().selecting = "no";
          el.style.cursor = "auto";
        },
      },
    );
    this.globalShortcut(
      keys("Meta", "Control"),
      () => {
        useSelectionStore().singleCard = true;
        setTimeout(() => {
          if (
            useSelectionStore().singleCard &&
            useBoardStore().selectedStickies.length > 1
          ) {
            useToastStore().show(/*$t*/ "message.singleCardMode");
          }
        }, 1000);
      },
      {
        up() {
          useSelectionStore().singleCard = false;
        },
      },
    );
    this.globalShortcut(
      key(" "),
      () => {
        useBoardStore().magnifying = true;
        el.style.cursor = "zoom-in";
        this.zoomed = false;

        setTimeout(() => {
          if (!this.zoomed) {
            useToastStore().show(/*$t*/ "message.keepBarPressed");
          }
        }, 1000);
      },
      {
        up() {
          useBoardStore().magnifying = false;
          el.style.cursor = "auto";
        },
      },
    );
  }

  beforeUnmount() {
    if (this.cleanupDragScroll) {
      this.cleanupDragScroll();
      this.cleanupDragScroll = null;
    }
  }

  activated() {
    window.addEventListener("resize", this.onWindowResize);
  }

  deactivated() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize() {
    this.applyBoardSize();
  }

  blur() {
    this.$el?.blur();
  }

  get zoomFactor() {
    return useZoomStore().factor;
  }

  get boardHasCards() {
    return Object.keys(this.board.cards).length > 0;
  }

  get isZooming() {
    return useZoomStore().zooming;
  }

  @Watch("isZooming")
  @Watch("boardStore.board.cardSize.factor")
  @Watch("boardHasCards")
  async onLevelOfDetailsChange() {
    await nextTick();

    if (!this.boardHasCards || this.isZooming) return;

    const boardEl = this.$el as HTMLElement;
    const sticky = boardEl.querySelector<HTMLDivElement>(".sticky-note");
    if (!sticky) return;

    const currentSize = sticky.getBoundingClientRect().width;
    if (currentSize === 0) return;

    if (currentSize <= 50) {
      this.levelOfDetails = 0;
    } else if (currentSize > 50 && currentSize <= 90) {
      this.levelOfDetails = 1;
    } else {
      this.levelOfDetails = 2;
    }
  }

  @Watch("boardStore.board")
  onBoardChange() {
    // clear all pinned sticky notes when changing the board
    useLinkStore().removeAllMarks();
  }

  get height() {
    return this.boardSize.height * fakeZoom;
  }

  get width() {
    return this.boardSize.width * fakeZoom;
  }

  get fixedFontSize() {
    if (this.active) {
      this.fixedFS = 100 / this.zoomFactor;
    }
    return this.fixedFS;
  }

  get readOnly() {
    return !useUserStore().isAllowed("edit");
  }

  @Watch("margin", { deep: true })
  onSpaceChange() {
    if (useBoardStore().isCurrentBoardFluid) {
      this.applyBoardSize();
    }
  }

  get margin() {
    return useBoardSizeStore().scrollableMargin;
  }

  @Watch("zoomFactor")
  onZoom() {
    this.applyBoardSize();
  }

  applyBoardSize() {
    const newBoardSize = calcBoardSize();

    this.boardSize.height = newBoardSize.height;
    this.boardSize.width = newBoardSize.width;

    applyFluidBoardSize(newBoardSize);
    useBoardSizeStore().size = newBoardSize;
  }

  getRelativeCardSizes(): CardSize[] {
    const res = new Array<CardSize & { order: number }>();

    const selector = ".board > .sticky-note";

    const elements = document.querySelectorAll<HTMLDivElement>(selector);

    elements.forEach((el) => {
      const id = el.getAttribute("id");
      const zIndex = parseInt(el.style.zIndex);

      if (!id) {
        // eslint-disable-next-line no-console
        console.error("No id found");
        return;
      }

      const card = this.board.cards[id];
      const order = zIndex || card.meta.zIndex;
      res.push(this.calcRelativeCardSize(card, order));
    });

    return res.sort((a, b) => a.order - b.order);
  }

  calcRelativeCardSize(
    card: BoardCard,
    order: number,
  ): CardSize & { order: number } {
    return {
      id: card.data.id,
      left: card.meta.pos.x - this.board.cardSize.x / 2,
      top: card.meta.pos.y - this.board.cardSize.y / 2,
      width: this.board.cardSize.x,
      height: this.board.cardSize.y,
      order,
    };
  }
}

export function fluidBoard(board: BoardBase) {
  return (board as any).boardComponent?.() || board;
}
