<script setup lang="ts">
import { computed, watch } from "vue";

import { toggleActions } from "@/action/toggleActions";
import SidePanel from "@/components/SidePanel/SidePanel.vue";
import { useShortcuts } from "@/composables/useShortcuts";
import { useSidePanelStore } from "@/store/sidePanel";
import { useTimerStore } from "@/store/timer";

import EditTimers from "./components/EditTimers.vue";

const { registerShortcut } = useShortcuts();
registerShortcut(toggleActions.showTimer);

watch(
  () => useSidePanelStore().panelLeftOpen,
  () => (useTimerStore().active = false),
);
const timersEditing = computed(() => useTimerStore().active);

function toggleTimer() {
  toggleActions.showTimer("topbar-menu");
}
</script>

<template>
  <div class="timer-panel" @click.stop>
    <SidePanel side="right" :active="timersEditing" @close="toggleTimer()">
      <EditTimers />
    </SidePanel>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/z-index";

.timer-panel {
  display: flex;
  align-items: center;
  position: relative;
  z-index: z-index.$menu;
}
</style>
