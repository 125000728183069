<script lang="ts" setup>
import { cardActions } from "@/action/cardActions";
import ArtSelector from "@/components/card//components/ArtSelector/ArtSelector.vue";
import { cardKey } from "@/components/card/injectKeys";
import { dropdownKey } from "@/components/ui/DropdownMenu/injectKeys";
import type { Art } from "@/model/session";
import { NO_ART } from "@/store/art";
import { injectStrict } from "@/utils/vue";

const card = injectStrict(cardKey);
const dropdown = injectStrict(dropdownKey);

defineProps<{ selected?: Art; arts: Art[] }>();

const handleArtChange = (art: Art | null) => {
  cardActions.setArt("action-menu", card.id, art?.id || NO_ART.id);
  dropdown.close();
};
</script>

<template>
  <ArtSelector :selected="selected" :arts="arts" @select="handleArtChange" />
</template>
