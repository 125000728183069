import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M5.75 4C4.13 4 3 5.16 3 6.375v11.25C3 18.84 4.13 20 5.75 20h8.375V4zm10.375 0v16h2.125c1.62 0 2.75-1.16 2.75-2.375V6.375C21 5.16 19.87 4 18.25 4zM1 6.375C1 3.862 3.228 2 5.75 2h12.5C20.772 2 23 3.862 23 6.375v11.25C23 20.138 20.772 22 18.25 22H5.75C3.228 22 1 20.138 1 17.625z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }