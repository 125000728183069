<template>
  <BasePlanningBoard
    ref="board"
    :planning-board="board"
    :cards-for-distribution="cardsForDistribution"
    :aria-label="$t('board.program')"
    tabindex="0"
    @click="boardComponent()?.blur()"
  />
</template>

<script lang="ts">
import { Options as Component, Vue } from "vue-class-component";
import { Provide } from "vue-property-decorator";

import type { Card } from "@/model/card";
import { useBoardsStore } from "@/store/boards";

import BasePlanningBoard from "./BasePlanningBoard.vue";
import { boardKey } from "./injectKeys";

@Component({ components: { BasePlanningBoard } })
export default class ProgramBoard extends Vue {
  @Provide({ to: boardKey as symbol })
  get board() {
    return useBoardsStore().boardByType("program");
  }

  get cardsForDistribution() {
    return useBoardsStore()
      .artTeamBoards()
      .reduce(
        (acc, board) => [
          ...acc,
          ...Object.values(board.cards).map((card) => card.data),
        ],
        [] as Card[],
      );
  }

  boardComponent() {
    return this.$refs.board as BasePlanningBoard;
  }

  // This should be callable by the parent component
  focus() {
    this.boardComponent()?.focus();
  }
}
</script>
