import { defineStore } from "pinia";

import type { BoardType } from "@/model/baseTypes";
import { isBacklogBoard, isObjectivesBoard } from "@/model/board";
import type {
  Line,
  LineComponent,
  RelativeCoordinate,
} from "@/model/coordinates";
import type { Shape } from "@/model/shape";
import { isFeatureEnabled, parseUrlWithoutRouter } from "@/utils/env/feature";

import { usePanModeStore } from "./panMode";

interface DrawState {
  active: boolean;
  selected: SelectedShape | null;
}

type SelectedShape = { shape: Shape } & Selected;
export type Selected =
  | { anchor: LineComponent | null }
  | { anchor: "line"; offset: Line<RelativeCoordinate> };

export const useDrawStore = defineStore("draw", {
  state: (): DrawState => ({
    active: false,
    selected: null,
  }),
  getters: {
    isSupportedBoard() {
      return (boardType: BoardType) =>
        !isObjectivesBoard(boardType) && !isBacklogBoard(boardType);
    },
  },
  actions: {
    toggleActive() {
      this.active = !this.active;
      this.updateSelected();
    },
    inactivate() {
      this.active = false;
      this.updateSelected();
    },
    updateSelected() {
      if (!isFeatureEnabled({ query: parseUrlWithoutRouter() }, "pan-mode")) {
        usePanModeStore().activate();
      }
      if (!this.active) {
        this.selected = null;
      }
    },
  },
});
