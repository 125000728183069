<script setup lang="ts" generic="T extends any[]">
import type { Action } from "@/action/types";
import { noKey } from "@/components/shortcut/key";
import ListItem from "@/components/ui/ListItem/ListItem.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { injectStrict } from "@/utils/vue";

import { actionSourceKey } from "./injectKeys";

const props = defineProps<{
  action?: Action<T>;
  params?: T; // params is not needed when T is []
}>();

const actionSource = injectStrict(actionSourceKey);

function execute() {
  const a = props.params || ([] as unknown as T);
  props.action!(actionSource, ...a);
}
</script>

<template>
  <ListItem v-if="action" @click="execute">
    <span>
      <SvgIcon v-if="action.data.icon" :name="action.data.icon" width="20" />
      {{ $t(action.data.name) }}
    </span>
    <span v-if="action.data.shortcut !== noKey" class="btn">
      {{ action.data.shortcut.name() }}
    </span>
  </ListItem>
</template>

<style lang="scss" scoped>
@use "@/styles/colors" as colors-old;
@use "@/styles/font";
@use "@/styles/mixins/shadow";

.list-item {
  svg {
    color: colors-old.$text-secondary-color;
  }

  .btn {
    border: 1px solid colors-old.$shortcut-border-color;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: font.$size-smaller;

    @include shadow.shortcut-button;
  }
}
</style>
