import { reactions } from "@/model/card";
import type {
  FieldChange,
  FieldChangeName,
  ReactionField,
  StickyChange,
  StickyChangeKind,
} from "@/model/change";
import { stickyActivityKeys } from "@/model/change";
import { loadReactiveUser } from "@/services/user.service";
import { i18n } from "@/translations/i18n";

import type {
  ServerFieldChange,
  ServerReactionField,
  ServerStickyChange,
  ServerStickyChangeDelete,
  ServerStickyChangeLink,
  ServerStickyChangeMirror,
  ServerStickyChangeUpdate,
  ServerUser,
} from "../serverModel";
import { mapUserId } from "./mapBackendData";

export function mapStickyChanges(
  changes: ServerStickyChange[],
): StickyChange[] {
  changes.reverse();
  return changes.flatMap((change) => {
    // Guard to ensure the FE only shows changes it knows how to handle
    const allowedKinds: StickyChangeKind[] = [
      "create",
      "update",
      "delete",
      "mirror",
      "unmirror",
      "link",
      "unlink",
    ];
    if (!allowedKinds.includes(change.kind)) {
      return [];
    }
    const fields = mapChangeFields(change);
    return change.kind === "update" && fields.length === 0
      ? []
      : { ...mapBaseChange(change), fields };
  });
}

function mapBaseChange(
  change: ServerStickyChange,
): Omit<StickyChange, "fields"> {
  return {
    stickyId: change.sticky_id,
    timestamp: new Date(change.timestamp),
    user: loadReactiveUser(
      {
        id: change.user_id,
        name: i18n.global.t("stickyChange.unknownUser"), // Fallback name if user was deleted
      },
      { mapBackendToAlm: true },
    ),
    kind: change.kind,
  };
}

function mapChangeFields(change: ServerStickyChange): FieldChange[] {
  switch (change.kind) {
    case "create":
      return [];
    case "delete":
      return mapUpdateFields(change);
    case "update":
      return mapUpdateFields(change);
    case "mirror":
      return mapMirrorFields(change);
    case "unmirror":
      return mapUnmirrorFields(change);
    case "link":
      return mapLinkFields(change);
    case "unlink":
      return mapUnlinkFields(change);
  }
}

function mapLinkFields(change: ServerStickyChangeLink): FieldChange[] {
  return [{ name: "link", new: change.linked_to }];
}

function mapUnlinkFields(change: ServerStickyChangeLink): FieldChange[] {
  return [{ name: "link", old: change.linked_to }];
}

function mapMirrorFields(change: ServerStickyChangeMirror): FieldChange[] {
  return [{ name: "mirrorBoard", new: change.to_board }];
}

function mapUnmirrorFields(change: ServerStickyChangeMirror): FieldChange[] {
  return [{ name: "mirrorBoard", old: change.to_board }];
}

function mapUpdateFields(
  change: ServerStickyChangeUpdate | ServerStickyChangeDelete,
): FieldChange[] {
  return (change.changes || []).flatMap((fieldChange) => {
    const key =
      stickyActivityKeys[fieldChange.key as keyof typeof stickyActivityKeys];
    switch (key) {
      case undefined:
        return [];
      case "reactions":
        return mapReactionsChange(
          fieldChange as ServerFieldChange<ServerReactionField>,
        );
      case "teamId":
      case "dependTeamId":
        return mapTeamChange(key, fieldChange as ServerFieldChange<number>);
      case "assignee":
      case "reporter":
        return mapUserChange(key, fieldChange as ServerFieldChange<ServerUser>);
      default:
        return {
          name: key,
          old: fieldChange.old,
          new: fieldChange.new,
        };
    }
  });
}

function mapReactionsChange(
  fieldChange: ServerFieldChange<ServerReactionField>,
): FieldChange<ReactionField> {
  return {
    name: "reactions",
    old: mapReactions(fieldChange.old),
    new: mapReactions(fieldChange.new),
  };
}

function mapReactions(field: ServerReactionField) {
  return Object.fromEntries(
    reactions.map((reaction) => [reaction, field?.[reaction]?.length || 0]),
  ) as ReactionField;
}

function mapTeamChange(
  key: FieldChangeName,
  fieldChange: ServerFieldChange<number>,
): FieldChange {
  return {
    name: key,
    old: fieldChange.old ? "" + fieldChange.old : undefined,
    new: fieldChange.new ? "" + fieldChange.new : undefined,
  };
}

function mapUserChange(
  key: FieldChangeName,
  fieldChange: ServerFieldChange<ServerUser>,
): FieldChange {
  return {
    name: key,
    old: fieldChange.old
      ? mapUserId(fieldChange.old.pip_id, fieldChange.old.alm_id)
      : undefined,
    new: fieldChange.new
      ? mapUserId(fieldChange.new.pip_id, fieldChange.new.alm_id)
      : undefined,
  };
}
