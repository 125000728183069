<script lang="ts" setup>
import SingleAriaElement from "@/components/a11y/SingleAriaElement.vue";
import SkipButton from "@/components/a11y/SkipButton.vue";
import type { Board } from "@/model/board";
import type { ActionSource } from "@/model/trigger";

import type { PlanningBoardLocation } from "./location/PlanningBoardLocation";

const props = defineProps<{
  location: PlanningBoardLocation;
  board: Board;
}>();
const emit = defineEmits<{
  overview: [PlanningBoardLocation, ActionSource?, string?];
}>();
</script>

<template>
  <SingleAriaElement class="base-planning-board-section">
    <div
      v-owned-cards="{
        board: props.board,
        location: props.location,
        tabindex: '-1',
      }"
      class="wrapper"
    >
      <slot></slot>
      <SkipButton
        :id="`overview-button-${props.location.left}-${props.location.top}`"
        class="overview-button"
        tabindex="-1"
        @click="
          emit(
            'overview',
            props.location,
            'keyboard-shortcut',
            `#overview-button-${props.location.left}-${props.location.top}`,
          )
        "
      >
        {{ $t("label.planningBoard.seeOverviewButton") }}
      </SkipButton>
    </div>
  </SingleAriaElement>
</template>

<style lang="scss" scoped>
@use "@/styles/board";

.base-planning-board-section {
  .wrapper {
    width: 100%;
    height: 100%;
  }

  .overview-button.overview-button {
    position: absolute;
    padding: board.len(14px);
    border-radius: board.len(8px);
    font-size: board.len(12px);
    line-height: 1.2;

    :deep(> *) {
      height: unset;
    }
  }
}
</style>
