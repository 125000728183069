<template>
  <div :id="board.id" class="board program-objectives-board">
    <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
    <div
      ref="objectivesBoard"
      class="objectives-board"
      tabindex="0"
      :aria-label="$t('label.objective.board')"
      @click="objectivesBoard?.blur()"
    >
      <section
        class="column program-objectives"
        aria-labelledby="program-objectives-title"
      >
        <div class="column-header">
          <div class="team-info">
            <h2 id="program-objectives-title">{{ programObjectivesTitle }}</h2>
            <div class="objectives-count">
              {{ $t("objectives.count", programObjectivesCount) }}
            </div>
          </div>
        </div>
        <div class="scrollable">
          <ObjectivesList
            group="program-objectives"
            :board="programBoard"
            :is-expendable="false"
            :has-status-distribution="false"
          />
        </div>
      </section>
      <section
        class="column program-objectives"
        :aria-label="$t('label.objective.team')"
      >
        <div class="column-header">
          <div class="team-info">
            <DropdownMenu :style="{ maxHeight: '50vh' }" class="select-team">
              <template #trigger="{ isOpen }">
                <BaseButton
                  data-dropdown-trigger
                  variant="ghost"
                  color="grey"
                  icon-after="chevron/down"
                  :aria-label="`${teamObjectivesTitle}: ${$t('page.team')}`"
                  :aria-expanded="isOpen"
                  aria-controls="team-list"
                  aria-haspopup="listbox"
                >
                  {{ teamObjectivesTitle }}
                </BaseButton>
              </template>
              <div
                id="team-list"
                v-keyboard-nav
                class="scrollable dropdown-scroll-container"
                role="listbox"
              >
                <ListItem
                  v-for="team in teams"
                  :key="team.id"
                  role="option"
                  :active="team.id === currentTeam.id"
                  @click="select(team)"
                >
                  <div>
                    <div class="team-title">{{ team.name }}</div>
                    <div class="team-objectives-count">
                      {{ $t("objectives.count", getTeamObjectivesCount(team)) }}
                    </div>
                  </div>
                </ListItem>
              </div>
            </DropdownMenu>
            <div class="objectives-count">
              {{ $t("objectives.count", teamObjectivesCount) }}
            </div>
          </div>

          <span class="arrows-container">
            <IconButton
              variant="outlined"
              size="small"
              icon="chevron/left"
              :aria-label="$t('label.objective.previousTeam')"
              :tooltip="$t('action.previous')"
              :disabled="isPrevDisabled"
              @click="shiftTeam(-1)"
            />
            <IconButton
              variant="outlined"
              size="small"
              icon="chevron/right"
              :aria-label="$t('label.objective.nextTeam')"
              :tooltip="$t('action.next')"
              :disabled="isNextDisabled"
              @click="shiftTeam(1)"
            />
          </span>
        </div>
        <div class="scrollable">
          <transition name="faster-fade" mode="out-in">
            <ObjectivesList
              :key="selectedTeamBoard.id"
              :board="selectedTeamBoard"
            />
          </transition>
        </div>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { Options as Component, mixins } from "vue-class-component";
import { Ref } from "vue-property-decorator";

import { boardActions } from "@/action/boardActions";
import ObjectivesList from "@/components/objectives/ObjectivesList.vue";
import BaseButton from "@/components/ui/BaseButton/BaseButton.vue";
import DropdownMenu from "@/components/ui/DropdownMenu/DropdownMenu.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import ListItem from "@/components/ui/ListItem/ListItem.vue";
import type { Team } from "@/model/session";
import { useArtStore } from "@/store/art";
import { useBoardsStore } from "@/store/boards";
import { useTeamStore } from "@/store/team";

import BoardBase from "./BoardBase";

@Component({
  name: "ProgramObjectivesBoard",
  components: {
    BaseButton,
    ObjectivesList,
    IconButton,
    DropdownMenu,
    ListItem,
  },
})
export default class ProgramObjectivesBoard extends mixins(BoardBase) {
  @Ref("objectivesBoard") objectivesBoard!: HTMLElement;

  get board() {
    return useBoardsStore().boardByType("objectives");
  }

  get programBoard() {
    return useBoardsStore().boardByType("program");
  }

  get selectedTeamBoard() {
    return this.getTeamBoard(this.currentTeam);
  }

  get currentTeam() {
    return useTeamStore().current;
  }

  get teams() {
    return useTeamStore().teamsInCurrentArt;
  }

  get programObjectivesCount() {
    const committedCount = this.programBoard.objectives.length;
    const uncommittedCount = this.programBoard.stretchObjectives.length;
    return committedCount + uncommittedCount;
  }

  get programObjectivesTitle() {
    const name = useArtStore().current.name;
    return this.$t("objectivesBoard.objectives", { name });
  }

  get teamObjectivesCount() {
    return this.getTeamObjectivesCount(this.currentTeam);
  }

  get teamObjectivesTitle() {
    const name = this.currentTeam.name;
    return this.$t("objectivesBoard.objectives", { name });
  }

  getTeamObjectivesCount(team: Team) {
    const board = this.getTeamBoard(team);
    const committedCount = board.objectives.length;
    const uncommittedCount = board.stretchObjectives.length;
    return committedCount + uncommittedCount;
  }

  getTeamBoard(team: Team) {
    return useBoardsStore().boardByType("team", { teamId: team.id });
  }

  select(team: Team) {
    boardActions.switchTeam("board", team);
  }

  get currentTeamIndex() {
    return this.teams.findIndex((t) => t.id === this.currentTeam.id);
  }

  shiftTeam(diff: number) {
    const newIndex = this.currentTeamIndex + diff;
    if (newIndex >= 0 && newIndex < this.teams.length) {
      boardActions.switchTeam("board", this.teams[newIndex]);
    }
  }

  get isNextDisabled() {
    return this.currentTeamIndex === this.teams.length - 1;
  }

  get isPrevDisabled() {
    return this.currentTeamIndex === 0;
  }

  focus() {
    this.objectivesBoard?.focus();
  }
}
</script>

<style lang="scss" scoped>
@use "@/styles/font";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/a11y" as colors-a11y;
@use "@/styles/variables/colors";

.program-objectives-board {
  background: colors-old.$board-background-color;
  transform: scale(10);
  transform-origin: top left;
  height: 100%;
  width: 100%;
  padding-top: 48px;
  font-size: 100%;
}

.objectives-board {
  display: flex;
  width: 100%;
  height: 100%;

  &:focus-visible {
    outline: 3px solid colors.$outline;
    outline-offset: -3px;
    border-radius: 4px;
  }

  .column {
    display: flex;
    flex-direction: column;
    width: 50vw;

    &.program-objectives {
      border-right: 1px solid colors-a11y.$divider-color;
    }

    .column-header {
      border-bottom: 1px solid colors-a11y.$divider-color;
      padding: 1rem 5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-size: font.$size-normal;
        font-weight: font.$weight-bold;
        padding: 10px;
        line-height: 20px;
      }

      .objectives-count {
        margin: 0 0 10px 10px;
        font-size: font.$size-small;
        font-weight: font.$weight-semi-bold;
        color: colors-old.$text-secondary-color;
      }

      .arrows-container {
        display: flex;
        gap: 0.4rem;
      }
    }

    .scrollable {
      position: relative;
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }
}

.select-team {
  .team-title {
    margin-bottom: 0.25rem;
  }

  .team-objectives-count {
    font-size: font.$size-small;
    font-weight: font.$weight-normal;
    color: colors-old.$text-secondary-color;
  }
}

.dropdown-scroll-container {
  position: relative;
}
</style>
