<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { navigationActions } from "@/action/navigationActions";
import ActionButton from "@/components/action/ActionButton.vue";
import { keyboardNavigation } from "@/directives/keyboardNavigation";
import { useBoardsStore } from "@/store/boards";

import MenuItemDelimiter from "../../MenuItemDelimiter.vue";

const vKeyboardNavLocal = keyboardNavigation();

const current = ref<string | null>(null);
const actions = navigationActions;

const route = useRoute();

watch(
  route,
  () => {
    // ok as board param is not repeatable
    current.value = route.params.board as string;
  },
  { immediate: true },
);

const hasSolutionBacklogBoard = computed(
  () => useBoardsStore().hasSolutionBacklogBoard,
);
const hasSolutionBoard = computed(() => useBoardsStore().hasSolutionBoard);
</script>

<template>
  <div
    v-keyboard-nav-local.soft-focus-initial.ignore-trigger="{
      selector: '.board-tab-item',
    }"
    role="tablist"
    aria-orientation="vertical"
    class="boards-item"
  >
    <ActionButton
      v-if="hasSolutionBacklogBoard"
      id="solution_backlog_tab"
      :action="actions.goToSolutionBacklog"
      :activated="current === 'solution_backlog'"
      :tabindex="current === 'solution_backlog' ? 0 : -1"
      class="board-tab-item"
      aria-controls="solution_backlog_panel"
      role="tab"
      position="right"
    />
    <ActionButton
      v-if="hasSolutionBoard"
      id="solution_tab"
      :action="actions.goToSolution"
      :activated="current === 'solution'"
      :tabindex="current === 'solution' ? 0 : -1"
      class="board-tab-item"
      aria-controls="solution_panel"
      role="tab"
      position="right"
    />
    <MenuItemDelimiter v-if="hasSolutionBoard" horizontal />
    <ActionButton
      id="backlog_tab"
      :action="actions.goToBacklog"
      :activated="current === 'backlog'"
      :tabindex="current === 'backlog' ? 0 : -1"
      class="board-tab-item"
      aria-controls="backlog_panel"
      role="tab"
      position="right"
    />
    <ActionButton
      id="program_tab"
      :action="actions.goToProgram"
      :activated="current === 'program'"
      :tabindex="current === 'program' ? 0 : -1"
      class="board-tab-item"
      aria-controls="program_panel"
      role="tab"
      position="right"
    />
    <ActionButton
      id="objectives_tab"
      :action="actions.goToObjectives"
      :activated="current === 'objectives'"
      :tabindex="current === 'objectives' ? 0 : -1"
      class="board-tab-item"
      aria-controls="objectives_panel"
      role="tab"
      position="right"
    />
    <ActionButton
      id="risk_tab"
      :action="actions.goToRisk"
      :activated="current === 'risk'"
      :tabindex="current === 'risk' ? 0 : -1"
      class="board-tab-item"
      aria-controls="risk_panel"
      role="tab"
      position="right"
    />
    <ActionButton
      id="team_tab"
      :action="actions.goToTeam"
      :activated="current === 'team'"
      :tabindex="current === 'team' ? 0 : -1"
      class="board-tab-item"
      aria-controls="team_panel"
      role="tab"
      position="right"
    />
    <ActionButton
      id="flex_tab"
      :action="actions.goToCanvas"
      :activated="current === 'flex'"
      :tabindex="current === 'flex' ? 0 : -1"
      class="board-tab-item"
      aria-controls="flex_panel"
      role="tab"
      position="right"
    />
  </div>
</template>

<style lang="scss" scoped>
.boards-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
