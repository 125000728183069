<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import { selectionActions } from "@/action/selectionActions";
import DeleteMenuItem from "@/components/DeleteMenuItem/DeleteMenuItem.vue";
import ActionButton from "@/components/action/ActionButton.vue";
import ActionSource from "@/components/action/ActionSource.vue";
import { useShortcuts } from "@/composables/useShortcuts";
import { useBoardStore } from "@/store/board";
import { useSelectionStore } from "@/store/selection";

import ShareMenuItem from "./ShareMenuItem.vue";

const actions = selectionActions;

const context = computed(
  () => useBoardStore().baseBoard.contextActions.selection || {},
);

const { registerShortcut } = useShortcuts();
registerShortcut(actions.undo);
registerShortcut(actions.link);

const { t } = useI18n();

const undoText = ref("");

// use a delayed watch instead of a computed value
// because the tooltip popper here is contained inside the selectionActionMenu popper
// updating both at the same time can disturb the position calculation of the selection action popper
watch(
  () => useSelectionStore().lastHistoryEntry,
  (lastEntry) => {
    setTimeout(() => {
      undoText.value = lastEntry
        ? t("action.undoSelectionAction", {
            action: lastEntry.action.name,
            count: lastEntry.stateBeforeAction.length,
          })
        : "";
    }, 100);
  },
);

const trapOptions = {
  extraElements: computed(() => useBoardStore().selectedStickyIds),
};

function canMirror() {
  return useSelectionStore().mirrorTargets.length > 0;
}
</script>

<template>
  <ActionSource
    id="selection-action-menu"
    v-focus-trap="trapOptions"
    value="selection-actions-menu"
    style="display: contents"
  >
    <div v-if="context.stickyMove" style="display: flex">
      <ActionButton :action="actions.alignHorizontal" />
      <ActionButton :action="actions.distributeHorizontal" />
      <ActionButton :action="actions.alignVertical" />
      <ActionButton :action="actions.distributeVertical" />
      <ActionButton :action="actions.lineup" />
    </div>
    <ActionButton
      v-if="context.mirror"
      :action="actions.mirror"
      :disabled="!canMirror()"
    />
    <ActionButton v-if="context.team" :action="actions.setTeam" />
    <ActionButton v-if="context.link" :action="actions.link" />
    <ShareMenuItem />
    <DeleteMenuItem :cards="useBoardStore().selectedStickies" />
    <ActionButton
      :action="actions.undo"
      :tooltip="undoText"
      :disabled="!undoText"
    />
  </ActionSource>
</template>
