<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import { navigationActions } from "@/action/navigationActions";
import { toggleActions } from "@/action/toggleActions";
import TeamObjectivesModal from "@/components/modal/TeamObjectivesModal.vue";
import IconButton from "@/components/ui/IconButton/IconButton.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { cancelLongClick } from "@/components/utils/Gestures";
import { useShortcuts } from "@/composables/useShortcuts";
import type { Objective } from "@/model/objective";
import { useBoardStore } from "@/store/board";
import { useBoardsStore } from "@/store/boards";
import { useLinkStore } from "@/store/link";
import { stopScrollPropagation } from "@/utils/dom/dom";
import { adjustScrollbarWidth } from "@/utils/dom/scrollbarWidth";

import TeamObjectiveRow from "./TeamObjectiveRow.vue";

const { registerShortcut } = useShortcuts();
registerShortcut(toggleActions.showTeamObjectives);

const root = ref();
const selectedObjectiveId = ref("");

onMounted(() => adjustScrollbarWidth(root.value));

const board = computed(() => useBoardsStore().boardByType("team"));
const masterCards = computed(() => [
  useBoardStore().activeCard,
  useLinkStore().markingCardLinkedCards,
]);

const highlightedObjectives = computed(() =>
  masterCards.value.flatMap(
    (card) => card?.data.objectives?.map((obj) => obj.id) || [],
  ),
);

function onScroll() {
  cancelLongClick();
}

function isSelected(objectiveId: Objective["id"]) {
  return (
    selectedObjectiveId.value === objectiveId ||
    highlightedObjectives.value.includes(objectiveId)
  );
}

function editObjectives() {
  navigationActions.openModal("board", TeamObjectivesModal, {
    attrs: { boardId: board.value.id },
  });
}

function toggleObjective(objectiveId: Objective["id"]) {
  if (selectedObjectiveId.value === objectiveId) {
    removeObjective();
  } else {
    selectedObjectiveId.value = objectiveId;
    const linkStore = useLinkStore();
    linkStore.markObjectiveLinkedCards(objectiveId);
  }
}

function blurObjective(event: FocusEvent) {
  if ((event.relatedTarget as Element)?.classList.contains("objective")) {
    return;
  }
  removeObjective();
}

function removeObjective() {
  if (selectedObjectiveId.value !== "") {
    useLinkStore().removeObjectiveLinkedMarks(selectedObjectiveId.value);
    selectedObjectiveId.value = "";
  }
}
</script>

<!-- eslint-disable vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
<template>
  <div ref="root" class="team-objectives" @keydown.esc="removeObjective">
    <div class="header" @click="editObjectives">
      <!-- eslint-enable vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
      <h2 class="header-title h3">
        <SvgIcon
          name="board/objectives"
          width="1.25em"
          height="1.25em"
          aria-hidden="true"
        />
        <span>{{ $t("teamObjectives.teamObjectives") }}</span>
      </h2>
      <IconButton
        id="team-objectives-trigger"
        icon="arrow/expand"
        size="small"
        :tooltip="$t('teamObjectives.expand')"
        :aria-label="$t('label.teamObjectives.expand')"
      />
    </div>
    <div class="scrollable" @wheel="stopScrollPropagation" @scroll="onScroll">
      <div>
        <div class="objective-group-header">
          <h3 class="h4">{{ $t("objectives.committed") }}</h3>
          <span aria-hidden="true">
            <h3 class="link h4">{{ $t("general.links") }}</h3>
            <h3 :title="$t('objectives.businessValue')" class="h4">
              {{ $t("objectives.bv") }}
            </h3>
          </span>
        </div>
        <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions-->
        <div
          v-for="(objective, index) in board.objectives"
          :key="objective.id"
          class="objective"
          @click="toggleObjective(objective.id)"
          @keypress.enter="toggleObjective(objective.id)"
          @keypress.space="toggleObjective(objective.id)"
          @blur="blurObjective"
        >
          <TeamObjectiveRow
            :index="index + 1"
            :objective="objective"
            :team="board.team"
            :selected="isSelected(objective.id)"
          />
        </div>
        <div />
        <div class="objective-group-header">
          <span>
            <h3 class="h4">{{ $t("objectives.uncommitted") }}</h3>
          </span>
          <span aria-hidden="true">
            <h3 class="link h4">{{ $t("general.links") }}</h3>
            <h3 :title="$t('objectives.businessValue')" class="h4">
              {{ $t("objectives.bv") }}
            </h3>
          </span>
        </div>
        <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions-->
        <div
          v-for="(stretchObjective, index) in board.stretchObjectives"
          :key="stretchObjective.id"
          class="objective"
          @click="toggleObjective(stretchObjective.id)"
          @keypress.enter="toggleObjective(stretchObjective.id)"
          @keypress.space="toggleObjective(stretchObjective.id)"
          @blur="blurObjective"
        >
          <TeamObjectiveRow
            :index="index + 1"
            :objective="stretchObjective"
            :team="board.team"
            :selected="isSelected(stretchObjective.id)"
          />
        </div>
        <div />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/styles/board";

.team-objectives {
  .header-title {
    font-size: 60% !important;
    display: inline-flex;
    align-items: center;
    gap: board.len(15px);
  }
}
</style>

<style lang="scss">
@use "@/styles/font";
@use "@/styles/board";
@use "@/styles/colors" as colors-old;
@use "@/styles/variables/a11y" as colors-a11y;
@use "@/styles/variables/colors";

.board-team {
  .team-objectives {
    padding: 0;
    display: flex;
    flex-direction: column;

    .objective-group-header {
      padding: board.len(12px) board.len(24px) 0 board.len(24px);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        color: colors-old.$text-primary-color;
        font-size: board.len(14px);
        font-weight: font.$weight-extra-bold;
      }

      .link {
        margin-right: board.len(38px);
      }
    }

    .header {
      width: 100%;
      color: colors-old.$text-primary-color;
      background-color: colors-old.$back-color;
      padding: board.len(6px) board.len(12px) board.len(6px) board.len(24px);
      border-bottom: board.len(3px) solid colors-a11y.$board-border;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .base-button {
        font-size: inherit;
        padding: board.len(12px);

        svg {
          width: board.len(20px);
          height: board.len(20px);
        }
      }
    }

    .scrollable {
      position: relative;
      padding-bottom: board.len(12px);
    }

    .objective {
      cursor: pointer;
    }
  }
}
</style>
