<script lang="ts" setup>
import { inject, useTemplateRef } from "vue";

import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";

import { MenuListConfigKey } from "../types";

const elRef = useTemplateRef<HTMLElement>("elRef");

withDefaults(
  defineProps<{
    as?: "li" | "div";
    static?: boolean;
    selected?: boolean;
  }>(),
  {
    as: "li",
    static: false,
    selected: false,
  },
);

const listConfig = inject(MenuListConfigKey);

const focus = () => elRef.value?.focus();
defineExpose({ focus });
</script>

<template>
  <component
    :is="as"
    ref="elRef"
    :class="[
      'menu-list-item',
      {
        static,
        selectable: listConfig?.selectable,
        selected,
        multiselect: listConfig?.multiselect,
      },
    ]"
    role="option"
    :aria-selected="selected ? 'true' : 'false'"
    :aria-disabled="static"
    tabindex="-1"
  >
    <div class="wrapper">
      <div v-if="$slots.before" class="before">
        <slot name="before"></slot>
      </div>
      <div class="content">
        <slot />
      </div>
      <template v-if="!listConfig?.multiselect">
        <div v-if="$slots.after" class="after">
          <slot name="after" />
        </div>
      </template>
      <template
        v-if="listConfig?.multiselect && listConfig?.selectable && selected"
      >
        <SvgIcon
          class="selected-icon"
          name="base/check"
          width="20"
          height="20"
        />
      </template>
    </div>
  </component>
</template>

<style lang="scss" scoped>
@use "@/styles/mixins/list";
@use "@/styles/variables/colors";
@use "@/styles/mixins/utils";
@use "@/styles/mixins/typography";

.menu-list-item {
  all: unset;
  flex-shrink: 0;
  outline: revert;
  padding: 2px 4px;

  &.static {
    pointer-events: none;
  }

  .wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 4px;
    overflow: hidden;

    .before,
    .after {
      color: colors.$text-subtle;
      flex-shrink: 0;
      min-width: 20px;
      min-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &:hover,
  :focus-visible {
    .wrapper {
      background-color: colors.$background-grey;

      .before,
      .after {
        color: colors.$text;
      }
    }
  }

  &:active:not(.multiselect) {
    .wrapper {
      background-color: colors.$primary-brand-light;
      color: colors.$primary-brand;

      .before,
      .after {
        color: colors.$primary-brand;
      }
    }
  }

  &.selected.selectable:not(.multiselect) {
    .wrapper {
      background-color: colors.$primary-brand-light-hover;
      color: colors.$primary-brand-hover;
      pointer-events: none;

      .before,
      .after {
        color: colors.$primary-brand;
      }
    }
  }

  .content {
    @include utils.ellipsis;
    @include typography.medium;

    display: block;
    flex-grow: 1;
    height: 20px;
    align-items: center;
  }

  .selected-icon {
    color: colors.$primary-brand;
    flex-shrink: 0;
  }
}
</style>
