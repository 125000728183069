import { defineStore } from "pinia";
import { ref, watch } from "vue";

import type { BoardData } from "@/model/board";
import type { Art, Team } from "@/model/session";
import { LocalStore, versioned } from "@/utils/LocalStore";

import { useSessionStore } from "./session";

type SessionId = string;
type ArtId = string;
type TeamId = string;
type FlexType = string;
type FlexId = string;
type State = Record<
  SessionId,
  {
    artId?: ArtId;
    teamInArt: Record<ArtId, TeamId>;
    flexInType: Record<FlexType, FlexId>;
  }
>;

export const useNavigationStore = defineStore("navigation", () => {
  const store = new LocalStore<State>("navigation", { sedes: versioned(1) });
  const state = ref(load());

  watch(state, () => store.save(state.value), { deep: true });

  function load() {
    const state = store.load() ?? {};
    // stored values may not have flexInType
    for (const session in state) {
      state[session].flexInType = state[session].flexInType ?? {};
    }
    return state;
  }

  function lastSelectedArtId() {
    const session = sessionState();
    return session.artId;
  }

  function lastSelectedTeamId(artId: string) {
    const session = sessionState();
    return session.teamInArt[artId];
  }

  function lastSelectedFlexId(flexTypeId: string) {
    const session = sessionState();
    return session.flexInType[flexTypeId];
  }

  function artSelected(art: Art) {
    const session = sessionState();
    session.artId = art.id;
  }

  function teamSelected(team: Team) {
    const session = sessionState();
    session.teamInArt[team.artId || ""] = team.id;
  }

  function flexSelected(flex: BoardData<"flex">) {
    const session = sessionState();
    session.flexInType[flex.flexType.id] = flex.id;
  }

  function sessionState() {
    const sessionId = useSessionStore().session.current.id;
    if (!(sessionId in state.value)) {
      state.value[sessionId] = { teamInArt: {}, flexInType: {} };
    }
    return state.value[sessionId];
  }

  return {
    lastSelectedArtId,
    lastSelectedTeamId,
    lastSelectedFlexId,
    artSelected,
    teamSelected,
    flexSelected,
  };
});
