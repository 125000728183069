/**
 * usage: ensureResolve(resolver=>{... call resolver() or not ...}) returns a Promise that is guaranteed to resolve
 */
export function ensureResolve(fun: (resolver: () => () => void) => void) {
  return new Promise<void>((resolve) => {
    let used = false;
    fun(() => {
      used = true;
      return resolve;
    });
    if (!used) {
      resolve();
    }
  });
}

/**
 * Execute a function after the first has ended.
 */
export function doAfter(first: any, after: () => void) {
  if (first?.then) {
    first.then(after);
  } else {
    after();
  }
}
