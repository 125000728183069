<script lang="ts" setup>
/**
 * A wrapper component that exposes a single interactive element (button) to the accessibility tree
 * Any slot content is hidden (from screen readers) until the wrapping button is clicked,
 * at which point it is exposed and the user can interact with it
 * User can move between child elements with left/right arrows (tab exits the content)
 *
 * This acts like a dropdown menu that only screen readers can interact with
 * For other users, the inner content is always exposed
 */
import { nextTick, ref } from "vue";

import { keyboardNavigation } from "@/directives/keyboardNavigation";

const props = defineProps<{ ariaLabel?: string }>();

const isOpen = ref<boolean>(false);

const thisElement = ref<HTMLElement | null>(null);

const vKeyboardNavLocal = keyboardNavigation();

const focusableElementSelector = [
  "a[href]",
  "button:not([disabled])",
  "input:not([disabled])",
  "select:not([disabled])",
  "textarea:not([disabled])",
  "[tabindex]",
].join(",");

/**
 * Handle keydown events on the wrapper to open/close the content
 */
const handleKeydown = async (e: KeyboardEvent) => {
  switch (e.key) {
    // Enter/Space directly on the wrapper -> open the content
    case e.target === e.currentTarget && "Enter":
    case e.target === e.currentTarget && " ":
      open();
      break;
    // Escape -> close the content and focus the wrapper
    case "Escape":
      isOpen.value = false;
      await nextTick();
      thisElement.value?.focus();
      break;
    // Passthrough other keys
    default:
      return;
  }
  e.preventDefault();
  e.stopPropagation();
};

/**
 * Handle click events
 * For screen readers, it opens the content
 * For mouse users, it hides the focus ring
 */
const handleClick = (e: MouseEvent) => {
  if (e.target === thisElement.value) {
    // Click on the wrapper -> open the content
    // (screen readers often use click events instead of keyboard events)
    open();
  } else {
    // Click on the content -> hide the focus ring (for mouse users)
    // (content is 100% width/height, so mouse-clicking anywhere will trigger this)
    thisElement.value?.blur();
  }
};

/**
 * Open the content and focus the first tabbable element
 */
const open = () => {
  isOpen.value = true;
  focusFirstElement();
};

/**
 * Reset the state when focus leaves the content
 */
const handleFocusout = (e: FocusEvent) => {
  if (isOpen.value && !thisElement.value?.contains(e.relatedTarget as Node)) {
    isOpen.value = false;
  }
};

/**
 * Focus the first tabbable element in the content
 */
const focusFirstElement = () => {
  thisElement.value
    ?.querySelector<HTMLElement>(focusableElementSelector)
    ?.focus();
};

/**
 * Expose the content when focus enters the wrapper
 * (happens when the user tabs from a sticky to the jump-to-stickies button)
 */
const handleContentFocusin = (e: FocusEvent) => {
  if (!isOpen.value && !thisElement.value?.contains(e.relatedTarget as Node)) {
    isOpen.value = true;
  }
};
</script>

<template>
  <!-- 
    Exposed to accessibility tree as a single button with no content
    On click, the content will be exposed and the user can interact with it
    When focus leaves the content, it will be hidden again (and only the wrapper will be exposed)
  -->
  <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
  <div
    ref="thisElement"
    :role="isOpen ? 'presentation' : 'button'"
    :aria-label="isOpen ? undefined : props.ariaLabel"
    :tabindex="isOpen ? undefined : 0"
    @click="handleClick"
    @keydown="handleKeydown"
    @focusout="handleFocusout"
  >
    <div
      v-keyboard-nav-local.soft-focus.ignore-trigger.horizontal="{
        selector: focusableElementSelector,
      }"
      class="inner-wrapper"
      :aria-hidden="!isOpen"
      @focusin="handleContentFocusin"
    >
      <slot :is-open="isOpen"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.inner-wrapper {
  height: 100%;
}
</style>
