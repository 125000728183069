import { isString } from "lodash-es";

import type { Key } from "@/components/shortcut/key";
import { noKey } from "@/components/shortcut/key";
import { previewDelay } from "@/model/Settings";
import type { ActionSource } from "@/model/trigger";
import { useActionStore } from "@/store/action";
import type { AnalyticsEvent, TrackingParams } from "@/utils/analytics/events";
import { actionEvent } from "@/utils/analytics/events";
import { trackEvent } from "@/utils/analytics/track";
import { doAfter } from "@/utils/async/utils";

import type {
  Action,
  ActionContext,
  ActionData,
  ActionDefinitionData,
  ActionSourceFun,
  ContextFun,
  ExecutionMode,
  Fun,
} from "./types";

/**
 * Create an action from its implementation and metadata.
 */
export function action<TParams extends unknown[], TReturn, TState>(
  impl: Fun<TParams, TReturn>,
  data: ActionDefinitionData<TParams, TState> = {},
): Action<TParams, TReturn, TState> {
  const ctx = actionContext(
    (_, ...params) => impl(...params),
    makeActionData(data),
  );
  return makeAction(ctx.execute, ctx.data);
}

/**
 * Create an action that has ActionContext as first parameter.
 */
export function ctxAction<TParams extends unknown[], TReturn, TState>(
  impl: ContextFun<TParams, TReturn>,
  data: ActionDefinitionData<TParams, TState> = {},
): Action<TParams, TReturn, TState> {
  const ctx = actionContext(impl, makeActionData(data));
  return makeAction(ctx.execute, ctx.data);
}

/**
 * Create an action that can be previewed (using `ActionContext.mode`).
 */
export function previewAction<TParams extends unknown[], TReturn, TState>(
  impl: ContextFun<TParams, TReturn>,
  data: ActionDefinitionData<TParams, TState> = {},
): Action<TParams, TReturn, TState> {
  const ctx = actionContext(impl, makeActionData(data));
  const actionFun = (
    source: ActionSource | ActionContext,
    ...params: TParams
  ) => {
    if (useActionStore().wasPreviewing === actionFun) {
      useActionStore().endPreview();
      ctx.mode = "confirm";
    } else {
      ctx.mode = "normal";
    }
    return ctx.execute(source, ...params);
  };
  ctx.data.startPreview = (...params: TParams) => {
    useActionStore().preview(
      actionFun,
      () => {
        ctx.mode = "preview";
        ctx.execute("internal", ...params);
      },
      previewDelay,
    );
  };
  ctx.data.stopPreview = (...params: TParams) => {
    if (useActionStore().wasPreviewing === actionFun) {
      ctx.mode = "undo";
      doAfter(ctx.execute("internal", ...params), () =>
        useActionStore().endPreview(),
      );
    }
  };
  return makeAction(actionFun, ctx.data);
}

export function assignShortcut(action: Action<any[]>, key: Key) {
  action.data.shortcut = key;
}

/**
 * Create an action without parameters from another action and parameters.
 */
export function applyActionParams<TParams extends unknown[]>(
  action: Action<TParams>,
  ...params: TParams
): Action {
  return makeAction(
    (source: ActionSource | ActionContext) => action(source, ...params),
    action.data,
  );
}

/**
 * Define a group of actions.
 */
export function defineActions<
  T extends { [id in string]: Action<any, any, any> },
>(name: string, actions: T): { [id in keyof T]: T[id] } {
  Object.entries(actions).forEach(([id, action]) => {
    action.data.id = name + "." + id;
  });
  return actions as unknown as { [id in keyof T]: T[id] };
}

type InternalActionContext<
  TParams extends unknown[],
  TReturn,
  TState,
> = ActionContext & {
  params?: TParams;
  data: ActionData<TParams, TState>;
  execute: ActionSourceFun<TParams, TReturn>;
};

function actionContext<TParams extends unknown[], TReturn, TState>(
  impl: ContextFun<TParams, TReturn>,
  data: ActionData<TParams, TState>,
): InternalActionContext<TParams, TReturn, TState> {
  const context: InternalActionContext<TParams, TReturn, TState> = {
    tracked: false,
    data,
    source: "internal" as ActionSource,
    mode: "normal" as ExecutionMode,
    track(arg: AnalyticsEvent | TrackingParams | null) {
      if (arg) {
        trackAction(data, this.source, this.params!, arg);
      }
      this.tracked = true;
    },
    execute(source: ActionSource | ActionContext, ...params: TParams) {
      context.source = isString(source) ? source : source.source;
      context.params = params;
      context.tracked = false;
      const res = impl(context, ...params);
      if (!context.tracked) {
        context.track({});
      }
      // an action calls another action with its context -> don't track the first action
      if (!isString(source)) {
        source.tracked = true;
      }
      return res;
    },
  };
  return context;
}

function makeAction<TParams extends unknown[], TReturn, TState>(
  fun: ActionSourceFun<TParams, TReturn>,
  data: ActionData<TParams, TState>,
): Action<TParams, TReturn, TState> {
  const res = fun as Action<TParams, TReturn, TState>;
  res.data = data;
  return res;
}

function makeActionData<TParams extends unknown[], TState>(
  data: ActionDefinitionData<TParams, TState>,
): ActionData<TParams, TState> {
  return {
    id: "",
    name: data.name || "",
    history: data.history,
    shortcut: noKey,
    icon: data.icon,
  };
}

const ignoreActions = new Array<string>();
const lastActionId: Record<string, string> = {};

function trackAction<TParams extends unknown[]>(
  data: ActionData<any, any>,
  source: ActionSource,
  params: TParams,
  track: AnalyticsEvent | TrackingParams,
) {
  if (source !== "internal" && !ignoreActions.includes(data.id)) {
    if (data.history?.merge) {
      const id = data.history.saveState(...params).id;
      if (id !== lastActionId[data.id]) {
        lastActionId[data.id] = id;
        sendEvent();
      }
    } else {
      sendEvent();
    }
  }

  function sendEvent() {
    trackEvent(
      "event" in track
        ? (track as AnalyticsEvent)
        : actionEvent({ eventName: data.id, source, params: track }),
    );
  }
}
