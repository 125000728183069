import { applyActionParams } from "@/action/actions";
import { boardActions } from "@/action/boardActions";
import { toggleActions } from "@/action/toggleActions";
import type { Action } from "@/action/types";
import type { BoardType } from "@/model/baseTypes";
import type { BoardData } from "@/model/board";
import type { Card } from "@/model/card";
import type { RelativeCoordinate } from "@/model/coordinates";
import type { StickyType } from "@/model/stickyType";
import { useDrawStore } from "@/store/draw";
import { useUserStore } from "@/store/user";

import type { BoardLocation } from "./location/BoardLocation";
import { fullBoardLocation } from "./location/BoardLocation";
import type { GroupIterations } from "./location/PlanningBoardLocation";

interface ContextActions {
  board?: BoardContextActions;
  selection?: SelectionContextActions;
}

interface BoardContextActions {
  syncProgramBacklog?: Action;
  draw?: Action;
}

interface SelectionContextActions {
  stickyMove?: boolean;
  link?: boolean;
  mirror?: boolean;
  team?: boolean;
}

interface PositionalActions {
  region?: RegionContextActions;
}

interface RegionContextActions {
  name: string;
  arrange?: Action;
  overview?: Action;
  sync?: Action;
  zoom?: Action;
}

/* eslint-disable @typescript-eslint/class-literal-property-style */
export abstract class BaseBoard<T extends BoardType> {
  constructor(protected board: BoardData<T>) {}

  get groups(): GroupIterations[] {
    return [];
  }

  location(_pos: RelativeCoordinate | number, _top?: number): BoardLocation {
    return fullBoardLocation;
  }

  positionalCardProperties(_pos: RelativeCoordinate): Partial<Card> {
    return {};
  }

  get showPermanentLinks() {
    return false;
  }

  get showRiskyLinks() {
    return false;
  }

  abstract get possibleStickyTypes(): StickyType[];

  get creatableStickyTypes(): StickyType[] {
    return this.possibleStickyTypes;
  }

  abstract get contextActions(): ContextActions;

  positionalActions(_pos: RelativeCoordinate): PositionalActions {
    return {};
  }

  drawAction(): Action | undefined {
    if (
      useUserStore().isAllowed("editBoard") &&
      useDrawStore().isSupportedBoard(this.board.type)
    ) {
      return toggleActions.showDraw;
    }
  }

  arrangeAction(loc: BoardLocation): Action | undefined {
    if (useUserStore().isAllowed("edit")) {
      return applyActionParams(
        boardActions.confirmArrange,
        this.board.id,
        loc.index(),
      );
    }
  }

  zoomAction(loc: BoardLocation): Action | undefined {
    return applyActionParams(boardActions.zoomToRegion, loc.bounds);
  }
}
