import { sortBy } from "lodash-es";
import { defineStore } from "pinia";

import { captureMessage } from "@/error/sentry";
import type { Id } from "@/model/baseTypes";
import type { Board, BoardId, BoardWithObjectives } from "@/model/board";
import {
  isArtBoard,
  isBacklogBoard,
  isSolutionBoard,
  isTeamBoard,
} from "@/model/board";
import type { Card } from "@/model/card";
import type { LinkableCardTree } from "@/model/link";
import type {
  Objective,
  ObjectiveType,
  ObjectiveUpdate,
} from "@/model/objective";
import type { Team } from "@/model/session";
import { isDependency, isNote } from "@/model/stickyType";
import { objectId } from "@/utils/objectId";

import { useBoardStore } from "./board";
import { useBoardsStore } from "./boards";
import { isCardFromTeam, useCardStore } from "./card";
import { getLinkTargetId, useLinkStore } from "./link";

export const useObjectiveStore = defineStore("objective", {
  getters: {
    objectiveById() {
      return (
        objectiveId: Objective["id"],
        {
          teamId,
          boardId,
        }: { teamId?: Team["id"]; boardId?: Board["id"] } = {},
      ): Objective | undefined => {
        const board = boardId
          ? useBoardsStore().boardById(boardId)
          : useBoardsStore().boardByType("team", { teamId });

        const objectives = [
          ...(("objectives" in board && board.objectives) || []),
          ...(("stretchObjectives" in board && board.stretchObjectives) || []),
        ];

        return objectives.find((objective) => objective.id === objectiveId);
      };
    },
    objectiveTypeById() {
      return (
        objectiveId: Objective["id"],
        {
          teamId,
          boardId,
        }: { teamId?: Team["id"]; boardId?: Board["id"] } = {},
      ): ObjectiveType | undefined => {
        const objective = this.objectiveById(objectiveId, { teamId, boardId });
        if (!objective) return;
        const board = useBoardsStore().boardByType("team", { teamId });
        const isInObjectives = board.objectives.some(
          (objective) => objective.id === objectiveId,
        );
        return isInObjectives ? "committed" : "uncommitted";
      };
    },
    linkedCardTree() {
      return (objective: Objective): LinkableCardTree => {
        const indirectIds = new Set<string>();
        const cards = sorted(
          objective.cards.flatMap((ref) => {
            const card = useCardStore().cards[ref.id];
            if (!card || !ref.isOrigin) {
              return [];
            }
            const childIds = linkedToCard(card).map((card) => card.id);
            childIds.forEach((id) => indirectIds.add(id));
            return { ...card, linked: true, childIds };
          }),
        );
        cards.forEach((card) => indirectIds.delete(card.id));

        const count = cards.length + indirectIds.size;
        return { cards, indirectLinkedIds: [...indirectIds], count };

        function linkedToCard(card: Card) {
          // Only show linked items that are 1 'level' below in the hierarchy,
          if (
            isNote(card) || // notes are never above items in hierarchy
            (isTeamBoard(card.type.origin) && !isDependency(card)) // team board workitems/risks are at the bottom of the hierarchy
          ) {
            return [];
          }

          return sorted(
            useLinkStore()
              .linksByCard(card.id)
              .map((link) => useCardStore().cards[getLinkTargetId(card, link)])
              .filter((linkedCard) =>
                shouldShowSecondaryLink(card, linkedCard),
              ),
          );
        }

        function shouldShowSecondaryLink(card: Card, secondaryCard?: Card) {
          if (!secondaryCard) return false;

          if (isDependency(card)) {
            return isCardFromTeam(secondaryCard, card.dependTeam?.id);
          }

          const board = card.type.origin;

          if (isSolutionBoard(board)) {
            // For solution-level items, show art/team level linked-items
            return !isSolutionBoard(secondaryCard.type.origin);
          }

          if (isArtBoard(board)) {
            // For art-level items, show team level linked-items
            return isTeamBoard(secondaryCard.type.origin);
          }

          return false;
        }

        function sorted<T extends Card>(cards: T[]) {
          return sortBy(cards, (card) =>
            isBacklogBoard(card.type.origin) ? 1 : isDependency(card) ? 2 : 3,
          );
        }
      };
    },
  },
  actions: {
    add(e: Partial<Objective> & BoardId, isCommitted = true) {
      const board = useBoardsStore().boards[e.boardId] as BoardWithObjectives;
      const id = e.id || objectId();
      const text = e.text ? e.text : "";
      const description = e.description ? e.description : "";
      const obj = { id, text, bv: 0, av: null, description, cards: [] };
      (isCommitted ? board.objectives : board.stretchObjectives).unshift(obj);
      return obj;
    },
    remove(item: Id & BoardId) {
      const boardId = item.boardId || useBoardStore().boardId();
      const board = useBoardsStore().boards[boardId] as BoardWithObjectives;
      const objectiveIndex = board.objectives.findIndex(
        (o) => o.id === item.id,
      );

      if (objectiveIndex > -1) {
        board.objectives.splice(objectiveIndex, 1);
      }

      const stretchObjectiveIndex = board.stretchObjectives.findIndex(
        (o) => o.id === item.id,
      );
      if (stretchObjectiveIndex > -1) {
        board.stretchObjectives.splice(stretchObjectiveIndex, 1);
      }
    },
    move(e: Id & BoardId & { stretch: boolean; rank: number }) {
      const boardId = e.boardId || useBoardStore().boardId();
      const board = useBoardsStore().boards[boardId] as BoardWithObjectives;
      const { objective, objectives, pos } = objectiveById(board, e.id);
      if (!objective) {
        void captureMessage("Move of unknown objective", { info: { data: e } });
        return;
      }
      objectives.splice(pos, 1);
      const target = e.stretch ? board.stretchObjectives : board.objectives;
      target.splice(e.rank, 0, objective);
    },
    update(e: Id & BoardId & ObjectiveUpdate) {
      const board = useBoardsStore().boards[e.boardId] as BoardWithObjectives;
      const { objective } = objectiveById(board, e.id);
      if (!objective) {
        if (e.text === "") {
          this.add(e);
        } else {
          void captureMessage("Update of unknown objective", {
            info: { data: e },
          });
        }
        return;
      }

      objective.text = e.text ?? objective.text;
      objective.description = e.description ?? objective.description;
      objective.bv = e.bv ?? objective.bv;
      objective.av = e.av ?? objective.av;
      objective.cards = e.cards || objective.cards;
    },
  },
});

function objectiveById(
  board: BoardWithObjectives,
  id: string,
): {
  objective: Objective;
  objectives: Objective[];
  pos: number;
} {
  let objectives = board.objectives;
  let pos = objectives.findIndex((o) => o.id === id);
  if (pos < 0) {
    objectives = board.stretchObjectives;
    pos = objectives.findIndex((o) => o.id === id);
  }
  return { objective: objectives[pos], objectives, pos };
}
