import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24",
  role: "presentation"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M18.432 4a1.57 1.57 0 0 0-1.108.46L5.257 16.525l-.832 3.049 3.048-.832L19.542 6.676A1.568 1.568 0 0 0 18.432 4m-1.365-1.728a3.568 3.568 0 0 1 3.888 5.818L8.7 20.345a1 1 0 0 1-.444.258l-4.993 1.362a1 1 0 0 1-1.228-1.228l1.362-4.993a1 1 0 0 1 .258-.444L15.91 3.045a3.6 3.6 0 0 1 1.157-.773",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }