import { nextTick } from "vue";

import { removeBoardScrollMargin } from "@/math/coordinate-systems";
import { fakeZoom } from "@/model/Settings";
import type { WindowCoordinate } from "@/model/coordinates";
import type { ZoomedSize } from "@/model/size";
import { pushZoomState } from "@/router/navigation";
import { useBoardStore } from "@/store/board";
import { useBoardSizeStore } from "@/store/boardSize";
import { useZoomStore } from "@/store/zoom";

export function applyZoom(
  zoom: number,
  scrollTo?: { target: WindowCoordinate; smooth?: boolean; push?: boolean },
) {
  if (!useBoardStore().currentBoard().cardSize) {
    return;
  }
  useZoomStore().setZoomFactor(zoom);
  // wait for the zoom to update the DOM before calculating the coordinates
  void nextTick(() => {
    if (scrollTo) {
      window.scrollTo({
        left: scrollTo.target.x,
        top: scrollTo.target.y,
        behavior: scrollTo.smooth ? "smooth" : "auto",
      });
      if (scrollTo.push) {
        pushZoomState();
      }
    } else {
      window.scrollTo({
        left: (document.documentElement.scrollWidth - window.innerWidth) / 2,
        top: (document.documentElement.scrollHeight - window.innerHeight) / 2,
        behavior: "auto",
      });
    }
  });
}

export function applyFluidBoardSize(size: ZoomedSize) {
  const rightBottom = useBoardSizeStore().rightBottomMargin;
  applyBoardSize({
    scale: size.zoom / fakeZoom,
    top: size.top + "px",
    left: size.left + "px",
    height: size.height + "px",
    width: size.width + "px",
    right: rightBottom.x + "px",
    bottom: rightBottom.y + "px",
  });
}

export function applyFullBoardSize() {
  // Board needs to be downscaled, so we follow the pattern of fluid boards:
  // - first scale down x 0.1 here then scale back up x 10 in styling of individual full sized board
  // We need to keep this behavior to avoid bad-looking zooming in and out when switching
  // between boards during transition animation.
  applyBoardSize({
    scale: 1 / fakeZoom,
    top: "0",
    left: "0",
    height: "100vh",
    width: "100%",
    bottom: "0",
    right: "0",
  });
}

function applyBoardSize(size: {
  scale: number;
  top: string;
  left: string;
  height: string;
  width: string;
  bottom: string;
  right: string;
}) {
  const boardElem = document.getElementById("boards");
  const borderElem = document.getElementById("boards-border");
  if (boardElem && borderElem) {
    const boardStyle = boardElem.style;
    boardStyle.transform = `scale(${size.scale})`;
    boardStyle.top = size.top;
    boardStyle.left = size.left;
    boardStyle.height = size.height;
    boardStyle.width = size.width;

    const borderStyle = borderElem.style;
    borderStyle.transform = `scale(${1 / size.scale})`;
    borderStyle.height = size.bottom;
    borderStyle.width = size.right;
  }
}

export function keepBoardScrollPos(action: () => void) {
  const scrollPos = window.scrollX - useBoardSizeStore().size.left;
  action();
  nextTick(() => {
    window.scrollTo({ left: scrollPos + useBoardSizeStore().size.left });
    pushZoomState();
  });
}

export function resetBoardScrollMargin() {
  if (!useBoardSizeStore().scrollableMarginLocked) {
    keepBoardScrollPos(removeBoardScrollMargin);
  } else {
    pushZoomState();
  }
}
