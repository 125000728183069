<script lang="ts" setup>
import { cardActions } from "@/action/cardActions";
import ActionMenuItem from "@/components/ActionMenu/ActionMenuItem.vue";
import SvgIcon from "@/components/ui/SvgIcon/SvgIcon.vue";
import { useLinkStore } from "@/store/link";

const handleDuplicate = () => {
  cardActions.duplicate("action-menu");
  useLinkStore().removeAllMarks();
};
</script>

<template>
  <ActionMenuItem
    name="duplicate"
    :tooltip-text="$t('cardAction.duplicate')"
    :aria-label="$t('label.actionMenu.duplicate')"
    @click="handleDuplicate"
  >
    <SvgIcon name="action/duplicate" width="20" height="20" />
  </ActionMenuItem>
</template>
