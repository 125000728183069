import { groupBy, mapValues, sortBy } from "lodash-es";

import type {
  FieldChange,
  StickyChange,
  StickyChangeKind,
} from "@/model/change";
import { stickyActivityKeys } from "@/model/change";
import { atDayStart } from "@/utils/date";

// StickyChange with properties related to how it should be rendered
interface DisplayableStickyChange extends StickyChange {
  expandable: boolean;
}

interface StickyChangesByDay {
  date: Date;
  changes: DisplayableStickyChange[];
}

export interface StickyChangesBySticky {
  id: string;
  changes: StickyChangesByKind;
}

type StickyChangesByKind = Record<StickyChangeKind, StickyChange[]>;

export function groupByDay(changes: StickyChange[]): StickyChangesByDay[] {
  return toByDayList(groupByDayStart(changes));
}

export function groupBySticky(
  changes: StickyChange[],
): StickyChangesBySticky[] {
  return toByStickyList(
    mapValues(groupByStickyId(sortFields(changes)), groupByKind),
  );
}

function toByStickyList(
  obj: Record<string, StickyChangesByKind>,
): StickyChangesBySticky[] {
  return Object.entries(obj).map(([id, changes]) => ({ id, changes }));
}

function toByDayList(
  obj: Record<string, StickyChange[]>,
): StickyChangesByDay[] {
  return Object.entries(obj).map(([date, changes]) => ({
    date: new Date(+date),
    changes: changes.map(makeDisplayable),
  }));
}

function sortFields(changes: StickyChange[]): StickyChange[] {
  return changes.map((change) => ({
    ...change,
    fields: sortFieldChanges(change.fields),
  }));
}

function sortFieldChanges(fields: FieldChange[]): FieldChange[] {
  return sortBy(fields, (field) =>
    Object.values(stickyActivityKeys).indexOf(field.name),
  );
}

function groupByDayStart(changes: StickyChange[]) {
  return groupBy(changes, (change) => atDayStart(change.timestamp).getTime());
}

function groupByStickyId(changes: StickyChange[]) {
  return groupBy(changes, (change) => change.stickyId);
}

function groupByKind(changes: StickyChange[]) {
  return groupBy(changes, (change) => change.kind) as StickyChangesByKind;
}

function makeDisplayable(change: StickyChange) {
  return {
    ...change,
    expandable: isExpandable(change),
  };
}

function isExpandable(change: StickyChange) {
  return ["update", "mirror", "unmirror", "link", "unlink"].includes(
    change.kind,
  );
}
